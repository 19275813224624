import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import axiosClient from 'utils/axios/axiosClient';
import AxiosDelete from 'utils/axios/axiosDelete';
import AxiosPost from 'utils/axios/axiosPost';

interface TypeProps {
  nohinDt: string | null;
  binCd: string | null;
  n2Atesaki1: string | null;
  sendAdr: string | null;
}

export function useGetStockDetails() {
  const getStock = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      sendAdr,
    };
    return await AxiosPost(API_URL.GET_MONITORDELIVERY, params);
  };

  const mutation = useMutation(getStock as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface TypePropsStockReceive {
  NyukaDt: string | null;
}

export function useGetStockReceive() {
  const getStockReceive = async ({ NyukaDt }: TypePropsStockReceive) => {
    const params = {
      NyukaDt,
    };
    return await AxiosPost(API_URL.GET_STOCK_RECEIVE, params);
  };

  const mutation = useMutation(getStockReceive as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useUpdateStockReceive() {
  const updateStockReceive = async ({ arr }: any) => {
    return await axiosClient.post(API_URL.UPDATE_STOCK_RECEIVE, arr, {
      responseType: 'blob',
    });
  };

  const mutation = useMutation(updateStockReceive as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useCreateStockReceive() {
  const createStockReceive = async ({ arr }: any) => {
    return await axiosClient.post(API_URL.CREATE_STOCK_RECEIVE, arr, {
      responseType: 'blob',
    });
  };

  const mutation = useMutation(createStockReceive as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface CheckZaikoProps {
  meisai: Array<{ code24: string; suryo: number }>;
}
export function useCheckStockZaiko() {
  const checkZaiko = async ({ meisai }: CheckZaikoProps) => {
    return await AxiosPost(API_URL.CHECK_STOCK_ZAIKO, meisai);
  };

  const mutation = useMutation(checkZaiko as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface BacklogDeletionProps {
  trokno: string;
  cd24: string;
  hatchubi: string;
}
export function useGetBacklogInfo() {
  const getBacklogInfo = async (request: BacklogDeletionProps) => {
    return await AxiosPost(API_URL.BACKLOG_DELETION, request);
  };

  const mutation = useMutation(getBacklogInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useBacklogDelete() {
  const deleteBacklog = async ({ arr }: any) => {
    return await AxiosDelete(API_URL.BACKLOG_DELETION, {}, arr);
  };

  const mutation = useMutation(deleteBacklog as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
