import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  syohinCd: string | null;
  syohinNm: string | null;
}

export function useGetStockInquiry() {
  const getStockInquiry = async ({ syohinCd = '', syohinNm = '' }: any) => {
    const params = {
      syohinCd,
      syohinNm,
    };
    return await AxiosPost(API_URL.GET_STOCK_VIEW, params);
  };

  const mutation = useMutation(getStockInquiry as any, {
    onMutate: (data: any) => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetStockInquiryExcel() {
  const getStockInquiryExcel = async ({
    syohinCd = null,
    syohinNm = null,
  }: TypeProps) => {
    const params = {
      syohinCd,
      syohinNm,
    };
    return await AxiosGetFile(API_URL.GET_STOCK_VIEW_XLSX, params);
  };

  const mutation = useMutation(getStockInquiryExcel as any, {
    onMutate: (data: any) => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
