import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ConfigProvider } from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { registerCellType, NumericCellType } from 'handsontable/cellTypes';

import { registerPlugin, UndoRedo } from 'handsontable/plugins';

registerCellType(NumericCellType);
registerPlugin(UndoRedo);
const queryClient = new QueryClient();
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ja_JP}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
          {/* <ReactQueryDevtools /> */}
        </BrowserRouter>
      </ConfigProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
