import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonWrapper = styled(Button)`
  width: 100%;
  height: 100%;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.5);
  }
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /* box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.5); */
  /* &:hover {
    opacity: 0.8;
  } */
  &:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
`;

export const MenuItemWrapper = styled.div.attrs(
  (props: { backgroundColor?: string }) => props,
)`
  background-color: ${props => props.backgroundColor || 'white'};
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  &:hover {
    filter: brightness(115%);
  }
`;

export const MenuTitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

export const MenuExtraText = styled.div`
  text-align: center;
  font-size: 13px;
`;
