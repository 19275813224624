import { Col, Divider, Row } from 'antd';
import { iDefinedValues } from 'api/common';
import MenuItem from 'components/MenuItem';
import { PATH } from 'configs/routes';
import { TEXT_ALL } from 'constants/text';
import { useSelector } from 'react-redux';
import { selectDefinedValues, selectUserInfo } from 'slice/app';
import { MenuContainer, MenuWrapper } from './top.style';

const Top = () => {
  const userInfo = useSelector(selectUserInfo);
  const definedValues: iDefinedValues = useSelector(selectDefinedValues);

  const masterMenuList = [
    {
      title: 'カレンダーメンテ',
      extraText: '各種カレンダーを登録する場合',
      textToolTip: '各種カレンダー及び締め時間を登録',
      backgroundColor: '#9ff69f',
      path: PATH.CALENDAR_MAINTE,
    },
    {
      title: 'お知らせメンテ',
      extraText: 'メニュー画面に表示されるお知らせ情報を修正する場合',
      textToolTip: 'メニュー画面に表示するお知らせの登録',
      backgroundColor: '#9ff69f',
      path: PATH.OSIRASE_MAINTE,
    },
    {
      title: '取引先メンテ',
      extraText: '取引先を登録、修正する場合',
      textToolTip: '取引先情報の登録',
      backgroundColor: '#9ff69f',
      path: PATH.TORIHIKISAKI_MAINTE,
    },
    {
      title: '商品メンテ',
      extraText: '商品を登録、修正する場合',
      textToolTip: '商品情報の登録',
      backgroundColor: '#9ff69f',
      path: PATH.SYOHIN_MAINTE,
    },
    {
      title: '班・お届け先(便)メンテ',
      extraText:
        '取引先の班・お届け先を登録、修正する場合(取引先メンテで登録後)',
      textToolTip: '班・お届け先(便)情報の登録',
      backgroundColor: '#9ff69f',
      path: PATH.HANOTODOKESAKI_MAINTE,
    },
    {
      title: 'マスタチェック',
      extraText: 'マスタの整合性チェック',
      textToolTip: 'マスタの整合性チェック',
      backgroundColor: '#FFAE5D',
      path: PATH.MASTER_CHECK,
    },
    {
      title: 'ご注文担当メンテ',
      extraText: '取引先の担当者を登録、修正する場合(取引先メンテで登録後)',
      textToolTip: 'ご注文担当の登録',
      backgroundColor: '#9ff69f',
      path: PATH.TANTOU_MAINTE,
    },
  ];

  const shippingMenuList = [
    {
      title: '出荷訂正',
      extraText: '出荷情報を訂正する場合',
      textToolTip: '出荷情報を訂正する場合',
      backgroundColor: '#a8bcff',
      path: PATH.SHIPPING_CORRECTION,
    },
    {
      title: '返品入力',
      extraText: '返品情報を入力する場合',
      textToolTip: '返品情報を入力する場合',
      backgroundColor: '#a8bcff',
      path: PATH.RETURN_INPUT,
    },
  ];

  const stockRefillMenuList = [
    {
      title: '発注入力',
      extraText: '発注情報を入力する場合',
      textToolTip: '発注情報を入力する場合',
      backgroundColor: '#ffa8a8',
      path: PATH.INPUT_STOCKREFILLORDER,
    },
    {
      title: '入荷入力',
      extraText: '入荷情報を入力する場合',
      textToolTip: '入荷情報を入力する場合',
      backgroundColor: '#ffa8a8',
      path: PATH.CREATE_STOCKREFILLRECEIVE,
    },
    {
      title: '注残削除処理',
      extraText: '注残削除処理',
      textToolTip: '注残削除処理',
      backgroundColor: '#ffa8a8',
      path: PATH.BACKLOG_DELETION,
    },
  ];

  const stockViewMenuList = [
    {
      title: '在庫問合せ',
      extraText: '在庫情報を確認する場合',
      textToolTip: '在庫情報を確認する場合',
      backgroundColor: '#cba8ff',
      path: PATH.STOCK_INQUIRY,
    },
    {
      title: '棚卸用一覧リスト',
      extraText: '棚卸を準備する場合',
      textToolTip: '棚卸を準備する場合',
      backgroundColor: '#cba8ff',
      path: PATH.INVENTORYLIST,
    },
  ];

  const kensakuMenuList = [
    {
      title: '情報検索',
      extraText: '',
      textToolTip: '情報検索',
      backgroundColor: '#e571f5',
      path: PATH.WEBSEARCH_MENU,
    },
  ];

  const warehouseMenuList = [
    {
      title: '出荷訂正',
      extraText: '出荷情報を訂正する場合',
      textToolTip: '出荷情報を訂正する場合',
      backgroundColor: '#a8bcff',
      path: PATH.SHIPPING_CORRECTION,
    },
    {
      title: '在庫問合せ',
      extraText: '在庫情報を確認する場合',
      textToolTip: '在庫情報を確認する場合',
      backgroundColor: '#a8bcff',
      path: PATH.STOCK_INQUIRY,
    },
  ];

  const renderMenu = () => {
    switch (userInfo.torihikicd) {
      case definedValues.torihikiCd_YHK:
        return (
          <>
            <Row gutter={[24, 12]}>
              {masterMenuList.map((menuItem, idx) => (
                <Col key={idx} span={12}>
                  <MenuItem
                    autoFocus={idx === 0}
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
            <Divider style={{ margin: '10px 0px', borderColor: '#868686' }} />
            <Row gutter={[24, 12]}>
              {shippingMenuList.map((menuItem, idx) => (
                <Col key={idx} span={12}>
                  <MenuItem
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
            <Divider style={{ margin: '10px 0px', borderColor: '#868686' }} />
            <Row gutter={[24, 12]}>
              {stockRefillMenuList.map((menuItem, idx) => (
                <Col span={12}>
                  <MenuItem
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
            <Divider style={{ margin: '10px 0px', borderColor: '#868686' }} />
            <Row gutter={[24, 12]}>
              {stockViewMenuList.map((menuItem, idx) => (
                <Col key={idx} span={12}>
                  <MenuItem
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
            <Divider style={{ margin: '10px 0px', borderColor: '#868686' }} />
            <Row gutter={[24, 12]}>
              {kensakuMenuList.map((menuItem, idx) => (
                <Col key={idx} span={12}>
                  <MenuItem
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
          </>
        );

      case definedValues.torihikiCd_soko:
        return (
          <>
            <Row gutter={[24, 12]}>
              {warehouseMenuList.map((menuItem, idx) => (
                <Col key={idx} span={12}>
                  <MenuItem
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
            <Divider style={{ margin: '10px 0px', borderColor: '#868686' }} />
            <Row gutter={[24, 12]}>
              {kensakuMenuList.map((menuItem, idx) => (
                <Col key={idx} span={12}>
                  <MenuItem
                    backgroundColor={menuItem.backgroundColor}
                    title={menuItem.title}
                    extraText={menuItem.extraText}
                    path={menuItem.path}
                  />
                </Col>
              ))}
            </Row>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <MenuContainer size={10} direction="vertical">
      <MenuWrapper>
        <span>
          {userInfo.kaisya && userInfo.kaisya}
          {userInfo.tensyo && userInfo.tensyo}
          {TEXT_ALL.SAMA}
        </span>
        <span>毎度お引き立てありがとうございます。</span>
      </MenuWrapper>
      {renderMenu()}
    </MenuContainer>
  );
};

export default Top;
