import { Space } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectIsLoggedIn } from 'slice/app';
import { PATH } from 'configs/routes';

const NotFoundPage = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    isLoggedIn
      ? navigate(PATH.TOP, { replace: true })
      : navigate(PATH.LOGIN, { replace: true });
  }, [isLoggedIn, navigate]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Space direction="vertical">
        <span
          style={{ fontSize: '4rem', fontWeight: 700, textAlign: 'center' }}
        >
          404
        </span>
        <span
          style={{ fontSize: '1rem', fontWeight: 500, textAlign: 'center' }}
        >
          Not Found
        </span>
        <span
          style={{ fontSize: '1rem', fontWeight: 500, textAlign: 'center' }}
        >
          The requested URL was not found on this server.
        </span>
      </Space>
    </div>
  );
};

export default NotFoundPage;
