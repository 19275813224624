import { LoadingWrapper } from './LoadingCallAPI.style';

const LoadingCallAPI = () => {
  return (
    <LoadingWrapper>
      <div className="spinner-box">
        <div className="three-quarter-spinner"></div>
      </div>
    </LoadingWrapper>
  );
};

export default LoadingCallAPI;
