import { Button, Col, Space } from 'antd';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import { useRePrintShipping } from 'api/shipping';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import { memo, useEffect, useRef } from 'react';
import messagePopup from 'utils/message/mesage';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';
import { useLogAccess } from 'api/auth';
import { selectUserInfo } from 'slice/app';
import { useSelector } from 'react-redux';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  oldShippingInformation?: any;
  onResetPage?: any;
  afterClose?: any;
}

const columns = [
  {
    title: 'Ｎｏ．',
    dataIndex: 'index',
    width: 70,
    key: 'index',
    render: (_value: any) => (
      <div style={{ textAlign: 'center' }}>{_value + 1}</div>
    ),
  },
  {
    title: '品名ＣＤ',
    dataIndex: 'syohinCd',
    width: 150,
    key: 'syohinCd',
  },
  {
    title: '品名',
    dataIndex: 'nnm',
    width: 475,
    key: 'nnm',
    render: (_value: any, record: any) => (
      <div style={{ lineHeight: 1 }}>
        {_value}
        {record.nnmsz}
      </div>
    ),
  },
  {
    title: '数量',
    dataIndex: 'bara',
    width: 85,
    key: 'bara',
    render: (_value: any) => (
      <div style={{ textAlign: 'right' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
];

const ShippingConfirmReprint = ({
  isModalVisible,
  setIsModalVisible,
  oldShippingInformation,
  afterClose,
}: Props) => {
  const userInfo = useSelector(selectUserInfo);
  const btnSubmitRef = useRef<any>(null);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const { mutate: rePrintShipping, isLoading: loadingRePrintShipping } =
    useRePrintShipping();

  const onSubmit = () => {
    const { naibuNo, denNo, denKbn, location } = oldShippingInformation?.header;

    messagePopup({
      type: 'question',
      content: '登録します。よろしいですか？',
      onOk: () => {
        rePrintShipping(
          { naibuNo, denNo, denKbn, location },
          {
            onSuccess(data, variables, context) {
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '出荷訂正',
                  pgName: '出荷訂正確認',
                  logType: '2',
                  action: '伝票再発行を実行',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: MSG_ERROR['MSG_REPRINT_SUCCESS'],
                onOk: () => setIsModalVisible(false),
              });
            },
            onError(error, variables, context) {
              messagePopup({
                type: 'error',
                content:
                  MSG_ERROR[(error as any)?.response?.data?.message] ||
                  error?.response?.data?.message,
              });
            },
          },
        );
      },
    });
  };

  useEffect(() => {
    isModalVisible &&
      setTimeout(() => {
        btnSubmitRef?.current?.focus();
      }, 0);
  }, [isModalVisible]);

  return (
    <ModalCustom
      titleModal="出荷訂正確認"
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        afterClose();
      }}
      hideButtonClose={true}
    >
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>出荷内容をご確認ください</h2>
          <DetailsOrder style={{ width: 780 }}>
            <DetailsOrderItem span={24}>
              <Label>受付Ｎｏ</Label>
              <Content>
                <b style={{ fontSize: 20 }}>
                  {oldShippingInformation?.header?.orderNo}
                </b>
              </Content>
            </DetailsOrderItem>
            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>得意先</Label>
                <Content>
                  {oldShippingInformation?.header?.torihikiCd}
                  {'　'}
                  {oldShippingInformation?.header?.kaisya}
                </Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>お届け先</Label>
                <Content>
                  <span>{oldShippingInformation?.header?.sendTo}</span>
                  <span>{oldShippingInformation?.header?.sendAdrs}</span>
                </Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>便</Label>
                <Content>
                  <span>
                    {oldShippingInformation?.header?.binCd}{' '}
                    {oldShippingInformation?.header?.binnm}
                  </span>
                </Content>
              </DetailsOrderItem>
            </Col>
            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>内部Ｎｏ</Label>
                <Content>{oldShippingInformation?.header?.naibuNo}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>伝票区分</Label>
                <Content>{oldShippingInformation?.header?.denKbn}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>伝票№</Label>
                <Content>{oldShippingInformation?.header?.denNo}</Content>
              </DetailsOrderItem>
              {oldShippingInformation?.header?.denKbn === '11' || (
                <DetailsOrderItem span={24}>
                  <Label>入荷日</Label>
                  <Content>
                    <span>
                      {moment(
                        oldShippingInformation?.header?.nyukaDt,
                        'YYMMDD',
                      ).format('YYYY/MM/DD (ddd)')}
                    </span>
                  </Content>
                </DetailsOrderItem>
              )}
              <DetailsOrderItem span={24}>
                <Label>納入日</Label>
                <Content>
                  <span>
                    {moment(
                      oldShippingInformation?.header?.nohinDt,
                      'YYMMDD',
                    ).format('YYYY/MM/DD (ddd)')}
                  </span>
                </Content>
              </DetailsOrderItem>
            </Col>
          </DetailsOrder>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="orderLine"
            height={'174px'}
            dataSource={oldShippingInformation?.listMeisai || []}
            columns={columns}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button
              ref={btnSubmitRef}
              type="primary"
              loading={loadingRePrintShipping}
              onClick={onSubmit}
            >
              登録します
            </Button>
            <Button
              type="default"
              disabled={loadingRePrintShipping}
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              訂正します（戻る）
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(ShippingConfirmReprint);
