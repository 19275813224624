export const columns = [
  {
    title: () => <div style={{ minWidth: 50 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>便CD</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>協力企業名</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>現場名</div>,
    dataIndex: 'genba',
    key: 'genba',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>配送住所</div>,
    dataIndex: 'sendAdr',
    key: 'sendAdr',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 200 }}>品名</div>,
    dataIndex: 'hinmei',
    key: 'hinmei',
    render: (_value: any) => (
      <div style={{ minWidth: 200, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>数量</div>,
    dataIndex: 'bara',
    key: 'bara',
    align: 'right',
    render: (_value: any) => {
      return (
        <div style={{ minWidth: 40, width: 'max-content' }}>
          {_value?.trim() ? Number(_value).toLocaleString() : _value}
        </div>
      );
    },
  },
  {
    title: () => <div style={{ minWidth: 60 }}>伝票No.</div>,
    dataIndex: 'denNo',
    key: 'denNo',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>合計重量</div>,
    dataIndex: 'jyuryoGoukei',
    key: 'jyuryoGoukei',
    align: 'right',
    render: (_value: any, record: any) => (
      <div
        style={{
          minWidth: 70,
          width: 'max-content',
          fontWeight: record.highlightRow ? 'bold' : 'normal',
        }}
      >
        {record.totalJyuryoGoukei ?? Number(_value).toLocaleString()}
      </div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>変更便名</div>,
    dataIndex: 'aaa',
    key: 'aaa',
    render: (_value: any) => (
      <div
        style={{
          width: 60,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ width: 30 }}>備考</div>,
    dataIndex: 'tokutei',
    key: 'tokutei',
    render: (_value: any) => <div style={{ width: 30 }}>{_value}</div>,
  },
];
