import { Button, Form, Input, Select, Space, Tabs } from 'antd';
import { PATH } from 'configs/routes';
import {
  ToASCII,
  IsHankakuEisu,
  Hankaku2Zenkaku,
  checkForSpecialChar,
  IsZenkaku,
} from 'constants/common';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormWrapper,
  TitleMainte,
} from 'views/login/FormLogin/formLogin.style';
import { BodyContent, Footer, Header, Wrapper } from './tantouMainte.style';
import messagePopup from 'utils/message/mesage';
import { MSG_ERROR } from 'constants/text';
import TorihikisakiSearch from 'views/common/torihikisakiSearch';
import { useGetCustomerMainte } from 'api/customer';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import {
  useDeleteTantou,
  useInsertTantou,
  useRestoreTantou,
  useUpdateTantou,
} from 'api/tantou';
import { FlexColumn, SwitchModeTabs } from 'components/StyleCommon';
import { selectUserInfo } from 'slice/app';
import { useSelector } from 'react-redux';
import { useLogAccess } from 'api/auth';

const MODE_CREATE = '登録モード';
const MODE_UPDATE = '修正モード';
const MODE_DELETE = '削除モード';

interface iTantou {
  kmancd: string;
  kmannm1: string;
  isDeleted: boolean;
}

const TantouMainte = () => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);

  const formRef = useRef<any>({});

  const [mode, setMode] = useState<string>(MODE_CREATE);

  const [isVisibleTorihikisaki, setIsVisibleTorihikisaki] =
    useState<boolean>(false);

  const previusTorihikiCd = useRef<any>();

  const [disableTorihiki, setDisableTorihiki] = useState(false);

  const [disableKmanCd, setDisableKmanCd] = useState(true);

  const [disableKmanNm, setDisableKmanNm] = useState(true);

  const [tantouOptions, setTantouOptions] = useState<any[]>([]);

  const [defaultFormValues, setDefaultFormValues] = useState<any>(null);

  const [valuesChange, setValuesChange] = useState(false);

  const [tantouDeleted, setTantouDeleted] = useState(false);

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const [form] = Form.useForm();

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const {
    mutate: getCustomerInfo,
    data: dataCustomer,
    error: errorCustomer,
    isLoading: loadingCustomer,
    reset: resetCustomerInfo,
  } = useGetCustomerMainte();

  const {
    mutate: insertTantou,
    isSuccess: isInsertSuccess,
    error: errorInsert,
    isLoading: loadingInsert,
  } = useInsertTantou();

  const {
    mutate: updateTantou,
    isSuccess: isUpdateSuccess,
    error: errorUpdate,
    isLoading: loadingUpdate,
  } = useUpdateTantou();

  const {
    mutate: deleteTantou,
    isSuccess: isDeleteSuccess,
    error: errorDelete,
    isLoading: loadingDelete,
  } = useDeleteTantou();

  const {
    mutate: restoreTantou,
    isSuccess: isRestoreSuccess,
    error: errorRestore,
    isLoading: loadingRestore,
  } = useRestoreTantou();

  useEffect(() => {
    if (!errorCustomer) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorCustomer?.response?.data?.message] ||
        errorCustomer?.response?.data?.message,
      onOk: () => {
        formRef?.current['torihikiCd']?.select();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCustomer]);

  useEffect(() => {
    if (!errorInsert) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorInsert?.response?.data?.message] ||
        errorInsert?.response?.data?.message,
      onOk: () => {
        formRef?.current['kmanCd']?.select();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorInsert]);

  useEffect(() => {
    if (!errorUpdate) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorUpdate?.response?.data?.message] ||
        errorUpdate?.response?.data?.message,
      onOk: () => {
        formRef?.current['kmanNm']?.select();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdate]);

  useEffect(() => {
    if (!errorDelete) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorDelete?.response?.data?.message] ||
        errorDelete?.response?.data?.message,
      onOk: () => {
        formRef?.current['btnSubmit']?.focus();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDelete]);

  useEffect(() => {
    if (!errorRestore) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[errorRestore?.response?.data?.message] ||
        errorRestore?.response?.data?.message,
      onOk: () => {
        formRef?.current['btnRestore']?.focus();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRestore]);

  useEffect(() => {
    if (!isInsertSuccess) return;
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'ご注文担当メンテ',
        pgName: '登録モード',
        logType: '2',
        action: '確定ボタン',
      },
    ] as any);
    messagePopup({
      type: 'success',
      content: MSG_ERROR['MSG_TANTOU_INSERT_SUCCESS'],
      onOk: () => {
        getCustomerInfo({
          torihikiCd: form.getFieldValue('torihikiCd'),
          hanList: false,
          tantoList: true,
        });
        form.setFieldsValue({
          kmanCd: '',
          kmanNm: '',
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInsertSuccess]);

  useEffect(() => {
    if (!isUpdateSuccess) return;
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'ご注文担当メンテ',
        pgName: '修正モード',
        logType: '2',
        action: '確定ボタン',
      },
    ] as any);
    messagePopup({
      type: 'success',
      content: MSG_ERROR['MSG_TANTOU_UPDATE_SUCCESS'],
      onOk: () => {
        getCustomerInfo({
          torihikiCd: form.getFieldValue('torihikiCd'),
          hanList: false,
          tantoList: true,
        });
        setDisableKmanCd(false);
        form.setFieldsValue({
          kmanCd: undefined,
          kmanNm: '',
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (!isDeleteSuccess) return;
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'ご注文担当メンテ',
        pgName: '削除モード',
        logType: '2',
        action: '確定ボタン',
      },
    ] as any);
    messagePopup({
      type: 'success',
      content: MSG_ERROR['MSG_TANTOU_DELETE_SUCCESS'],
      onOk: () => {
        getCustomerInfo({
          torihikiCd: form.getFieldValue('torihikiCd'),
          hanList: false,
          tantoList: true,
        });
        setDisableKmanCd(false);
        form.setFieldsValue({
          kmanCd: undefined,
          kmanNm: '',
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (!isRestoreSuccess) return;
    logAccess([
      {
        tokuiCd: userInfo.torihikicd,
        gyoumuId: 'ご注文担当メンテ',
        pgName: '修正モード',
        logType: '2',
        action: '確定ボタン',
      },
    ] as any);
    messagePopup({
      type: 'success',
      content: MSG_ERROR['MSG_TANTOU_RESTORE_SUCCESS'],
      onOk: () => {
        getCustomerInfo({
          torihikiCd: form.getFieldValue('torihikiCd'),
          hanList: false,
          tantoList: true,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRestoreSuccess]);

  useEffect(() => {
    if (!dataCustomer) {
      setDefaultFormValues(null);
      setValuesChange(false);
      setTantouOptions([]);
      setTantouDeleted(false);
      form.setFieldsValue({
        torihikiNm: '',
      });
      setDisableTorihiki(false);
      setDisableKmanCd(true);
      setDisableKmanNm(true);
      setTimeout(() => {
        formRef.current['torihikiCd'].focus();
      }, 0);
      return;
    }
    const { kaisya, tensyo, torihikicd, tantoList } = dataCustomer.data;
    form.setFieldsValue({
      torihikiCd: torihikicd,
      torihikiNm: `${kaisya}${tensyo || ''}`,
    });

    if (mode === MODE_CREATE) {
      setDisableTorihiki(true);
      setDisableKmanCd(false);
      setDisableKmanNm(false);
      setTimeout(() => {
        setDefaultFormValues(form.getFieldsValue());
        formRef.current['kmanCd'].focus();
      }, 0);
    } else {
      if (!tantoList?.length) {
        messagePopup({
          type: 'info',
          content: MSG_ERROR['MSG_TANTOU_NOT_EXIST'],
          onOk: () => {
            setTantouOptions([]);
            formRef?.current['torihikiCd']?.select();
          },
        });
      } else {
        let tantouOptions = tantoList?.map((item: iTantou) => {
          return {
            value: item.kmancd,
            label: (
              <>
                <span
                  style={{
                    textDecoration: item.isDeleted ? 'line-through' : 'none',
                  }}
                >
                  {`${item.kmancd} : ${item.kmannm1}`}
                </span>
                <span
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                  }}
                >{`${item.isDeleted ? ' (は削除済)' : ''}
         `}</span>
              </>
            ),
            kmannm: item.kmannm1,
            deleted: item.isDeleted.toString(),
          };
        });
        setTantouOptions(
          mode === MODE_UPDATE
            ? tantouOptions
            : tantouOptions.filter(
                (option: { value: string; label: string; deleted: string }) =>
                  option.deleted !== 'true',
              ),
        );
        if (!form.getFieldValue('kmanNm')?.length) {
          setDisableKmanCd(false);
          setTimeout(() => {
            formRef.current['kmanCd'].focus();
          }, 0);
        } else {
          setDefaultFormValues(form.getFieldsValue());
          setDisableTorihiki(true);
          setDisableKmanCd(true);
          setDisableKmanNm(false);
          setTimeout(() => {
            formRef.current['kmanNm'].select();
          }, 0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCustomer]);

  const onFinish = (values: any) => {
    const { torihikiCd: tokucd, kmanCd: kmancd, kmanNm: kmannm1 } = values;
    switch (mode) {
      case MODE_CREATE:
        messagePopup({
          type: 'question',
          content: '担当者情報を登録してもよろしいですか',
          onOk: () => {
            insertTantou({ tokucd, kmancd, kmannm1 });
          },
        });
        break;

      case MODE_UPDATE:
        if (tantouDeleted)
          messagePopup({
            type: 'question',
            content: '担当者情報を復活してもよろしいですか',
            onOk: () => {
              restoreTantou({ tokucd, kmancd });
            },
          });
        else
          messagePopup({
            type: 'question',
            content: '担当者情報を修正してもよろしいですか',
            onOk: () => {
              updateTantou({ tokucd, kmancd, kmannm1 });
            },
          });
        break;

      case MODE_DELETE:
        messagePopup({
          type: 'question',
          content: '担当者情報を削除してもよろしいですか',
          onOk: () => {
            deleteTantou({ tokucd, kmancd });
          },
        });
        break;

      default:
        break;
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        formRef?.current[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  const clearScreen = () => {
    resetCustomerInfo();
    form.resetFields();
  };

  useEffect(() => {
    clearScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Render label with mode
  function labelModeOnChange() {
    switch (mode) {
      case MODE_CREATE:
        return '登録モード';
      case MODE_UPDATE:
        return '修正モード';
      case MODE_DELETE:
        return '削除モード';
      default:
        break;
    }
  }

  return (
    <Wrapper>
      {(loadingCustomer ||
        loadingInsert ||
        loadingUpdate ||
        loadingDelete ||
        loadingRestore) && <LoadingCallAPI />}
      <Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}
        >
          <TitleMainte>
            <span className="label-mode">{labelModeOnChange()}</span>
          </TitleMainte>
          <Space>
            <b>モード選択：</b>
            <SwitchModeTabs
              activeKey={mode}
              onChange={key => {
                function changeKey(toKey: any) {
                  switch (toKey) {
                    case MODE_CREATE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: 'ご注文担当メンテ',
                          pgName: mode,
                          logType: '2',
                          action: '登録ボタン',
                        },
                      ] as any);
                      break;
                    case MODE_UPDATE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: 'ご注文担当メンテ',
                          pgName: mode,
                          logType: '2',
                          action: '修正ボタン',
                        },
                      ] as any);
                      break;
                    case MODE_DELETE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: 'ご注文担当メンテ',
                          pgName: mode,
                          logType: '2',
                          action: '削除ボタン',
                        },
                      ] as any);
                      break;

                    default:
                      break;
                  }
                  setMode(toKey);
                }
                if (valuesChange) {
                  messagePopup({
                    type: 'warning',
                    content: MSG_ERROR['MSG_VALUES_CHANGE'],
                    onOk: () => {
                      changeKey(key);
                      setValuesChange(false);
                    },
                  });
                } else {
                  changeKey(key);
                }
              }}
            >
              <Tabs.TabPane tab={'登録モード'} key={MODE_CREATE} />
              <Tabs.TabPane tab={'修正モード'} key={MODE_UPDATE} />
              <Tabs.TabPane tab={'削除モード'} key={MODE_DELETE} />
            </SwitchModeTabs>
          </Space>
        </div>
      </Header>
      <BodyContent>
        <FormWrapper
          id="tantou-form"
          form={form}
          className="form-mainte"
          labelCol={{ flex: '300px' }}
          wrapperCol={{ flex: 1 }}
          onFinish={onFinish}
          // autoComplete="off"
          requiredMark={false}
          labelAlign="left"
          colon={false}
          initialValues={{
            torihikiCd: '',
            torihikiNm: '',
            kmanCd: mode === MODE_CREATE ? '' : undefined,
            kmanNm: '',
          }}
          onFinishFailed={onFinishFailed}
          onBlur={() => {
            if (
              defaultFormValues &&
              JSON.stringify(defaultFormValues) !==
                JSON.stringify(form.getFieldsValue())
            ) {
              setValuesChange(true);
            } else {
              setValuesChange(false);
            }
          }}
        >
          <div style={{ width: '100%', fontWeight: 'bold' }}>{'設定情報'}</div>
          <Form.Item
            label={'１．取引先コード(半角英数字6桁)'}
            className={disableTorihiki ? 'disable-input' : ''}
          >
            <Space size={4}>
              <Form.Item name="torihikiCd" noStyle>
                <Input
                  disabled={disableTorihiki}
                  autoFocus
                  ref={el => (formRef.current['torihikiCd'] = el)}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const fomartedTorihikiCd = ToASCII(
                        e.target.value,
                        true,
                      ).trim();
                      const isChangedValue =
                        fomartedTorihikiCd !== previusTorihikiCd.current;

                      if (!isChangedValue) {
                        if (
                          !form.getFieldValue('torihikiNm') ||
                          !tantouOptions.length
                        ) {
                          formRef.current.btnSearch.focus();
                        } else {
                          formRef.current['kmanCd'].focus();
                        }
                        return;
                      }

                      if (!fomartedTorihikiCd) {
                        formRef.current.btnSearch.focus();
                        return;
                      }

                      formRef.current['torihikiCd'].blur();
                    }
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onFocus={event => {
                    previusTorihikiCd.current = event.target.value;
                  }}
                  onBlur={event => {
                    const fomartedTorihikiCd = ToASCII(
                      event.target.value,
                      true,
                    ).trim();
                    form.setFieldsValue({
                      torihikiCd: fomartedTorihikiCd,
                    });
                    const isChangedValue =
                      fomartedTorihikiCd !== previusTorihikiCd.current;

                    if (!isChangedValue) return;

                    if (!fomartedTorihikiCd) {
                      clearScreen();
                      return;
                    }

                    if (IsHankakuEisu(fomartedTorihikiCd)) {
                      messagePopup({
                        type: 'info',
                        content: '取引先コード' + MSG_ERROR['MSG_HANKAKU_EISU'],
                        onOk: () => {
                          if (dataCustomer) {
                            resetCustomerInfo();
                          } else {
                            formRef.current['torihikiCd'].select();
                          }
                        },
                      });
                      return;
                    }
                    getCustomerInfo({
                      torihikiCd: fomartedTorihikiCd,
                      hanList: false,
                      tantoList: true,
                    });
                  }}
                  maxLength={6}
                  style={{ width: 100 }}
                />
              </Form.Item>
              <Form.Item name="torihikiNm" noStyle>
                <Input disabled style={{ width: 300 }} />
              </Form.Item>
              <Button
                ref={el => (formRef.current.btnSearch = el)}
                disabled={disableTorihiki}
                type="default"
                onClick={() => {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: 'ご注文担当メンテ',
                      pgName: 'ご注文担当メンテ',
                      logType: '2',
                      action: '取引先検索ボタン',
                    },
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: 'ご注文担当メンテ',
                      pgName: '取引先検索',
                      logType: '1',
                      action: '',
                    },
                  ] as any);
                  setIsVisibleTorihikisaki(true);
                }}
              >
                {'取引先検索'}
              </Button>
            </Space>
          </Form.Item>
          <Form.Item
            label={
              mode === MODE_CREATE ? '２．担当者コード(半角1桁)' : '２．担当者'
            }
            className={disableKmanCd ? 'disable-input' : ''}
          >
            <Space size={4}>
              <Form.Item
                name="kmanCd"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!value?.length) {
                        return Promise.reject(
                          new Error('担当者コード' + MSG_ERROR['MSG_INPUT']),
                        );
                      }
                      if (value === '0' || IsHankakuEisu(value)) {
                        return Promise.reject(
                          new Error(MSG_ERROR['MSG_FORMATERR_TANTOUCD']),
                        );
                      }
                      if (checkForSpecialChar(value)) {
                        return Promise.reject(
                          new Error(
                            '担当者コード' + MSG_ERROR['MSG_INVALID_CHAR'],
                          ),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {mode === MODE_CREATE ? (
                  <Input
                    disabled={disableKmanCd}
                    ref={el => (formRef.current['kmanCd'] = el)}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        formRef.current['kmanNm'].focus();
                      }
                    }}
                    onKeyUp={event =>
                      (event.key === 'Enter' || event.key === 'Tab') &&
                      event.currentTarget.select()
                    }
                    onBlur={event => {
                      form.setFieldsValue({
                        kmanCd: ToASCII(event.target.value, true).trim(),
                      });
                    }}
                    maxLength={1}
                    style={{ width: 50 }}
                  />
                ) : (
                  <Select
                    open={openDropdown}
                    onDropdownVisibleChange={open => setOpenDropdown(open)}
                    onKeyDown={(event: any) => {
                      if (event.key === 'Enter') {
                        setOpenDropdown(false);
                      } else if (event.key === ' ') {
                        event.preventDefault();
                        setOpenDropdown(true);
                      }
                    }}
                    style={{
                      width: !!tantouOptions.length ? 300 : 50,
                    }}
                    placeholder={
                      !!tantouOptions.length
                        ? '担当者を選択してください'
                        : undefined
                    }
                    disabled={disableKmanCd}
                    ref={el => (formRef.current['kmanCd'] = el)}
                    options={tantouOptions}
                    onChange={(_value, option: any) => {
                      setDisableTorihiki(true);
                      setDisableKmanCd(true);
                      form.setFieldsValue({
                        kmanNm: option.kmannm,
                      });
                      if (mode === MODE_UPDATE) {
                        if (option.deleted === 'true') {
                          setTantouDeleted(true);
                          setTimeout(() => {
                            formRef.current.btnRestore.focus();
                          }, 0);
                        } else {
                          setDisableKmanNm(false);
                          setTimeout(() => {
                            setDefaultFormValues(form.getFieldsValue());
                            formRef.current['kmanNm'].select();
                          }, 0);
                        }
                      } else {
                        setTimeout(() => {
                          formRef.current.btnSubmit.focus();
                        }, 0);
                      }
                    }}
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger.parentNode as HTMLElement
                    }
                  />
                )}
              </Form.Item>
              {tantouDeleted && (
                <Button
                  ref={el => (formRef.current.btnRestore = el)}
                  form={'tantou-form'}
                  type="primary"
                  htmlType="submit"
                  hidden={!tantouDeleted}
                >
                  {'復活'}
                </Button>
              )}
              {mode === MODE_CREATE ? <span>{'([1-9、A-Z])'}</span> : null}
            </Space>
          </Form.Item>
          <Form.Item
            className={disableKmanNm ? 'disable-input' : ''}
            label={'３．担当者名(全角10桁)'}
            name="kmanNm"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value?.length) {
                    return Promise.reject(
                      new Error('担当者名' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  if (!IsZenkaku(value)) {
                    return Promise.reject(
                      new Error('担当者名' + MSG_ERROR['MSG_HANKAKU']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (formRef.current['kmanNm'] = el)}
              disabled={disableKmanNm}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  formRef.current.btnSubmit.focus();
                }
              }}
              maxLength={10}
              style={{
                width: 300,
                textDecoration: tantouDeleted ? 'line-through' : 'none',
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onBlur={event => {
                form.setFieldsValue({
                  kmanNm: Hankaku2Zenkaku(event.target.value).trim(),
                });
              }}
            />
          </Form.Item>
          {dataCustomer ? (
            <Form.Item
              label={'４．登録済担当者'}
              style={{ alignItems: 'baseline' }}
            >
              <FlexColumn>
                {dataCustomer?.data?.tantoList?.map((item: iTantou) => {
                  return (
                    <span key={item.kmancd}>
                      <span
                        style={{
                          textDecoration: item.isDeleted
                            ? 'line-through'
                            : 'unset',
                        }}
                      >
                        {item.kmancd}　{item.kmannm1}
                      </span>
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        {item.isDeleted ? '（削除済み）' : ''}
                      </span>
                    </span>
                  );
                })}
              </FlexColumn>
            </Form.Item>
          ) : null}
        </FormWrapper>
      </BodyContent>
      <Footer>
        <Button
          disabled={tantouDeleted}
          ref={el => (formRef.current.btnSubmit = el)}
          form={'tantou-form'}
          type="primary"
          htmlType="submit"
          hidden={
            mode === MODE_CREATE ? !dataCustomer : !form.getFieldValue('kmanCd')
          }
        >
          {'確定'}
        </Button>
        <Button onClick={() => window.print()}>{'印刷'}</Button>
        <Button
          ref={el => (formRef.current.btnReset = el)}
          onClick={() => {
            if (valuesChange) {
              messagePopup({
                type: 'warning',
                content: MSG_ERROR['MSG_VALUES_CHANGE'],
                onOk: () => {
                  clearScreen();
                },
                onCancel: () => formRef.current.btnReset.focus(),
              });
            } else {
              clearScreen();
            }
          }}
        >
          {'画面クリア'}
        </Button>
        <Button
          ref={el => (formRef.current.btnClose = el)}
          onClick={() => {
            if (valuesChange) {
              messagePopup({
                type: 'warning',
                content: MSG_ERROR['MSG_VALUES_CHANGE'],
                onOk: () => {
                  navigate(PATH.TOP);
                },
                onCancel: () => formRef.current.btnClose.focus(),
              });
            } else {
              navigate(PATH.TOP);
            }
          }}
        >
          {'メニューへ戻る'}
        </Button>
      </Footer>
      <TorihikisakiSearch
        isModalVisible={isVisibleTorihikisaki}
        setIsModalVisible={setIsVisibleTorihikisaki}
        setTorihikiCd={(torihikiCd: any) => {
          if (torihikiCd === form.getFieldValue('torihikiCd')) return;
          previusTorihikiCd.current = torihikiCd;
          form.setFieldsValue({
            torihikiCd,
          });
          getCustomerInfo({ torihikiCd, hanList: false, tantoList: true });
        }}
        focusAfterClose={() => {
          formRef.current.btnSearch.focus();
        }}
      />
    </Wrapper>
  );
};

export default TantouMainte;
