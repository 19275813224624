/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Select, Space, Tabs } from 'antd';
import { useLogAccess } from 'api/auth';
import { useGetCustomerMainte } from 'api/customer';
import {
  useDeleteHanBinCds,
  useDeleteOtodokesaki,
  useGetHanInfo,
  useGetListBin,
  useRestoreHan,
  useRestoreOtodokesaki,
  useUpsertHanOtodokesaki,
} from 'api/hanOtodokesaki';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { SwitchModeTabs } from 'components/StyleCommon';
import {
  checkForSpecialChar,
  Hankaku2Zenkaku,
  IsHankakuEisu,
  isNumeric,
  IsZenkaku,
  onlyLettersAndNumbers,
  ToASCII,
  UseFocus,
  // isNumberPhoneFax,
} from 'constants/common';
import { toFullWidthAll } from 'constants/fullWidth';
import { MSG_ERROR } from 'constants/text';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import TorihikisakiSearch from 'views/common/torihikisakiSearch';
import {
  BodyForm,
  FormWrapper,
  HeaderForm,
  TitleMainte,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import { DividerStyle, TitleGenbaCD } from './hanOtodo.style';
// import { listKeyRefs } from './list.oto';

// const { confirm } = Modal;

const GroupAddress = [
  {
    id: 7,
    genbaTitle: 'お届け先　Ａ',
    genbaCd: 'A',
  },
  {
    id: 15,
    genbaTitle: 'お届け先　Ｂ',
    genbaCd: 'B',
  },
  {
    id: 23,
    genbaTitle: 'お届け先　Ｃ',
    genbaCd: 'C',
  },
  {
    id: 31,
    genbaTitle: 'お届け先　Ｄ',
    genbaCd: 'D',
  },
];

const MODE_CREATE_UPDATE = '登録／修正モード';
const MODE_DELETE = '削除モード';

const AVAILABLE = '99999999';

const HanOtodokesaki = () => {
  const [mode, setMode] = useState(MODE_CREATE_UPDATE);
  const [mutationMode, setMutationMode] = useState<
    'create' | 'update' | 'delete'
  >('create');

  const [form] = Form.useForm();
  const [torihikiCd, setTorihikiCd] = useState<string | null>(null);
  const [hanCd, setHanCd] = useState<string | null>(null);
  const [previousValue, setPreviousValue] = useState<any>({
    torihikiCd: '',
    hanCd: '',
  });
  const [refFieldTohi, setFocusFieldTohi] = UseFocus();
  const [refBtnSearch, setFocusBtnSearch] = UseFocus();
  const [refHanCd, setFocusHancd] = UseFocus();
  const [refHanNm, setFocusHanNm] = UseFocus();
  const [refSyozoku, setFocusSyozoku] = UseFocus();
  const [refHikitoriBin, setFocusHikitoriBin] = UseFocus();
  const [refCaptyHanCd, setFocusCaptyHanCd] = UseFocus();
  const [disableInputTori, setDisableInputTori] = useState(false);
  const [disableInputHanCd, setDisableInputHanCd] = useState(true);
  const [disableInput, setDisableInput] = useState(true);
  const hanOtodokesakiRef = useRef<any>({});
  const [defaultJsonValue, setDefaultJsonValue] = useState<any>(null);
  const [refSubmitDelHan, setFocusSubmitDelHan] = UseFocus();
  const [refFocusRestoreHan, setFocusRestoreHan] = UseFocus();
  const _refSubmit = useRef<any>(null);
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);

  const [isVisibleTorihikisaki, setIsVisibleTorihikisaki] =
    useState<boolean>(false);

  const [openDropdown, setOpenDropdown] = React.useState<{
    hancd: boolean;
    hikitoriBin: boolean;
  }>({
    hancd: false,
    hikitoriBin: false,
  });

  const {
    mutate: mutateTorihikisaki,
    data: dataTorihikisaki,
    isLoading: loadingTorihikisaki,
    reset: resetTorihikisaki,
  } = useGetCustomerMainte();

  const {
    mutate: mutateHanInfo,
    data: dataHanInfo,
    error: errorHanInfo,
    isLoading: loadingHanInfo,
    reset: resetHanInfo,
  } = useGetHanInfo();

  const {
    mutate: mutateListBin,
    data: dataListBin,
    isLoading: loadingListBin,
  } = useGetListBin();

  const {
    mutate: mutationUpsertHanOtodokesaki,
    isLoading: loadingUpsertHanOtodokesaki,
    reset: resetHanOtodokesaki,
  } = useUpsertHanOtodokesaki();

  const {
    mutate: mutationDeleteOtodo,
    isLoading: loadingDeleteOtodo,
    reset: resetDeleteOtodokesaki,
  } = useDeleteOtodokesaki();

  const {
    mutate: mutationDeleteHanBinCds,
    isLoading: loadingDeleteHanBinCds,
    reset: resetDeleteHanBinCds,
  } = useDeleteHanBinCds();

  const {
    mutate: mutationRestoreOtodokesaki,
    isLoading: loadingRestoreOtodokesaki,
  } = useRestoreOtodokesaki();

  const { mutate: mutationRestoreHan, isLoading: loadingRestoreHan } =
    useRestoreHan();

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  useEffect(() => {
    form.setFieldsValue({
      torihikiCd: torihikiCd,
    });
    torihikiCd &&
      mutateTorihikisaki(
        { torihikiCd, tantoList: false, hanList: true },
        {
          onError(error, variables, context) {
            messagePopup({
              type: 'info',
              content:
                MSG_ERROR[(error as any)?.response?.data?.message] ||
                error?.response?.data?.message,
              onOk: () => {
                form.setFieldsValue({
                  // torihikiCd: '',
                  torihikiNm: '',
                });
                setFocusFieldTohi();
              },
            });
          },
        },
      );
  }, [torihikiCd]);

  useEffect(() => {
    if (dataTorihikisaki) {
      form.setFieldsValue({
        torihikiCd: `${dataTorihikisaki?.data?.torihikicd}`,
        torihikiNm: `${dataTorihikisaki?.data?.kaisya} ${dataTorihikisaki?.data?.tensyo !== null
          ? dataTorihikisaki?.data?.tensyo
          : ''
          }`,
      });
      setDisableInputTori(true);
      setTimeout(() => {
        setFocusHancd();
      }, 0);
    }
    if (mode === MODE_DELETE && dataTorihikisaki?.data?.hanList?.length === 0) {
      messagePopup({
        type: 'info',
        content: MSG_ERROR['MSG_HAN_NOT_EXIST'],
        onOk: () => {
          setDisableInputHanCd(true);
          setDisableInputTori(false);
          setTimeout(() => {
            setFocusFieldTohi();
          }, 0);
        },
      });
    }
  }, [dataTorihikisaki]);

  const CheckNull = (value: any) => {
    return value !== null ? value : '';
  };

  useEffect(() => {
    mutateListBin(
      {},
      {
        onError(error, variables, context) {
          messagePopup({
            type: 'error',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              error?.response?.data?.message,
          });
        },
      },
    );
  }, []);

  useEffect(() => {
    if (errorHanInfo)
      messagePopup({
        type: 'info',
        content: MSG_ERROR[(errorHanInfo as any)?.response?.data?.message],
      });
  }, [errorHanInfo]);

  function labelModeOnChange() {
    switch (mode) {
      case MODE_CREATE_UPDATE:
        return '登録／修正モード';
      case MODE_DELETE:
        return '削除モード';
      default:
        break;
    }
  }

  function resetForm() {
    form.resetFields();
    setTorihikiCd(null);
    setHanCd(null);
    setDisableInputTori(false);
    setDisableInputHanCd(true);
    setDisableInput(true);
    GroupAddress.map((item: any, index: number) =>
      SetFieldValue('genbaCd', item.genbaCd, index),
    );
    resetMutation();
    setDefaultJsonValue(null);
  }
  function resetMutation() {
    resetTorihikisaki();
    resetHanInfo();
    resetHanOtodokesaki();
    resetDeleteOtodokesaki();
    resetDeleteHanBinCds();
  }

  const cvUndefined = (_value: any) => {
    return _value === undefined ? '' : _value;
  };

  const cvPostNo = (_first: any, _last: any) => {
    if (
      _first === undefined ||
      _last === undefined ||
      _first === '' ||
      _last === ''
    ) {
      return '';
    } else {
      return _first + '-' + _last;
    }
  };

  const CheckDataRequest = (
    genbaCd: any,
    nm: any,
    postCd: any,
    adrs: any,
    telNo: any,
    faxNo: any,
    binCd: any,
    captyHanCd: any,
    deldate: any,
  ) => {
    if (
      (!nm && !postCd && !adrs && !telNo && !faxNo && !binCd && !captyHanCd) ||
      (deldate !== undefined && deldate !== AVAILABLE && deldate !== '')
    ) {
      return null;
    } else {
      const finalRequest = {
        genbaCd: cvUndefined(genbaCd),
        nm: cvUndefined(nm),
        postCd: postCd,
        adrs: cvUndefined(adrs),
        telNo: cvUndefined(telNo),
        faxNo: cvUndefined(faxNo),
        binCd: cvUndefined(binCd),
        captyHanCd: cvUndefined(captyHanCd),
      };
      return finalRequest;
    }
  };

  const onFinish = (values: any) => {
    messagePopup({
      type: 'question',
      content:
        mode === MODE_CREATE_UPDATE
          ? '班・お届け先（便）情報を登録してもよろしいですか'
          : '班および班に紐づくお届け先（便）情報を削除してもよろしいですか',
      onOk: () => {
        switch (mode) {
          case MODE_CREATE_UPDATE:
            const _otodokesakiInfos = [];
            const _genbaGroupA = CheckDataRequest(
              values.genbaCd1,
              values.genbanm1,
              cvPostNo(values.postcd11, values.postcd21),
              values.adrs1,
              values.tel1,
              values.fax1,
              values.binCd1,
              values.captyHanCd1,
              values.deldate1,
            );

            if (_genbaGroupA !== null) _otodokesakiInfos.push(_genbaGroupA);

            const _genbaGroupB = CheckDataRequest(
              values.genbaCd2,
              values.genbanm2,
              cvPostNo(values.postcd12, values.postcd22),
              values.adrs2,
              values.tel2,
              values.fax2,
              values.binCd2,
              values.captyHanCd2,
              values.deldate2,
            );
            if (_genbaGroupB !== null) _otodokesakiInfos.push(_genbaGroupB);
            const _genbaGroupC = CheckDataRequest(
              values.genbaCd3,
              values.genbanm3,
              cvPostNo(values.postcd13, values.postcd23),
              values.adrs3,
              values.tel3,
              values.fax3,
              values.binCd3,
              values.captyHanCd3,
              values.deldate3,
            );
            if (_genbaGroupC !== null) _otodokesakiInfos.push(_genbaGroupC);

            const _genbaGroupD = CheckDataRequest(
              values.genbaCd4,
              values.genbanm4,
              cvPostNo(values.postcd14, values.postcd24),
              values.adrs4,
              values.tel4,
              values.fax4,
              values.binCd4,
              values.captyHanCd4,
              values.deldate4,
            );
            if (_genbaGroupD !== null) _otodokesakiInfos.push(_genbaGroupD);

            const request = {
              torihikiCd: cvUndefined(values.torihikiCd),
              hanBinInfoRequest: {
                binCd: cvUndefined(values.hikitoriBin),
                captyHanCd: cvUndefined(values.captyHanCd),
              },
              hanInfoRequest: {
                hanCd: cvUndefined(values.hanCd),
                hanNm: cvUndefined(values.hanNm),
                syozoku: cvUndefined(values.syozoku),
              },
              otodokesakiInfos: _otodokesakiInfos,
            };
            // console.log(request, 'request');

            if (mutationMode === 'create') {
              mutationUpsertHanOtodokesaki(request, {
                onSuccess(data, variables, context) {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '班・お届け先（便）メンテ',
                      pgName: '登録／修正モード',
                      logType: '2',
                      action: '確定ボタン',
                    },
                  ] as any);
                  messagePopup({
                    type: 'success',
                    content: MSG_ERROR['MSG_INSERT_HANOTO_SUCCESS'],
                    onOk: () => resetForm(),
                  });
                },
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                  });
                },
              });
            } else if (mutationMode === 'update') {
              const _updateRequest = { ...request, type: 'update' };
              mutationUpsertHanOtodokesaki(_updateRequest, {
                onSuccess(data, variables, context) {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '班・お届け先（便）メンテ',
                      pgName: '登録／修正モード',
                      logType: '2',
                      action: '確定ボタン',
                    },
                  ] as any);
                  messagePopup({
                    type: 'success',
                    content: MSG_ERROR['MSG_UPDATE_HANOTO_SUCCESS'],
                    onOk: () => resetForm(),
                  });
                },
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                  });
                },
              });
            }
            break;
          case MODE_DELETE:
            // const _requestDelete = {
            //   torihikiCd: cvUndefined(values.torihikiCd),
            //   hanCd: cvUndefined(values.hanCd),
            //   binCds: [
            //     cvUndefined(values.binCd1) && values.genbaCd1,
            //     cvUndefined(values.binCd2) && values.genbaCd2,
            //     cvUndefined(values.binCd3) && values.genbaCd3,
            //     cvUndefined(values.binCd4) && values.genbaCd4,
            //   ],
            // };
            const _requestDelete = {
              torihikiCd: cvUndefined(values.torihikiCd),
              hanCd: cvUndefined(values.hanCd),
              genbaCds: [
                cvUndefined(values.binCd1) && values.genbaCd1,
                cvUndefined(values.binCd2) && values.genbaCd2,
                cvUndefined(values.binCd3) && values.genbaCd3,
                cvUndefined(values.binCd4) && values.genbaCd4,
              ].filter(item => item),
            };
            mutationDeleteHanBinCds(_requestDelete, {
              onSuccess(data, variables, context) {
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: '班・お届け先（便）メンテ',
                    pgName: '削除モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: MSG_ERROR['MSG_DELETE_HAN_SUCCESS'],
                  onOk: () => resetForm(),
                });
              },
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                });
              },
            });
            break;
        }
      },
    });
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    // console.log(errorFields);
    // const _listRefs: any = listKeyRefs(
    //   'genbanm',
    //   'postcd1',
    //   'postcd2',
    //   'adrs',
    //   'tel',
    //   'fax',
    //   'binCd',
    //   'captyHanCd',
    // );
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        switch (errorFields[0]?.name[0]) {
          case 'torihikiCd':
            setFocusFieldTohi();
            return;
          case 'hanCd':
            setFocusHancd();
            return;
          case 'hanNm':
            setFocusHanNm();
            return;
          case 'syozoku':
            setFocusSyozoku();
            return;
          case 'hikitoriBin':
            setFocusHikitoriBin();
            return;
          case 'captyHanCd':
            setFocusCaptyHanCd();
            return;
          default:
            hanOtodokesakiRef.current[errorFields[0]?.name[0]].focus();
            return;
        }
        // for (let i = 0; i < _listRefs.length; i++) {
        //   switch (_listRefs[i].key) {
        //     case errorFields[0]?.name[0]:
        //       FocusElement(_listRefs[i].ref, _listRefs[i].index);
        //       return;
        //   }
        // }
      },
    });
  };

  useEffect(() => {
    if (
      // (mode === 'create' && dataTorihikisaki) ||
      // (mode === 'delete' && dataTorihikisaki)
      dataTorihikisaki
    ) {
      setDisableInputHanCd(false);
    } else {
      setDisableInputHanCd(true);
    }
  }, [mode, dataTorihikisaki]);

  useEffect(() => {
    if (mode === MODE_CREATE_UPDATE && hanCd) {
      setDisableInput(false);
      setTimeout(() => {
        setFocusHanNm();
      }, 0);
    } else {
      setDisableInput(true);
    }
  }, [mode, hanCd]);

  // const FocusElement = (arr: any, position: number) => {
  //   if (arr && arr.current[position]) arr.current[position].focus();
  // };

  const SetFieldValue = (type: string, item: any, position: Number) => {
    switch (position) {
      case 0:
        switch (type) {
          case 'genbaCd':
            form.setFieldsValue({
              genbaCd1: item,
            });
            break;
          case 'genbanm':
            form.setFieldsValue({
              genbanm1: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'postcd':
            const _val1 = item.substring(0, 3);
            const _val2 = item.substring(4, 8);
            setTimeout(() => {
              form.setFieldsValue({
                postcd11: _val1,
                postcd21: _val2,
              });
            }, 0);
            break;
          case 'postcd1':
            form.setFieldsValue({
              postcd11: ToASCII(item).trim(),
            });
            break;
          case 'postcd2':
            form.setFieldsValue({
              postcd21: ToASCII(item).trim(),
            });
            break;
          case 'adrs':
            form.setFieldsValue({
              adrs1: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'tel':
            form.setFieldsValue({
              tel1: ToASCII(item).trim(),
            });
            break;
          case 'fax':
            form.setFieldsValue({
              fax1: ToASCII(item).trim(),
            });
            break;
          case 'binCd':
            form.setFieldsValue({
              binCd1: item,
            });
            break;
          case 'captyHanCd':
            form.setFieldsValue({
              captyHanCd1: ToASCII(item, false, true).trim(),
            });
            break;
          case 'deldate':
            form.setFieldsValue({
              deldate1: ToASCII(item),
            });
            break;
        }
        break;
      case 1:
        switch (type) {
          case 'genbaCd':
            form.setFieldsValue({
              genbaCd2: item,
            });
            break;
          case 'genbanm':
            form.setFieldsValue({
              genbanm2: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'postcd':
            const _val1 = item.substring(0, 3);
            const _val2 = item.substring(4, 8);
            setTimeout(() => {
              form.setFieldsValue({
                postcd12: _val1,
                postcd22: _val2,
              });
            }, 0);
            break;
          case 'postcd1':
            form.setFieldsValue({
              postcd12: ToASCII(item).trim(),
            });
            break;
          case 'postcd2':
            form.setFieldsValue({
              postcd22: ToASCII(item).trim(),
            });
            break;
          case 'adrs':
            form.setFieldsValue({
              adrs2: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'tel':
            form.setFieldsValue({
              tel2: ToASCII(item).trim(),
            });
            break;
          case 'fax':
            form.setFieldsValue({
              fax2: ToASCII(item).trim(),
            });
            break;
          case 'binCd':
            form.setFieldsValue({
              binCd2: item,
            });
            break;
          case 'captyHanCd':
            form.setFieldsValue({
              captyHanCd2: ToASCII(item, false, true).trim(),
            });
            break;
          case 'deldate':
            form.setFieldsValue({
              deldate2: ToASCII(item),
            });
            break;
        }
        break;
      case 2:
        switch (type) {
          case 'genbaCd':
            form.setFieldsValue({
              genbaCd3: item,
            });
            break;
          case 'genbanm':
            form.setFieldsValue({
              genbanm3: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'postcd':
            const _val1 = item.substring(0, 3);
            const _val2 = item.substring(4, 8);
            setTimeout(() => {
              form.setFieldsValue({
                postcd13: _val1,
                postcd23: _val2,
              });
            }, 0);
            break;
          case 'postcd1':
            form.setFieldsValue({
              postcd13: ToASCII(item).trim(),
            });
            break;
          case 'postcd2':
            form.setFieldsValue({
              postcd23: ToASCII(item).trim(),
            });
            break;
          case 'adrs':
            form.setFieldsValue({
              adrs3: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'tel':
            form.setFieldsValue({
              tel3: ToASCII(item).trim(),
            });
            break;
          case 'fax':
            form.setFieldsValue({
              fax3: ToASCII(item).trim(),
            });
            break;
          case 'binCd':
            form.setFieldsValue({
              binCd3: item,
            });
            break;
          case 'captyHanCd':
            form.setFieldsValue({
              captyHanCd3: ToASCII(item, false, true).trim(),
            });
            break;
          case 'deldate':
            form.setFieldsValue({
              deldate3: ToASCII(item),
            });
            break;
        }
        break;
      case 3:
        switch (type) {
          case 'genbaCd':
            form.setFieldsValue({
              genbaCd4: item,
            });
            break;
          case 'genbanm':
            form.setFieldsValue({
              genbanm4: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'postcd':
            const _val1 = item.substring(0, 3);
            const _val2 = item.substring(4, 8);
            setTimeout(() => {
              form.setFieldsValue({
                postcd14: _val1,
                postcd24: _val2,
              });
            }, 0);
            break;
          case 'postcd1':
            form.setFieldsValue({
              postcd14: ToASCII(item).trim(),
            });
            break;
          case 'postcd2':
            form.setFieldsValue({
              postcd24: ToASCII(item).trim(),
            });
            break;
          case 'adrs':
            form.setFieldsValue({
              adrs4: Hankaku2Zenkaku(item).trimEnd(),
            });
            break;
          case 'tel':
            form.setFieldsValue({
              tel4: ToASCII(item).trim(),
            });
            break;
          case 'fax':
            form.setFieldsValue({
              fax4: ToASCII(item).trim(),
            });
            break;
          case 'binCd':
            form.setFieldsValue({
              binCd4: item,
            });
            break;
          case 'captyHanCd':
            form.setFieldsValue({
              captyHanCd4: ToASCII(item, false, true).trim(),
            });
            break;
          case 'deldate':
            form.setFieldsValue({
              deldate4: ToASCII(item),
            });
            break;
        }
    }
  };

  const checkTorihikicd = (_value: string) => {
    if (_value) {
      if (_value !== previousValue.torihikiCd) {
        if (IsHankakuEisu(_value)) {
          messagePopup({
            type: 'info',
            content: '取引先コード' + MSG_ERROR['MSG_HANKAKU_EISU'],
            onOk: () => {
              form.setFieldsValue({
                torihikiCd: _value,
                torihikiNm: '',
              });
              setFocusFieldTohi();
            },
          });
          return;
        }
        setTorihikiCd(_value);
      }
    } else {
      setTorihikiCd(null);
      form.setFieldsValue({
        torihikiNm: '',
      });
    }
  };

  function mutateHan(_value: any, popupConfirm: boolean = false) {
    mutateHanInfo(
      { torihikiCd: torihikiCd, hanCd: _value },
      {
        onSuccess(data, variables, context) {
          if (data && data?.data !== '') {
            if (popupConfirm) {
              messagePopup({
                type: 'question',
                content:
                  '入力した内容をクリアし、修正モードに切り替えます。\r\nよろしいですか。',
                onOk: () => {
                  //set data
                  form.setFieldsValue({
                    hanNm: CheckNull(data?.data?.hannm),
                    syozoku: CheckNull(data?.data?.syozoku),
                    hikitoriBin: CheckNull(data?.data?.binCd),
                    captyHanCd: CheckNull(data?.data?.captyHanCd),
                  });

                  data?.data?.otodokesakiList?.map(
                    (item: any, indexList: number) =>
                      Object.keys(item).map((key, index) =>
                        SetFieldValue(key, item[key], indexList),
                      ),
                  );
                  setTimeout(() => {
                    const _default = form.getFieldsValue(true);
                    setDefaultJsonValue(_default);
                  }, 0);
                  if (mode === MODE_DELETE) {
                    setMutationMode('delete');
                    setFocusSubmitDelHan();
                  } else {
                    setMutationMode('update');
                    if (data?.data?.deldate !== AVAILABLE) {
                      setFocusRestoreHan();
                    } else {
                      setFocusHanNm();
                    }
                  }
                  setDisableInputHanCd(true);
                  setFocusHanNm();
                },
                onCancel: () => {
                  form.setFieldsValue({
                    hanCd: hanCd,
                  });
                  Modal.destroyAll();
                },
              });
            } else {
              form.setFieldsValue({
                hanNm: CheckNull(data?.data?.hannm),
                syozoku: CheckNull(data?.data?.syozoku),
                hikitoriBin: CheckNull(data?.data?.binCd),
                captyHanCd: CheckNull(data?.data?.captyHanCd),
              });

              data?.data?.otodokesakiList?.map((item: any, indexList: number) =>
                Object.keys(item).map((key, index) =>
                  SetFieldValue(key, item[key], indexList),
                ),
              );
              setTimeout(() => {
                const _default = form.getFieldsValue(true);
                setDefaultJsonValue(_default);
              }, 0);
              if (mode === MODE_DELETE) {
                setMutationMode('delete');
                setFocusSubmitDelHan();
              } else {
                setMutationMode('update');
                if (data?.data?.deldate !== AVAILABLE) {
                  setFocusRestoreHan();
                } else {
                  setFocusHanNm();
                }
              }
              setDisableInputHanCd(true);
              setFocusHanNm();
            }
          } else {
            setMutationMode('create');
            setDisableInputHanCd(false);
            setFocusHanNm();
            setHanCd(_value);
          }
        },
      },
    );
  }

  const isDeletedHan =
    dataHanInfo &&
    dataHanInfo?.data !== '' &&
    dataHanInfo?.data?.deldate !== AVAILABLE;

  const GroupAddressComponent = ({
    isDeleted,
    index,
    item,
  }: {
    isDeleted: boolean;
    index: number;
    item: any;
  }) => {
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);

    return (
      <div key={index}>
        <Space>
          <Space
            style={{
              width: '300px',
              margin: '5px 0px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TitleGenbaCD>{item.genbaTitle}</TitleGenbaCD>
            {mode === MODE_DELETE &&
              !isDeletedHan &&
              dataHanInfo?.data?.otodokesakiList?.length > 0 &&
              dataHanInfo?.data?.otodokesakiList[index] &&
              dataHanInfo?.data?.otodokesakiList[index]?.genbanm !== '' &&
              dataHanInfo?.data?.otodokesakiList[index].deldate ===
              AVAILABLE && (
                <Button
                  onClick={() => {
                    messagePopup({
                      type: 'question',
                      content: 'お届け先（便）情報を削除してもよろしいですか',
                      onOk: () => {
                        mutationDeleteOtodo(
                          {
                            torihikiCd: torihikiCd,
                            hanCd: hanCd,
                            genbaCd:
                              dataHanInfo?.data?.otodokesakiList[index]
                                ?.genbaCd,
                          },
                          {
                            onSuccess(data, variables, context) {
                              messagePopup({
                                type: 'success',
                                content:
                                  MSG_ERROR['MSG_DELETE_OTODOKESAKI_SUCCESS'],
                                onOk: () => {
                                  mutateHan(hanCd);
                                  // mutateHanInfo({
                                  //   torihikiCd: torihikiCd,
                                  //   hanCd: hanCd,
                                  // });
                                },
                              });
                            },
                            onError(error, variables, context) {
                              messagePopup({
                                type: 'info',
                                content:
                                  MSG_ERROR[
                                  (error as any)?.response?.data?.message
                                  ] || error?.response?.data?.message,
                              });
                            },
                          },
                        );
                      },
                    });
                  }}
                >
                  お届け先削除
                </Button>
              )}
            {mode !== MODE_DELETE && isDeleted && !isDeletedHan && (
              <Button
                onClick={() => {
                  messagePopup({
                    type: 'question',
                    content: 'お届け先（便）情報を復活してもよろしいですか',
                    onOk: () => {
                      mutationRestoreOtodokesaki(
                        {
                          torihikiCd: torihikiCd,
                          hanCd: hanCd,
                          genbaCd:
                            dataHanInfo?.data?.otodokesakiList[index]?.genbaCd,
                        },
                        {
                          onSuccess(data, variables, context) {
                            messagePopup({
                              type: 'success',
                              content: 'お届け先（便）情報を復活しました',
                              onOk: () => mutateHan(hanCd),
                              // mutateHanInfo({
                              //   torihikiCd: torihikiCd,
                              //   hanCd: hanCd,
                              // }),
                            });
                          },
                          onError(error, variables, context) {
                            messagePopup({
                              type: 'info',
                              content:
                                MSG_ERROR[
                                (error as any)?.response?.data?.message
                                ] || error?.reponse?.data?.message,
                            });
                          },
                        },
                      );
                    },
                  });
                }}
              >
                お届け先復活
              </Button>
            )}
          </Space>
          {!isDeletedHan && isDeleted && (
            <span
              style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: 16,
              }}
            >
              このお届け先（便）情報は削除済みです。
            </span>
          )}
        </Space>

        <Form.Item name={`genbaCd${index + 1}`} hidden={true}>
          <Input readOnly style={{ width: 50 }} />
        </Form.Item>
        <Form.Item
          label={`１．お届け先名(全角20桁)`}
          name={`genbanm${index + 1}`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
          rules={[
            ({ getFieldValue }) => ({
              validator(_: any, value: string) {
                const _adrs = getFieldValue(`adrs${index + 1}`) || '';
                const _binCd = getFieldValue(`binCd${index + 1}`) || '';
                const _captyHanCd =
                  getFieldValue(`captyHanCd${index + 1}`) || '';
                const _telNo = getFieldValue(`tel${index + 1}`) || '';
                const _faxNo = getFieldValue(`fax${index + 1}`) || '';
                const _post1 = getFieldValue(`postcd1${index + 1}`) || '';
                const _post2 = getFieldValue(`postcd2${index + 1}`) || '';

                if (
                  [undefined, ''].includes(value) &&
                  (_adrs ||
                    _binCd ||
                    _captyHanCd ||
                    _telNo ||
                    _faxNo ||
                    _post1 ||
                    _post2)
                ) {
                  return Promise.reject(
                    new Error('お届け先名' + MSG_ERROR['MSG_INPUT']),
                  );
                }
                if (value && !IsZenkaku(value)) {
                  return Promise.reject(
                    new Error('お届け先名' + MSG_ERROR['MSG_HANKAKU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={disableInput || isDeleted || isDeletedHan}
            // ref={el => (genbanm.current[index] = el)}
            ref={el => (hanOtodokesakiRef.current[`genbanm${index + 1}`] = el)}
            style={{ width: 300 }}
            maxLength={20}
            onBlur={event => {
              SetFieldValue('genbanm', event.target.value, index);
            }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onPressEnter={e => {
              e.preventDefault();
              hanOtodokesakiRef.current[`postcd1${index + 1}`].focus();
              // FocusElement(hanOtodokesakiRef.current['postcd1'], index);
            }}
            className={isDeleted || isDeletedHan ? 'delete-input' : ''}
          />
        </Form.Item>
        <Form.Item
          label={`２．郵便番号(半角数字3桁,4桁)`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
        >
          <Space size={4}>
            <Form.Item
              name={`postcd1${index + 1}`}
              noStyle
              rules={[
                ({ getFieldValue }) => ({
                  validator(_: any, value: string) {
                    const _post2 = getFieldValue(`postcd2${index + 1}`);
                    if ([undefined, ''].includes(value) && !_post2) {
                      return Promise.resolve();
                    }
                    if (value === '' || _post2 === '') {
                      return Promise.reject(
                        new Error('郵便番号' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (!isNumeric(value) || !isNumeric(_post2)) {
                      return Promise.reject(
                        new Error('郵便番号' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput || isDeleted || isDeletedHan}
                ref={el =>
                  (hanOtodokesakiRef.current[`postcd1${index + 1}`] = el)
                }
                maxLength={3}
                style={{ width: 70 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onBlur={event => {
                  SetFieldValue('postcd1', event.target.value, index);
                }}
                onPressEnter={e => {
                  e.preventDefault();
                  hanOtodokesakiRef.current[`postcd2${index + 1}`].focus();
                  // FocusElement(hanOtodokesakiRef.current['postcd2'], index);
                }}
                className={isDeleted || isDeletedHan ? 'delete-input' : ''}
              />
            </Form.Item>
            <span>-</span>
            <Form.Item
              name={`postcd2${index + 1}`}
              noStyle
              rules={[
                ({ getFieldValue }) => ({
                  validator(_: any, value: string) {
                    const _post1 = getFieldValue(`postcd1${index + 1}`);
                    if ([undefined, ''].includes(value) && !_post1) {
                      return Promise.resolve();
                    }
                    if (value === '' || _post1 === '') {
                      return Promise.reject(
                        new Error('郵便番号' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (!isNumeric(value) || !isNumeric(_post1)) {
                      return Promise.reject(
                        new Error('郵便番号' + MSG_ERROR['MSG_NUMERIC']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput || isDeleted || isDeletedHan}
                ref={el =>
                  (hanOtodokesakiRef.current[`postcd2${index + 1}`] = el)
                }
                maxLength={4}
                style={{ width: 100 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onBlur={event => {
                  SetFieldValue('postcd2', event.target.value, index);
                }}
                onPressEnter={e => {
                  e.preventDefault();
                  hanOtodokesakiRef.current[`adrs${index + 1}`].focus();
                  // FocusElement(hanOtodokesakiRef.current['adrs'], index);
                }}
                className={isDeleted || isDeletedHan ? 'delete-input' : ''}
              />
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label={`３．住所(全角40桁)`}
          name={`adrs${index + 1}`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
          rules={[
            ({ getFieldValue }) => ({
              validator(_: any, value: string) {
                const _genbanm = getFieldValue(`genbanm${index + 1}`) || '';
                const _binCd = getFieldValue(`binCd${index + 1}`) || '';
                const _captyHanCd =
                  getFieldValue(`captyHanCd${index + 1}`) || '';
                const _telNo = getFieldValue(`tel${index + 1}`) || '';
                const _faxNo = getFieldValue(`fax${index + 1}`) || '';
                const _post1 = getFieldValue(`postcd1${index + 1}`) || '';
                const _post2 = getFieldValue(`postcd2${index + 1}`) || '';
                if (
                  [undefined, ''].includes(value) &&
                  (_genbanm ||
                    _binCd ||
                    _captyHanCd ||
                    _telNo ||
                    _faxNo ||
                    _post1 ||
                    _post2)
                ) {
                  return Promise.reject(
                    new Error('住所' + MSG_ERROR['MSG_INPUT']),
                  );
                }
                if (value && !IsZenkaku(value)) {
                  return Promise.reject(
                    new Error('住所' + MSG_ERROR['MSG_HANKAKU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={disableInput || isDeleted || isDeletedHan}
            ref={el => (hanOtodokesakiRef.current[`adrs${index + 1}`] = el)}
            style={{ width: 500 }}
            maxLength={40}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              SetFieldValue('adrs', event.target.value, index);
            }}
            onPressEnter={e => {
              e.preventDefault();
              // FocusElement(hanOtodokesakiRef.current['tel'], index);
              hanOtodokesakiRef.current[`tel${index + 1}`].focus();
            }}
            className={isDeleted || isDeletedHan ? 'delete-input' : ''}
          />
        </Form.Item>
        <Form.Item
          label={`４．電話番号(半角15桁)`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
        >
          <Space>
            <Form.Item
              noStyle
              name={`tel${index + 1}`}
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    // if (value && !isNumberPhoneFax(value)) {
                    //   return Promise.reject(
                    //     new Error('電話番号' + MSG_ERROR['MSG_ZENKAKU']),
                    //   );
                    // }
                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('電話番号' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput || isDeleted || isDeletedHan}
                ref={el => (hanOtodokesakiRef.current[`tel${index + 1}`] = el)}
                maxLength={15}
                style={{ width: 150 }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onBlur={event => {
                  SetFieldValue('tel', event.target.value, index);
                }}
                onPressEnter={e => {
                  e.preventDefault();
                  hanOtodokesakiRef.current[`fax${index + 1}`].focus();
                  // FocusElement(hanOtodokesakiRef.current['fax'], index);
                }}
                className={isDeleted || isDeletedHan ? 'delete-input' : ''}
              />
            </Form.Item>
            <span>(例 xxx-xxxx-xxxx)</span>
          </Space>
        </Form.Item>
        <Form.Item
          label={`５．FAX番号(半角15桁)`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
        >
          <Space>
            <Form.Item
              noStyle
              name={`fax${index + 1}`}
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    // if (value && !isNumberPhoneFax(value)) {
                    //   return Promise.reject(
                    //     new Error('電話番号' + MSG_ERROR['MSG_ZENKAKU']),
                    //   );
                    // }
                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('FAX番号' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableInput || isDeleted || isDeletedHan}
                ref={el => (hanOtodokesakiRef.current[`fax${index + 1}`] = el)}
                maxLength={15}
                style={{ width: 150 }}
                onBlur={event => {
                  SetFieldValue('fax', event.target.value, index);
                }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onPressEnter={e => {
                  e.preventDefault();
                  hanOtodokesakiRef.current[`binCd${index + 1}`].focus();
                  // FocusElement(hanOtodokesakiRef.current['binCd'], index);
                }}
                className={isDeleted || isDeletedHan ? 'delete-input' : ''}
              />
            </Form.Item>
            <span>(例 xxx-xxxx-xxxx)</span>
          </Space>
        </Form.Item>
        <Form.Item
          label={`６．便`}
          name={`binCd${index + 1}`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
          rules={[
            ({ getFieldValue }) => ({
              validator(_: any, value: string) {
                const _genbanm = getFieldValue(`genbanm${index + 1}`) || '';
                const _adrs = getFieldValue(`adrs${index + 1}`) || '';
                const _captyHanCd =
                  getFieldValue(`captyHanCd${index + 1}`) || '';
                const _telNo = getFieldValue(`tel${index + 1}`) || '';
                const _faxNo = getFieldValue(`fax${index + 1}`) || '';
                const _post1 = getFieldValue(`postcd1${index + 1}`) || '';
                const _post2 = getFieldValue(`postcd2${index + 1}`) || '';
                if (
                  [undefined, ''].includes(value) &&
                  (_genbanm ||
                    _adrs ||
                    _captyHanCd ||
                    _telNo ||
                    _faxNo ||
                    _post1 ||
                    _post2)
                ) {
                  return Promise.reject(
                    new Error('便' + MSG_ERROR['MSG_SELECT']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            open={openDropdown}
            onDropdownVisibleChange={open => setOpenDropdown(open)}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                setOpenDropdown(false);
                hanOtodokesakiRef.current[`captyHanCd${index + 1}`].focus();
              } else if (event.key === ' ') {
                event.preventDefault();
                setOpenDropdown(true);
              }
            }}
            placeholder={'選択してください'}
            disabled={disableInput || isDeleted || isDeletedHan}
            ref={el => (hanOtodokesakiRef.current[`binCd${index + 1}`] = el)}
            style={{ width: 200 }}
            allowClear={true}
            onChange={event => {
              hanOtodokesakiRef.current[`captyHanCd${index + 1}`].focus();
              // FocusElement(hanOtodokesakiRef.current['captyHanCd'], index);
            }}
            className={isDeleted || isDeletedHan ? 'delete-input' : ''}
            getPopupContainer={(trigger: HTMLElement) =>
              trigger.parentNode as HTMLElement
            }
          >
            {dataListBin?.data?.map((item: any, index: number) => (
              <Select.Option
                key={index}
                value={item.bincd}
              >{`${item.bincd} : ${item.binnm1}`}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`７．旧班コード(半角数字3桁)`}
          name={`captyHanCd${index + 1}`}
          className={
            disableInput || isDeleted || isDeletedHan ? 'disable-input' : ''
          }
          rules={[
            ({ getFieldValue }) => ({
              validator(_: any, value: string) {
                const _genbanm = getFieldValue(`genbanm${index + 1}`) || '';
                const _adrs = getFieldValue(`adrs${index + 1}`) || '';
                const _binCd = getFieldValue(`binCd${index + 1}`) || '';
                const _telNo = getFieldValue(`tel${index + 1}`) || '';
                const _faxNo = getFieldValue(`fax${index + 1}`) || '';
                const _post1 = getFieldValue(`postcd1${index + 1}`) || '';
                const _post2 = getFieldValue(`postcd2${index + 1}`) || '';

                if (
                  [undefined, ''].includes(value) &&
                  (_genbanm ||
                    _adrs ||
                    _binCd ||
                    _telNo ||
                    _faxNo ||
                    _post1 ||
                    _post2)
                ) {
                  return Promise.reject(
                    new Error('旧班コード' + MSG_ERROR['MSG_INPUT']),
                  );
                }
                let _value = ToASCII(value, false, false);
                if (_value
                  && !onlyLettersAndNumbers(_value)
                ) {
                  return Promise.reject(
                    new Error('旧班コード' + MSG_ERROR['MSG_NUMERICLETTER']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={disableInput || isDeleted || isDeletedHan}
            ref={el =>
              (hanOtodokesakiRef.current[`captyHanCd${index + 1}`] = el)
            }
            style={{ width: 70 }}
            maxLength={3}
            onBlur={event => {
              SetFieldValue('captyHanCd', event.target.value, index);
              // if (index < 3) {
              //   if (
              //     hanOtodokesakiRef.current[`captyHanCd${index + 1}`]?.input
              //       .disabled === false
              //   ) {
              //     hanOtodokesakiRef.current[`genbanm${index + 2}`].focus();
              //   } else if (
              //     hanOtodokesakiRef.current[`captyHanCd${index + 2}`]?.input
              //       .disabled === false
              //   ) {
              //     hanOtodokesakiRef.current[`genbanm${index + 3}`].focus();
              //   } else {
              //     if (_refSubmit.current) {
              //       _refSubmit.current.focus();
              //     }
              //   }
              // }
            }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onPressEnter={(e: any) => {
              e.preventDefault();
              if (index === 3) {
                SetFieldValue('captyHanCd', e.target.value, index);
                if (_refSubmit.current) {
                  _refSubmit.current.focus();
                }
              } else {
                e.target.blur();
              }
            }}
            className={isDeleted || isDeletedHan ? 'delete-input' : ''}
          />
        </Form.Item>
        <Form.Item name={`deldate${index + 1}`} hidden={true}>
          <Input readOnly style={{ width: 50 }} />
        </Form.Item>
      </div>
    );
  };

  const memoGroupsAddress = React.useMemo(
    () =>
      GroupAddress.map((item: any, index: number) => {
        const isDeleted =
          dataHanInfo &&
          dataHanInfo?.data !== '' &&
          dataHanInfo?.data?.otodokesakiList[index]?.deldate !== AVAILABLE &&
          dataHanInfo?.data?.otodokesakiList[index]?.genbanm !== '';

        return (
          <GroupAddressComponent
            key={index}
            isDeleted={isDeleted}
            index={index}
            item={item}
          />
        );
      }),
    [mode, dataHanInfo, dataListBin, disableInput],
  );

  useEffect(() => {
    if (memoGroupsAddress)
      GroupAddress.map((item: any, index: number) =>
        SetFieldValue('genbaCd', item.genbaCd, index),
      );
  }, [memoGroupsAddress]);

  const CheckChangesValues = () => {
    if (
      (defaultJsonValue !== null &&
        JSON.stringify(defaultJsonValue) !==
        JSON.stringify(form.getFieldsValue(true))) ||
      (dataHanInfo && dataHanInfo?.data === '' && form.getFieldsValue(true))
    ) {
      return true;
    }
    return false;
  };

  return (
    <WrapperMainte>
      {(loadingTorihikisaki ||
        loadingListBin ||
        loadingHanInfo ||
        loadingUpsertHanOtodokesaki ||
        loadingDeleteOtodo ||
        loadingDeleteHanBinCds ||
        loadingRestoreOtodokesaki ||
        loadingRestoreHan) && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="order"
        labelCol={{ flex: '300px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateTrigger={['onSubmit']}
        // autoComplete="off"
        requiredMark={false}
        labelAlign="left"
        colon={false}
        initialValues={{ hanCd: '' }}
      // onKeyPress={e => {
      //   if (e.key === 'Enter') {
      //     e.preventDefault();
      //   }
      // }}
      >
        <HeaderForm>
          <TitleMainte>
            <span className="label-mode">{labelModeOnChange()}</span>
            {/* <span>モード設定</span> */}
          </TitleMainte>
          <SwitchModeTabs
            activeKey={mode}
            onChange={(key: string) => {
              function changeKey(toKey: any) {
                let typeMutation = '';
                switch (toKey) {
                  case MODE_CREATE_UPDATE:
                    typeMutation = 'create';
                    logAccess([
                      {
                        tokuiCd: userInfo.torihikicd,
                        gyoumuId: '班・お届け先（便）メンテ',
                        pgName: mode,
                        logType: '2',
                        action: '登録／修正ボタン',
                      },
                    ] as any);
                    break;
                  case MODE_DELETE:
                    typeMutation = 'delete';
                    logAccess([
                      {
                        tokuiCd: userInfo.torihikicd,
                        gyoumuId: '班・お届け先（便）メンテ',
                        pgName: mode,
                        logType: '2',
                        action: '削除ボタン',
                      },
                    ] as any);
                    break;

                  default:
                    break;
                }
                setMode(toKey as any);
                setMutationMode(typeMutation as any);
                resetForm();
              }
              if (CheckChangesValues()) {
                messagePopup({
                  type: 'warning',
                  content: MSG_ERROR['MSG_VALUES_CHANGE'],
                  onOk: () => {
                    changeKey(key);
                  },
                });
              } else {
                changeKey(key);
              }
            }}
          >
            <Tabs.TabPane tab={'登録／修正モード'} key={MODE_CREATE_UPDATE} />
            <Tabs.TabPane tab={'削除モード'} key={MODE_DELETE} />
          </SwitchModeTabs>
        </HeaderForm>
        <BodyForm>
          <TitleGenbaCD>設定情報</TitleGenbaCD>
          <Form.Item
            label="１．取引先コード(半角英数字6桁)"
            className={disableInputTori ? 'disable-input' : ''}
          >
            <Space size={4}>
              <Form.Item
                name="torihikiCd"
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!value) {
                        return Promise.reject(
                          new Error('取引先コード' + MSG_ERROR['MSG_INPUT']),
                        );
                      }
                      if (IsHankakuEisu(value)) {
                        return Promise.reject(
                          new Error(
                            '取引先コード' + MSG_ERROR['MSG_HANKAKU_EISU'],
                          ),
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
                noStyle
              >
                <Input
                  autoFocus
                  ref={refFieldTohi}
                  disabled={disableInputTori}
                  maxLength={6}
                  style={{ width: 100 }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onFocus={event => {
                    setPreviousValue({
                      ...previousValue,
                      torihikiCd: event.target.value,
                    });
                  }}
                  onBlur={(event: any) => {
                    event.preventDefault();
                    let _value = ToASCII(event.target.value);
                    form.setFieldsValue({
                      torihikiCd: _value,
                    });
                    checkTorihikicd(_value);
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      event.target.blur();
                      if (!event.target.value) {
                        setFocusBtnSearch();
                      }
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="torihikiNm" noStyle>
                <Input disabled style={{ width: 300 }} />
              </Form.Item>
              <Button
                ref={refBtnSearch}
                disabled={disableInputTori}
                onClick={() => {
                  logAccess([
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '班・お届け先（便）メンテ',
                      pgName: '班・お届け先（便）メンテ',
                      logType: '2',
                      action: '取引先検索ボタン',
                    },
                    {
                      tokuiCd: userInfo.torihikicd,
                      gyoumuId: '班・お届け先（便）メンテ',
                      pgName: '取引先検索',
                      logType: '1',
                      action: '',
                    },
                  ] as any);
                  setIsVisibleTorihikisaki(true);
                }}
                type="default"
              >
                取引先検索
              </Button>
            </Space>
          </Form.Item>
          <DividerStyle />
          <Space>
            <Space
              style={{
                width: '300px',
                marginBottom: '5px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TitleGenbaCD>班設定</TitleGenbaCD>
              {mode === MODE_DELETE &&
                dataHanInfo &&
                dataHanInfo?.data &&
                dataHanInfo?.data?.deldate === AVAILABLE && (
                  <Button
                    type="primary"
                    style={{ minWidth: 106 }}
                    htmlType="submit"
                    ref={refSubmitDelHan}
                  >
                    班削除
                  </Button>
                )}
              {mode !== MODE_DELETE && isDeletedHan && (
                <Button
                  ref={refFocusRestoreHan}
                  style={{ width: 106 }}
                  onClick={() => {
                    messagePopup({
                      type: 'question',
                      content:
                        '班および班に紐づくお届け先（便）情報を復活してもよろしいですか',
                      onOk: () => {
                        mutationRestoreHan(
                          {
                            torihikiCd: torihikiCd,
                            hanCd: hanCd,
                          },
                          {
                            onSuccess(data, variables, context) {
                              messagePopup({
                                type: 'success',
                                content:
                                  '班および班に紐づくお届け先（便）情報を復活しました',
                                onOk: () => mutateHan(hanCd),
                                // mutateHanInfo({
                                //   torihikiCd: torihikiCd,
                                //   hanCd: hanCd,
                                // }),
                              });
                            },
                            onError(error, variables, context) {
                              messagePopup({
                                type: 'info',
                                content:
                                  MSG_ERROR[
                                  (error as any)?.response?.data?.message
                                  ] || error?.reponse?.data?.message,
                              });
                            },
                          },
                        );
                      },
                    });
                  }}
                >
                  班復活
                </Button>
              )}
            </Space>
            {isDeletedHan && (
              <span style={{ color: 'red', fontWeight: 'bold', fontSize: 16 }}>
                この班は削除済みです。
              </span>
            )}
          </Space>

          <Form.Item
            label="２．班コード(半角数字3桁)"
            className={disableInputHanCd || isDeletedHan ? 'disable-input' : ''}
            name="hanCd"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error(
                        '班コード' +
                        MSG_ERROR[
                        mode === MODE_DELETE ? 'MSG_SELECT' : 'MSG_INPUT'
                        ],
                      ),
                    );
                  }
                  let _value = ToASCII(value, false, false);
                  if (!onlyLettersAndNumbers(_value)) {
                    return Promise.reject(
                      new Error('班コード' + MSG_ERROR['MSG_NUMERICLETTER']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {mode === MODE_DELETE ? (
              <Select
                open={openDropdown.hancd}
                onDropdownVisibleChange={open =>
                  setOpenDropdown({ ...openDropdown, hancd: open })
                }
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    setOpenDropdown({ ...openDropdown, hancd: false });
                  } else if (event.key === ' ') {
                    event.preventDefault();
                    setOpenDropdown({ ...openDropdown, hancd: true });
                  }
                }}
                placeholder={'選択してください'}
                ref={refHanCd}
                disabled={disableInputHanCd}
                style={{ width: 200 }}
                onSelect={(value: string) => {
                  setHanCd(value);
                  setDisableInputHanCd(true);
                  mutateHan(value);
                }}
                className={isDeletedHan ? 'delete-input' : ''}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              >
                {dataTorihikisaki &&
                  dataTorihikisaki?.data?.hanList?.length > 0 && (
                    <Select.Option key="班を選択してください" value="">
                      班を選択してください
                    </Select.Option>
                  )}

                {dataTorihikisaki?.data?.hanList?.map(
                  (item: any, index: number) => (
                    <Select.Option key={index} value={item.hancd}>
                      <span
                        style={{
                          textDecoration: item.isDeleted
                            ? 'line-through'
                            : 'none',
                        }}
                      >
                        {`${item.hancd} : ${item.hannm}`}
                      </span>
                      <span
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                        }}
                      >{`${item.isDeleted &&
                        form.getFieldValue('hanCd') !== item.hancd
                        ? ' (は削除済)'
                        : ''
                        }
                      `}</span>
                    </Select.Option>
                  ),
                )}
              </Select>
            ) : (
              <Input
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={event => {
                  setPreviousValue({
                    ...previousValue,
                    hanCd: event.target.value,
                  });
                }}
                onBlur={event => {
                  event.preventDefault();
                  let str = ToASCII(event.target.value).trimEnd();
                  form.setFieldsValue({
                    hanCd: str,
                  });
                  if (str !== previousValue.hanCd)
                    form
                      .validateFields(['hanCd'])
                      .then(value => {
                        setHanCd(value.hanCd);
                        mutateHan(value.hanCd, previousValue.hanCd !== '');
                      })
                      .catch(({ errorFields }: any) => {
                        setHanCd(null);
                        messagePopup({
                          type: 'info',
                          content: errorFields[0]?.errors[0],
                          onOk: () => {
                            setFocusHancd();
                          },
                        });
                      });
                }}
                onPressEnter={(event: any) => {
                  event.target.blur();
                }}
                disabled={
                  disableInputHanCd ||
                  (dataHanInfo &&
                    dataHanInfo?.data !== '' &&
                    dataHanInfo?.data?.deldate !== AVAILABLE)
                }
                ref={refHanCd}
                maxLength={3}
                style={{ width: 70 }}
                className={isDeletedHan ? 'delete-input' : ''}
              />
            )}
          </Form.Item>
          <Form.Item
            label="３．班名(全角20桁)"
            name="hanNm"
            className={disableInput || isDeletedHan ? 'disable-input' : ''}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('班名' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  if (value && !IsZenkaku(value)) {
                    return Promise.reject(
                      new Error('班名' + MSG_ERROR['MSG_HANKAKU']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={refHanNm}
              disabled={disableInput || isDeletedHan}
              style={{ width: 300 }}
              maxLength={20}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onBlur={event => {
                form.setFieldsValue({
                  hanNm: Hankaku2Zenkaku(event.target.value).trimEnd(),
                });
              }}
              onPressEnter={e => {
                e.preventDefault();
                setFocusSyozoku();
              }}
              className={isDeletedHan ? 'delete-input' : ''}
            />
          </Form.Item>
          <Form.Item
            label="４．所属(全角20桁)"
            name="syozoku"
            className={disableInput || isDeletedHan ? 'disable-input' : ''}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (value && !IsZenkaku(value)) {
                    return Promise.reject(
                      new Error('所属' + MSG_ERROR['MSG_HANKAKU']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={refSyozoku}
              disabled={disableInput || isDeletedHan}
              style={{ width: 300 }}
              maxLength={20}
              onBlur={event => {
                form.setFieldsValue({
                  syozoku: Hankaku2Zenkaku(event.target.value).trim(),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onPressEnter={e => {
                e.preventDefault();
                setFocusHikitoriBin();
              }}
              className={isDeletedHan ? 'delete-input' : ''}
            />
          </Form.Item>
          <DividerStyle />
          <Space style={{ marginBottom: '5px' }}>
            <TitleGenbaCD>引取</TitleGenbaCD>
          </Space>
          <Form.Item
            label="５．引取用便"
            name="hikitoriBin"
            className={disableInput || isDeletedHan ? 'disable-input' : ''}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('引取用便' + MSG_ERROR['MSG_SELECT']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              allowClear
              open={openDropdown.hikitoriBin}
              onDropdownVisibleChange={open =>
                setOpenDropdown({ ...openDropdown, hikitoriBin: open })
              }
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  setOpenDropdown({ ...openDropdown, hikitoriBin: false });
                  setFocusCaptyHanCd();
                } else if (event.key === ' ') {
                  event.preventDefault();
                  setOpenDropdown({ ...openDropdown, hikitoriBin: true });
                }
              }}
              placeholder={'選択してください'}
              disabled={disableInput || isDeletedHan}
              ref={refHikitoriBin}
              style={{ width: 200 }}
              onChange={event => setFocusCaptyHanCd()}
              className={isDeletedHan ? 'delete-input' : ''}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger.parentNode as HTMLElement
              }
            >
              {dataListBin?.data?.map((item: any, index: number) => (
                <Select.Option
                  key={index}
                  value={item.bincd}
                >{`${item.bincd} : ${item.binnm1}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="６．旧班コード(半角数字3桁)"
            name="captyHanCd"
            className={disableInput || isDeletedHan ? 'disable-input' : ''}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('旧班コード' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  let _value = ToASCII(value, false, false);
                  if (!onlyLettersAndNumbers(_value)) {
                    return Promise.reject(
                      new Error('旧班コード' + MSG_ERROR['MSG_NUMERICLETTER']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              disabled={disableInput || isDeletedHan}
              ref={refCaptyHanCd}
              style={{ width: 70 }}
              maxLength={3}
              onChange={event => {
                form.setFieldsValue({
                  captyHanCd: toFullWidthAll(event.target.value).trim(),
                });
              }}
              onBlur={event => {
                form.setFieldsValue({
                  captyHanCd: ToASCII(event.target.value),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onPressEnter={(e: any) => {
                e.target.blur();
                setTimeout(() => {
                  if (
                    hanOtodokesakiRef.current[`captyHanCd1`]?.input.disabled ===
                    false
                  ) {
                    hanOtodokesakiRef.current[`genbanm1`].focus();
                    // FocusElement(hanOtodokesakiRef.current['genbanm'], 0);
                  } else if (
                    hanOtodokesakiRef.current[`captyHanCd2`]?.input.disabled ===
                    false
                  ) {
                    hanOtodokesakiRef.current[`genbanm2`].focus();
                    // FocusElement(hanOtodokesakiRef.current['genbanm'], 1);
                  } else if (
                    hanOtodokesakiRef.current[`captyHanCd3`]?.input.disabled ===
                    false
                  ) {
                    hanOtodokesakiRef.current[`genbanm3`].focus();
                    // FocusElement(hanOtodokesakiRef.current['genbanm'], 2);
                  } else if (
                    hanOtodokesakiRef.current[`captyHanCd4`]?.input.disabled ===
                    false
                  ) {
                    hanOtodokesakiRef.current[`genbanm4`].focus();
                    // FocusElement(hanOtodokesakiRef.current['genbanm'], 3);
                  } else {
                    if (_refSubmit.current) {
                      _refSubmit.current.focus();
                    }
                  }
                }, 0);
              }}
              className={isDeletedHan ? 'delete-input' : ''}
            />
          </Form.Item>
          <DividerStyle />
          {memoGroupsAddress}
          <Space
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {mode !== MODE_DELETE && torihikiCd && !isDeletedHan && (
              <Button
                style={{ width: 100 }}
                type="primary"
                htmlType="submit"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    form.submit();
                  }
                }}
                ref={_refSubmit}
              >
                確定
              </Button>
            )}
            <Button style={{ width: 100 }} onClick={() => window.print()}>
              印刷
            </Button>
            <Button
              style={{ width: 100 }}
              onClick={event => {
                if (CheckChangesValues()) {
                  messagePopup({
                    type: 'warning',
                    content: MSG_ERROR['MSG_VALUES_CHANGE'],
                    onOk: () => {
                      resetForm();
                    },
                    onCancel: () => {
                      (
                        (event.target as HTMLElement)
                          .offsetParent as HTMLButtonElement
                      ).focus();
                    },
                  });
                } else {
                  resetForm();
                }
              }}
            >
              画面クリア
            </Button>
            <Button
              onClick={event => {
                event.preventDefault();
                if (CheckChangesValues()) {
                  messagePopup({
                    type: 'warning',
                    content: MSG_ERROR['MSG_VALUES_CHANGE'],
                    onOk: () => {
                      resetForm();
                      navigate(-1);
                    },
                    onCancel: () => {
                      (
                        (event.target as HTMLElement)
                          .offsetParent as HTMLButtonElement
                      ).focus();
                    },
                  });
                } else {
                  resetForm();
                  navigate(-1);
                }
              }}
            >
              メニューへ戻る
            </Button>
          </Space>
        </BodyForm>
      </FormWrapper>
      <TorihikisakiSearch
        isModalVisible={isVisibleTorihikisaki}
        setIsModalVisible={setIsVisibleTorihikisaki}
        setTorihikiCd={setTorihikiCd}
        focusAfterClose={() => {
          setFocusBtnSearch();
        }}
      />
    </WrapperMainte>
  );
};

export default HanOtodokesaki;
