export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>伝票区分</div>,
    dataIndex: 'denKbn',
    key: 'denKbn',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 350 }}>品名(全角)</div>,
    dataIndex: 'nnm',
    key: 'nnm',
    render: (_value: any) => (
      <div style={{ minWidth: 350, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>規格</div>,
    dataIndex: 'nnmsz',
    key: 'nnmsz',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>回数</div>,
    dataIndex: 'countOrderKey',
    key: 'countOrderKey',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>数量合計</div>,
    dataIndex: 'sumBara',
    key: 'sumBara',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
];
