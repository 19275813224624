import { HotColumn, HotTable } from '@handsontable/react';
import { Button, Form, Input, Select, Space } from 'antd';
import { iDefinedValues } from 'api/common';
import { useCheckStockZaiko } from 'api/stockDetails';
import { useGetSyohinInfosShinki } from 'api/syohin';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import PickerCustom from 'components/RangePickerCustom/PickerCustom';
import { PATH } from 'configs/routes';
import { checkForSpecialChar, isNumeric, ToASCII } from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import { registerAllModules } from 'handsontable/registry';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectDefinedValues, selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import SyohinSearch from 'views/common/syohinSearch';
import {
  FooterForm,
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import ConfirmModal, { stockRefillReceiveData } from './confirmModal';

// register Handsontable's modules
registerAllModules();

const SYOHINCD_MAX_LENGTH = 11;

const SURYO_MAX_LENGTH = 6;

const InputStockRefillReceiveCreate = ({
  dataSiirem,
  setValuesChange,
}: any) => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const definedValues: iDefinedValues = useSelector(selectDefinedValues);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const [isVisibleSyohinSearch, setIsVisibleSyohinSearch] =
    useState<boolean>(false);

  const [disableSiire, setDisableSiire] = useState<boolean>(false);

  const [isVisibleConfirmModal, setIsVisibleConfirmModal] =
    useState<boolean>(false);

  const [stockRefillData, setStockRefillData] = useState<
    Array<stockRefillReceiveData>
  >([]);

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const hotTableComponent = useRef<any>(null);

  const dimension = useRef<{ col: number; row: number }>({
    col: -1,
    row: -1,
  });

  const formRef = useRef<any>({});

  const [form] = Form.useForm();

  const { mutate: checkZaiko, isLoading: loadingCheckZaiko } =
    useCheckStockZaiko();

  const { mutate: getLstSyohin, isLoading: loadingLstSyohin } =
    useGetSyohinInfosShinki();

  useEffect(() => {
    if (!definedValues) return;
    form.setFieldsValue({ siireCd: definedValues.tokyoGas_SiiresakiCd });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definedValues]);

  useEffect(() => {
    if (disableSiire) setValuesChange(true);
    else setValuesChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableSiire]);

  const onFinish = ({ nyukaDt, siireCd }: any) => {
    const allRowsData = hotTableComponent?.current?.hotInstance?.getData();
    // Skip rows having no data
    let allMeisai = allRowsData?.filter(
      (item: any) => !!item[0] || !!item[1] || !!item[3],
    );
    if (!allMeisai?.length) {
      // If do not fill any product
      messagePopup({
        type: 'info',
        content: '品名CDを入力してください',
        onOk: () => {
          hotTableComponent?.current?.hotInstance.selectCell(0, 0);
        },
      });
      return;
    }
    let errFlag = false;
    // Loop all rows in the table to validate
    allRowsData?.every((meisai: any, index: number) => {
      // ---------------------------------------------
      // meisai[0]: product code
      // meisai[1]: product name
      // meisai[2]:
      // meisai[3]: amount
      // ---------------------------------------------

      // Skip rows having no data
      if (
        !meisai[0]?.length &&
        !meisai[1]?.length &&
        !meisai[3]?.toString()?.length
      )
        return true;
      let duplicatedIndex: any = null;
      for (let idx = 0; idx < index; idx++) {
        if (
          !!allRowsData[idx][0]?.length &&
          allRowsData[idx][0] === meisai[0]
        ) {
          duplicatedIndex = idx;
          break;
        }
      }
      if (duplicatedIndex !== null) {
        // If have duplicated product in the table
        messagePopup({
          type: 'info',
          content: `この品名は${
            duplicatedIndex + 1
          }行目ですでに入力されています`,
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (!meisai[0]?.length) {
        // If product code is empty
        messagePopup({
          type: 'info',
          content: '品名CDを入力してから「入荷数」を入力してください',
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (checkForSpecialChar(meisai[0])) {
        // If product code contains special characters
        messagePopup({
          type: 'info',
          content: '品名CD' + MSG_ERROR['MSG_INVALID_CHAR'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (meisai[0]?.length > SYOHINCD_MAX_LENGTH) {
        // Check maximum characters
        messagePopup({
          type: 'info',
          content: `品名CDには${SYOHINCD_MAX_LENGTH}文字以下を入力してください`,
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (!meisai[1]?.length) {
        // If product code do not exist
        messagePopup({
          type: 'info',
          content: MSG_ERROR['MSG_009'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (!meisai[3]?.toString()?.length) {
        // If amount is empty
        messagePopup({
          type: 'info',
          content: '入荷数' + MSG_ERROR['MSG_INPUT'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 3);
          },
        });
        errFlag = true;
        return false;
      }
      if (!isNumeric(meisai[3], true)) {
        // If amount is not a number
        messagePopup({
          type: 'info',
          content: '入荷数' + MSG_ERROR['MSG_010'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 3);
          },
        });
        errFlag = true;
        return false;
      }
      if (parseInt(meisai[3]) === 0) {
        // If amount equals zero
        messagePopup({
          type: 'info',
          content: '入荷数に0は入力できません',
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 3);
          },
        });
        errFlag = true;
        return false;
      }
      if (meisai[3]?.toString()?.length > SURYO_MAX_LENGTH) {
        // Check maximum characters
        messagePopup({
          type: 'info',
          content: `入荷数には${SURYO_MAX_LENGTH}文字以下を入力してください`,
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 3);
          },
        });
        errFlag = true;
        return false;
      }
      return true;
    });
    if (errFlag) return;
    const selectedSiire = dataSiirem?.data?.find(
      (item: { siirecd: string; siirenm: string }) => item.siirecd === siireCd,
    );
    let request = allMeisai.map((item: any, index: number) => {
      return {
        index,
        code24: item[0],
        nnm: item[1],
        location: item[2],
        suryo: item[3],
        code10: item[4],
        code5: item[5],
        kanm: item[6],
        nyukaDt,
        siirecd: siireCd,
        siirenm: selectedSiire?.siirenm,
      };
    });
    // Check zaiko if return
    if (request.find((item: any) => item.suryo < 0)) {
      checkZaiko(
        {
          meisai: request.map((item: any) => ({
            code24: item.code24,
            suryo: item.suryo,
          })),
        },
        {
          onSuccess({ data }, variables, context) {
            if (!!data.length) {
              messagePopup({
                type: 'info',
                content: (
                  <div style={{ textAlign: 'left' }}>
                    <p>
                      {'以下の商品は在庫処分数量が現在庫数を超えています。'}
                    </p>
                    {data.map((item: { code24: string; zaiko: number }) => {
                      return (
                        <div key={item.code24}>
                          <span>{`　${item.code24}（現在庫数：${item.zaiko}）`}</span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                ),
                onOk: () => {
                  const errorIndex = hotTableComponent?.current?.hotInstance
                    ?.getData()
                    ?.findIndex((row: any) => row[0] === data[0].code24);
                  hotTableComponent?.current?.hotInstance.selectCell(
                    errorIndex,
                    3,
                  );
                },
              });
              return;
            }
            // Open confirm modal with infos were entered before
            setStockRefillData(request);
            setIsVisibleConfirmModal(true);
          },
          onError(error, variables, context) {
            messagePopup({
              type: 'error',
              content: error?.response?.data?.message,
            });
          },
        },
      );
    } else {
      // Open confirm modal with infos were entered before
      setStockRefillData(request);
      setIsVisibleConfirmModal(true);
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    // Show the first error from form's validator and focus on the error field after click on OK button
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        formRef?.current[errorFields[0]?.name[0]]?.focus();
      },
    });
  };

  const clearTable = () => {
    hotTableComponent?.current?.hotInstance?.updateSettings({
      data: [],
    });
    hotTableComponent?.current?.hotInstance.scrollViewportTo(0, 0);
  };

  const clearScreen = () => {
    setIsVisibleConfirmModal(false);
    setDisableSiire(false);
    form.resetFields();
    form.setFieldsValue({ siireCd: definedValues.tokyoGas_SiiresakiCd });
    clearTable();
    setTimeout(() => {
      formRef?.current?.torihikiCd?.focus();
    }, 300);
  };

  const checkSyohinCd = (row: number, syohinCd: string) => {
    let errCell: any = null;
    if (!syohinCd?.length) {
      errCell = [row, 0, '品名CDを入力してから「入荷数」を入力してください'];
    } else if (checkForSpecialChar(syohinCd)) {
      errCell = [row, 0, '品名CD' + MSG_ERROR['MSG_INVALID_CHAR']];
    } else if (syohinCd?.length > SYOHINCD_MAX_LENGTH) {
      errCell = [
        row,
        0,
        `品名CDには${SYOHINCD_MAX_LENGTH}文字以下を入力してください`,
      ];
    } else {
      // Find the duplicated product
      const allRowsData = hotTableComponent?.current?.hotInstance?.getData();
      let duplicatedIndex: any = null;
      for (let idx = 0; idx < row; idx++) {
        if (!!allRowsData[idx][0]?.length && allRowsData[idx][0] === syohinCd) {
          duplicatedIndex = idx;
          break;
        }
      }
      if (duplicatedIndex !== null) {
        // If have duplicated product in the table
        errCell = [
          row,
          0,
          `この品名は${duplicatedIndex + 1}行目ですでに入力されています`,
        ];
      }
    }
    return errCell;
  };

  return (
    <WrapperMainte>
      {(loadingLstSyohin || loadingCheckZaiko) && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="input-stock-refill"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        initialValues={{
          siireCd: null,
          nyukaDt: moment(),
          nyukaDtFull: moment().format('YYYY/MM/DD (ddd)'),
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        style={{ marginBottom: 20 }}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>

        <Form.Item
          label={
            <label>
              仕入先
              <label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
        >
          <Space size={4}>
            <Form.Item
              noStyle
              name="siireCd"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('仕入先' + MSG_ERROR['MSG_008']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                open={openDropdown}
                onDropdownVisibleChange={open => setOpenDropdown(open)}
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    setOpenDropdown(false);
                    formRef.current['nyukaDt'].focus();
                  } else if (event.key === ' ') {
                    event.preventDefault();
                    setOpenDropdown(true);
                  }
                }}
                disabled={disableSiire}
                autoFocus
                style={{
                  width: 255,
                }}
                placeholder={'選択してください'}
                ref={el => (formRef.current.siireCd = el)}
                options={dataSiirem?.data?.map(
                  (siire: { siirecd: string; siirenm: string }) => {
                    return {
                      value: siire.siirecd,
                      label: `${siire.siirecd} ${siire.siirenm}`,
                    };
                  },
                )}
                onSelect={(value: any) => {
                  if (value) {
                    formRef.current['nyukaDt'].focus();
                  }
                }}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              />
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label={
            <label>
              入荷日
              <label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
        >
          <Space size={4}>
            <PickerCustom
              title={'入荷日'}
              nextFocus={() =>
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                }, 0)
              }
              form={form}
              refForm={formRef}
              autoFocus={false}
              formName="nyukaDt"
              hideDD={true}
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                }, 0);
              }}
              validateRules={(value: any) => {
                if (!value) {
                  return '入荷日' + MSG_ERROR['MSG_INPUT'];
                }
                return null;
              }}
            />
            {/* <Form.Item
              noStyle
              name="nyukaDt"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('入荷日' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePickerCommon
                itemName={'入荷日'}
                placeholder={DATE_FORMAT}
                allowClear={false}
                refForm={formRef}
                formName="nyukaDt"
                // nextFocus="nyukaDtFull"
                nextFocus={() => formRef.current['nyukaDtFull'].focus()}
                onChange={(value: any) => {
                  form.setFieldsValue({
                    nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                  });
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                  }, 0);
                }}
                style={{ width: 100, padding: '2px 10px' }}
                format={DATE_FORMAT}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              />
            </Form.Item> */}
            <Form.Item
              noStyle
              name="nyukaDtFull"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('品名' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.nyukaDtFull = el)}
                disabled
                style={{ width: 150 }}
                onBlur={event => {
                  form.setFieldsValue({});
                }}
                onPressEnter={(event: any) => {}}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>
        {/* <Button
          ref={el => (formRef.current.btnSearchSyohin = el)}
          type="default"
          onClick={() => {
            if (!form.getFieldValue('siireCd')) {
              messagePopup({
                type: 'info',
                content: '仕入先' + MSG_ERROR['MSG_008'],
                onOk: () => {
                  formRef?.current['siireCd']?.focus();
                },
              });
              return;
            }
            setIsVisibleSyohinSearch(true);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') formRef.current.btnSearchSyohin.click();
          }}
          style={{ marginBottom: 4 }}
        >
          商品検索
        </Button> */}
      </FormWrapper>

      <HotTable
        ref={hotTableComponent}
        renderAllRows={true}
        width="100%"
        height="308px"
        minRows={99}
        maxRows={99}
        colHeaders={true}
        rowHeaders={true}
        rowHeaderWidth={40}
        afterGetRowHeader={(col, TH) => {
          TH.className = 'htRight';
        }}
        undo={false}
        rowHeights={28}
        licenseKey="non-commercial-and-evaluation"
        stretchH="all"
        activeHeaderClassName="header-table"
        hiddenColumns={{
          columns: [4, 5, 6],
        }}
        afterSelection={(row, column) => {
          dimension.current.row = row;
          dimension.current.col = column;
        }}
        beforeKeyDown={(e: any) => {
          if (
            e.target.closest('.handsontable') &&
            (e.keyCode === 9 || e.keyCode === 13) &&
            dimension.current.col > -1 &&
            dimension.current.row > -1
          ) {
            if (e.keyCode === 13) {
              e.stopImmediatePropagation();
            }
            if (dimension.current.col === 0) {
              if (e.shiftKey) {
                if (dimension.current.row === 0) {
                  e.stopImmediatePropagation();
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.deselectCell();
                    formRef.current['nyukaDt'].focus();
                  }, 0);
                } else {
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(
                      dimension.current.row - 1,
                      3,
                    );
                  }, 0);
                }
              } else {
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(
                    dimension.current.row,
                    3,
                  );
                }, 0);
              }
            } else if (dimension.current.col === 3) {
              if (e.shiftKey) {
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(
                    dimension.current.row,
                    0,
                  );
                }, 0);
              } else {
                if (dimension.current.row === 98) {
                  e.stopImmediatePropagation();
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.deselectCell();
                    formRef.current.btnSubmit.focus();
                  }, 0);
                } else {
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(
                      dimension.current.row + 1,
                      0,
                    );
                  }, 0);
                }
              }
            }
          }
          if (!e.target.closest('.handsontable') && e.keyCode !== 46) {
            e.stopImmediatePropagation();
            return;
          }
        }}
        beforeChange={(changes, source: any) => {
          if (!form.getFieldValue('siireCd')) {
            setTimeout(() => {
              messagePopup({
                type: 'info',
                content: '仕入先' + MSG_ERROR['MSG_008'],
                onOk: () => {
                  clearTable();
                  formRef?.current['siireCd']?.focus();
                },
              });
            }, 200);
            return true;
          }
          if (source === 'reset') return true;
          changes.forEach(cell => {
            // ---------------------------------------------
            // cell[0]: row index
            // cell[1]: column index
            // cell[2]: current value
            // cell[3]: new value
            // ---------------------------------------------

            if (cell[1] === 0) {
              // If change data in product code column
              cell[3] = ToASCII(cell[3], true).trim();
              if (!cell[3].length) {
                // Clear the entire row data if product code is empty
                hotTableComponent?.current?.hotInstance.setDataAtCell(
                  cell[0],
                  1,
                  null,
                  'reset',
                );
                hotTableComponent?.current?.hotInstance.setDataAtCell(
                  cell[0],
                  2,
                  null,
                  'reset',
                );
                hotTableComponent?.current?.hotInstance.setDataAtCell(
                  cell[0],
                  3,
                  null,
                  'reset',
                );
              }
            } else if (cell[1] === 3) {
              // If change data in amount column
              const value = ToASCII(cell[3]?.toString(), false)
                ?.replaceAll(',', '')
                ?.trim();
              // Remove all zero before the number
              cell[3] = isNumeric(value, true) ? parseInt(value) : value;
            }
          });
        }}
        afterChange={(changes, source: any) => {
          if (!form.getFieldValue('siireCd')) return true;
          let newSyohinCdList: any[] = [];
          let errCell: any = null;
          changes?.forEach(cell => {
            // ---------------------------------------------
            // cell[0]: row index
            // cell[1]: column index
            // cell[2]: old value
            // cell[3]: current value
            // ---------------------------------------------

            if (cell[1] === 0) {
              // If change data in product code column
              if (cell[2] !== cell[3]) {
                // Only validate when value was changed
                if (checkForSpecialChar(cell[3])) {
                  if (!errCell) {
                    errCell = [
                      cell[0],
                      cell[1],
                      '品名CD' + MSG_ERROR['MSG_INVALID_CHAR'],
                    ];
                  }
                } else if (cell[3]?.length > SYOHINCD_MAX_LENGTH) {
                  if (!errCell) {
                    errCell = [
                      cell[0],
                      cell[1],
                      `品名CDには${SYOHINCD_MAX_LENGTH}文字以下を入力してください`,
                    ];
                  }
                } else {
                  // Find the duplicated product
                  const allRowsData =
                    hotTableComponent?.current?.hotInstance?.getData();
                  let duplicatedIndex: any = null;
                  for (let idx = 0; idx < cell[0]; idx++) {
                    if (
                      !!allRowsData[idx][0]?.length &&
                      allRowsData[idx][0] === cell[3]
                    ) {
                      duplicatedIndex = idx;
                      break;
                    }
                  }
                  if (!errCell && duplicatedIndex !== null) {
                    // If have duplicated product in the table
                    errCell = [
                      cell[0],
                      cell[1],
                      `この品名は${
                        duplicatedIndex + 1
                      }行目ですでに入力されています`,
                    ];
                  }
                }
                if (!!cell[3]?.length) {
                  newSyohinCdList.push(cell[3]);
                }
              }
            } else if (cell[1] === 3) {
              // If change data in amount column
              const syohinNm =
                hotTableComponent?.current?.hotInstance.getDataAtCell(
                  cell[0],
                  1,
                );
              if (!errCell && cell[2] !== cell[3]) {
                // Only validate when value was changed
                // Check product code first
                if (!!cell[3]?.toString()?.length) {
                  errCell = checkSyohinCd(
                    cell[0],
                    hotTableComponent?.current?.hotInstance.getDataAtCell(
                      cell[0],
                      0,
                    ),
                  );
                }
                if (!errCell) {
                  if (!cell[3]?.toString()?.length && !!syohinNm) {
                    // If amount is empty
                    errCell = [
                      cell[0],
                      cell[1],
                      '入荷数' + MSG_ERROR['MSG_INPUT'],
                    ];
                  } else if (
                    !!cell[3]?.toString()?.length &&
                    !isNumeric(cell[3], true)
                  ) {
                    // If amount is not a number
                    errCell = [
                      cell[0],
                      cell[1],
                      '入荷数' + MSG_ERROR['MSG_010'],
                    ];
                  } else if (parseInt(cell[3]) === 0) {
                    // If amount equals zero
                    errCell = [cell[0], cell[1], '入荷数に0は入力できません'];
                  } else if (cell[3]?.toString()?.length > SURYO_MAX_LENGTH) {
                    errCell = [
                      cell[0],
                      cell[1],
                      `入荷数には${SURYO_MAX_LENGTH}文字以下を入力してください`,
                    ];
                  }
                }
              }
            }
          });
          let allCds = hotTableComponent?.current?.hotInstance
            ?.getData()
            .map((item: any) => item[0]);
          if (newSyohinCdList.length)
            // Only call API get product infos if have new product code
            getLstSyohin(
              {
                siireCd: form.getFieldValue('siireCd'),
                listCd24: newSyohinCdList,
              },
              {
                onSuccess({ data }, variables, context) {
                  let failedCds: any[] = [];
                  let syohinNotBelongToSiireLst: string[] = [];
                  data.forEach((item: any) => {
                    // Find the first valid product code to fill product name
                    const syohinIndex = allCds.findIndex(
                      (i: any) => i === item.cd24,
                    );
                    if (item.isBelongToSiire) {
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        syohinIndex,
                        1,
                        `${item.nnm || ''}${item.nnmsz || ''}`,
                        'reset',
                      );
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        syohinIndex,
                        2,
                        item.location,
                        'reset',
                      );
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        syohinIndex,
                        4,
                        item.cd10,
                        'reset',
                      );
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        syohinIndex,
                        5,
                        item.code5,
                        'reset',
                      );
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        syohinIndex,
                        6,
                        item.kanm,
                        'reset',
                      );
                    } else {
                      if (
                        !checkForSpecialChar(item.cd24) &&
                        item.cd24?.length <= SYOHINCD_MAX_LENGTH &&
                        !!item.nnm?.length
                      ) {
                        syohinNotBelongToSiireLst.push(item.cd24);
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          syohinIndex,
                          0,
                          null,
                          'reset',
                        );
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          syohinIndex,
                          1,
                          null,
                          'reset',
                        );
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          syohinIndex,
                          2,
                          null,
                          'reset',
                        );
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          syohinIndex,
                          3,
                          null,
                          'reset',
                        );
                      } else {
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          syohinIndex,
                          1,
                          null,
                          'reset',
                        );
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          syohinIndex,
                          2,
                          null,
                          'reset',
                        );
                      }
                    }

                    // Clear data of other duplicate products
                    allCds?.every((cd24: any, index: number) => {
                      if (cd24 !== item.cd24) return true;
                      if (index === syohinIndex) return true;
                      if (
                        syohinNotBelongToSiireLst.findIndex(
                          (syohinCd: string) => syohinCd === cd24,
                        ) >= 0
                      ) {
                        // Clear all row data if syohin is not belongs to selected siiire
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          index,
                          0,
                          null,
                          'reset',
                        );
                        hotTableComponent?.current?.hotInstance.setDataAtCell(
                          index,
                          3,
                          null,
                          'reset',
                        );
                      }
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        index,
                        1,
                        null,
                        'reset',
                      );
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        index,
                        2,
                        null,
                        'reset',
                      );
                      return true;
                    });
                    if (!item?.nnm) {
                      if (
                        !checkForSpecialChar(item.cd24) &&
                        item.cd24?.length <= SYOHINCD_MAX_LENGTH &&
                        syohinNotBelongToSiireLst.findIndex(
                          cd24 => cd24 === item.cd24,
                        ) < 0
                      )
                        // Product code is not exist
                        failedCds.push({ cd24: item.cd24, row: syohinIndex });
                    }
                  });
                  if (syohinNotBelongToSiireLst.length) {
                    messagePopup({
                      type: 'info',
                      content: (
                        <div style={{ textAlign: 'left' }}>
                          <p>{MSG_ERROR['MSG_NOT_BELONG_TO_SIIRE']}</p>
                          {[...new Set(syohinNotBelongToSiireLst)].map(
                            (cd24: string) => {
                              return (
                                <div key={cd24}>
                                  <span>{`　${cd24}`}</span>
                                  <br />
                                </div>
                              );
                            },
                          )}
                        </div>
                      ),
                    });
                  } else if (errCell) {
                    messagePopup({
                      type: 'info',
                      content: errCell[2],
                      onOk: () => {
                        hotTableComponent?.current?.hotInstance.selectCell(
                          errCell[0],
                          errCell[1],
                        );
                      },
                    });
                  } else if (!!failedCds.length) {
                    messagePopup({
                      type: 'info',
                      content: MSG_ERROR['MSG_009'],
                      onOk: () => {
                        hotTableComponent?.current?.hotInstance.selectCell(
                          failedCds[0].row,
                          0,
                        );
                      },
                    });
                  }
                },
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                  });
                },
              },
            );
          else if (errCell) {
            setTimeout(() => {
              messagePopup({
                type: 'info',
                content: errCell[2],
                onOk: () => {
                  hotTableComponent?.current?.hotInstance.selectCell(
                    errCell[0],
                    errCell[1],
                  );
                },
              });
            }, 200);
          }

          // Disable siire if have any product
          let allSyohinCds = allCds?.filter((item: any) => !!item);
          if (allSyohinCds.length) setDisableSiire(true);
          else setDisableSiire(false);
        }}
      >
        <HotColumn title="品名CD" width={100} />
        <HotColumn
          title="品　名"
          width={350}
          readOnly
          className="readonly-column"
        />
        <HotColumn
          title="ﾛｹｰｼｮﾝ"
          width={80}
          readOnly
          className="readonly-column htCenter"
        />
        <HotColumn title="入荷数" width={80} type="numeric" />
        <HotColumn title="code10" width={80} />
        <HotColumn title="code5" width={80} />
        <HotColumn title="kanm" width={80} />
      </HotTable>

      <SyohinSearch
        isModalVisible={isVisibleSyohinSearch}
        setIsModalVisible={setIsVisibleSyohinSearch}
        setCd24={(cd24: any) => {
          const allRowsData =
            hotTableComponent?.current?.hotInstance?.getData();
          // Find the product code already in the table
          let existedIndex = allRowsData?.findIndex(
            (item: any) => item[0] === cd24,
          );
          if (existedIndex < 0) {
            const blankIndex = allRowsData?.findIndex(
              (item: any) => !item[0]?.length,
            );
            if (blankIndex < 0) {
              messagePopup({
                type: 'info',
                content: '品目数がオーバーしました（1品目削除してください）',
              });
            } else {
              hotTableComponent?.current?.hotInstance.setDataAtCell(
                blankIndex,
                0,
                cd24,
              );
            }
          } else {
            // If have duplicated product in the table
            messagePopup({
              type: 'info',
              content: `この品名は${
                existedIndex + 1
              }行目ですでに入力されています`,
              onOk: () => {
                hotTableComponent?.current?.hotInstance.selectCell(
                  existedIndex,
                  0,
                );
              },
            });
          }
        }}
        focusAfterClose={() => {
          formRef?.current?.btnSearchSyohin?.focus();
        }}
      />
      <FooterForm>
        <Space>
          <Button
            onKeyDown={e => {
              if (e.shiftKey && e.key === 'Tab') {
                e.preventDefault();
                hotTableComponent?.current?.hotInstance.selectCell(98, 3);
              }
            }}
            ref={el => (formRef.current.btnSubmit = el)}
            type="primary"
            htmlType="submit"
            form="input-stock-refill"
            style={{ minWidth: 100 }}
          >
            確定
          </Button>
          <Button
            type="default"
            style={{ minWidth: 100 }}
            onClick={event => {
              messagePopup({
                type: 'question',
                content: '画面をクリアします。よろしいですか?',
                onOk: () => clearScreen(),
                onCancel: () => {
                  (
                    (event.target as HTMLElement)
                      .offsetParent as HTMLButtonElement
                  ).focus();
                },
              });
            }}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            style={{ minWidth: 100 }}
            onClick={event => {
              messagePopup({
                type: 'question',
                content: '終了します。よろしいですか?',
                onOk: () => navigate(PATH.TOP),
                onCancel: () => {
                  (
                    (event.target as HTMLElement)
                      .offsetParent as HTMLButtonElement
                  ).focus();
                },
              });
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FooterForm>

      <ConfirmModal
        isModalVisible={isVisibleConfirmModal}
        setIsModalVisible={setIsVisibleConfirmModal}
        data={stockRefillData}
        onResetPage={clearScreen}
        onSubmitFailed={(firstErrSyohinCd: string) => {
          setIsVisibleConfirmModal(false);
          const errorIndex = hotTableComponent?.current?.hotInstance
            ?.getData()
            ?.findIndex((row: any) => row[0] === firstErrSyohinCd);
          hotTableComponent?.current?.hotInstance.selectCell(errorIndex, 3);
        }}
      />
    </WrapperMainte>
  );
};

export default InputStockRefillReceiveCreate;
