export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>YHK納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>YHK入荷日</div>,
    dataIndex: 'nyukaDt',
    key: 'nyukaDt',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>YHK分類</div>,
    dataIndex: 'code5',
    key: 'code5',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>YHK品番</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 90 }}>HAT品番</div>,
    dataIndex: 'opsCd24',
    key: 'opsCd24',
    render: (_value: any) => (
      <div style={{ minWidth: 90, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>バラ数</div>,
    dataIndex: 'bara',
    key: 'bara',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 170 }}>品名</div>,
    dataIndex: 'nnm',
    key: 'nnm',
    render: (_value: any) => (
      <div style={{ minWidth: 170, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 30 }}>規格</div>,
    dataIndex: 'nnmsz',
    key: 'nnmsz',
    render: (_value: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 30 }}>会社</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 170 }}>送先</div>,
    dataIndex: 'sendTo1',
    key: 'sendTo1',
    render: (_value: any) => (
      <div style={{ minWidth: 170, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 170 }}>班と所属</div>,
    dataIndex: 'hansyozoku',
    key: 'hansyozoku',
    width: 100,
    render: (_value: any) => (
      <div style={{ minWidth: 170, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 170 }}>件名</div>,
    dataIndex: 'kenmei',
    key: 'kenmei',
    render: (_value: any) => (
      <div style={{ minWidth: 170, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 30 }}>削除</div>,
    dataIndex: 'del',
    key: 'del',
    render: (_value: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{_value}</div>
    ),
  },
];
