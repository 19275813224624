import { Divider } from 'antd';
import styled from 'styled-components';

export const DividerStyle = styled(Divider)`
  margin: 10px 0px;
`;

export const TitleGenbaCD = styled.div`
  min-width: 70px;
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
`;
