import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import {
  ButtonGroupLogin,
  ErrorText,
  FormWrapper,
  WrapperLogin,
} from './formLogin.style';
import { MSG_ERROR } from 'constants/text';
import { useLogAccess, useLogin } from 'api/auth';
import { loginSuccess } from 'slice/app';
import { PATH } from 'configs/routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UseFocus } from 'constants/common';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const FormLogin = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate: loginMutation, isLoading } = useLogin();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [refLogIn, setFocusLogIn] = UseFocus();
  const [refPassword, setFocusPassword] = UseFocus();

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const onFinish = (values: any) => {
    const { id, passwd } = values;
    const loginData = '(UserId:' + id + ',Password:' + passwd + ')';
    loginMutation({ id, passwd } as any, {
      onSuccess(data, variables, context) {
        logAccess([
          {
            tokuiCd: '',
            gyoumuId: 'Abstract',
            pgName: 'AbstractPage',
            logType: '4',
            action: 'ログイン成功' + loginData,
          },
        ] as any);

        dispatch(loginSuccess(data));
        navigate(PATH.TOP);
        setErrorMsg('');
      },
      onError(error, variables, context) {
        logAccess([
          {
            tokuiCd: '',
            gyoumuId: 'Abstract',
            pgName: 'AbstractPage',
            logType: '5',
            action: 'ログイン失敗' + loginData,
          },
        ] as any);

        setErrorMsg(
          MSG_ERROR[error?.response?.data?.message] ||
            error?.response?.data?.message,
        );
        setFocusLogIn();
      },
    });
  };

  return (
    <WrapperLogin title="ログイン">
      <FormWrapper
        className={isLoading ? 'disable-input' : ''}
        form={form}
        name="login"
        labelCol={{ flex: '110px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger={false}
      >
        <Form.Item label="ユーザーＩＤ" name="id">
          <Input
            ref={refLogIn}
            autoFocus
            placeholder="（半角）"
            maxLength={10}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                setFocusPassword();
              }
            }}
          />
        </Form.Item>
        <Form.Item label="パスワード" name="passwd">
          <Input.Password
            ref={refPassword}
            placeholder="（半角）"
            maxLength={10}
            iconRender={visible =>
              !visible ? (
                <Button type="text" size="small">
                  <EyeOutlined />
                </Button>
              ) : (
                <Button type="text" size="small">
                  <EyeInvisibleOutlined />
                </Button>
              )
            }
          />
        </Form.Item>
        <ErrorText>{errorMsg}</ErrorText>
        <ButtonGroupLogin>
          <Space>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              ログイン
            </Button>
            <Button
              type="default"
              disabled={isLoading}
              htmlType="button"
              onClick={() => {
                form.resetFields();
                setErrorMsg('');
              }}
            >
              キャンセル
            </Button>
          </Space>
        </ButtonGroupLogin>
      </FormWrapper>
    </WrapperLogin>
  );
};

export default FormLogin;
