import { Button, Form, Input, Radio, Space } from 'antd';
import { useLogAccess } from 'api/auth';
import { useGetStockInquiry, useGetStockInquiryExcel } from 'api/stockInquiry';
import { useGetSyohinMainte } from 'api/syohin';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { FlexBetween } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  Hankaku2Zenkaku,
  IsHankaku,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
// import InputSearchProduct from './inputFieldSyohin';

const MODE_SYOHINCD = 'SyohinCd';
const MODE_SYOHINNM = 'SyohinNm';

function StockInquiry() {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const [form] = Form.useForm();
  const formRef = useRef<any>({});
  const previousSyohinCd = useRef<any>();
  const previousSyohinNm = useRef<any>();
  const navigate = useNavigate();
  const [listStockInquiry, setListStockInquiry] = useState([]);
  const [mode, setMode] = useState<string>('');

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  useEffect(() => {
    const newMode = form.getFieldValue('mode');
    setMode(newMode);
    if (newMode === MODE_SYOHINCD) {
      setTimeout(() => {
        formRef.current.syohinCd.select();
      }, 0);
    } else {
      setTimeout(() => {
        formRef.current.syohinNm.select();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('mode')]);

  /**
   * *API GetStockView
   * todo Get Stock View info to init
   * @param
   */
  const {
    mutate: mutateStockInquiry,
    data: dataStockInquiry,
    isLoading: loadingInquiry,
    reset: resetStockInquiry,
  } = useGetStockInquiry();

  const { mutate: excelMutation, isLoading: isLoadingExcel } =
    useGetStockInquiryExcel();

  const { mutate: getSyohinInfo } = useGetSyohinMainte();

  const columns: any = () => [
    {
      title: () => <div style={{ minWidth: 40 }}>№</div>,
      dataIndex: 'index',
      key: 'index',
      render: (_value: any, record: any) => (
        <div style={{ textAlign: 'right', minWidth: 40 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>ﾛｹｰｼｮﾝ</div>,
      dataIndex: 'location',
      key: 'location',
      render: (_value: any) => <div style={{ minWidth: 60 }}>{_value}</div>,
    },
    {
      title: () => <div style={{ minWidth: 40 }}>分類</div>,
      dataIndex: 'code5',
      key: 'code5',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 40,
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 100 }}>分類名</div>,
      dataIndex: 'code5nm',
      key: 'code5nm',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 100,
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 100 }}>品名CD</div>,
      dataIndex: 'cd24',
      key: 'cd24',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 100,
          }}
        >
          {_value}
        </div>
      ),
    },

    {
      title: () => <div style={{ minWidth: 200 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any) => (
        <div
          style={{
            minWidth: 200,
          }}
        >
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 160 }}>規格</div>,
      dataIndex: 'nnmsz',
      key: 'nnmsz',
      render: (_value: any, record: any) => (
        <div style={{ minWidth: 160, textAlign: 'left' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>バラ数</div>,
      dataIndex: 'zaiko',
      key: 'zaiko',
      render: (_value: any, record: any) => (
        <div style={{ minWidth: 60, textAlign: 'right' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>引当</div>,
      dataIndex: 'hikiate',
      key: 'hikiate',
      render: (_value: any, record: any) => (
        <div style={{ minWidth: 60, textAlign: 'right' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>入荷予定日</div>,
      dataIndex: 'nyukabi',
      key: 'nyukabi',
      render: (_value: any, record: any) => (
        <div style={{ minWidth: 80 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>注残</div>,
      dataIndex: 'chuzan',
      key: 'chuzan',
      render: (_value: any, record: any) => (
        <div style={{ minWidth: 60, textAlign: 'right' }}>
          {_value !== 0 ? _value : null}
        </div>
      ),
    },
  ];

  const handleExcel = () => {
    const { syohinCd, syohinNm, mode } = form.getFieldsValue();
    const request = {
      syohinCd: mode === MODE_SYOHINCD ? syohinCd : '',
      syohinNm: mode === MODE_SYOHINNM ? syohinNm : '',
    };
    excelMutation(request, {
      onSuccess(data: any) {
        // Download file excel
        let fileName = data.headers['content-disposition'] || 'errorName';
        const file = new Blob([data?.data], {
          type: 'application/octet-stream',
        });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;

        link.download = decodeURIComponent(
          fileName.split('filename*=')[1].split(';')[0],
        ).substring(7);
        link.click();
      },
      onError: async (error, variables, context) => {
        const errorMsg = JSON.parse(await error.response.data.text())?.message;
        messagePopup({
          type: 'info',
          content: MSG_ERROR[errorMsg] || errorMsg,
          onOk: () => focusSearchInput(mode),
        });
      },
    });
  };

  const onFinish = (values: any) => {
    const { syohinCd, syohinNm, mode } = values;
    const request = {
      syohinCd: mode === MODE_SYOHINCD ? syohinCd : '',
      syohinNm: mode === MODE_SYOHINNM ? syohinNm : '',
    };
    mutateStockInquiry(request, {
      onSuccess({ data }: any) {
        if (data?.length > 0) {
          logAccess([
            {
              tokuiCd: userInfo.torihikicd,
              gyoumuId: '在庫問合せ',
              pgName: '在庫問合せ',
              logType: '2',
              action: '検索実行',
            },
          ] as any);
          setListStockInquiry(
            data?.map((item: any, index: any) => {
              return {
                ...item,
                index: index + 1,
              };
            }),
          );
          formRef.current.btnReset.focus();
        } else {
          messagePopup({
            type: 'info',
            content: MSG_ERROR['MSG_NO_DATA'],
            onOk: () => {
              focusSearchInput(mode);
              setListStockInquiry([]);
            },
          });
        }
      },
      onError(error: any) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            error?.response?.data?.message,
          onOk: () => {
            focusSearchInput(mode);
            setListStockInquiry([]);
          },
        });
      },
    });
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        errorFields[0]?.name[0] === 'syohinNnm'
          ? formRef?.current?.syohinCd?.select()
          : formRef?.current[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  const clearScreen = () => {
    form.setFieldsValue({
      syohinCd: '',
      syohinNnm: '',
      syohinNm: '',
    });
    setTimeout(() => {
      focusSearchInput(mode);
    }, 0);
    setListStockInquiry([]);
    resetStockInquiry();
  };

  const focusSearchInput = (mode: string) => {
    mode === MODE_SYOHINCD
      ? formRef.current?.syohinCd?.select()
      : formRef.current?.syohinNm?.select();
  };

  return (
    <WrapperMainte>
      {loadingInquiry && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="stock-inquiry"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        initialValues={{
          syohinCd: '',
          syohinNm: '',
          mode: MODE_SYOHINCD,
        }}
        style={{ marginBottom: 20 }}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>

        <Form.Item label={'検索対象'} name="mode">
          <Radio.Group
            onChange={() => {
              form.resetFields(['syohinCd', 'syohinNnm', 'syohinNm']);
              setListStockInquiry([]);
              resetStockInquiry();
            }}
          >
            <Radio value={MODE_SYOHINCD} key={MODE_SYOHINCD}>
              品名コード
            </Radio>
            <Radio value={MODE_SYOHINNM} key={MODE_SYOHINNM}>
              品名
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            <label>
              品名コード{' '}
              <label style={{ color: 'red' }} hidden={mode !== MODE_SYOHINCD}>
                【必須】
              </label>
            </label>
          }
          className={mode !== MODE_SYOHINCD ? 'disable-input' : ''}
        >
          <Space size={4}>
            <Form.Item
              noStyle
              name="syohinCd"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (mode === MODE_SYOHINCD && !value) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_INPUT']),
                      );
                    }

                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }

                    if (!IsHankaku(value)) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_ZENKAKU']),
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {/* <InputSearchProduct
                disabled={mode !== MODE_SYOHINCD}
                form={form}
                refForm={formRef}
              /> */}
              <Input
                disabled={mode !== MODE_SYOHINCD}
                ref={el => (formRef.current.syohinCd = el)}
                autoFocus
                maxLength={11}
                style={{ width: 120 }}
                onBlur={(event: any) => {
                  let value = ToASCII(event.target.value, true, true).trim();
                  form.setFieldsValue({
                    syohinCd: value,
                  });
                  if (previousSyohinCd.current !== value) {
                    form
                      .validateFields(['syohinCd'])
                      .then(res => {
                        const _value = res.syohinCd;
                        if (!value) {
                          form.setFieldsValue({
                            syohinNnm: null,
                          });
                          formRef.current.btnSubmit.focus();
                          return;
                        }
                        getSyohinInfo(_value, {
                          onSuccess({ data }, variables, context) {
                            form.setFieldsValue({
                              syohinNnm: `${data.nnm || ''}${data.nnmsz || ''}`,
                            });
                            formRef.current.btnSubmit.focus();
                          },
                          onError(error, variables, context) {
                            form.setFieldsValue({
                              syohinNnm: null,
                            });
                            // messagePopup({
                            //   type: 'info',
                            //   content:
                            //     MSG_ERROR[
                            //       (error as any)?.response?.data?.message
                            //     ] || error?.response?.data?.message,
                            //   onOk: () => {
                            //     formRef.current?.syohinCd?.select();
                            //   },
                            // });
                          },
                        });
                      })
                      .catch(({ values, errorFields }) => {
                        form.setFieldsValue({
                          syohinNnm: null,
                        });
                        messagePopup({
                          type: 'info',
                          content: errorFields[0]?.errors[0],
                          onOk: () => {
                            formRef?.current.syohinCd.select();
                          },
                        });
                      });
                  }
                }}
                onKeyUp={event =>
                  (event.key === 'Enter' || event.key === 'Tab') &&
                  event.currentTarget.select()
                }
                onFocus={(event: any) => {
                  previousSyohinCd.current = event.target.value;
                }}
                onPressEnter={(event: any) => {
                  event.preventDefault();
                  formRef?.current.btnSubmit.focus();
                  // if (previousSyohinCd.current === event.target.value) {
                  //   formRef?.current.btnSubmit.focus();
                  // } else {
                  //   event.target.blur();
                  // }
                }}
              />
            </Form.Item>
            <Form.Item
              name="syohinNnm"
              noStyle
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    // if (
                    //   !!form.getFieldValue('syohinCd')?.length &&
                    //   !value?.length
                    // ) {
                    //   return Promise.reject(
                    //     new Error(MSG_ERROR['MSG_SYOHIN_NOT_EXIST']),
                    //   );
                    // }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          className={mode !== MODE_SYOHINNM ? 'disable-input' : ''}
          label={<label>品名</label>}
          name="syohinNm"
          rules={[
            () => ({
              validator(_: any, value: string) {
                let _value = ToASCII(value, false, false);
                if (checkForSpecialChar(_value)) {
                  return Promise.reject(
                    new Error('品名' + MSG_ERROR['MSG_INVALID_CHAR']),
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            autoFocus
            disabled={mode !== MODE_SYOHINNM}
            ref={el => (formRef.current.syohinNm = el)}
            maxLength={60}
            style={{ width: 424 }}
            onBlur={(event: any) => {
              let value = Hankaku2Zenkaku(event.target.value).trim();
              form.setFieldsValue({
                syohinNm: value,
              });
              if (previousSyohinNm.current !== value) {
                form
                  .validateFields(['syohinNm'])
                  .then(() => {
                    formRef?.current.btnSubmit.focus();
                  })
                  .catch(({ values, errorFields }) =>
                    messagePopup({
                      type: 'info',
                      content: errorFields[0]?.errors[0],
                      onOk: () => {
                        formRef?.current.syohinNm.select();
                      },
                    }),
                  );
              }
            }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={(event: any) => {
              previousSyohinNm.current = event.target.value;
              // event.currentTarget.select();
            }}
            onPressEnter={(event: any) => {
              event.preventDefault();
              if (previousSyohinNm.current === event.target.value) {
                formRef?.current.btnSubmit.focus();
              } else {
                event.target.blur();
              }
            }}
          />
        </Form.Item>
      </FormWrapper>

      <Space
        style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}
      >
        <Button
          ref={el => (formRef.current.btnSubmit = el)}
          type="primary"
          htmlType="submit"
          form="stock-inquiry"
          style={{ minWidth: '100px' }}
          disabled={loadingInquiry}
        >
          呼出し
        </Button>
        <Button
          type="default"
          style={{ minWidth: '120px' }}
          onClick={e => {
            e.preventDefault();
            form
              .validateFields()
              .then(res => handleExcel())
              .catch(({ values, errorFields }) =>
                messagePopup({
                  type: 'info',
                  content: errorFields[0]?.errors[0],
                  onOk: () => {
                    errorFields[0]?.name[0] === 'syohinNnm'
                      ? formRef?.current?.syohinCd?.select()
                      : formRef?.current[errorFields[0]?.name[0]]?.select();
                  },
                }),
              );
          }}
          loading={isLoadingExcel}
        >
          EXCEL出力
        </Button>
        <Button
          ref={el => (formRef.current.btnReset = el)}
          type="default"
          style={{ minWidth: '100px' }}
          onClick={() => clearScreen()}
        >
          画面クリア
        </Button>
      </Space>
      <TableCustom
        rowKey={'index'}
        height={'230px'}
        columns={columns()}
        dataSource={listStockInquiry}
      />
      <FlexBetween style={{ marginTop: 8 }}>
        <div>
          {dataStockInquiry?.data?.length > 0 &&
            `該当データは、${dataStockInquiry?.data?.length}件です。`}
        </div>

        <Space>
          <Button
            ref={el => (formRef.current.btnClose = el)}
            type="default"
            onClick={() => navigate(PATH.TOP)}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FlexBetween>
    </WrapperMainte>
  );
}

export default StockInquiry;
