import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';

export interface iDefinedValues {
  akagi_SiiresakiCd: string;
  hat_SiiresakiCd: string;
  tokyoGas_SiiresakiCd: string;
  tokyoGas_SiiresakiNm: string;
  torihikiCd_YHK: string;
  torihikiCd_soko: string;
  serverTime: string;
}

export function useGetDefinedValues() {
  const getDefinedValues = async () => {
    return await AxiosPost(API_URL.GET_DEFINED_VALUE);
  };

  const mutation = useMutation(getDefinedValues as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
