import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from 'slice/app';

export default configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    app: appReducer,
  },
});
