import { HotColumn, HotTable } from '@handsontable/react';
import { Button, Form, Input, Radio, Space } from 'antd';
import { useGetCustomerMainte } from 'api/customer';
import { useCheckZaiko } from 'api/returnInput';
import { useGetSyohinInfos } from 'api/syohin';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import PickerCustom from 'components/RangePickerCustom/PickerCustom';
import { PATH } from 'configs/routes';
import {
  IsHankakuEisu,
  ToASCII,
  checkForSpecialChar,
  isNumeric,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import { registerAllModules } from 'handsontable/registry';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import SyohinSearch from 'views/common/syohinSearch';
import TorihikisakiSearch from 'views/common/torihikisakiSearch';
import {
  FooterForm,
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import ReturnConfirm from './returnConfirm';

// register Handsontable's modules
registerAllModules();

const SYOHINCD_MAX_LENGTH = 11;

const BARA_MAX_LENGTH = 6;

function getKeyboardFocusableElements(element = document) {
  return [
    ...element.querySelectorAll(
      'a[href], input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    ),
  ].filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
  );
}

const focusNextEle = (e: any) => {
  const keyboardFocusElement: any = getKeyboardFocusableElements();
  const index = keyboardFocusElement.findIndex(
    (element: any) => element === e.target,
  );
  // console.log(index, keyboardFocusElement);
  if (index !== -1) {
    if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
      e.preventDefault();
      keyboardFocusElement[index + 1]
        ? keyboardFocusElement[index + 1]?.focus()
        : keyboardFocusElement[0]?.focus();
    } else if (e.shiftKey && e.key === 'Tab') {
      e.preventDefault();
      keyboardFocusElement[index - 1]
        ? keyboardFocusElement[index - 1]?.focus()
        : keyboardFocusElement[keyboardFocusElement.length]?.focus();
    }
  }
};

const ReturnInput = () => {
  let inProgess: boolean = false;
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const [isVisibleTorihikisaki, setIsVisibleTorihikisaki] =
    useState<boolean>(false);

  const [isVisibleSyohin, setIsVisibleSyohin] = useState<boolean>(false);

  const [previusTorihikiCd, setPreviusTorihikiCd] = useState<string | null>(
    null,
  );

  const [isVisibleReturnConfirm, setIsVisibleReturnConfirm] =
    useState<boolean>(false);

  const [returnData, setReturnData] = useState({});

  const hotTableComponent = useRef<any>(null);
  const formRef = useRef<any>({});

  const dimension = useRef<{ col: number; row: number }>({
    col: -1,
    row: -1,
  });

  const [form] = Form.useForm();

  const {
    mutate: getCustomerInfo,
    data: dataCustomer,
    error: errorCustomer,
    isLoading: loadingCustomer,
    reset: resetCustomerInfo,
  } = useGetCustomerMainte();

  const { mutate: checkZaiko, isLoading: loadingCheckZaiko } = useCheckZaiko();

  const { mutate: getLstSyohin, isLoading: loadingLstSyohin } =
    useGetSyohinInfos();

  const onFinish = (values: any) => {
    if (inProgess) return;
    const allRowsData = hotTableComponent?.current?.hotInstance?.getData();
    // Skip rows having no data
    let allMeisai = allRowsData?.filter(
      (item: any) => !!item[0] || !!item[1] || !!item[2],
    );
    if (!allMeisai?.length) {
      // If do not fill any product
      messagePopup({
        type: 'info',
        content: '品名CDを入力してください',
        onOk: () => {
          hotTableComponent?.current?.hotInstance.selectCell(0, 0);
        },
      });
      return;
    }
    let errFlag = false;
    // Loop all rows in the table to validate
    allRowsData?.every((meisai: any, index: number) => {
      // ---------------------------------------------
      // meisai[0]: product code
      // meisai[1]: product name
      // meisai[2]: amount
      // ---------------------------------------------

      // Skip rows having no data
      if (
        !meisai[0]?.length &&
        !meisai[1]?.length &&
        !meisai[2]?.toString()?.length
      )
        return true;
      let duplicatedIndex: any = null;
      for (let idx = 0; idx < index; idx++) {
        if (
          !!allRowsData[idx][0]?.length &&
          allRowsData[idx][0] === meisai[0]
        ) {
          duplicatedIndex = idx;
          break;
        }
      }
      if (duplicatedIndex !== null) {
        // If have duplicated product in the table
        messagePopup({
          type: 'info',
          content: `この品名は${
            duplicatedIndex + 1
          }行目ですでに入力されています`,
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (!meisai[0]?.length) {
        // If product code is empty
        messagePopup({
          type: 'info',
          content: '品名CDを入力してから「返品数」を入力してください',
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (checkForSpecialChar(meisai[0])) {
        // If product code contains special characters
        messagePopup({
          type: 'info',
          content: '品名CD' + MSG_ERROR['MSG_INVALID_CHAR'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (meisai[0]?.length > SYOHINCD_MAX_LENGTH) {
        // Check maximum characters
        messagePopup({
          type: 'info',
          content: `品名CDには${SYOHINCD_MAX_LENGTH}文字以下を入力してください`,
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (!meisai[1]?.length) {
        // If product code do not exist
        messagePopup({
          type: 'info',
          content: MSG_ERROR['MSG_009'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 0);
          },
        });
        errFlag = true;
        return false;
      }
      if (!meisai[2]?.toString()?.length) {
        // If amount is empty
        messagePopup({
          type: 'info',
          content: '返品数' + MSG_ERROR['MSG_INPUT'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 2);
          },
        });
        errFlag = true;
        return false;
      }
      if (!isNumeric(meisai[2])) {
        // If amount is not a number
        messagePopup({
          type: 'info',
          content: '返品数' + MSG_ERROR['MSG_010'],
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 2);
          },
        });
        errFlag = true;
        return false;
      }
      if (parseInt(meisai[2]) === 0) {
        // If amount equals zero
        messagePopup({
          type: 'info',
          content: '返品数に0は入力できません',
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 2);
          },
        });
        errFlag = true;
        return false;
      }
      if (meisai[2]?.toString()?.length > BARA_MAX_LENGTH) {
        // Check maximum characters
        messagePopup({
          type: 'info',
          content: `返品数には${BARA_MAX_LENGTH}文字以下を入力してください`,
          onOk: () => {
            hotTableComponent?.current?.hotInstance.selectCell(index, 2);
          },
        });
        errFlag = true;
        return false;
      }
      return true;
    });
    if (errFlag) return;
    let meisai = allMeisai.map((item: any, index: number) => {
      return { index, cd24: item[0], nnm: item[1], bara: item[2] };
    });
    const { torihikiCd, torihikiNm, nyukaDt, shorikubun, urikubun } = values;
    let request = {
      header: {
        torihikiCd,
        torihikiNm,
        nyukaDt,
        urikubun,
        shorikubun,
      },
      meisai,
    };
    if (shorikubun === '在庫処分') {
      // Check zaiko
      checkZaiko(
        {
          meisai: meisai.map((item: any) => ({
            cd24: item.cd24,
            bara: item.bara,
          })),
        },
        {
          onSuccess({ data }, variables, context) {
            if (!!data.length) {
              messagePopup({
                type: 'info',
                content: (
                  <div style={{ textAlign: 'left' }}>
                    <p>
                      {'以下の商品は在庫処分数量が現在庫数を超えています。'}
                    </p>
                    {data.map((item: { cd24: string; zaiko: number }) => {
                      return (
                        <div key={item.cd24}>
                          <span>{`　${item.cd24}（現在庫数：${item.zaiko}）`}</span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                ),
                onOk: () => {
                  const errorIndex = hotTableComponent?.current?.hotInstance
                    ?.getData()
                    ?.findIndex((row: any) => row[0] === data[0].cd24);
                  hotTableComponent?.current?.hotInstance.selectCell(
                    errorIndex,
                    2,
                  );
                },
              });
              return;
            }

            // Open confirm modal with infos were entered before
            setReturnData(request);
            setIsVisibleReturnConfirm(true);
          },
          onError(error, variables, context) {
            messagePopup({
              type: 'error',
              content: error?.response?.data?.message,
            });
          },
        },
      );
    } else {
      // Open confirm modal with infos were entered before
      setReturnData(request);
      setIsVisibleReturnConfirm(true);
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    // Show the first error from form's validator and focus on the error field after click on OK button
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        formRef?.current[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  const clearScreen = () => {
    setIsVisibleReturnConfirm(false);
    resetCustomerInfo();
    form.resetFields();
    hotTableComponent?.current?.hotInstance?.updateSettings({
      data: [],
    });
    hotTableComponent?.current?.hotInstance.scrollViewportTo(0, 0);
    setTimeout(() => {
      formRef?.current?.torihikiCd?.focus();
    }, 300);
  };

  useEffect(() => {
    // Handle error after calling API get customer info
    if (!errorCustomer) {
      return;
    }
    messagePopup({
      type: 'info',
      content:
        MSG_ERROR[errorCustomer?.response?.data?.message] ||
        errorCustomer?.response?.data?.message,
      onOk: () => {
        formRef?.current['torihikiCd']?.select();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCustomer]);

  useEffect(() => {
    if (!dataCustomer) {
      form.setFieldsValue({
        torihikiNm: '',
      });
      setTimeout(() => {
        formRef.current['torihikiCd'].focus();
      }, 0);
      return;
    }
    const { kaisya, torihikicd } = dataCustomer.data;
    form.setFieldsValue({
      torihikiCd: torihikicd,
      torihikiNm: kaisya,
    });
    setTimeout(() => {
      formRef.current['nyukaDt'].focus();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCustomer]);

  const checkSyohinCd = (row: number, syohinCd: string) => {
    let errCell: any = null;
    if (!syohinCd?.length) {
      errCell = [row, 0, '品名CDを入力してから「返品数」を入力してください'];
    } else if (checkForSpecialChar(syohinCd)) {
      errCell = [row, 0, '品名CD' + MSG_ERROR['MSG_INVALID_CHAR']];
    } else if (syohinCd?.length > SYOHINCD_MAX_LENGTH) {
      errCell = [
        row,
        0,
        `品名CDには${SYOHINCD_MAX_LENGTH}文字以下を入力してください`,
      ];
    } else {
      // Find the duplicated product
      const allRowsData = hotTableComponent?.current?.hotInstance?.getData();
      let duplicatedIndex: any = null;
      for (let idx = 0; idx < row; idx++) {
        if (!!allRowsData[idx][0]?.length && allRowsData[idx][0] === syohinCd) {
          duplicatedIndex = idx;
          break;
        }
      }
      if (duplicatedIndex !== null) {
        // If have duplicated product in the table
        errCell = [
          row,
          0,
          `この品名は${duplicatedIndex + 1}行目ですでに入力されています`,
        ];
      }
    }
    return errCell;
  };

  return (
    <WrapperMainte>
      {(loadingCustomer || loadingLstSyohin || loadingCheckZaiko) && (
        <LoadingCallAPI />
      )}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="return-input"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        initialValues={{
          shorikubun: '返品',
          urikubun: '53',
          nyukaDt: moment(),
          nyukaDtFull: moment().format('YYYY/MM/DD (ddd)'),
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        style={{ marginBottom: 20 }}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>

        <Form.Item
          label={
            <label>
              伝票区分
              {/* <label style={{ color: 'red' }}>【必須】</label> */}
            </label>
          }
        >
          <Space size={4}>
            <Form.Item noStyle>
              <Input
                value={'32'}
                disabled
                style={{ width: 50 }}
                onKeyDown={focusNextEle}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Input
                value={'返品'}
                disabled
                style={{ width: 100 }}
                onKeyDown={focusNextEle}
              />
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label={
            <label>
              扱便
              {/* <label style={{ color: 'red' }}>【必須】</label> */}
            </label>
          }
        >
          <Space size={4}>
            <Form.Item noStyle>
              <Input
                value={'903'}
                disabled
                style={{ width: 50 }}
                onKeyDown={focusNextEle}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Input
                value={'返品入荷'}
                disabled
                style={{ width: 100 }}
                onKeyDown={focusNextEle}
              />
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          label={
            <label>
              得意先
              <label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
        >
          <Space size={4}>
            <Form.Item
              noStyle
              name="torihikiCd"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('取引先コード' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error(
                          '取引先コード' + MSG_ERROR['MSG_HANKAKU_EISU'],
                        ),
                      );
                    }
                    if (!dataCustomer) {
                      // If customer is not exist
                      return Promise.reject(
                        new Error(MSG_ERROR['MSG_TORIHIKI_NOT_EXIST']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                autoFocus
                ref={el => (formRef.current.torihikiCd = el)}
                maxLength={6}
                style={{ width: 100 }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const fomartedTorihikiCd = ToASCII(
                      e.target.value,
                      true,
                    ).trim();
                    const isChangedValue =
                      fomartedTorihikiCd !== previusTorihikiCd;

                    if (!isChangedValue) {
                      if (!form.getFieldValue('torihikiNm')) {
                        formRef.current.buttonSearch.focus();
                      } else {
                        formRef.current['nyukaDt'].focus();
                      }
                      return;
                    }

                    if (!fomartedTorihikiCd) {
                      formRef.current.buttonSearch.focus();
                      return;
                    }

                    formRef.current['torihikiCd'].blur();
                  }
                }}
                onFocus={event => setPreviusTorihikiCd(event.target.value)}
                onBlur={event => {
                  const fomartedTorihikiCd = ToASCII(
                    event.target.value,
                    true,
                  ).trim();
                  form.setFieldsValue({
                    torihikiCd: fomartedTorihikiCd,
                  });
                  const isChangedValue =
                    fomartedTorihikiCd !== previusTorihikiCd;

                  if (!isChangedValue) return;

                  if (!fomartedTorihikiCd) {
                    resetCustomerInfo();
                    return;
                  }

                  if (IsHankakuEisu(fomartedTorihikiCd)) {
                    messagePopup({
                      type: 'info',
                      content: '取引先コード' + MSG_ERROR['MSG_HANKAKU_EISU'],
                      onOk: () => {
                        if (dataCustomer) {
                          resetCustomerInfo();
                        } else {
                          formRef.current['torihikiCd'].select();
                        }
                      },
                    });
                    return;
                  }
                  getCustomerInfo({
                    torihikiCd: fomartedTorihikiCd,
                    hanList: false,
                    tantoList: false,
                  });
                }}
              ></Input>
            </Form.Item>
            <Form.Item noStyle name="torihikiNm">
              <Input
                ref={el => (formRef.current.kaisya = el)}
                disabled={true}
                style={{ width: 300 }}
                onBlur={event => {
                  form.setFieldsValue({});
                }}
              ></Input>
            </Form.Item>
            <Button
              ref={el => (formRef.current.buttonSearch = el)}
              type="default"
              onClick={() => setIsVisibleTorihikisaki(true)}
              onKeyPress={event => {
                if (event.key === 'Enter') formRef.current.buttonSearch.click();
              }}
            >
              取引先検索
            </Button>
          </Space>
        </Form.Item>

        <Form.Item
          label={
            <label>
              入荷日
              <label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
        >
          <Space size={4}>
            <PickerCustom
              title={'入荷日'}
              nextFocus={() =>
                setTimeout(() => {
                  // hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                  formRef.current.shorikubun.focus();
                }, 0)
              }
              form={form}
              refForm={formRef}
              autoFocus={false}
              formName="nyukaDt"
              hideDD={true}
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                }, 0);
              }}
              validateRules={(value: any) => {
                if (!value) {
                  return '入荷日' + MSG_ERROR['MSG_INPUT'];
                }
                return null;
              }}
            />
            {/* <Form.Item
              noStyle
              name="nyukaDt"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('入荷日' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePickerCommon
                itemName={'入荷日'}
                placeholder={DATE_FORMAT}
                allowClear={false}
                refForm={formRef}
                formName="nyukaDt"
                // nextFocus="nyukaDtFull"
                nextFocus={() => formRef.current['nyukaDtFull'].focus()}
                onChange={(value: any) => {
                  form.setFieldsValue({
                    nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                  });
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                  }, 0);
                }}
                style={{ width: 100, padding: '2px 10px' }}
                format={DATE_FORMAT}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              />
            </Form.Item> */}
            <Form.Item
              noStyle
              name="nyukaDtFull"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('品名' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.nyukaDtFull = el)}
                disabled
                style={{ width: 150 }}
                onBlur={event => {
                  form.setFieldsValue({});
                }}
                onPressEnter={(event: any) => {}}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item
          name="shorikubun"
          label={
            <label>
              処理区分
              {/* <label style={{ color: 'red' }}>【必須】</label> */}
            </label>
          }
        >
          <Radio.Group>
            <Radio
              ref={el => (formRef.current.shorikubun = el)}
              style={{ minWidth: 150 }}
              value="返品"
              onKeyDown={focusNextEle}
            >
              返品
            </Radio>
            <Radio value="在庫処分" onKeyDown={focusNextEle}>
              在庫処分
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="urikubun"
          label={
            <label>
              売区
              {/* <label style={{ color: 'red' }}>【必須】</label> */}
            </label>
          }
        >
          <Radio.Group>
            <Radio
              ref={el => (formRef.current.urikubun53 = el)}
              style={{ minWidth: 150 }}
              value="53"
              onKeyDown={focusNextEle}
            >
              53：協力店責任
            </Radio>
            <Radio
              ref={el => (formRef.current.urikubun55 = el)}
              value="55"
              onKeyDown={e => {
                if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
                  e.preventDefault();
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(0, 0);
                  }, 0);
                } else if (e.shiftKey && e.key === 'Tab') {
                  e.preventDefault();
                  formRef.current?.urikubun53?.focus();
                }
              }}
            >
              55：ＣＡＰ責任
            </Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Button
          ref={el => (formRef.current.btnSearchSyohin = el)}
          type="default"
          onClick={() => setIsVisibleSyohin(true)}
          onKeyPress={event => {
            if (event.key === 'Enter') formRef.current.btnSearchSyohin.click();
          }}
          style={{ marginBottom: 4 }}
        >
          商品検索
        </Button> */}
      </FormWrapper>
      <HotTable
        ref={hotTableComponent}
        renderAllRows={true}
        width="100%"
        height="308px"
        minRows={99}
        maxRows={99}
        colHeaders={true}
        rowHeaders={true}
        rowHeaderWidth={40}
        afterGetRowHeader={(col, TH) => {
          TH.className = 'htRight';
        }}
        undo={false}
        rowHeights={28}
        licenseKey="non-commercial-and-evaluation"
        stretchH="all"
        activeHeaderClassName="header-table"
        afterSelection={(row, column) => {
          dimension.current.row = row;
          dimension.current.col = column;
        }}
        beforeKeyDown={(e: any) => {
          if (
            e.target.closest('.handsontable') &&
            (e.keyCode === 9 || e.keyCode === 13) &&
            dimension.current.col > -1 &&
            dimension.current.row > -1
          ) {
            if (e.keyCode === 13) {
              e.stopImmediatePropagation();
            }
            if (dimension.current.col === 0) {
              if (e.shiftKey) {
                if (dimension.current.row === 0) {
                  e.stopImmediatePropagation();
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.deselectCell();
                    // formRef.current['nyukaDt'].focus();
                    formRef.current?.urikubun55?.focus();
                  }, 0);
                } else {
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(
                      dimension.current.row - 1,
                      2,
                    );
                  }, 0);
                }
              } else {
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(
                    dimension.current.row,
                    2,
                  );
                }, 0);
              }
            } else if (dimension.current.col === 2) {
              if (e.shiftKey) {
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(
                    dimension.current.row,
                    0,
                  );
                }, 0);
              } else {
                if (dimension.current.row === 98) {
                  e.stopImmediatePropagation();
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.deselectCell();
                    formRef.current.btnSubmit.focus();
                  }, 0);
                } else {
                  setTimeout(() => {
                    hotTableComponent?.current?.hotInstance.selectCell(
                      dimension.current.row + 1,
                      0,
                    );
                  }, 0);
                }
              }
            }
          }
          if (!e.target.closest('.handsontable') && e.keyCode !== 46) {
            e.stopImmediatePropagation();
            return;
          }
        }}
        beforeChange={(changes, source: any) => {
          inProgess = true;
          if (source === 'reset') return;
          changes.forEach(cell => {
            // ---------------------------------------------
            // cell[0]: row index
            // cell[1]: column index
            // cell[2]: current value
            // cell[3]: new value
            // ---------------------------------------------

            if (cell[1] === 0) {
              // If change data in product code column
              cell[3] = ToASCII(cell[3], true).trim();
              if (!cell[3].length) {
                // Clear the entire row data if product code is empty
                hotTableComponent?.current?.hotInstance.setDataAtCell(
                  cell[0],
                  1,
                  null,
                  'reset',
                );
                hotTableComponent?.current?.hotInstance.setDataAtCell(
                  cell[0],
                  2,
                  null,
                  'reset',
                );
              }
            } else if (cell[1] === 2) {
              // If change data in amount column
              const value = ToASCII(cell[3]?.toString(), false)
                ?.replaceAll(',', '')
                ?.trim();
              // Remove all zero before the number
              cell[3] = isNumeric(value) ? parseInt(value) : value;
            }
          });
        }}
        afterChange={(changes, source: any) => {
          if (source === 'reset') return;
          let newSyohinCdList: any[] = [];
          let errCell: any = null;
          changes?.forEach(cell => {
            // ---------------------------------------------
            // cell[0]: row index
            // cell[1]: column index
            // cell[2]: old value
            // cell[3]: current value
            // ---------------------------------------------

            if (cell[1] === 0) {
              // If change data in product code column
              if (cell[2] !== cell[3]) {
                // Only validate when value was changed
                if (checkForSpecialChar(cell[3])) {
                  if (!errCell) {
                    errCell = [
                      cell[0],
                      cell[1],
                      '品名CD' + MSG_ERROR['MSG_INVALID_CHAR'],
                    ];
                  }
                } else if (cell[3]?.length > SYOHINCD_MAX_LENGTH) {
                  if (!errCell) {
                    errCell = [
                      cell[0],
                      cell[1],
                      `品名CDには${SYOHINCD_MAX_LENGTH}文字以下を入力してください`,
                    ];
                  }
                } else {
                  // Find the duplicated product
                  const allRowsData =
                    hotTableComponent?.current?.hotInstance?.getData();
                  let duplicatedIndex: any = null;
                  for (let idx = 0; idx < cell[0]; idx++) {
                    if (
                      !!allRowsData[idx][0]?.length &&
                      allRowsData[idx][0] === cell[3]
                    ) {
                      duplicatedIndex = idx;
                      break;
                    }
                  }
                  if (!errCell && duplicatedIndex !== null) {
                    // If have duplicated product in the table
                    errCell = [
                      cell[0],
                      cell[1],
                      `この品名は${
                        duplicatedIndex + 1
                      }行目ですでに入力されています`,
                    ];
                  }
                }
                if (!!cell[3]?.length) {
                  newSyohinCdList.push(cell[3]);
                }
              }
            } else if (cell[1] === 2) {
              // If change data in amount column
              const syohinNm =
                hotTableComponent?.current?.hotInstance.getDataAtCell(
                  cell[0],
                  1,
                );
              if (!errCell && cell[2] !== cell[3]) {
                // Only validate when value was changed
                // Check product code first
                if (!!cell[3]?.toString()?.length) {
                  errCell = checkSyohinCd(
                    cell[0],
                    hotTableComponent?.current?.hotInstance.getDataAtCell(
                      cell[0],
                      0,
                    ),
                  );
                }
                if (!errCell) {
                  if (!cell[3]?.toString()?.length && !!syohinNm) {
                    // If amount is empty
                    errCell = [
                      cell[0],
                      cell[1],
                      '返品数' + MSG_ERROR['MSG_INPUT'],
                    ];
                  } else if (
                    !!cell[3]?.toString()?.length &&
                    !isNumeric(cell[3])
                  ) {
                    // If amount is not a number
                    errCell = [
                      cell[0],
                      cell[1],
                      '返品数' + MSG_ERROR['MSG_010'],
                    ];
                  } else if (parseInt(cell[3]) === 0) {
                    // If amount equals zero
                    errCell = [cell[0], cell[1], '返品数に0は入力できません'];
                  } else if (cell[3]?.toString()?.length > BARA_MAX_LENGTH) {
                    errCell = [
                      cell[0],
                      cell[1],
                      `返品数には${BARA_MAX_LENGTH}文字以下を入力してください`,
                    ];
                  }
                }
              }
            }
          });
          let allCds = hotTableComponent?.current?.hotInstance
            ?.getData()
            .map((item: any) => item[0]);
          if (newSyohinCdList.length)
            // Only call API get product infos if have new product code
            getLstSyohin(
              { syohinCds: newSyohinCdList },
              {
                onSuccess({ data }, variables, context) {
                  let failedCds: any[] = [];
                  data.forEach((item: any) => {
                    // Find the first valid product code to fill product name
                    const syohinIndex = allCds.findIndex(
                      (i: any) => i === item.cd24,
                    );
                    hotTableComponent?.current?.hotInstance.setDataAtCell(
                      syohinIndex,
                      1,
                      `${item.nnm || ''}${item.nnmsz || ''}`,
                      'reset',
                    );
                    // Clear data of other duplicate products
                    allCds?.every((cd24: any, index: number) => {
                      if (cd24 !== item.cd24) return true;
                      if (index === syohinIndex) return true;
                      hotTableComponent?.current?.hotInstance.setDataAtCell(
                        index,
                        1,
                        null,
                        'reset',
                      );
                      return true;
                    });
                    if (!item?.nnm) {
                      if (
                        !checkForSpecialChar(item.cd24) &&
                        item.cd24?.length <= SYOHINCD_MAX_LENGTH
                      )
                        // Product code is not exist
                        failedCds.push({ cd24: item.cd24, row: syohinIndex });
                    }
                  });
                  if (errCell) {
                    messagePopup({
                      type: 'info',
                      content: errCell[2],
                      onOk: () => {
                        inProgess = false;
                        hotTableComponent?.current?.hotInstance.selectCell(
                          errCell[0],
                          errCell[1],
                        );
                      },
                    });
                  } else if (!!failedCds.length) {
                    messagePopup({
                      type: 'info',
                      content: MSG_ERROR['MSG_009'],
                      onOk: () => {
                        inProgess = false;
                        hotTableComponent?.current?.hotInstance.selectCell(
                          failedCds[0].row,
                          0,
                        );
                        return;
                      },
                    });
                  } else inProgess = false;
                },
                onError(error, variables, context) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                    onOk: () => {
                      inProgess = false;
                    },
                  });
                },
              },
            );
          else if (errCell) {
            setTimeout(() => {
              messagePopup({
                type: 'info',
                content: errCell[2],
                onOk: () => {
                  inProgess = false;
                  hotTableComponent?.current?.hotInstance.selectCell(
                    errCell[0],
                    errCell[1],
                  );
                },
              });
            }, 200);
          } else {
            inProgess = false;
          }
        }}
      >
        <HotColumn title="品名CD" width={100} />
        <HotColumn
          title="品　名"
          readOnly
          width={350}
          className="readonly-column"
        />
        <HotColumn title="返品数" width={80} type="numeric" />
      </HotTable>
      <FooterForm>
        <Space>
          <Button
            onKeyDown={e => {
              if (e.shiftKey && e.key === 'Tab') {
                e.preventDefault();
                setTimeout(() => {
                  hotTableComponent?.current?.hotInstance.selectCell(98, 2);
                }, 0);
              }
            }}
            ref={el => (formRef.current.btnSubmit = el)}
            type="primary"
            htmlType="submit"
            form="return-input"
            style={{ minWidth: 100 }}
          >
            確定
          </Button>
          <Button
            type="default"
            style={{ minWidth: 100 }}
            onClick={event => {
              messagePopup({
                type: 'question',
                content: '画面をクリアします。よろしいですか?',
                onOk: () => clearScreen(),
                onCancel: () => {
                  (
                    (event.target as HTMLElement)
                      .offsetParent as HTMLButtonElement
                  ).focus();
                },
              });
            }}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            style={{ minWidth: 100 }}
            onClick={event => {
              messagePopup({
                type: 'question',
                content: '終了します。よろしいですか?',
                onOk: () => navigate(PATH.TOP),
                onCancel: () => {
                  (
                    (event.target as HTMLElement)
                      .offsetParent as HTMLButtonElement
                  ).focus();
                },
              });
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FooterForm>

      <TorihikisakiSearch
        isModalVisible={isVisibleTorihikisaki}
        setIsModalVisible={setIsVisibleTorihikisaki}
        setTorihikiCd={(torihikiCd: any) => {
          if (torihikiCd === form.getFieldValue('torihikiCd')) return;
          setPreviusTorihikiCd(torihikiCd);
          form.setFieldsValue({
            torihikiCd,
          });
          getCustomerInfo({ torihikiCd, hanList: false, tantoList: false });
        }}
        focusAfterClose={() => {
          formRef.current.buttonSearch.focus();
        }}
      />

      <SyohinSearch
        isModalVisible={isVisibleSyohin}
        setIsModalVisible={setIsVisibleSyohin}
        setCd24={(cd24: any) => {
          const allRowsData =
            hotTableComponent?.current?.hotInstance?.getData();
          // Find the product code already in the table
          let existedIndex = allRowsData?.findIndex(
            (item: any) => item[0] === cd24,
          );
          if (existedIndex < 0) {
            const blankIndex = allRowsData?.findIndex(
              (item: any) => !item[0]?.length,
            );
            if (blankIndex < 0) {
              messagePopup({
                type: 'info',
                content: '品目数がオーバーしました（1品目削除してください）',
              });
            } else {
              hotTableComponent?.current?.hotInstance.setDataAtCell(
                blankIndex,
                0,
                cd24,
              );
              hotTableComponent?.current?.hotInstance.selectCell(blankIndex, 2);
            }
          } else {
            // If have duplicated product in the table
            messagePopup({
              type: 'info',
              content: `この品名は${
                existedIndex + 1
              }行目ですでに入力されています`,
              onOk: () => {
                hotTableComponent?.current?.hotInstance.selectCell(
                  existedIndex,
                  0,
                );
              },
            });
          }
        }}
        focusAfterClose={() => {
          formRef?.current?.btnSearchSyohin?.focus();
        }}
      />

      <ReturnConfirm
        isModalVisible={isVisibleReturnConfirm}
        setIsModalVisible={setIsVisibleReturnConfirm}
        data={returnData}
        onResetPage={clearScreen}
        onSubmitFailed={(firstErrSyohinCd: string) => {
          setIsVisibleReturnConfirm(false);
          const errorIndex = hotTableComponent?.current?.hotInstance
            ?.getData()
            ?.findIndex((row: any) => row[0] === firstErrSyohinCd);
          hotTableComponent?.current?.hotInstance.selectCell(errorIndex, 2);
        }}
      />
    </WrapperMainte>
  );
};

export default ReturnInput;
