import { Button, Col, Space } from 'antd';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import messagePopup from 'utils/message/mesage';
import moment from 'moment';
import { memo, useEffect, useRef } from 'react';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';
import {
  useChangeDenKbnShippingInfo,
  useUpdateShippingInfo,
} from 'api/shipping';
import { MSG_ERROR } from 'constants/text';
import { useLogAccess } from 'api/auth';
import { selectUserInfo } from 'slice/app';
import { useSelector } from 'react-redux';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  oldShippingInformation?: any;
  newShippingInformation?: any;
  onResetPage?: any;
  afterClose?: any;
  isChangeDenKbn?: boolean;
}

const columns = [
  {
    title: 'Ｎｏ．',
    dataIndex: 'index',
    width: 70,
    key: 'index',
    render: (_value: any) => (
      <div style={{ textAlign: 'center' }}>{_value + 1}</div>
    ),
  },
  {
    title: '品名ＣＤ',
    dataIndex: 'syohinCd',
    width: 150,
    key: 'syohinCd',
  },
  {
    title: '品名',
    dataIndex: 'nnm',
    width: 450,
    key: 'nnm',
    render: (_value: any, record: any) => (
      <div style={{ lineHeight: 1 }}>
        {_value}
        {record.nnmsz}
      </div>
    ),
  },
  {
    title: '変更前数量',
    dataIndex: 'oldBara',
    width: 85,
    key: 'oldBara',
    render: (_value: any) => <div style={{ textAlign: 'right' }}>{_value}</div>,
  },
  {
    title: '変更数量',
    width: 85,
    dataIndex: 'newBara',
    key: 'newBara',
    render: (_value: any) => <div style={{ textAlign: 'right' }}>{_value}</div>,
  },
];

const ShippingConfirm = ({
  isModalVisible,
  setIsModalVisible,
  oldShippingInformation,
  newShippingInformation,
  onResetPage,
  afterClose,
  isChangeDenKbn,
}: Props) => {
  const btnSubmitRef = useRef<any>(null);
  const userInfo = useSelector(selectUserInfo);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const { mutate: updateShippingInfo, isLoading: loadingUpdateShippingInfo } =
    useUpdateShippingInfo();

  const { mutate: changeDenKbn, isLoading: loadingChangeDenKbn } =
    useChangeDenKbnShippingInfo();

  const onSubmit = () => {
    messagePopup({
      type: 'question',
      content: '登録します。よろしいですか？',
      onOk: () => {
        const {
          header: {
            naibuNo,
            denNo,
            denKbn,
            nyukaDt,
            nohinDt,
            sendTo,
            binCd,
            sendAdrs,
            updDt,
          },
          listMeisai,
        } = newShippingInformation;
        let meisai = listMeisai.map((syohin: any) => {
          const { syohinCd, newBara, oldBara } = syohin;
          return { syohinCd, newBara: Number(newBara), oldBara };
        });
        let request = {
          naibuNo,
          denNo,
          denKbn,
          header: {
            nyukabi: nyukaDt,
            nonyubi: nohinDt,
            otodokesakiNm: sendTo,
            binCd,
            sendAdrs,
            updDt,
          },
          meisai,
        };
        if (isChangeDenKbn)
          changeDenKbn(request, {
            onSuccess(data, variables, context) {
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '出荷訂正',
                  pgName: '出荷訂正確認',
                  logType: '2',
                  action: '在庫出荷を変更',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: MSG_ERROR['MSG_UPDATE_SHIPPING_SUCCESS'],
                onOk: () => {
                  onResetPage();
                },
              });
            },
            onError(error, variables, context) {
              // console.log({ error });
              if (
                (error as any)?.response?.data?.message.search('MSG') === -1
              ) {
                if (
                  (error as any)?.response?.data?.message ===
                  'ZAIKO_IS_NOT_ENOUGH'
                ) {
                  messagePopup({
                    type: 'error',
                    content:
                      MSG_ERROR[(error as any)?.response?.data?.message] ||
                      error?.response?.data?.message,
                    onOk: () => btnSubmitRef.current.focus(),
                  });
                } else {
                  const listZaiko: any =
                    JSON.parse((error as any)?.response?.data?.message) || [];
                  messagePopup({
                    type: 'error',
                    content: (
                      <div style={{ textAlign: 'left' }}>
                        <p>{'以下の商品の数量が現在庫数を超えています。'}</p>
                        {listZaiko.map(
                          (item: { Code24: string; Zaiko: number }) => {
                            return (
                              <div key={item.Code24}>
                                <span>{`　${item.Code24}（現在庫数：${item.Zaiko}）`}</span>
                                <br />
                              </div>
                            );
                          },
                        )}
                      </div>
                    ),
                    onOk: () => btnSubmitRef.current.focus(),
                  });
                }
              } else {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                  onOk: () => btnSubmitRef.current.focus(),
                });
              }

              // messagePopup({
              //   type: 'error',
              //   content:
              //     MSG_ERROR[(error as any)?.response?.data?.message] ||
              //     error?.response?.data?.message,
              //   onOk: () => btnSubmitRef.current.focus(),
              // });
            },
          });
        else
          updateShippingInfo(request, {
            onSuccess(data, variables, context) {
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '出荷訂正',
                  pgName: '出荷訂正確認',
                  logType: '2',
                  action: '出荷情報を訂正',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: MSG_ERROR['MSG_UPDATE_SHIPPING_SUCCESS'],
                onOk: () => {
                  onResetPage();
                },
              });
            },
            onError(error, variables, context) {
              messagePopup({
                type: 'error',
                content:
                  MSG_ERROR[(error as any)?.response?.data?.message] ||
                  error?.response?.data?.message,
                onOk: () => btnSubmitRef.current.focus(),
              });
            },
          });
      },
      onCancel: () => btnSubmitRef.current.focus(),
    });
  };

  useEffect(() => {
    isModalVisible &&
      setTimeout(() => {
        btnSubmitRef?.current?.focus();
      }, 0);
  }, [isModalVisible]);

  console.log(newShippingInformation);

  return (
    <ModalCustom
      titleModal="出荷訂正確認"
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        afterClose();
      }}
      hideButtonClose={true}
    >
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>出荷内容をご確認ください</h2>
          <DetailsOrder style={{ width: 840 }}>
            <DetailsOrderItem span={24}>
              <Label>受付Ｎｏ</Label>
              <Content>
                <b style={{ fontSize: 20 }}>
                  {newShippingInformation?.header?.orderNo}
                </b>
              </Content>
            </DetailsOrderItem>

            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>得意先</Label>
                <Content>
                  {newShippingInformation?.header?.torihikiCd}
                  {'　'}
                  {newShippingInformation?.header?.kaisya}
                </Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>お届け先</Label>
                <Content>
                  <span
                    className={
                      newShippingInformation?.header?.sendTo !==
                      oldShippingInformation?.sendTo
                        ? 'new-data'
                        : ''
                    }
                  >
                    {newShippingInformation?.header?.sendTo}
                  </span>
                  <span
                    className={
                      newShippingInformation?.header?.sendAdrs !==
                      oldShippingInformation?.sendAdrs
                        ? 'new-data'
                        : ''
                    }
                  >
                    {newShippingInformation?.header?.sendAdrs}
                  </span>
                </Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>便</Label>
                <Content>
                  <span
                    className={
                      newShippingInformation?.header?.binCd !==
                      oldShippingInformation?.binCd
                        ? 'new-data'
                        : ''
                    }
                  >
                    {newShippingInformation?.header?.binCd}{' '}
                    {newShippingInformation?.header?.binnm}
                  </span>
                </Content>
              </DetailsOrderItem>
            </Col>
            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>内部Ｎｏ</Label>
                <Content>{newShippingInformation?.header?.naibuNo}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>伝票区分</Label>
                <Content>
                  <span className={isChangeDenKbn ? 'new-data' : ''}>
                    {isChangeDenKbn
                      ? '11'
                      : newShippingInformation?.header?.denKbn}
                  </span>
                </Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>伝票№</Label>
                <Content>{newShippingInformation?.header?.denNo}</Content>
              </DetailsOrderItem>
              {newShippingInformation?.header?.denKbn === '11' || (
                <DetailsOrderItem span={24}>
                  <Label>入荷日</Label>
                  <Content>
                    <span
                      className={
                        newShippingInformation?.header?.nyukaDt !==
                          oldShippingInformation?.nyukaDt || isChangeDenKbn
                          ? 'new-data'
                          : ''
                      }
                    >
                      {isChangeDenKbn
                        ? '000000'
                        : moment(
                            newShippingInformation?.header?.nyukaDt,
                            'YYMMDD',
                          ).format('YYYY/MM/DD (ddd)')}
                    </span>
                  </Content>
                </DetailsOrderItem>
              )}
              <DetailsOrderItem span={24}>
                <Label>納入日</Label>
                <Content>
                  <span
                    className={
                      newShippingInformation?.header?.nohinDt !==
                      oldShippingInformation?.nohinDt
                        ? 'new-data'
                        : ''
                    }
                  >
                    {moment(
                      newShippingInformation?.header?.nohinDt,
                      'YYMMDD',
                    ).format('YYYY/MM/DD (ddd)')}
                  </span>
                </Content>
              </DetailsOrderItem>
            </Col>
          </DetailsOrder>
          {/* <DetailsOrderItem
            span={24}
            style={{ minHeight: 85, padding: '5px 10px' }}
          >
            <Label> 【お届け先】</Label>
            <Content>
              {newShippingInformation?.header?.otodokesakiNm} <br />
              {newShippingInformation?.header?.otodokesakiAddress}
              <br />
              {newShippingInformation?.header?.otodokesakiTelNo}
            </Content>
          </DetailsOrderItem> */}
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="orderLine"
            height={'174px'}
            dataSource={newShippingInformation?.listMeisai || []}
            columns={columns}
            checkChangeValue={(record: any) => {
              if (record.newBara !== record.oldBara) return 'new-data';
              return '';
            }}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button
              ref={btnSubmitRef}
              type="primary"
              loading={loadingUpdateShippingInfo || loadingChangeDenKbn}
              onClick={onSubmit}
            >
              登録します
            </Button>
            <Button
              disabled={loadingUpdateShippingInfo || loadingChangeDenKbn}
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              訂正します（戻る）
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(ShippingConfirm);
