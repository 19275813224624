export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>便CD</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 130 }}>協力企業名</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 130, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>現場名</div>,
    dataIndex: 'genbanm',
    key: 'genbanm',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>配送住所</div>,
    dataIndex: 'sendAdr',
    key: 'sendAdr',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>発行伝票枚数</div>,
    dataIndex: 'maisuu',
    key: 'maisuu',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>積込時確認者</div>,
    dataIndex: 'ccc',
    key: 'ccc',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 100 }}>伝票回収枚数</div>,
    dataIndex: 'aaa',
    key: 'aaa',
    render: (_value: any) => (
      <div style={{ width: 100, textAlign: 'right' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 100 }}>確認者サイン</div>,
    dataIndex: 'bbb',
    key: 'bbb',
    render: (_value: any) => <div style={{ width: 100 }}>{_value}</div>,
  },
];
