import { DatePicker } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { InputCheck, ToASCII } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import { useEffect, useState } from 'react';
import messagePopup from 'utils/message/mesage';
import { CalendarOutlined } from '@ant-design/icons';
import { DatePickerWrapper } from './Datepicker.style';

const DatePickerCustom = ({
  refForm,
  formName,
  type,
  nextFocus,
  title,
  autoFocus,
  previousFocus,
  pressEnter = {},
  clearTimeOutRef,
  placeholder = 'YYMMDD',
  ...props
}: PickerProps<any> | any) => {
  const [visibleDatepickerDropdown, setVisibleDatepickerDropdown] =
    useState('none');
  const [valueDatePicker, setValueDatePicker] = useState('');
  const [open, setOpen] = useState(false);

  let lstInputDatePicker = document.querySelectorAll(
    `[placeholder="${placeholder}"]`,
  );
  useEffect(() => {
    lstInputDatePicker?.forEach(item => item.setAttribute('maxlength', '6'));
    lstInputDatePicker?.forEach(item => {
      item.setAttribute('maxlength', '6');
      item.addEventListener('keyup', (event: any) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
          document.execCommand('selectall', false, undefined);
        }
      });
      item.addEventListener('mouseup', event => {
        event.stopPropagation();
      });
    });
  }, [lstInputDatePicker]);

  const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
    let year: string;
    let month: string;
    let value: string;
    if (props.format === 'YYMMDD') {
      if (props.picker === 'month') {
        year = moment().year().toString();
        value = ToASCII(valueDatePicker);

        if (valueDatePicker.length === 1) {
          value = ToASCII(year + '0' + valueDatePicker);
        }
        if (valueDatePicker.length === 2) {
          value = ToASCII(year + valueDatePicker);
        }
      } else {
        year = moment().year().toString().slice(2, 4);
        month =
          Number(moment().month()) + 1 > 9
            ? (Number(moment().month()) + 1).toString()
            : '0' + (Number(moment().month()) + 1).toString();
        value = ToASCII(valueDatePicker);

        if (valueDatePicker.length === 1) {
          value = ToASCII(year + month + '0' + valueDatePicker);
        }
        if (valueDatePicker.length === 2) {
          value = ToASCII(year + month + valueDatePicker);
        }
        if (valueDatePicker.length === 3) {
          value = ToASCII(year + '0' + valueDatePicker);
        }
        if (valueDatePicker.length === 4) {
          value = ToASCII(year + valueDatePicker);
        }
        if (valueDatePicker.length === 5) {
          value = ToASCII(year.slice(0, 1) + valueDatePicker);
        }
      }
    }
    setOpen(false);
    setVisibleDatepickerDropdown('none');
    setTimeout(() => {
      if (value !== elem.target.value) {
        //Validate neu success thi 43, no thi 34
        let valueDate = moment(value, 'YYMMDD' as any);
        if (value && InputCheck(value)) {
          messagePopup({
            type: 'info',
            content: title + MSG_ERROR[InputCheck(value) || ''],
            onOk: () => {
              refForm?.current[formName]?.focus();
              document.execCommand('selectall', false, undefined);
            },
          });
        } else {
          if (valueDate && valueDate.isValid() && props.onChange) {
            props.onChange(valueDate, value);
          }
        }
      }
    }, 0);
  };

  return (
    <DatePickerWrapper displayDropdown={visibleDatepickerDropdown}>
      <DatePicker
        suffixIcon={
          <CalendarOutlined
            onClick={event => {
              event.preventDefault();
              if (visibleDatepickerDropdown === 'none')
                setVisibleDatepickerDropdown('block');
              else setVisibleDatepickerDropdown('none');
            }}
          />
        }
        placeholder={placeholder}
        autoFocus={autoFocus}
        open={open}
        onFocus={event => {
          setOpen(true);
          setValueDatePicker(ToASCII(event?.target.value));
          pressEnter.current = false;
        }}
        ref={(el: any) => (refForm.current[formName] = el)}
        onKeyDown={(event: any) => {
          if (
            event.key === 'Enter' ||
            (!event.shiftKey && event.keyCode === 9)
          ) {
            pressEnter.current = true;
            setTimeout(() => {
              nextFocus();
            }, 0);
          } else if (event.shiftKey && event.keyCode === 9) {
            setTimeout(() => {
              clearTimeOutRef && clearTimeOutRef();
              refForm.current[previousFocus]?.focus();
            }, 0);
          } else {
            setTimeout(() => {
              setValueDatePicker(ToASCII(event.target.value));
            }, 0);
          }
        }}
        onSelect={(value: any) => {
          setTimeout(() => {
            setValueDatePicker(moment(value).format(props.format));
          }, 0);
        }}
        onBlur={onBlur}
        onOpenChange={(_isOpen: any) => {
          if (!_isOpen) setVisibleDatepickerDropdown('none');
        }}
        {...props}
      />
    </DatePickerWrapper>
  );
};

export default DatePickerCustom;
