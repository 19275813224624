export const KIGYO_KBN = Object.freeze({
  KBN_YHK: '9',
  KBN_KYORYOKUTEN: '1',
  KBN_KYOTEN: '2',
  KBN_KYODOSOKO: '6',
  KBN_KYODOSOKO_HAISOSAKI: '5',
});

export const IsYhk = function (kbn: string) {
  if (kbn === KIGYO_KBN.KBN_YHK) return true;
  return false;
};

export const editCanma = function (
  quantity: number,
  minimumFractionDigits: boolean = false,
) {
  if (minimumFractionDigits)
    return quantity.toLocaleString('en-US', { minimumFractionDigits: 2 });
  return quantity.toLocaleString('en-US');
};
