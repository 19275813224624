/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'antd';
import ModalCustom from 'components/ModalCustom';
import TableCustom from 'components/TableCommon';
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import {
  BodyContent,
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Footer,
  HeaderLabel,
  Label,
} from './orderSheet.style';

interface Props {
  dataDetails: any;
  isModalVisible: boolean;
  setIsModalVisible: any;
  formSearch?: any;
}

const OrderSheet = ({
  dataDetails,
  isModalVisible,
  setIsModalVisible,
  formSearch,
}: Props) => {
  const [dataSource, setDataSource] = React.useState<any>([]);

  useEffect(() => {
    if (dataDetails) {
      const { data } = dataDetails;
      if (data?.data?.length !== 0) {
        const dataSource = data?.data?.map(
          (orderDetails: any, index: number) => ({
            index: index + 1,
            ...orderDetails,
          }),
        );
        setDataSource(dataSource);
      }
    }
  }, [dataDetails]);

  const columns: any = [
    {
      title: () => <div style={{ minWidth: 40 }}>行番</div>,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (_value: string, record: any) => (
        <div
          style={{
            minWidth: 40,
            width: 'max-content',
            padding: '2px 4px',
          }}
        >
          <Button
            style={{ visibility: !record.canEdit ? 'hidden' : 'unset' }}
            disabled={!record.canEdit}
            onClick={() => {
              if (formSearch) {
                formSearch.setFieldsValue({
                  naibuNo: record.naibuNo,
                  denNo: record.denNo,
                  denKbn: record.denKbn,
                  location: '',
                });
                setTimeout(() => {
                  formSearch.submit();
                }, 300);
              }

              setIsModalVisible(false);
            }}
            type="default"
          >
            選択
          </Button>
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 90 }}>品名コード</div>,
      dataIndex: 'code24',
      key: 'code24',
      align: 'left',
      render: (value: string) => (
        <div style={{ minWidth: 90, width: 'max-content' }}>{value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 435 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'index',
      align: 'left',
      render: (value: string, record: any) => (
        <div
          style={{
            minWidth: 435,
            width: 'max-content',
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1,
          }}
        >
          {value}
          <br />
          {CheckValue(record?.nnmsz).trim()}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>ご注文数</div>,
      dataIndex: 'bara',
      key: 'bara',
      align: 'right',
      render: (value: string) => (
        <div style={{ minWidth: 70, width: 'max-content' }}>
          {Number(value).toLocaleString()}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 50 }}>出荷数</div>,
      dataIndex: 'shuxjBara',
      key: 'shuxjBara',
      align: 'right',
      render: (value: string, record: any) => (
        <div
          style={{
            minWidth: 50,
            width: 'max-content',
            visibility: !record.canEdit ? 'hidden' : 'unset',
          }}
        >
          {Number(value).toLocaleString()}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 110 }}>納期</div>,
      dataIndex: 'nohinDt',
      key: 'nohinDt',
      align: 'center',
      render: (value: string, record: any) => (
        <div style={{ minWidth: 110, width: 'max-content' }}>
          {CheckDeliveryDate(value, record?.kakakuKbn)}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>内部番号</div>,
      dataIndex: 'naibuNo',
      key: 'naibuNo',
      align: 'center',
      render: (value: string, record: any) => (
        <div
          style={{
            minWidth: 80,
            width: 'max-content',
            visibility: !record.canEdit ? 'hidden' : 'unset',
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 40 }}>伝区</div>,
      dataIndex: 'denKbn',
      key: 'denKbn',
      align: 'center',
      render: (value: string, record: any) => (
        <div
          style={{
            minWidth: 40,
            width: 'max-content',
            visibility: !record.canEdit ? 'hidden' : 'unset',
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>伝票番号</div>,
      dataIndex: 'denNo',
      key: 'denNo',
      align: 'center',
      render: (value: string) => (
        <div style={{ minWidth: 80, width: 'max-content' }}>{value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>備考</div>,
      dataIndex: 'kakakuKbn',
      key: 'kakakuKbn',
      align: 'center',
      render: (value: string) => (
        <div style={{ minWidth: 80, width: 'max-content' }}>
          {remarks(value)}
        </div>
      ),
    },
  ];

  function DeliveryMethod(NOHIN_KBN: string, KAKAKU_KBN: string) {
    let string = '';
    switch (NOHIN_KBN) {
      case '3':
        string = '東雲引取';
        break;
      case '1':
        if (KAKAKU_KBN === '2' || KAKAKU_KBN === '7') {
          string = '特別';
        } else {
          string = '配送';
        }
        break;
      default:
        string = '';
        break;
    }
    return string;
  }

  function CheckValue(value: string) {
    if (value === null || value === undefined || value === '') {
      return '';
    } else {
      return value;
    }
  }

  function CheckDeliveryDate(date: string, KAKAKU_KBN: string) {
    let work = '　';
    switch (KAKAKU_KBN) {
      case '1':
      case '6':
        work = '予';
        break;
      case '2':
      case '7':
        work = '特';
        break;
      default:
        work = '　';
        break;
    }
    if (
      date === null ||
      date === '000000' ||
      date === '00000000' ||
      date === '999999' ||
      date === '99999999'
    ) {
      return '';
    } else if (date?.length === 6) {
      let currentYear = date.substring(0, 2);
      let newYear =
        parseInt(currentYear) <= 60
          ? `20${currentYear}${date.substring(2, 6)}`
          : `19${currentYear}${date.substring(2, 6)}`;
      return work + moment(newYear).format('YYYY/MM/DD');
    } else {
      return work + moment(date).format('YYYY/MM/DD');
    }
  }

  function remarks(KAKAKU_KBN: string) {
    switch (KAKAKU_KBN) {
      case '1':
      case '6':
        return '納期に注意';
      case '2':
      case '7':
        return '　';
      default:
        return '　';
    }
  }

  return (
    <>
      <ModalCustom
        theme="light"
        titleModal=""
        isModalVisible={isModalVisible}
        size="medium"
        setIsModalVisible={setIsModalVisible}
        hideButtonClose={true}
        afterClose={() => {
          setDataSource([]);
        }}
        widthModal="1035px"
      >
        <HeaderLabel>
          <span style={{ position: 'absolute', left: 10 }}>
            {CheckValue(dataSource[0]?.inpCd)}
          </span>
          <span style={{ fontSize: 24, fontWeight: 600 }}>注文書（控）</span>
          <span style={{ position: 'absolute', right: 50 }}>
            ご注文日
            {`　${moment(CheckValue(dataSource[0]?.inpDt)).format(
              'YYYY-MM-DD',
            )} ${CheckValue(
              dataSource[0]?.inpTm?.substring(0, 2),
            )}:${CheckValue(dataSource[0]?.inpTm?.substring(2, 4))}`}
          </span>
        </HeaderLabel>
        <DetailsOrder>
          <DetailsOrderItem span={24}>
            <Label>受付 Ｎｏ</Label>
            <Content className="orderNo">
              <b>{CheckValue(dataSource[0]?.orderNo)}</b>
            </Content>
          </DetailsOrderItem>
          <DetailsOrderItem span={12}>
            <Label>納品方法</Label>
            <Content>
              {DeliveryMethod(
                CheckValue(dataSource[0]?.nohinKbn),
                CheckValue(dataSource[0]?.kakakuKbn),
              )}
            </Content>
          </DetailsOrderItem>
          <DetailsOrderItem span={12}>
            <Label>ご注文担当</Label>
            <Content>{CheckValue(dataSource[0]?.kmannm)}様</Content>
          </DetailsOrderItem>
          <DetailsOrderItem span={12}>
            <Label>取引先</Label>
            <Content>
              {`${CheckValue(dataSource[0]?.tokuiCd)} ${CheckValue(
                dataSource[0]?.kaisya,
              )}`}
            </Content>
          </DetailsOrderItem>
          <DetailsOrderItem span={12}>
            <Label>件名</Label>
            <Content>{`${CheckValue(dataSource[0]?.kenmei)}`}</Content>
          </DetailsOrderItem>
          <DetailsOrderItem span={12}>
            <Label>班</Label>
            <Content>{`${CheckValue(dataSource[0]?.hanCd)} ${CheckValue(
              dataSource[0]?.hanNm,
            )}`}</Content>
          </DetailsOrderItem>
          <DetailsOrderItem span={12}>
            <Label>工事番号</Label>
            <Content>{`${CheckValue(dataSource[0]?.koujiNo)}`}</Content>
          </DetailsOrderItem>
        </DetailsOrder>
        <DetailsOrderItem
          style={{ minHeight: 85, padding: '5px 10px' }}
          span={24}
        >
          <Label>【お届け先】</Label>
          <Content>
            {`${CheckValue(dataSource[0]?.sendAdd1)} ${CheckValue(
              dataSource[0]?.sendAdd2,
            )} ${CheckValue(dataSource[0]?.sendAdd3)}`}
            <br />
            {`${CheckValue(dataSource[0]?.sendTo1)} ${CheckValue(
              dataSource[0]?.sendTo2,
            )}`}
            <br />
            {`${CheckValue(dataSource[0]?.telNo)}`}
          </Content>
        </DetailsOrderItem>
        <BodyContent>
          <TableCustom
            rowKey="index"
            height={'289px'}
            columns={columns}
            dataSource={dataSource}
          />
        </BodyContent>
        <Footer>
          <Button
            style={{ width: 92 }}
            onClick={event => {
              event.preventDefault();
              setIsModalVisible(false);
            }}
          >
            戻る
          </Button>
        </Footer>
      </ModalCustom>
    </>
  );
};

export default memo(OrderSheet);
