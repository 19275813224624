import { Card, Form } from 'antd';
import styled from 'styled-components';

export const WrapperLogin = styled(Card)`
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 450px;
  overflow: hidden;
  border: none;
  .ant-card-head {
    background: #3b3bcf;
    border: none;
    .ant-card-head-title {
      padding: 12px 0;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }
  }
  .ant-card-body {
    padding: 20px 30px;
  }
`;
export const TitleMainte = styled.div`
  display: flex;
  flex-direction: column;
  .label-mode {
    font-weight: 600;
    color: #ff8306;
    font-size: 20px;
  }
`;

export const HeaderForm = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  .labelShimeMsg {
    color: #ff0000;
  }
`;
export const BodyForm = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const FooterForm = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    font-weight: 500;
  }
`;

export const WrapperMainte = styled.div`
  width: auto;
  min-width: 990px;
  .handsontable {
    font-size: 16px;
  }
  .handsontable thead th .relative {
    background-color: #3b3bcf;
    color: #fff;
  }
`;

export const FormWrapper = styled(Form)`
  .ant-upload-list {
    width: 210mm;
  }
  .ant-form-item-control {
    flex: 1 1 !important;
  }
  .ant-form-item-label > label {
    font-weight: 600;
  }
  .ant-btn {
    font-weight: 500;
  }
  .ant-form-item {
    margin-bottom: 18px;
    height: 30px;
    align-items: center;
  }

  &.form-feature {
    .ant-row {
      flex-flow: row;
    }
    .ant-form-item {
      margin-bottom: 4px;
      height: 30px;
      align-items: center;
    }
    .ant-form-item-label {
      background: #b2cbff;
      padding-left: 8px;
      margin-right: 4px;
      padding-bottom: 0;
      align-items: center;
      display: flex;
      & > label {
        font-weight: 600;
        height: 30px;
      }
    }
  }
  &.form-mainte {
    .ant-row {
      flex-flow: row;
    }
    .ant-form-item {
      margin-bottom: 4px;
      height: auto;
      align-items: center;
      .ant-form-item-explain {
        display: none;
      }
    }
    .flex-formsearch {
      display: flex;
      gap: 12px;
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
    .ant-form-item-label {
      background: #b2cbff;
      padding-left: 8px;
      margin-right: 4px;
      padding-bottom: 0;
      align-items: center;
      display: flex;
      & > label {
        font-weight: 600;
        height: 30px;
      }
    }
    .ant-form-item-explain {
      display: none !important;
    }
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-form-item-has-error .ant-input-number-focused,
    .ant-form-item-has-error .ant-picker-focused,
    .ant-form-item-has-error .ant-input-number:focus,
    .ant-form-item-has-error .ant-picker:focus,
    .ant-form-item-has-error .ant-input-number,
    .ant-form-item-has-error .ant-picker {
      background-color: #fff;
      border-color: #949494 !important;
    }
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
    .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open
      .ant-select-selector,
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused
      .ant-select-selector,
    .ant-form-item-has-error .ant-input-number-focused,
    .ant-form-item-has-error .ant-picker-focused,
    .ant-form-item-has-error .ant-input-number:focus,
    .ant-form-item-has-error .ant-picker:focus {
      box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.2) !important;
      border-color: #1928da !important;
    }
    .ant-form-item-has-error .ant-input-affix-wrapper
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
      .ant-form-item-has-error
      :not(.ant-input-disabled):not(.ant-input-borderless).ant-input[type='password']:focus {
      box-shadow: none !important;
    }
    .ant-form-item-has-error
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: #949494 !important;
      &:hover:not(:disabled) {
        border-color: #1928da !important;
      }
    }
    .disable-input .ant-form-item-label {
      background: #d8d8d8;
      & > label {
        color: #898989;
      }
    }
    .ant-form-item-has-error .ant-input-group-addon {
      color: unset;
      border-color: #949494;
    }
    .input-number {
      text-align: right;
    }
    .delete-input {
      text-decoration: line-through;
      pointer-events: none;
    }
  }

  &.disable-input {
    .ant-form-item-control {
      pointer-events: none;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 25px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 25px;
  }
  &#websearch-mainte {
    width: 990px;
  }
`;

export const ButtonGroupLogin = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  color: #ff0000;
  margin-bottom: 8px;
`;
