import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  sysDate: string;
}

export function useGetStockCheckList() {
  const getStockCheckList = async ({ sysDate }: TypeProps) => {
    const params = {
      sysDate,
    };

    return await AxiosGetFile(API_URL.GET_STOCKCHECKLIST, params);
  };

  const mutation = useMutation(getStockCheckList as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
