export const menuList = [
  {
    group: [
      {
        title: '出荷明細情報',
        textToolTip: '出荷明細情報',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/ShippingInformation',
        number: '40.',
      },
      {
        title: '出荷明細情報★入力日時表示版',
        textToolTip: '出荷明細情報★入力日時表示版',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/ShippingDetailsInformation',
        number: '566.',
      },
      {
        title: '入荷明細情報 在庫入荷のみ',
        textToolTip: '入荷明細情報 在庫入荷のみ',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/StockDetails',
        number: '41.',
      },
      {
        title: '発注処理チェック',
        textToolTip: '発注処理チェック',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/OrderProcessingCheck',
        number: '69.',
      },
      {
        title: '注残チェック',
        textToolTip: '注残チェック',
        extraText: ``,
        backgroundColor: '#a0d5ff',
        path: '/CheckOrderBacklog',
        number: '72.',
      },
    ],
  },
  {
    group: [
      {
        title: '協力店情報',
        textToolTip: '協力店情報',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/CooperatingStoreInformation',
        number: '82.',
      },
      {
        title: '在庫情報',
        textToolTip: '在庫情報',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/InventoryInformation',
        number: '245.',
      },
      {
        title: '在庫情報○○商品用',
        textToolTip: '在庫情報 ○○商品用',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/StockInformationProducts',
        number: '246.',
      },
      {
        title: '協力店情報（班・便なし）',
        textToolTip: '協力店情報（班・便なし）',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/PartnerShipStore',
        number: '592.',
      },
    ],
  },
  {
    group: [
      {
        title: '出荷明細商品別集計情報',
        textToolTip: '出荷明細商品別集計情報',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/ShippingDetailsAggregate',
        number: '74.',
      },
      {
        title: '出荷行数集計',
        textToolTip: '出荷行数集計',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/ShippedLines',
        number: '78.',
      },
      {
        title: '商品別出荷集計',
        textToolTip: '商品別出荷集計',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/ShipmentTotalByProduct',
        number: '79.',
      },
      {
        title: '商品別入荷集計',
        textToolTip: '商品別入荷集計',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/CollectingGoodsByProduct',
        number: '80.',
      },
    ],
  },
  {
    group: [
      {
        title: '便別配送モニター',
        textToolTip: '便別配送モニター',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/MonitorDelivery',
        number: '119.',
      },
      {
        title: '便別配送モニター≪印刷用≫',
        textToolTip: '便別配送モニター≪印刷用≫',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/MonitorDeliveryByFlight',
        number: '120.',
      },
      // {
      //   title: '便別配車リスト',
      //   textToolTip: '便別配車リスト',
      //   extraText: '',
      //   backgroundColor: '#ffffa7',
      //   path: '/VehicleAllocationList',
      //   number: '121.',
      // },
      {
        title: '便配車リスト≪印刷用≫',
        textToolTip: '便配車リスト≪印刷用≫',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/FlightDispatchList',
        number: '122.',
      },
      {
        title: '先納期 大口リスト',
        textToolTip: '先納期 大口リスト',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/PreDelivery',
        number: '123.',
      },
      {
        title: '伝票回収チェックリスト',
        textToolTip: '伝票回収チェックリスト',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/VoucherCollectionChecklist',
        number: '124.',
      },
      {
        title: '伝票回収チェックリスト≪印刷用≫',
        textToolTip: '伝票回収チェックリスト≪印刷用≫',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/ListRevokedVouchers',
        number: '125.',
      },
      {
        title: '伝票回収チェックリスト（伝票調査用）',
        textToolTip: '伝票回収チェックリスト（伝票調査用）',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/SlipCollectionChecklist',
        number: '128.',
      },
      {
        title: '発行伝票枚数 確認リスト≪印刷用≫',
        textToolTip: '発行伝票枚数 確認リスト≪印刷用≫',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/ConfirmListVoucher',
        number: '129.',
      },
      {
        title: '出荷明細情報（報告用）',
        textToolTip: '出荷明細情報（報告用）',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/ShippingInformationForReport',
        number: '130.',
      },
    ],
  },
  {
    group: [
      {
        title: 'HAT商品の納入日調査',
        textToolTip: 'HAT商品の納入日調査',
        extraText: '',
        backgroundColor: '#c4ffa7',
        path: '/HatProductDelivery',
        number: '583.',
      },
    ],
  },
];
export const data = [
  {
    title: '明細情報',
    backgroundColor: '#a0d5ff',
    group: [
      {
        title: '出荷明細情報（ＹＨＫ用）',
        textToolTip: '出荷明細情報',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/ShippingInformation',
        number: '40.',
      },
      {
        title: '出荷明細情報（ＹＨＫ用） ★入力日時表示版',
        textToolTip: '出荷明細情報★入力日時表示版',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/ShippingDetailsInformation',
        number: '566.',
      },
      {
        title: '入荷明細情報（ＹＨＫ用）在庫入荷のみ',
        textToolTip: '入荷明細情報 在庫入荷のみ',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/StockDetails',
        number: '41.',
      },
      {
        title: '発注処理チェック',
        textToolTip: '発注処理チェック',
        extraText: '',
        backgroundColor: '#a0d5ff',
        path: '/OrderProcessingCheck',
        number: '69.',
      },
      {
        title: '注残チェック',
        textToolTip: '注残チェック',
        extraText: ``,
        backgroundColor: '#a0d5ff',
        path: '/CheckOrderBacklog',
        number: '72.',
      },
      //注残削除処理 73
    ],
  },
  {
    title: 'マスタ情報',
    backgroundColor: '#ffd1a4',

    group: [
      {
        title: '協力店情報（ＹＨＫ用）',
        textToolTip: '協力店情報',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/CooperatingStoreInformation',
        number: '82.',
      },
      {
        title: '在庫情報（ＹＨＫ用）',
        textToolTip: '在庫情報',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/InventoryInformation',
        number: '245.',
      },
      {
        title: '在庫情報（ＹＨＫ用）アカギ商品用',
        textToolTip: '在庫情報 ○○商品用',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/StockInformationProducts',
        number: '246.',
      },
      {
        title: '協力店情報（班・便なし）',
        textToolTip: '協力店情報（班・便なし）',
        extraText: '',
        backgroundColor: '#ffd1a4',
        path: '/PartnerShipStore',
        number: '592.',
      },
    ],
  },
  {
    title: '集計情報',
    backgroundColor: '#dedede',

    group: [
      {
        title: '出荷明細商品別集計情報（ＹＨＫ用）',
        textToolTip: '出荷明細商品別集計情報',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/ShippingDetailsAggregate',
        number: '74.',
      },
      {
        title: 'YHK出荷行数集計',
        textToolTip: '出荷行数集計',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/ShippedLines',
        number: '78.',
      },
      {
        title: 'YHK商品別出荷集計',
        textToolTip: '商品別出荷集計',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/ShipmentTotalByProduct',
        number: '79.',
      },
      {
        title: 'YHK商品別入荷集計',
        textToolTip: '商品別入荷集計',
        extraText: '',
        backgroundColor: '#efefef',
        path: '/CollectingGoodsByProduct',
        number: '80.',
      },
    ],
  },
  {
    title: 'キャプティ便情報',
    backgroundColor: '#ffffa7',

    group: [
      {
        title: 'キャプティ便配送モニター',
        textToolTip: '便別配送モニター',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/MonitorDelivery',
        number: '119.',
      },
      // {
      //   title: 'キャプティ便配送モニター≪印刷用≫',
      //   textToolTip: '便別配送モニター≪印刷用≫',
      //   extraText: '',
      //   backgroundColor: '#ffffa7',
      //   path: '/MonitorDeliveryByFlight',
      //   number: '120.',
      // },
      // {
      //   title: 'キャプティ便配車リスト',
      //   textToolTip: '便別配車リスト',
      //   extraText: '',
      //   backgroundColor: '#ffffa7',
      //   path: '/VehicleAllocationList',
      //   number: '121.',
      // },
      // {
      //   title: 'キャプティ便配車リスト≪印刷用≫',
      //   textToolTip: '便配車リスト≪印刷用≫',
      //   extraText: '',
      //   backgroundColor: '#ffffa7',
      //   path: '/FlightDispatchList',
      //   number: '122.',
      // },
      {
        title: '先納期 大口リスト',
        textToolTip: '先納期 大口リスト',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/PreDelivery',
        number: '123.',
      },
      {
        title: '伝票回収チェックリスト',
        textToolTip: '伝票回収チェックリスト',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/VoucherCollectionChecklist',
        number: '124.',
      },
      // {
      //   title: '伝票回収チェックリスト≪印刷用≫',
      //   textToolTip: '伝票回収チェックリスト≪印刷用≫',
      //   extraText: '',
      //   backgroundColor: '#ffffa7',
      //   path: '/ListRevokedVouchers',
      //   number: '125.',
      // },
      {
        title: '伝票回収チェックリスト（伝票調査用）',
        textToolTip: '伝票回収チェックリスト（伝票調査用）',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/SlipCollectionChecklist',
        number: '128.',
      },
      // {
      //   title: '発行伝票枚数　確認リスト≪印刷用≫',
      //   textToolTip: '発行伝票枚数 確認リスト≪印刷用≫',
      //   extraText: '',
      //   backgroundColor: '#ffffa7',
      //   path: '/ConfirmListVoucher',
      //   number: '129.',
      // },
      {
        title: '出荷明細情報（報告用）',
        textToolTip: '出荷明細情報（報告用）',
        extraText: '',
        backgroundColor: '#ffffa7',
        path: '/ShippingInformationForReport',
        number: '130.',
      },
    ],
  },
  {
    title: 'HAT油井さん用',
    backgroundColor: '#c4ffa7',
    group: [
      {
        title: 'HAT商品の納入日調査',
        textToolTip: 'HAT商品の納入日調査',
        extraText: '',
        backgroundColor: '#c4ffa7',
        path: '/HatProductDelivery',
        number: '583.',
      },
    ],
  },
];
