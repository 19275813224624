import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const CustomModal = styled(Modal)`
  top: 5%;
  padding-bottom: 0px;
  max-width: unset;
  .ant-modal-header {
    height: 55px;
    background-color: #1928da;
    display: flex;
    align-items: center;
    padding-left: 33px;
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      width: 100%;
    }
  }
  .ant-modal-close {
    top: 0px;
    right: 3px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;
  }
  .ant-modal-content {
    border-radius: 12px 12px 10px 10px;
  }
`;

export const HeaderModel = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-right: 40px;
  color: #fff;
  .title-modal {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const LinkManual = styled.a`
  position: absolute;
  text-decoration: none;
  color: #fff;
  /* font-size: 16px; */
  top: 20px;
  right: 60px;
`;

export const CloseButtonCustom = styled(Button)`
  align-self: center;
  width: 115px;
  /* height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;*/
`;
