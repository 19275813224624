import React from 'react';
import SyohinInfo from 'views/common/syohinInfo';

const SyohinInfoFile = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const syohinCd: any = queryParams.get('syohinCd');

  return <SyohinInfo syohinCd={syohinCd} />;
};

export default SyohinInfoFile;
