/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Form, Input, Select, Space, Tabs } from 'antd';
import { useLogAccess } from 'api/auth';
import { iDefinedValues } from 'api/common';
import {
  iKigyoType,
  useCreateCustomerMainte,
  useDeleteCustomerMainte,
  useGetCustomerMainte,
  useGetKigyoType,
  useGetTensyoh,
  useUpdateCustomerMainte,
} from 'api/customer';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { SwitchModeTabs } from 'components/StyleCommon';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  Hankaku2Zenkaku,
  IsHankaku,
  IsHankakuEisu,
  isNumeric,
  IsZenkaku,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectDefinedValues, selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  BodyForm,
  FooterForm,
  FormWrapper,
  HeaderForm,
  TitleMainte,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import TorihikisakiSearch from '../common/torihikisakiSearch';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const MODE_CREATE = '登録モード';
const MODE_UPDATE = '修正モード';
const MODE_DELETE = '削除モード';

const TorihikiMainte = () => {
  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  /**
   * *API GET_TORIHIKIBYTORIHIKICD
   * todo Get torihiki info to update or delete
   * @param torihikicd
   */
  const {
    mutate: mutationGetCustomer,
    isSuccess: isSuccessGetCustomer,
    isLoading: loadingGetCustomer,
    reset: resetGetCustomer,
  } = useGetCustomerMainte();

  const {
    mutate: getKigyoType,
    data: dataKigyoType,
    isLoading: loadingKigyoType,
  } = useGetKigyoType();

  const {
    mutate: getTensyoh,
    data: dataTensyoh,
    isLoading: loadingTensyoh,
  } = useGetTensyoh();

  /**
   * *API INSERT_TORIHIKI
   * todo Input torihiki info to insert into system
   * @param {...torihiki}
   */
  const { mutate: mutationCreateCustomer, isLoading: loadingCreateCustomer } =
    useCreateCustomerMainte();

  /**
   * *API UPDATE_TORIHIKI
   * todo Update torihiki info
   * @param {...torihiki}
   */
  const { mutate: mutationUpdateCustomer, isLoading: loadingUpdateCustomer } =
    useUpdateCustomerMainte();

  /**
   * *API DELETE_TORIHIKI
   * todo Delete torihiki info
   * @param torihikicd
   */
  const { mutate: mutationDeleteCustomer, isLoading: loadingDeleteCustomer } =
    useDeleteCustomerMainte();

  const definedValues: iDefinedValues = useSelector(selectDefinedValues);

  const userInfo = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const previousTorihikiCd = useRef<any>('');
  const refForm = React.useRef<any>({});
  const [mode, setMode] = React.useState<any>(MODE_UPDATE);
  const [isVisibleTorihikisaki, setIsVisibleTorihikisaki] =
    React.useState(false);
  const [disableInput, setDisableInput] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [disableInputTorihiki, setDisableInputTorihiki] = React.useState(false);
  const [enableInputPWD, setEnableInputPWD] = React.useState(false);
  const [valuesChange, setValuesChange] = React.useState(false);
  const [form] = Form.useForm();
  const [defaultFormValues, setDefaultFormValues] = React.useState<any>(null);

  function getKeyboardFocusableElements(element = document) {
    return [
      ...element.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
      ),
    ].filter(
      el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
    );
  }
  const keyboardFocusElement: any = getKeyboardFocusableElements();

  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.findIndex(
      (element: any) => element === e.target,
    );
    // console.log(keyboardFocusElement);
    // console.log(index);
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement[index + 1]
          ? keyboardFocusElement[index + 1]?.focus()
          : keyboardFocusElement[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement[index - 1]
          ? keyboardFocusElement[index - 1]?.focus()
          : keyboardFocusElement[keyboardFocusElement.length]?.focus();
      }
    }
  };

  const [openDropdown, setOpenDropdown] = React.useState<{
    tensyoKana: boolean;
    atukai: boolean;
  }>({
    tensyoKana: false,
    atukai: false,
  });

  // Set mode
  const setModeNohinKbn = (mode: any) => {
    setMode(mode);
    setDisableInputTorihiki(false);
    resetForm();
  };

  // Render label with mode
  function labelModeOnChange() {
    switch (mode) {
      case MODE_CREATE:
        return '登録モード';
      case MODE_UPDATE:
        return '修正モード';
      case MODE_DELETE:
        return '削除モード';
      default:
        break;
    }
  }

  // Click button done
  const onFinish = (values: any) => {
    const {
      atukai,
      torihikicd,
      torihikiKana,
      tensyoKana,
      torihikiNm,
      tensyo,
      postcd1,
      postcd2,
      adrs1,
      adrs2,
      adrs3,
      tel,
      fax,
      captyTorihikiCd,
      id,
      passwd,
    } = values;

    let request = {
      atukai,
      torihikicd,
      torihikiKana,
      tensyoKana,
      torihikiNm,
      tensyo,
      postcd: postcd1 + '-' + postcd2,
      adrs1,
      adrs2,
      adrs3,
      tel,
      fax,
      // captytoricd:
      //   captyTorihikiCd.length >= 7
      //     ? captyTorihikiCd?.slice(
      //         captyTorihikiCd.length - 7,
      //         captyTorihikiCd.length,
      //       )
      //     : captyTorihikiCd,
      captyTorihikiCd,
      id,
      passwd,
    };
    const { passwd: pwd, ...restObject } = request;

    switch (mode) {
      case MODE_CREATE:
        messagePopup({
          type: 'question',
          content: MSG_ERROR.MSG_CONFIRM_INSERT_TORIHIKI_MAINTE,
          onOk: () =>
            mutationCreateCustomer(request, {
              // Display msg when insert torihiki success
              onSuccess(data, variables, context) {
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: '取引先メンテ',
                    pgName: '登録モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: MSG_ERROR.MSG_INSERT_TORIHIKI_MAINTE_SUCCESS,
                  onOk: () => resetForm(),
                });
              },
              // Display msg when insert torihiki info failed
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                });
              },
            }),
        });
        break;
      case MODE_UPDATE:
        messagePopup({
          type: 'question',
          content: MSG_ERROR.MSG_CONFIRM_UPDATE_TORIHIKI_MAINTE,
          onOk: () =>
            mutationUpdateCustomer(enableInputPWD ? request : restObject, {
              // Display msg when update torihiki success
              onSuccess(data, variables, context) {
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: '取引先メンテ',
                    pgName: '修正モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: MSG_ERROR.MSG_UPDATE_TORIHIKI_MAINTE_SUCCESS,
                  onOk: () => resetForm(),
                });
              },
              // Display msg when update torihiki info failed
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                });
              },
            }),
        });
        break;
      case MODE_DELETE:
        messagePopup({
          type: 'question',
          content: MSG_ERROR.MSG_CONFIRM_DELETE_TORIHIKI_MAINTE,
          onOk: () =>
            mutationDeleteCustomer(torihikicd, {
              // Display msg when delete torihiki success
              onSuccess(data, variables, context) {
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: '取引先メンテ',
                    pgName: '削除モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: MSG_ERROR.MSG_DELETE_TORIHIKI_MAINTE_SUCCESS,
                  onOk: () => resetForm(),
                });
              },
              // Display msg when delete torihiki info failed
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content: (
                    <pre
                      style={{ textAlign: 'left', margin: 0, lineHeight: 1.2 }}
                    >
                      {error?.response?.data?.message}
                    </pre>
                  ),
                });
              },
            }),
        });
        break;
      default:
        break;
    }
  };

  // Display msg error validate
  const onFinishFailed = ({ values, errorFields }: any) => {
    // console.log(errorFields);
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  React.useEffect(() => {
    getKigyoType(
      { fromCustomerMainte: true },
      {
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
          });
        },
      },
    );

    getTensyoh(
      {},
      {
        onError(error, variables, context) {
          messagePopup({
            type: 'info',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              (error as any)?.response?.data?.message,
          });
        },
      },
    );
  }, []);

  // Set status disabled input with mode
  React.useEffect(() => {
    refForm.current?.torihikicd.focus();
    if (mode === MODE_CREATE) {
      setEnableInputPWD(true);
      setDisableInput(false);
      setDefaultFormValues(form.getFieldsValue());
    } else {
      setDisableInput(true);
      setEnableInputPWD(false);
      setDefaultFormValues(null);
    }
  }, [mode, reset]);

  // Reset form
  function resetForm() {
    setReset(!reset);
    setValuesChange(false);
    form.resetFields();
    setDisableInputTorihiki(false);
    resetGetCustomer();
    setTimeout(() => {
      refForm.current?.torihikicd.focus();
      refForm.current?.torihikicd.select();
    }, 300);
  }

  // Func call API GET_TORIHIKIBYTORIHIKICD
  const getInfoTorihiki = (value: string) => {
    const isChangedValue = value !== previousTorihikiCd.current;
    form.setFieldsValue({
      torihikicd: value,
    });
    if (!isChangedValue) return;
    mode !== MODE_CREATE &&
      form
        .validateFields(['torihikicd'])
        .then(value => {
          const { torihikicd } = value;

          mutationGetCustomer(
            { torihikiCd: torihikicd, hanList: false, tantoList: false },
            {
              // Display torihiki info when call API GET_TORIHIKIBYTORIHIKICD success
              onSuccess(data, variables, context) {
                const {
                  atukai,
                  torihikicd,
                  kaisyaH,
                  tensyoH,
                  kaisya,
                  tensyo,
                  postcd,
                  adrs1,
                  adrs2,
                  adrs3,
                  tel,
                  fax,
                  // captytoricd,
                  id,
                  passwd,
                  chumonfax,
                } = data?.data;
                form.setFieldsValue({
                  atukai,
                  torihikicd,
                  torihikiKana: kaisyaH,
                  tensyoKana: tensyoH,
                  torihikiNm: kaisya,
                  tensyo,
                  postcd1: postcd?.split('-')[0],
                  postcd2: postcd?.split('-')[1],
                  adrs1,
                  adrs2,
                  adrs3,
                  tel,
                  fax,
                  captyTorihikiCd: chumonfax,
                  id,
                  passwd,
                });
                setDisableInputTorihiki(true);
                mode === MODE_UPDATE && setDisableInput(false);
                setTimeout(() => {
                  setDefaultFormValues(form.getFieldsValue());
                }, 0);
                setTimeout(() => {
                  refForm.current?.torihikiNm.focus();
                  refForm.current?.torihikiNm.select();
                }, 300);
              },
              // Display msg when get torihiki info failed
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                  onOk: () => {
                    refForm?.current?.torihikicd?.focus();
                    refForm?.current?.torihikicd?.select();
                  },
                });
              },
            },
          );
        })
        .catch(reason => {
          const { errorFields } = reason;
          messagePopup({
            type: 'info',
            content: errorFields[0]?.errors[0],
            onOk: () => {
              refForm?.current[errorFields[0]?.name[0]]?.focus();
              refForm?.current[errorFields[0]?.name[0]]?.select();
            },
          });
        });
  };

  // Render element
  return (
    <WrapperMainte>
      {(loadingGetCustomer ||
        loadingCreateCustomer ||
        loadingUpdateCustomer ||
        loadingDeleteCustomer ||
        loadingKigyoType ||
        loadingTensyoh) && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="torihiki-mainte"
        labelCol={{ flex: '325px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        initialValues={{
          adrs1: '',
          adrs2: '',
          adrs3: '',
          captyTorihikiCd: '',
          fax: '',
          id: '',
          passwd: '',
          passwdConfirm: '',
          postcd1: '',
          postcd2: '',
          tel: '',
          tensyo: '',
          tensyoKana: null,
          atukai: null,
          torihikiKana: '',
          torihikiNm: '',
          torihikicd: '',
        }}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onBlur={() => {
          if (
            defaultFormValues &&
            JSON.stringify(defaultFormValues) !==
              JSON.stringify(form.getFieldsValue())
          ) {
            setValuesChange(true);
          } else {
            setValuesChange(false);
          }
        }}
      >
        <HeaderForm>
          <TitleMainte>
            <span className="label-mode">{labelModeOnChange()}</span>
          </TitleMainte>
          <Space>
            <b>モード選択：</b>
            <SwitchModeTabs
              activeKey={mode}
              onChange={key => {
                function changeMode(toKey: any) {
                  const formKey = mode;
                  switch (toKey) {
                    case MODE_CREATE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: '取引先メンテ',
                          pgName: formKey,
                          logType: '2',
                          action: '登録ボタン',
                        },
                      ] as any);
                      break;
                    case MODE_UPDATE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: '取引先メンテ',
                          pgName: formKey,
                          logType: '2',
                          action: '修正ボタン',
                        },
                      ] as any);
                      break;
                    case MODE_DELETE:
                      logAccess([
                        {
                          tokuiCd: userInfo.torihikicd,
                          gyoumuId: '取引先メンテ',
                          pgName: formKey,
                          logType: '2',
                          action: '削除ボタン',
                        },
                      ] as any);
                      break;

                    default:
                      break;
                  }
                  setModeNohinKbn(toKey);
                }
                if (valuesChange) {
                  messagePopup({
                    type: 'warning',
                    content: MSG_ERROR['MSG_VALUES_CHANGE'],
                    onOk: () => {
                      setValuesChange(false);
                      changeMode(key);
                    },
                    onCancel: () => refForm?.current?.torihikicd?.focus(),
                  });
                } else {
                  changeMode(key);
                }
              }}
            >
              <Tabs.TabPane tab={'登録モード'} key={MODE_CREATE} />
              <Tabs.TabPane tab={'修正モード'} key={MODE_UPDATE} />
              <Tabs.TabPane tab={'削除モード'} key={MODE_DELETE} />
            </SwitchModeTabs>
          </Space>
        </HeaderForm>
        <BodyForm>
          <span>設定情報</span>
          <Form.Item
            className={disableInputTorihiki ? 'disable-input' : ''}
            label="　１．取引先コード(半角英数字6桁)"
            style={{ marginBottom: 24 }}
          >
            <Space size={4}>
              <Form.Item
                name="torihikicd"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInputTorihiki) {
                        // 必須チェック
                        if (!value) {
                          return Promise.reject(
                            new Error('取引先コード' + MSG_ERROR['MSG_INPUT']),
                          );
                        }
                        // 半角英数字チェック
                        if (IsHankakuEisu(value)) {
                          return Promise.reject(
                            new Error(
                              '取引先コード' + MSG_ERROR['MSG_HANKAKU_EISU'],
                            ),
                          );
                        }
                        if (
                          mode === MODE_DELETE &&
                          value === definedValues?.torihikiCd_YHK
                        ) {
                          return Promise.reject(
                            new Error(MSG_ERROR['MSG_LOGINUSER_CANNOT_DELETE']),
                          );
                        }
                        if (
                          mode === MODE_DELETE &&
                          value === definedValues?.torihikiCd_soko
                        ) {
                          return Promise.reject(
                            new Error(MSG_ERROR['MSG_SOKO_CANNOT_DELETE']),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.torihikicd = el)}
                  disabled={disableInputTorihiki || loadingGetCustomer}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onFocus={event => {
                    previousTorihikiCd.current = event.target.value;
                  }}
                  onBlur={event => {
                    getInfoTorihiki(ToASCII(event.target.value, true).trim());
                  }}
                  onKeyDown={focusNextEle}
                  maxLength={6}
                  style={{ width: 100 }}
                ></Input>
              </Form.Item>
              {mode !== MODE_CREATE && (
                <Button
                  ref={el => (refForm.current.buttonTorihikicd = el)}
                  disabled={disableInputTorihiki}
                  type="default"
                  onClick={() => {
                    setIsVisibleTorihikisaki(true);
                    logAccess([
                      {
                        tokuiCd: userInfo.torihikicd,
                        gyoumuId: '取引先メンテ',
                        pgName: '取引先メンテ',
                        logType: '2',
                        action: '取引先検索ボタン',
                      },
                      {
                        tokuiCd: userInfo.torihikicd,
                        gyoumuId: '取引先メンテ',
                        pgName: '取引先検索',
                        logType: '1',
                        action: '',
                      },
                    ] as any);
                  }}
                  onKeyDown={focusNextEle}
                >
                  取引先検索
                </Button>
              )}
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　２．取引先名(全角15桁)"
            name="torihikiNm"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('取引先名' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 全角チェック
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('取引先名' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.torihikiNm = el)}
              disabled={disableInput}
              maxLength={15}
              style={{ width: 300 }}
              onBlur={event => {
                form.setFieldsValue({
                  torihikiNm: Hankaku2Zenkaku(event.target.value.trimEnd()),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            ></Input>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　３．支部/部･課(全角10桁)"
            name="tensyo"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    // 全角チェック
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('支部/部･課' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.tensyo = el)}
              disabled={disableInput}
              maxLength={10}
              style={{ width: 200 }}
              onBlur={event => {
                form.setFieldsValue({
                  tensyo: Hankaku2Zenkaku(event.target.value.trimEnd()),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            ></Input>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　４．取引先名かな(全角30桁)"
            name="torihikiKana"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('取引先名かな' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 全角チェック
                    if (!IsZenkaku(value)) {
                      return Promise.reject(
                        new Error('取引先名かな' + MSG_ERROR['MSG_HANKAKU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.torihikiKana = el)}
              disabled={disableInput}
              maxLength={30}
              style={{ width: 600 }}
              onBlur={event => {
                form.setFieldsValue({
                  torihikiKana: Hankaku2Zenkaku(event.target.value.trimEnd()),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            ></Input>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　５．事業部署"
            name="tensyoKana"
            // rules={[
            //   () => ({
            //     validator(_: any, value: string) {
            //       if (!disableInput) {
            //         if (value === null) {
            //           return Promise.reject(
            //             new Error('事業部署' + MSG_ERROR['MSG_SELECT']),
            //           );
            //         }
            //       }
            //       return Promise.resolve();
            //     },
            //   }),
            // ]}
          >
            <Select
              open={openDropdown.tensyoKana}
              onDropdownVisibleChange={open =>
                setOpenDropdown({ ...openDropdown, tensyoKana: open })
              }
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  setOpenDropdown({ ...openDropdown, tensyoKana: false });
                  refForm.current.atukai.focus();
                } else if (event.key === ' ') {
                  event.preventDefault();
                  setOpenDropdown({ ...openDropdown, tensyoKana: true });
                }
                focusNextEle(event);
              }}
              placeholder={'選択してください'}
              ref={el => (refForm.current.tensyoKana = el)}
              disabled={disableInput}
              style={{ width: 200 }}
              // showAction={['focus']}
              options={dataTensyoh?.data?.map((item: string) => {
                return {
                  value: item,
                  label: !item ? '------ 指定しない ------' : item,
                };
              })}
              onChange={() => {
                refForm.current.atukai.focus();
              }}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger.parentNode as HTMLElement
              }
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　６．取引先区分"
            name="atukai"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    if (!value) {
                      return Promise.reject(
                        new Error('取引先区分' + MSG_ERROR['MSG_SELECT']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              open={openDropdown.atukai}
              onDropdownVisibleChange={open =>
                setOpenDropdown({ ...openDropdown, atukai: open })
              }
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  setOpenDropdown({ ...openDropdown, atukai: false });
                  refForm.current.postcd1.focus();
                } else if (event.key === ' ') {
                  event.preventDefault();
                  setOpenDropdown({ ...openDropdown, atukai: true });
                }
                focusNextEle(event);
              }}
              placeholder={'選択してください'}
              ref={el => (refForm.current.atukai = el)}
              disabled={disableInput}
              style={{ width: 200 }}
              // showAction={['focus']}
              options={dataKigyoType?.data?.map(
                (kigyotype: iKigyoType, index: number) => {
                  return {
                    value: kigyotype.kigyotypeId,
                    label: `${index + 1}. ${kigyotype.kigyotypeNm}`,
                  };
                },
              )}
              onChange={(_value: any) => {
                refForm.current.postcd1.focus();
              }}
              getPopupContainer={(trigger: HTMLElement) =>
                trigger.parentNode as HTMLElement
              }
            />
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　７．郵便番号(半角数字3桁,4桁)"
          >
            <Space direction="horizontal" size={4}>
              <Form.Item
                name="postcd1"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        // 必須チェック
                        if (!value) {
                          return Promise.reject(
                            new Error('郵便番号' + MSG_ERROR['MSG_INPUT']),
                          );
                        }
                        // 郵便番号チェック
                        // 半角数字チェック
                        if (!isNumeric(value)) {
                          return Promise.reject(
                            new Error('郵便番号' + MSG_ERROR['MSG_NUMERIC']),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.postcd1 = el)}
                  disabled={disableInput}
                  maxLength={3}
                  style={{ width: 60 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      postcd1: ToASCII(event.target.value.trim(), false),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
              <Form.Item
                name="postcd2"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        // 必須チェック
                        if (!value) {
                          return Promise.reject(
                            new Error('郵便番号' + MSG_ERROR['MSG_INPUT']),
                          );
                        }
                        // 郵便番号チェック
                        // 半角数字チェック
                        if (!isNumeric(value)) {
                          return Promise.reject(
                            new Error('郵便番号' + MSG_ERROR['MSG_NUMERIC']),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.postcd2 = el)}
                  disabled={disableInput}
                  maxLength={4}
                  style={{ width: 80 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      postcd2: ToASCII(event.target.value.trim(), false),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　８．事務所(連絡先)住所(全角15桁)"
            style={{ alignItems: 'baseline' }}
          >
            <Space direction="vertical" size={8}>
              <Form.Item
                name="adrs1"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        // 必須チェック
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              '１番目の事務所(連絡先)住所' +
                                MSG_ERROR['MSG_INPUT'],
                            ),
                          );
                        }
                        // 全角チェック
                        if (!IsZenkaku(value)) {
                          return Promise.reject(
                            new Error(
                              '事務所(連絡先)住所' + MSG_ERROR['MSG_HANKAKU'],
                            ),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.adrs1 = el)}
                  disabled={disableInput}
                  maxLength={15}
                  style={{ width: 300 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      adrs1: Hankaku2Zenkaku(event.target.value.trimEnd()),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
              <Form.Item
                name="adrs2"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        // 全角チェック
                        if (!IsZenkaku(value)) {
                          return Promise.reject(
                            new Error(
                              '事務所(連絡先)住所' + MSG_ERROR['MSG_HANKAKU'],
                            ),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.adrs2 = el)}
                  disabled={disableInput}
                  maxLength={15}
                  style={{ width: 300 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      adrs2: Hankaku2Zenkaku(event.target.value.trimEnd()),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
              <Form.Item
                name="adrs3"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        // 全角チェック
                        if (!IsZenkaku(value)) {
                          return Promise.reject(
                            new Error(
                              '事務所(連絡先)住所' + MSG_ERROR['MSG_HANKAKU'],
                            ),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.adrs3 = el)}
                  disabled={disableInput}
                  maxLength={15}
                  style={{ width: 300 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      adrs3: Hankaku2Zenkaku(event.target.value.trimEnd()),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="　９．電話番号(半角15桁)"
          >
            <Space>
              <Form.Item
                name="tel"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        // 半角チェック
                        if (!IsHankaku(value)) {
                          return Promise.reject(
                            new Error('電話番号' + MSG_ERROR['MSG_ZENKAKU']),
                          );
                        }
                        // 禁則文字チェック
                        if (checkForSpecialChar(value)) {
                          return Promise.reject(
                            new Error(
                              '電話番号' + MSG_ERROR['MSG_INVALID_CHAR'],
                            ),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.tel = el)}
                  disabled={disableInput}
                  maxLength={15}
                  style={{ width: 300 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      tel: ToASCII(event.target.value.trim(), false),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
              <span>(例 xxx-xxxx-xxxx)</span>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１０．FAX番号(半角15桁)"
          >
            <Space>
              <Form.Item
                name="fax"
                noStyle
                rules={[
                  () => ({
                    validator(_: any, value: string) {
                      if (!disableInput) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        // 半角チェック
                        if (!IsHankaku(value)) {
                          return Promise.reject(
                            new Error('FAX番号' + MSG_ERROR['MSG_ZENKAKU']),
                          );
                        }
                        // 禁則文字チェック
                        if (checkForSpecialChar(value)) {
                          return Promise.reject(
                            new Error(
                              'FAX番号' + MSG_ERROR['MSG_INVALID_CHAR'],
                            ),
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  ref={el => (refForm.current.fax = el)}
                  disabled={disableInput}
                  maxLength={15}
                  style={{ width: 300 }}
                  onBlur={event => {
                    form.setFieldsValue({
                      fax: ToASCII(event.target.value.trim(), true),
                    });
                  }}
                  onKeyUp={event =>
                    (event.key === 'Enter' || event.key === 'Tab') &&
                    event.currentTarget.select()
                  }
                  onKeyDown={focusNextEle}
                ></Input>
              </Form.Item>
              <span>(例 xxx-xxxx-xxxx)</span>
            </Space>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１１．ｷｬﾌﾟﾃｨ取引先ｺｰﾄﾞ(半角英数字10桁)"
            name="captyTorihikiCd"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error(
                          'キャプティ取引先コード' + MSG_ERROR['MSG_INPUT'],
                        ),
                      );
                    }
                    // 半角英数字チェック
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error(
                          'キャプティ取引先コード' +
                            MSG_ERROR['MSG_HANKAKU_EISU'],
                        ),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.captyTorihikiCd = el)}
              disabled={disableInput}
              maxLength={10}
              style={{ width: 200 }}
              onBlur={event => {
                form.setFieldsValue({
                  captyTorihikiCd: ToASCII(event.target.value.trim(), true),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            ></Input>
          </Form.Item>
          <Form.Item
            className={disableInput ? 'disable-input' : ''}
            label="１２．ユーザーID(半角英数字10桁)"
            name="id"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!disableInput) {
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('ユーザーID' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 半角英数チェック
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error('ユーザーID' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              ref={el => (refForm.current.id = el)}
              disabled={disableInput}
              maxLength={10}
              style={{ width: 200 }}
              onBlur={event => {
                form.setFieldsValue({
                  id: ToASCII(event.target.value.trim(), false),
                });
              }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
            ></Input>
          </Form.Item>
          <Form.Item
            style={{ visibility: mode !== MODE_UPDATE ? 'hidden' : 'unset' }}
          >
            <Checkbox
              ref={el => (refForm.current.checkPwd = el)}
              style={{ opacity: mode !== MODE_UPDATE ? '0' : '1' }}
              disabled={mode !== MODE_UPDATE || disableInput}
              checked={enableInputPWD}
              onChange={e => {
                setEnableInputPWD(e.target.checked);
                form.setFieldsValue({ passwd: '', passwdConfirm: '' });
                if (e.target.checked) {
                  setTimeout(() => {
                    refForm.current.passwd.focus();
                  }, 300);
                }
              }}
              onKeyDown={focusNextEle}
            >
              パスワード変更
            </Checkbox>
          </Form.Item>
          <Form.Item
            className={disableInput || !enableInputPWD ? 'disable-input' : ''}
            label="１３．パスワード(半角英数字10桁)"
            name="passwd"
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!(disableInput || !enableInputPWD)) {
                    // 登録モード、または修正モードでパスワード変更にチェックした場合
                    // 必須チェック
                    if (!value) {
                      return Promise.reject(
                        new Error('パスワード' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    // 半角英数チェック
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error('パスワード' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              ref={el => (refForm.current.passwd = el)}
              disabled={disableInput || !enableInputPWD}
              maxLength={10}
              style={{ width: 200 }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
              iconRender={visible =>
                !visible ? (
                  <Button
                    type="text"
                    size="small"
                    onKeyDown={e => {
                      e.key === 'Enter' && (e.target as any).click();
                    }}
                  >
                    <EyeOutlined />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    size="small"
                    onKeyDown={e => {
                      e.key === 'Enter' && (e.target as any).click();
                    }}
                  >
                    <EyeInvisibleOutlined />
                  </Button>
                )
              }
            ></Input.Password>
          </Form.Item>
          <Form.Item
            className={disableInput || !enableInputPWD ? 'disable-input' : ''}
            label="１４．パスワード確認(半角英数字10桁)"
            name="passwdConfirm"
            rules={[
              ({ getFieldValue }) => ({
                validator(_: any, value: string) {
                  if (!(disableInput || !enableInputPWD)) {
                    if (!value) {
                      return Promise.reject(
                        new Error('パスワード' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    if (IsHankakuEisu(value)) {
                      return Promise.reject(
                        new Error('パスワード' + MSG_ERROR['MSG_HANKAKU_EISU']),
                      );
                    }
                    if (getFieldValue('passwd') !== value) {
                      return Promise.reject(
                        new Error('パスワード' + MSG_ERROR['MSG_NOT_MATCH']),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              ref={el => (refForm.current.passwdConfirm = el)}
              disabled={disableInput || !enableInputPWD}
              maxLength={10}
              style={{ width: 200 }}
              onKeyUp={event =>
                (event.key === 'Enter' || event.key === 'Tab') &&
                event.currentTarget.select()
              }
              onKeyDown={focusNextEle}
              iconRender={visible =>
                !visible ? (
                  <Button
                    type="text"
                    size="small"
                    onKeyDown={e => {
                      e.key === 'Enter' && (e.target as any).click();
                    }}
                  >
                    <EyeOutlined />
                  </Button>
                ) : (
                  <Button
                    type="text"
                    size="small"
                    onKeyDown={e => {
                      e.key === 'Enter' && (e.target as any).click();
                    }}
                  >
                    <EyeInvisibleOutlined />
                  </Button>
                )
              }
            ></Input.Password>
          </Form.Item>
        </BodyForm>
      </FormWrapper>
      <FooterForm>
        <Space>
          {(mode !== MODE_CREATE && !isSuccessGetCustomer) || (
            <Button
              disabled={
                loadingCreateCustomer ||
                loadingUpdateCustomer ||
                loadingDeleteCustomer
              }
              ref={el => (refForm.current.btnSubmit = el)}
              type="primary"
              htmlType="submit"
              form="torihiki-mainte"
              style={{ minWidth: 100 }}
            >
              確定
            </Button>
          )}
          <Button
            onClick={() => window.print()}
            type="default"
            style={{ minWidth: 100 }}
          >
            印刷
          </Button>
          <Button
            ref={el => (refForm.current.btnReset = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              if (valuesChange) {
                messagePopup({
                  type: 'warning',
                  content: MSG_ERROR['MSG_VALUES_CHANGE'],
                  onOk: () => {
                    resetForm();
                  },
                  onCancel: () => refForm?.current?.btnReset?.focus(),
                });
              } else {
                resetForm();
              }
            }}
          >
            画面クリア
          </Button>
          <Button
            ref={el => (refForm.current.btnClose = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              if (valuesChange) {
                messagePopup({
                  type: 'warning',
                  content: MSG_ERROR['MSG_VALUES_CHANGE'],
                  onOk: () => {
                    navigate(PATH.TOP);
                  },
                  onCancel: () => refForm?.current?.btnClose?.focus(),
                });
              } else {
                navigate(PATH.TOP);
              }
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FooterForm>
      <TorihikisakiSearch
        isModalVisible={isVisibleTorihikisaki}
        setIsModalVisible={setIsVisibleTorihikisaki}
        setTorihikiCd={(torihikiCd: any) => {
          if (torihikiCd === form.getFieldValue('torihikicd')) return;
          // previousTorihikiCd.current = torihikiCd;
          form.setFieldsValue({
            torihikicd: torihikiCd,
          });
          getInfoTorihiki(torihikiCd);
        }}
        focusAfterClose={() => {
          refForm?.current?.buttonTorihikicd?.focus();
        }}
      />
    </WrapperMainte>
  );
};

export default TorihikiMainte;
