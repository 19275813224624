import axios from 'axios';
import { API_URL } from 'constants/api';
import { SESSION_STORAGE_ITEM } from 'constants/common';
import moment from 'moment';
import AxiosGet from '../axiosGet';
import getLocalToken from '../token';

const AxiosGetFile = async (url: string, params = {}) => {
  //Edit get to post method
  //Refactoring Axios Get File
  try {
    const response = await axiosClient.post(
      url,
      {
        ...params,
      },
      {
        responseType: 'blob',
      },
    );
    return Promise.resolve(response);
  } catch (error) {
    // console.log({ error });
    return Promise.reject(error);
  }
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    Accept: 'application/json',
    Pragma: 'no-cache',
  },
});

axiosClient.interceptors.request.use((config: any) => {
  const token = getLocalToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use((response: any) => {
  // TODO: handle expired token
  const expiredTime = new Date(
    sessionStorage.getItem(SESSION_STORAGE_ITEM.EXPIREDAT) || '',
  );
  if (moment(expiredTime).diff(moment(), 'minutes') < 60) {
    AxiosGet(API_URL.REFETCH_TOKEN).then((res: any) => {
      sessionStorage.setItem(SESSION_STORAGE_ITEM.EXPIREDAT, res.expiredAt);
      sessionStorage.setItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN, res.token);
    });
  }
  return response;
});

export default AxiosGetFile;
