import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  tokuiCd: string | null;
  sendTo1: string | null;
  hanCd: string | null;
  fromDt: string | null;
  toDt: string | null;
  denKbn: string | null;
  orderNo: string | null;
  denNo: string | null;
  binCd: string | null;
  code24: string | null;
  nnm: string | null;
  kigyoGroups: string | null;
}

export function useGetShippingDetailsAggregation() {
  const getShipping = async ({
    tokuiCd,
    sendTo1,
    hanCd,
    fromDt,
    toDt,
    denKbn,
    orderNo,
    denNo,
    binCd,
    code24,
    nnm,
    kigyoGroups,
  }: TypeProps) => {
    const params = {
      tokuiCd,
      sendTo1,
      hanCd,
      fromDt,
      toDt,
      denKbn,
      orderNo,
      denNo,
      binCd,
      code24,
      nnm,
      kigyoGroups,
    };
    return await AxiosPost(API_URL.GET_SHIPPINGDETAILSAGGREGATE, params);
  };

  const mutation = useMutation(getShipping as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetShippingDetailsAggregationExcel() {
  const getShipping = async ({
    tokuiCd,
    sendTo1,
    hanCd,
    fromDt,
    toDt,
    denKbn,
    orderNo,
    denNo,
    binCd,
    code24,
    nnm,
    kigyoGroups,
  }: TypeProps) => {
    const params = {
      tokuiCd,
      sendTo1,
      hanCd,
      fromDt,
      toDt,
      denKbn,
      orderNo,
      denNo,
      binCd,
      code24,
      nnm,
      kigyoGroups,
    };
    return await AxiosGetFile(
      API_URL.GET_SHIPPINGDETAILSAGGREGATE_EXCEL,
      params,
    );
  };

  const mutation = useMutation(getShipping as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
