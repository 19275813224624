/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from 'antd';
import { useGetCustomer } from 'api/customer';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import ModalCustom from 'components/ModalCustom';
import {
  BodyContent,
  HeaderContent,
  InputField,
  Label,
} from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import {
  checkForSpecialChar,
  EditCanma,
  Hankaku2Zenkaku,
  UseFocus,
} from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import { useEffect, useState, useRef } from 'react';
import messagePopup from 'utils/message/mesage';
interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  setTorihikiCd: any;
  focusAfterClose?: any;
}

const TorihikisakiSearch = ({
  isModalVisible,
  setIsModalVisible,
  setTorihikiCd,
  focusAfterClose,
}: Props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<any>([]);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [refInput, setFocusInput] = UseFocus();
  const refButton = useRef<any>(null);

  const {
    mutate: getTorihikisaki,
    data: dataTorihikisaki,
    error: errorTorihikisaki,
    isLoading: loadingTorihikisaki,
    reset: resetTorihikisaki,
  } = useGetCustomer();

  useEffect(() => {
    if (dataTorihikisaki?.data) {
      setDataSource(dataTorihikisaki?.data?.data);
      dataTorihikisaki?.data?.data?.length === 0 &&
        messagePopup({
          type: 'info',
          content: MSG_ERROR['MSG_NO_DATA'],
          onOk: () => setFocusInput(),
        });

      setTimeout(() => {
        if (refButton.current) {
          refButton.current.focus();
        }
      }, 0);
    }
  }, [dataTorihikisaki]);

  useEffect(() => {
    if (errorTorihikisaki) {
      messagePopup({
        type: 'error',
        content: MSG_ERROR[(errorTorihikisaki as any)?.response?.data?.message],
        onOk: () => setFocusInput(),
      });
      setDataSource([]);
    }
  }, [errorTorihikisaki]);

  const handleGetTorihikisaki = (value: any) => {
    const { name } = value;
    if (!checkForSpecialChar(name)) {
      getTorihikisaki({ name });
    } else {
      messagePopup({
        type: 'info',
        content: '取引先名' + MSG_ERROR['MSG_INVALID_CHAR'],
        onOk: () => setFocusInput(),
      });
    }
  };

  const ClearData = () => {
    resetTorihikisaki();
    setDataSource([]);
    form.resetFields();
    !isSelected && focusAfterClose && focusAfterClose();
    setIsSelected(false);
  };

  const columns: any = [
    {
      title: () => <div style={{ minWidth: 80 }}></div>,
      dataIndex: 'torihikiCd',
      key: 'torihikiCd',
      align: 'center',
      render: (_value: any, _record: any) => (
        <div
          style={{
            minWidth: 80,
            padding: '2px 0px',
          }}
        >
          <Button
            size="small"
            onClick={event => {
              event.stopPropagation();
              setTorihikiCd(_value);
              setIsSelected(true);
              setIsModalVisible(false);
            }}
          >
            選択
          </Button>
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 150 }}>取引先コード </div>,
      dataIndex: 'torihikiCd',
      key: 'torihikiCd',
      render: (_value: any) => <div style={{ minWidth: 150 }}>{_value}</div>,
    },
    {
      title: () => <div style={{ minWidth: 400 }}>取引先名 </div>,
      dataIndex: 'kaisya',
      key: 'kaisya',
      render: (_value: any) => <div style={{ minWidth: 400 }}>{_value}</div>,
    },
    {
      title: () => <div style={{ minWidth: 150 }}>支店名 </div>,
      dataIndex: 'tensyo',
      key: 'tensyo',
      render: (_value: any) => <div style={{ minWidth: 150 }}>{_value}</div>,
    },
  ];

  return (
    <ModalCustom
      titleModal="取引先検索"
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={ClearData}
      widthModal="905px"
    >
      {loadingTorihikisaki && <LoadingCallAPI />}
      <HeaderContent>
        <Label>取引先名の「一部」を入力</Label>
        <Form
          initialValues={{ name: '' }}
          form={form}
          onFinish={handleGetTorihikisaki}
        >
          <Form.Item name="name" noStyle>
            <div style={{ display: 'flex' }}>
              <InputField
                autoFocus
                ref={refInput}
                maxLength={15}
                disabled={loadingTorihikisaki}
                placeholder="(全角･半角いづれも可)"
                onBlur={event => {
                  form.setFieldsValue({
                    name: Hankaku2Zenkaku(event.target.value),
                  });
                }}
                onPressEnter={(e: any) => {
                  e.preventDefault();
                  if (refButton.current) {
                    refButton.current.focus();
                  }
                }}
              />
              <Button
                ref={refButton}
                type="primary"
                htmlType="submit"
                style={{ minWidth: 100, marginRight: 5 }}
                disabled={loadingTorihikisaki}
              >
                検索
              </Button>
              <Button
                disabled={loadingTorihikisaki}
                onClick={() => ClearData()}
                type="default"
              >
                画面クリア
              </Button>
            </div>
          </Form.Item>
        </Form>
      </HeaderContent>
      <BodyContent>
        <TableCustom
          rowKey="torihikiCd"
          height={'347px'}
          columns={columns}
          dataSource={dataSource}
        />
        <div className="error_maxlength">
          {dataTorihikisaki &&
            (dataTorihikisaki?.data?.count > 1000
              ? `表示可能な最大件数を超えました。該当データは、${EditCanma(
                  dataTorihikisaki?.data?.count,
                )}件です。上位1,000件を表示します。`
              : `該当データは、${EditCanma(
                  dataTorihikisaki?.data?.count || 0,
                )}件です。`)}
        </div>
      </BodyContent>
    </ModalCustom>
  );
};

export default TorihikisakiSearch;
