import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

interface Order {
  orderNo: string | null;
  needCheck: boolean;
}
export function useGetOrderDetails() {
  const getOrderDetails = async ({ orderNo, needCheck }: Order) => {
    return await AxiosPost(
      `${API_URL.GET_ORDER_HISTORY}`,
      { orderNo, needCheck },
      {},
    );
  };

  const mutation = useMutation(getOrderDetails as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface Shipping {
  naibuNo: string | null;
  denNo: string | null;
  denKbn: string | null;
}
export function useGetShippingInfo() {
  const getShippingInfo = async ({ naibuNo, denNo, denKbn }: Shipping) => {
    let body = {
      naibuNo,
      denNo,
      denKbn,
    };
    return await AxiosPost(`${API_URL.GET_SHIPPING_INFO}`, body, {});
  };

  const mutation = useMutation(getShippingInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useUpdateShippingInfo() {
  const getShippingInfo = async (request: any) => {
    return await AxiosPut(`${API_URL.GET_SHIPPING_INFO}`, request, {});
  };

  const mutation = useMutation(getShippingInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useChangeDenKbnShippingInfo() {
  const changeDenKbnShippingInfo = async (request: any) => {
    return await AxiosPut(
      `${API_URL.CHANGE_DENKBN_SHIPPING_INFO}`,
      request,
      {},
    );
  };

  const mutation = useMutation(changeDenKbnShippingInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}

interface Shipping {
  naibuNo: string | null;
  denNo: string | null;
  denKbn: string | null;
  location: string | null;
}
export function useRePrintShipping() {
  const rePrintShipping = async ({
    naibuNo,
    denNo,
    denKbn,
    location,
  }: Shipping) => {
    let body = {
      naibuNo,
      denNo,
      denKbn,
      location,
    };
    return await AxiosPost(`${API_URL.REPRINT_SHIPPING}`, body, {});
  };

  const mutation = useMutation(rePrintShipping as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
