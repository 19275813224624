import { createSlice } from '@reduxjs/toolkit';
import { SESSION_STORAGE_ITEM } from 'constants/common';
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoggedIn: !!sessionStorage.getItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN),
    userInfo: JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_ITEM.USER_INFO) || '{}',
    ),
    expiredAt: sessionStorage.getItem(SESSION_STORAGE_ITEM.EXPIREDAT) || '',
    definedValues: {},
    serverTime: '',
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    loginSuccess(state, action) {
      const { tokenResponse, ...rest } = action.payload.data;
      sessionStorage.setItem(
        SESSION_STORAGE_ITEM.USER_INFO,
        JSON.stringify(rest),
      );
      sessionStorage.setItem(
        SESSION_STORAGE_ITEM.EXPIREDAT,
        tokenResponse.expiredAt,
      );
      sessionStorage.setItem(
        SESSION_STORAGE_ITEM.ACCESS_TOKEN,
        tokenResponse.token,
      );
      state.isLoggedIn = true;
      state.userInfo = rest;
    },
    autoLogin(state, action) {
      const { tokenResponse, ...rest } = action.payload;
      sessionStorage.setItem(
        SESSION_STORAGE_ITEM.USER_INFO,
        JSON.stringify(rest),
      );
      sessionStorage.setItem(
        SESSION_STORAGE_ITEM.EXPIREDAT,
        tokenResponse.expiredAt,
      );
      sessionStorage.setItem(
        SESSION_STORAGE_ITEM.ACCESS_TOKEN,
        tokenResponse.token,
      );
      state.isLoggedIn = true;
      state.userInfo = rest;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = {};
      sessionStorage.clear();
    },
    setDefinedValues(state, action) {
      state.definedValues = action.payload;
    },
    setServerTime(state, action) {
      state.serverTime = action.payload;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  setIsLoggedIn,
  loginSuccess,
  autoLogin,
  logout,
  setDefinedValues,
  setServerTime,
} = appSlice.actions;

export const selectIsLoggedIn = (state: any) => state.app.isLoggedIn;
export const selectUserInfo = (state: any) => state.app.userInfo;
export const selectDefinedValues = (state: any) => state.app.definedValues;
export const selectServerTime = (state: any) => state.app.serverTime;

export const appReducer = appSlice.reducer;
