import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosGetFile from './../utils/axios/getFile/axiosGetFile';

interface TypeProps {
  nohinDt: string | null;
  binCd: string | null;
  n2Atesaki1: string | null;
  bunrui: string | null;
  sendAdr: string | null;
}

export function useGetFlightDispatchList() {
  const getList = async ({
    nohinDt,
    binCd,
    n2Atesaki1,
    bunrui,
    sendAdr,
  }: TypeProps) => {
    const params = {
      nohinDt,
      binCd,
      n2Atesaki1,
      bunrui,
      sendAdr,
    };
    return await AxiosGetFile(API_URL.GET_FLIGHTDISPATCHLIST, params);
  };

  const mutation = useMutation(getList as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
