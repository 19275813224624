import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

export function useGetCheckOrderBacklogDetail() {
  const getCheck = async (request: any) => {
    return await AxiosPost(API_URL.GET_CHECKORDERBACKLOGDETAIL, request, {});
  };

  const mutation = useMutation(getCheck as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetCheckOrderBacklogDetailExcel() {
  const getCheck = async (request: any) => {
    return await AxiosGetFile(
      API_URL.GET_CHECKORDERBACKLOGDETAIL_EXCEL,
      request,
    );
  };

  const mutation = useMutation(getCheck as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetCheckOrderBacklogSum() {
  const getCheck = async () => {
    return await AxiosPost(API_URL.GET_CHECKORDERBACKLOGSUM, {});
  };

  const mutation = useMutation(getCheck as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetCheckOrderBacklogSumExcel() {
  const getCheck = async (body: any) => {
    return await AxiosGetFile(API_URL.GET_CHECKORDERBACKLOGSUM_EXCEL, body);
  };

  const mutation = useMutation(getCheck as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
