/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Form, Space } from 'antd';
import { iDefinedValues } from 'api/common';
import { useGetLstSyohin } from 'api/syohin';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import ModalCustom from 'components/ModalCustom';
import {
  BodyContent,
  HeaderContent,
  InputField,
  Label,
} from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import { editCanma } from 'constants/cmnDefine';
import { checkForSpecialChar, EditCanma, UseFocus } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDefinedValues } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { IconSearch } from './syohinSearch.style';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  lstSyohin?: any;
  setLstSyohin?: any;
  setCd24?: any;
  focusAfterClose?: any;
}

const SyohinSearch = ({
  isModalVisible,
  setIsModalVisible,
  lstSyohin,
  setLstSyohin,
  setCd24,
  focusAfterClose,
}: Props) => {
  const [form] = Form.useForm();
  const [refInput, setFocusInput] = UseFocus();
  const refButton = useRef<any>(null);
  const definedValues: iDefinedValues = useSelector(selectDefinedValues);
  const HAT_SIIRESAKICD = definedValues?.hat_SiiresakiCd;
  const [dataSource, setDataSource] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);

  const {
    mutate: getLstSyohin,
    data: dataLstSyohin,
    isLoading: loadingLstSyohin,
    reset: resetLstSyohin,
  } = useGetLstSyohin();

  useEffect(() => {
    setDataSource(dataLstSyohin?.data?.data?.slice(0, pageCurrent * 100));
  }, [pageCurrent]);

  const onFinish = (value: any) => {
    const { searchStr } = value;
    if (!checkForSpecialChar(searchStr)) {
      getLstSyohin(
        { searchStr },
        {
          onSuccess(data, variables, context) {
            setPageCurrent(1);
            setDataSource(data?.data?.data?.slice(0, 100));
            data?.data?.data.length === 0 &&
              messagePopup({
                type: 'info',
                content: MSG_ERROR['MSG_NO_DATA'],
                onOk: () => setFocusInput(),
              });
            setTimeout(() => {
              if (refButton.current) {
                refButton.current.focus();
              }
            }, 0);
          },
          onError(error, variables, context) {
            messagePopup({
              type: 'error',
              content: MSG_ERROR[(error as any)?.response?.data?.message],
              onOk: () => {
                setFocusInput();
              },
            });
          },
        },
      );
    } else {
      messagePopup({
        type: 'info',
        content: '品名' + MSG_ERROR['MSG_INVALID_CHAR'],
        onOk: () => setFocusInput(),
      });
    }
  };

  const columns: any = [
    {
      title: () => <div style={{ minWidth: 50, padding: 0 }}></div>,
      dataIndex: 'cd24',
      key: 'cd24',
      render: (_value: any, _record: any) => {
        // eslint-disable-next-line array-callback-return
        const indexReplace = lstSyohin?.filter((item: any) => {
          if (item.cd24 === '') return item;
        })?.[0]?.index;
        const dataSearch = {
          index: indexReplace,
          cd24: _record.cd24,
          isCopyRow: false,
          validateSuryo: false,
        };
        const replaceArray = lstSyohin?.map((u: any) =>
          u?.index !== dataSearch.index ? u : dataSearch,
        );
        const existsProduct = lstSyohin?.find(
          (item: any) => item.cd24 === _value,
        );
        return (
          <div style={{ minWidth: 50, padding: 2 }}>
            <Button
              size="small"
              style={{ minWidth: 48 }}
              onClick={event => {
                setIsModalVisible(false);
                if (lstSyohin && existsProduct) {
                  messagePopup({
                    type: 'error',
                    content: 'この品名は1行目ですでに入力されています',
                  });
                } else {
                  setLstSyohin && setLstSyohin(replaceArray);
                }
                setCd24 && setCd24(_value);
              }}
            >
              選択
            </Button>
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 100 }}>品名コード</div>,
      dataIndex: 'cd24',
      key: 'cd24',
      render: (_value: any, _record: any) => (
        <Space
          size={0}
          align="center"
          style={{ minWidth: 100 }}
          split={<Divider type="vertical" />}
        >
          <div style={{ minWidth: 65 }}>{_value}</div>
          <Button
            type="text"
            icon={
              <IconSearch
                tabIndex={5}
                url={
                  _record.siyo === '1'
                    ? 'svgIcon/Search-icon-color.svg'
                    : 'svgIcon/Search-icon-no-color.svg'
                }
              />
            }
            onClick={event => {
              event.stopPropagation();
              if (_record.siyo === '1') {
                let widthScreen = window.screen.width;
                let heightScreen = window.screen.height;
                window.open(
                  `SyohinInfo?syohinCd=${_record?.cd24}`,
                  '_blank',
                  `resizable=yes, width=${widthScreen}, height=${heightScreen}, top=${heightScreen}, left=${widthScreen}`,
                );
              } else {
                messagePopup({
                  type: 'info',
                  content: MSG_ERROR['MSG_NO_FILE_PDF'],
                });
              }
            }}
          />
        </Space>
      ),
    },
    {
      title: () => <div style={{ minWidth: 440 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any, _record: any) => {
        return <div style={{ minWidth: 440 }}>{_value}</div>;
      },
    },
    {
      title: () => <div style={{ minWidth: 60 }}>在庫数</div>,
      dataIndex: 'zaiko',
      key: 'zaiko',
      render: (_value: any, _record: any) => {
        let valueView = 0;
        if (_record.siireCd === HAT_SIIRESAKICD) {
          valueView = _record.zaikoHat;
        } else {
          valueView = _record.zaikoOther;
        }
        return (
          <div style={{ textAlign: 'right', minWidth: 60 }}>
            {editCanma(valueView)}
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 126 }}>単位</div>,
      dataIndex: 'tninm',
      key: 'tninm',
      render: (_value: any, _record: any) => {
        return (
          <div style={{ textAlign: 'center', minWidth: 126 }}>{_value}</div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 60 }}>単位重量</div>,
      dataIndex: 'jyuryo',
      key: 'jyuryo',
      render: (_value: any, _record: any) => {
        return (
          <div style={{ textAlign: 'right', minWidth: 60 }}>
            {editCanma(_value)}
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 60 }}>在庫区分</div>,
      dataIndex: 'zaikoKbn',
      key: 'zaikoKbn',
      render: (_value: any, _record: any) => {
        return (
          <div style={{ textAlign: 'center', minWidth: 60 }}>
            {editCanma(_value)}
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 60 }}>標準入数</div>,
      dataIndex: 'irisu',
      key: 'irisu',
      render: (_value: any, _record: any) => {
        return (
          <div style={{ textAlign: 'right', minWidth: 60 }}>
            {editCanma(Number(_value))}
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 76 }}>販売単価他</div>,
      dataIndex: 'bold',
      key: 'bold',
      render: (_value: any, _record: any) => {
        return (
          <div style={{ minWidth: 76 }}>
            {_record.st3 === 1 ? (
              <div style={{ textAlign: 'center' }}>手配書要</div>
            ) : (
              <div style={{ textAlign: 'right' }}>
                {editCanma(_value, true)}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const ClearData = () => {
    resetLstSyohin();
    form.resetFields();
    setDataSource([]);
  };

  useEffect(() => {
    const node = document.querySelector<HTMLElement>(
      '.syohin-search .ant-table-body',
    );
    if (node) {
      node.addEventListener('scroll', () => {
        const perc =
          (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;
        if (perc === 100) {
          const a = Math.round(dataLstSyohin?.data?.data?.length / 100);
          if (a >= pageCurrent) {
            setPageCurrent(pageCurrent + 1);
            return;
          }
        }
      });
    }
  });

  return (
    <ModalCustom
      titleModal="商品検索"
      size="large"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        ClearData();
        focusAfterClose();
      }}
      widthModal="1140px"
    >
      {loadingLstSyohin && <LoadingCallAPI />}
      <HeaderContent>
        <Label>品名の「一部」を入力</Label>
        <Form initialValues={{ searchStr: '' }} form={form} onFinish={onFinish}>
          <div style={{ display: 'flex' }}>
            <Form.Item name="searchStr" noStyle>
              <InputField
                autoFocus
                ref={refInput}
                maxLength={30}
                disabled={loadingLstSyohin}
                placeholder="(全角･半角いづれも可)"
                onPressEnter={(e: any) => {
                  e.preventDefault();
                  if (refButton.current) {
                    refButton.current.focus();
                  }
                }}
              />
            </Form.Item>
            <Button
              ref={refButton}
              type="primary"
              htmlType="submit"
              style={{ minWidth: 100, marginRight: 5 }}
              disabled={loadingLstSyohin}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  form.submit();
                }
              }}
            >
              検索
            </Button>
            <Button
              disabled={loadingLstSyohin}
              onClick={() => ClearData()}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  ClearData();
                }
              }}
              type="default"
            >
              画面クリア
            </Button>
          </div>
        </Form>
      </HeaderContent>
      <BodyContent>
        <TableCustom
          className="syohin-search"
          rowKey="cd24"
          height={'347px'}
          columns={columns}
          dataSource={dataSource}
        />
        <div className="error_maxlength">
          {dataLstSyohin &&
            (dataLstSyohin?.data?.count > 1000
              ? `表示可能な最大件数を超えました。該当データは、${EditCanma(
                  dataLstSyohin?.data?.count,
                )}件です。上位1,000件を表示します。`
              : `該当データは、${EditCanma(
                  dataLstSyohin?.data?.count || 0,
                )}件です。`)}
        </div>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(SyohinSearch);
