import { Button, Checkbox, Form, Input, Select, Space } from 'antd';
import { useGetStockReceive } from 'api/stockDetails';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import PickerCustom from 'components/RangePickerCustom/PickerCustom';
import TableCustom from 'components/TableCommon';
import { PATH } from 'configs/routes';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  FooterForm,
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import InputRefill from './InputRefill';
import StockRefillReceiveConfirm from './stockRefillReceiveConfirm';

const InputStockRefillReceiveUpdate = ({
  dataSiirem,
  setValuesChange,
}: any) => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const formRef = useRef<any>({});
  const inputRef = useRef<any>([]);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState<boolean>(false);
  const [record, setRecord] = useState<any>({});
  const [listMeisai, setListMeisai] = useState<any>([]);

  const [form] = Form.useForm();

  const columns: any = (
    lstMeisai: any,
    setLstMeisai: any,
    setValuesChange: any,
    inputRef: any,
    formRef: any,
  ) => [
    {
      title: () => <div style={{ minWidth: 40 }}>№</div>,
      dataIndex: 'index',
      key: 'index',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 40, padding: '0 2px' }}>
          {_value}
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>発注日</div>,
      dataIndex: 'hachubi',
      key: 'hachubi',
      render: (_value: any) => (
        <div style={{ textAlign: 'left', minWidth: 60 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 80 }}>発注№</div>,
      dataIndex: 'chuNo2',
      key: 'chuNo2',
      render: (_value: any) => (
        <div style={{ textAlign: 'center', minWidth: 80 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>入荷保留</div>,
      dataIndex: 'del',
      key: 'del',
      render: (_value: any, record: any) => {
        return (
          <div
            style={{ minWidth: 70, display: 'flex', justifyContent: 'center' }}
          >
            <Checkbox
              checked={_value}
              onChange={(event: any) => {
                setValuesChange(true);
                let updateArray: any;
                if (event.target.checked) {
                  let updateSyohin = {
                    ...record,
                    del: true,
                    suryo: 0,
                  };
                  updateArray = lstMeisai?.map((u: any) =>
                    u?.index !== updateSyohin.index ? u : updateSyohin,
                  );
                } else {
                  let updateSyohin = {
                    ...record,
                    del: false,
                    suryo: record.previousSuryo,
                  };
                  updateArray = lstMeisai?.map((u: any) =>
                    u?.index !== updateSyohin.index ? u : updateSyohin,
                  );
                }
                setLstMeisai(updateArray);
              }}
            />
          </div>
        );
      },
    },
    {
      title: () => <div style={{ minWidth: 60 }}>品名CD</div>,
      dataIndex: 'code24',
      key: 'code24',
      render: (_value: any) => (
        <div style={{ minWidth: 60, textAlign: 'center' }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 350 }}>品名</div>,
      dataIndex: 'nnm',
      key: 'nnm',
      render: (_value: any) => <div style={{ minWidth: 350 }}>{_value}</div>,
    },
    {
      title: () => <div style={{ minWidth: 60 }}>発注数</div>,
      dataIndex: 'hSuu',
      key: 'hSuu',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 60 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>入荷数</div>,
      dataIndex: 'suryo',
      key: 'suryo',
      render: (_value: any, record: any) => (
        <div
          style={{ minWidth: 70, display: 'flex', justifyContent: 'center' }}
        >
          <InputRefill
            dfValue={_value}
            lstMeisai={lstMeisai}
            setLstMeisai={setLstMeisai}
            record={record}
            setValuesChange={setValuesChange}
            inputRef={inputRef}
            formRef={formRef}
          />
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}>発注残</div>,
      dataIndex: 'hachuzan',
      key: 'hachuzan',
      render: (_value: any) => (
        <div style={{ textAlign: 'right', minWidth: 60 }}>{_value}</div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 70 }}>ﾛｹｰｼｮﾝ</div>,
      dataIndex: 'location',
      key: 'location',
      render: (_value: any) => (
        <div style={{ textAlign: 'center', minWidth: 70 }}>{_value}</div>
      ),
    },
  ];

  /**
   * *API GetStockReceive
   * todo Get GetStockReceive to update
   * @param siirecd
   */
  const {
    mutate: mutationGetStockReceive,
    isLoading: loadingGetStockReceive,
    data: dataGetStockReceive,
    reset: resetGetStockReceive,
  } = useGetStockReceive();

  function inititalValues() {
    resetGetStockReceive();
    mutationGetStockReceive(
      { NyukaDt: moment().format('YYMMDD') },
      {
        onSuccess(data, variables, context) {
          const {
            header: { siirenm, siirecd },
            meisai,
          } = data?.data;
          form.setFieldsValue({
            siirenm: siirenm,
            siirecd: siirecd,
            nyukaDt: moment(),
            nyukaDtFull: moment().format('YYYY/MM/DD (ddd)'),
          });
          setListMeisai(
            meisai?.map((item: any, index: any) => {
              return {
                ...item,
                index: index + 1,
                del: false,
                suryo: item.hachuzan,
                previousSuryo: item.hachuzan,
              };
            }),
          );
          formRef.current.nyukaDt.focus();
        },
      },
    );
  }

  useEffect(() => {
    inititalValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    setRecord({ header: form.getFieldsValue(true), meisai: listMeisai });
    if (checkLstMeisai(listMeisai)) {
      setIsVisibleConfirm(true);
    }
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        if (errorFields[0]?.name[0] === 'nyukaDtFull') {
          formRef?.current['nyukaDt']?.focus();
        } else formRef?.current[errorFields[0]?.name[0]]?.focus();
      },
    });
  };

  // Display msg error validate lstMeisai
  function checkLstMeisai(lstMeisai: any) {
    const errorBara = lstMeisai.find(
      (syohin: any) => syohin.errorBara === true && !syohin.del,
    );

    if (!!errorBara) {
      messagePopup({
        type: 'info',
        content: errorBara.msgErrorBara,
        onOk: () => errorBara?.focusBara(),
      });
      return false;
    }
    return true;
  }

  const clearScreen = () => {
    inititalValues();
    setTimeout(() => {
      formRef?.current?.nyukaDt?.focus();
    }, 300);
  };

  return (
    <WrapperMainte>
      {loadingGetStockReceive && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="create-stockrefillreceive"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        onFinish={onFinish}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        initialValues={{
          siirecd: null,
          siirenm: '',
          nyukaDt: moment(),
          nyukaDtFull: moment().format('YYYY/MM/DD (ddd)'),
        }}
        style={{ marginBottom: 20 }}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>

        <Form.Item
          label={
            <label>
              仕入先
              <label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
          name="siirecd"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (!value) {
                  return Promise.reject(
                    new Error('仕入先' + MSG_ERROR['MSG_INPUT']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            disabled
            placeholder={'選択してください'}
            ref={el => (formRef.current.siirecd = el)}
            style={{
              width: 255,
            }}
            getPopupContainer={(trigger: HTMLElement) =>
              trigger.parentNode as HTMLElement
            }
          >
            {dataSiirem?.data?.map((item: any) => {
              return (
                <Select.Option value={item.siirecd} key={item.siirenm}>
                  {`${item.siirecd} ${item.siirenm}`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <label>
              入荷日
              <label style={{ color: 'red' }}>【必須】</label>
            </label>
          }
        >
          <Space size={4}>
            <PickerCustom
              title={'入荷日'}
              nextFocus={() => {
                inputRef.current[1].focus();
                inputRef.current[1].select();
              }}
              form={form}
              refForm={formRef}
              autoFocus={false}
              formName="nyukaDt"
              hideDD={true}
              afterChangeValue={(value: any) => {
                setValuesChange(true);
                form.setFieldsValue({
                  nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
                mutationGetStockReceive(
                  { NyukaDt: moment(value).format('YYMMDD') },
                  {
                    onSuccess(data, variables, context) {
                      const {
                        header: { siirenm, siirecd },
                        meisai,
                      } = data?.data;
                      form.setFieldsValue({
                        siirenm: siirenm,
                        siirecd: siirecd,
                      });
                      setListMeisai(
                        meisai?.map((item: any, index: any) => {
                          return {
                            ...item,
                            index: index + 1,
                            del: false,
                            suryo: item.hachuzan,
                            previousSuryo: item.hachuzan,
                          };
                        }),
                      );
                      setTimeout(() => {
                        inputRef.current[1].select();
                        inputRef.current[1].focus();
                      }, 0);
                    },
                  },
                );
              }}
              validateRules={(value: any) => {
                return null;
              }}
            />
            {/* <Form.Item noStyle name="nyukaDt">
              <DatePickerCommon
                itemName={'入荷日'}
                placeholder={DATE_FORMAT}
                allowClear={false}
                refForm={formRef}
                formName="nyukaDt"
                nextFocus={() => {
                  inputRef.current[1].focus();
                  inputRef.current[1].select();
                }}
                onChange={(value: any) => {
                  setValuesChange(true);
                  form.setFieldsValue({
                    nyukaDtFull: moment(value).format('YYYY/MM/DD (ddd)'),
                  });
                }}
                style={{ width: 100, padding: '2px 10px' }}
                format={DATE_FORMAT}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              />
            </Form.Item> */}
            <Form.Item
              noStyle
              name="nyukaDtFull"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (!value) {
                      return Promise.reject(
                        new Error('入荷日' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.nyukaDtFull = el)}
                disabled
                style={{ width: 150 }}
              ></Input>
            </Form.Item>
          </Space>
        </Form.Item>
      </FormWrapper>
      <TableCustom
        rowKey="index"
        height={'290px'}
        columns={columns(
          listMeisai,
          setListMeisai,
          setValuesChange,
          inputRef,
          formRef,
        )}
        dataSource={listMeisai}
      />
      <FooterForm style={{ justifyContent: 'space-between' }}>
        <Space>
          <Button
            ref={el => (formRef.current.btnCheckAll = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              setValuesChange(true);
              let updateArray: any;
              updateArray = listMeisai?.map((u: any) => ({
                ...u,
                del: true,
                suryo: 0,
              }));
              setListMeisai(updateArray);
            }}
          >
            全チェック
          </Button>
          <Button
            ref={el => (formRef.current.btnUnCheckAll = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              let updateArray: any;
              updateArray = listMeisai?.map((u: any) => ({
                ...u,
                del: false,
                suryo: u.previousSuryo,
              }));
              setListMeisai(updateArray);
            }}
          >
            全チェック解除
          </Button>
        </Space>
        <Space>
          <Button
            ref={el => (formRef.current.btnSubmit = el)}
            type="primary"
            htmlType="submit"
            disabled={
              !dataGetStockReceive ||
              dataGetStockReceive?.data?.meisai?.length === 0
            }
            form="create-stockrefillreceive"
            style={{ minWidth: 100 }}
          >
            確定
          </Button>
          <Button
            ref={el => (formRef.current.btnReset = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              messagePopup({
                type: 'question',
                content: '画面をクリアします。よろしいですか?',
                onOk: () => {
                  clearScreen();
                  setValuesChange(false);
                },
                onCancel: () => formRef?.current?.btnReset?.focus(),
              });
            }}
          >
            画面クリア
          </Button>
          <Button
            ref={el => (formRef.current.btnClose = el)}
            type="default"
            style={{ minWidth: 100 }}
            onClick={() => {
              messagePopup({
                type: 'question',
                content: '終了します。よろしいですか?',
                onOk: () => {
                  navigate(PATH.TOP);
                  setValuesChange(false);
                },
                onCancel: () => formRef?.current?.btnClose?.focus(),
              });
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </FooterForm>
      <StockRefillReceiveConfirm
        isModalVisible={isVisibleConfirm}
        setIsModalVisible={setIsVisibleConfirm}
        record={record}
        onResetPage={clearScreen}
        afterClose={() => formRef?.current?.btnSubmit?.focus()}
      />
    </WrapperMainte>
  );
};

export default InputStockRefillReceiveUpdate;
