export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>便CD</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>協力企業名</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>配送住所</div>,
    dataIndex: 'sendAdr',
    key: 'sendAdr',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>商品分類</div>,
    dataIndex: 'bunrui',
    key: 'bunrui',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>品名</div>,
    dataIndex: 'hinmei',
    key: 'hinmei',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>数量</div>,
    dataIndex: 'bara',
    key: 'bara',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>単重量</div>,
    dataIndex: 'jyuryo',
    key: 'jyuryo',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>合計重量</div>,
    dataIndex: 'jyuryoGoukei',
    key: 'jyuryoGoukei',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>伝票番号</div>,
    dataIndex: 'denNo',
    key: 'denNo',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>内部番号</div>,
    dataIndex: 'naibuNo',
    key: 'naibuNo',
    width: 100,
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  // {
  //   title: () => <div style={{ minWidth: 90 }}>ロケ頭１桁</div>,
  //   dataIndex: 'locCap',
  //   key: 'locCap',
  //   render: (_value: any) => (
  //     <div style={{ minWidth: 90, width: 'max-content' }}>{_value}</div>
  //   ),
  // },
  {
    title: () => <div style={{ minWidth: 50 }}>備考</div>,
    dataIndex: 'tokutei',
    key: 'tokutei',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
];
