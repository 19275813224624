import { Button, Form, Input, Radio, Space } from 'antd';
import { useGetBacklogInfo } from 'api/stockDetails';
import { useGetSyohinMainte } from 'api/syohin';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import PickerCustom from 'components/RangePickerCustom/PickerCustom';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  IsHankaku,
  IsHankakuEisu,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  FooterForm,
  FormWrapper,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import ConfirmModal from './confirmModal';

const DELETE_BY_CODE = '指定削除';
const DELETE_BY_DATE = '日付で削除';

const BacklogDeletion = () => {
  const [form] = Form.useForm();
  const userInfo = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const formRef = useRef<any>({});
  const previousTrokno = useRef<any>();
  const previousSyohinCd = useRef<any>();
  const [mode, setMode] = useState<string>(DELETE_BY_CODE);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  /**
   * *API GET_SYOHIN_BYCD24
   * todo Get Syohin info
   * @param cd24
   */
  const { mutate: getSyohinInfo, isLoading: loadingGetSyohin } =
    useGetSyohinMainte();

  const {
    mutate: getBacklogInfo,
    data: dataBacklog,
    isLoading: loadingBacklogInfo,
  } = useGetBacklogInfo();

  const onFinish = (values: any) => {
    const { trokno, cd24, hatchubi } = values;
    getBacklogInfo(
      {
        trokno,
        cd24,
        hatchubi: hatchubi ? moment(hatchubi).format('YYMMDD') : null,
      },
      {
        onSuccess({ data }, variables, context) {
          if (!data.length) {
            messagePopup({
              type: 'info',
              content: MSG_ERROR['MSG_NO_DATA'],
              onOk: () => {
                if (mode === DELETE_BY_CODE) {
                  formRef.current.trokno.select();
                } else if (mode === DELETE_BY_DATE) {
                  formRef.current.hatchubi.focus();
                }
              },
            });
            return;
          }
          setOpenConfirmModal(true);
        },
        onError(error, variables, context) {
          messagePopup({
            type: 'error',
            content:
              MSG_ERROR[(error as any)?.response?.data?.message] ||
              error?.response?.data?.message,
          });
        },
      },
    );
  };

  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        errorFields[0]?.name[0] === 'syohinNm'
          ? formRef?.current?.cd24?.select()
          : formRef?.current[errorFields[0]?.name[0]]?.focus();
      },
    });
  };

  useEffect(() => {
    if (mode === DELETE_BY_CODE) {
      form.setFieldsValue({
        hatchubi: null,
        hatchubiFull: null,
      });
      formRef.current.trokno.focus();
    } else if (mode === DELETE_BY_DATE) {
      form.setFieldsValue({
        trokno: null,
        cd24: null,
        syohinNm: null,
      });
      formRef.current.hatchubi.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const clearScreen = () => {
    form.resetFields();
    if (mode === DELETE_BY_CODE) {
      setTimeout(() => {
        formRef.current.trokno.focus();
      }, 0);
    } else if (mode === DELETE_BY_DATE) {
      setTimeout(() => {
        formRef.current.hatchubi.focus();
      }, 0);
    }
    setOpenConfirmModal(false);
  };

  return (
    <WrapperMainte>
      {(loadingGetSyohin || loadingBacklogInfo) && <LoadingCallAPI />}
      <FormWrapper
        form={form}
        className="form-mainte"
        name="backlog-deletion"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        scrollToFirstError
        initialValues={{
          mode: mode,
          hatchubi: null,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <HeaderForm>
          <span>{labelTokuisaki}</span>
        </HeaderForm>
        <Form.Item label={'処理区分'} name="mode">
          <Radio.Group
            onChange={e => {
              setMode(e.target.value);
            }}
          >
            <Radio value={DELETE_BY_CODE} key={DELETE_BY_CODE}>
              {DELETE_BY_CODE}
            </Radio>
            <Radio value={DELETE_BY_DATE} key={DELETE_BY_DATE}>
              {DELETE_BY_DATE}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            <label>
              登録番号
              <label style={{ color: 'red' }} hidden={mode !== DELETE_BY_CODE}>
                【必須】
              </label>
            </label>
          }
          className={mode !== DELETE_BY_CODE ? 'disable-input' : ''}
          name="trokno"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (mode !== DELETE_BY_CODE) {
                  return Promise.resolve();
                }
                if (!value) {
                  return Promise.reject(
                    new Error('登録番号' + MSG_ERROR['MSG_INPUT']),
                  );
                }
                if (IsHankakuEisu(value)) {
                  return Promise.reject(
                    new Error('登録番号' + MSG_ERROR['MSG_HANKAKU_EISU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            ref={el => (formRef.current.trokno = el)}
            maxLength={6}
            style={{ width: 120 }}
            disabled={mode !== DELETE_BY_CODE}
            onBlur={(event: any) => {
              let value = ToASCII(event.target.value, true, true).trim();
              form.setFieldsValue({
                trokno: value,
              });
              if (previousTrokno.current !== value) {
                form
                  .validateFields(['trokno'])
                  .then(() => {
                    formRef?.current.cd24.select();
                  })
                  .catch(({ values, errorFields }) =>
                    messagePopup({
                      type: 'info',
                      content: errorFields[0]?.errors[0],
                      onOk: () => {
                        formRef?.current.trokno.select();
                      },
                    }),
                  );
              }
            }}
            onFocus={(event: any) => {
              previousTrokno.current = event.target.value;
            }}
            onPressEnter={(event: any) => {
              event.preventDefault();
              if (previousTrokno.current === event.target.value) {
                formRef?.current.cd24.select();
              } else {
                event.target.blur();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={'品名コード'}
          className={mode !== DELETE_BY_CODE ? 'disable-input' : ''}
        >
          <Space size={4}>
            <Form.Item
              name="cd24"
              noStyle
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (mode !== DELETE_BY_CODE) {
                      return Promise.resolve();
                    }
                    if (!IsHankaku(value)) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_ZENKAKU']),
                      );
                    }
                    if (checkForSpecialChar(value)) {
                      return Promise.reject(
                        new Error('品名コード' + MSG_ERROR['MSG_INVALID_CHAR']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                ref={el => (formRef.current.cd24 = el)}
                disabled={mode !== DELETE_BY_CODE}
                maxLength={11}
                style={{ width: 120 }}
                onBlur={(event: any) => {
                  let value = ToASCII(event.target.value, true, true).trim();
                  form.setFieldsValue({
                    cd24: value,
                  });
                  if (previousSyohinCd.current !== value) {
                    form
                      .validateFields(['cd24'])
                      .then(res => {
                        const _value = res.cd24;
                        if (!value) {
                          form.setFieldsValue({
                            syohinNm: null,
                          });
                          formRef.current.btnSubmit.focus();
                          return;
                        }
                        getSyohinInfo(_value, {
                          onSuccess({ data }, variables, context) {
                            form.setFieldsValue({
                              syohinNm: `${data.nnm || ''}${data.nnmsz || ''}`,
                            });
                            formRef.current.btnSubmit.focus();
                          },
                          onError(error, variables, context) {
                            form.setFieldsValue({
                              syohinNm: null,
                            });
                            messagePopup({
                              type: 'info',
                              content:
                                MSG_ERROR[
                                  (error as any)?.response?.data?.message
                                ] || error?.response?.data?.message,
                              onOk: () => {
                                formRef.current?.cd24?.select();
                              },
                            });
                          },
                        });
                      })
                      .catch(({ values, errorFields }) => {
                        form.setFieldsValue({
                          syohinNm: null,
                        });
                        messagePopup({
                          type: 'info',
                          content: errorFields[0]?.errors[0],
                          onOk: () => {
                            formRef?.current.cd24.select();
                          },
                        });
                      });
                  }
                }}
                onFocus={(event: any) => {
                  previousSyohinCd.current = event.target.value;
                }}
                onPressEnter={(event: any) => {
                  event.preventDefault();
                  if (previousSyohinCd.current === event.target.value) {
                    formRef?.current.btnSubmit.focus();
                  } else {
                    event.target.blur();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="syohinNm"
              noStyle
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (mode !== DELETE_BY_CODE) {
                      return Promise.resolve();
                    }
                    if (
                      !!form.getFieldValue('cd24')?.length &&
                      !value?.length
                    ) {
                      return Promise.reject(
                        new Error(MSG_ERROR['MSG_SYOHIN_NOT_EXIST']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled style={{ width: 300 }} />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label={
            <label>
              発注日
              <label style={{ color: 'red' }} hidden={mode !== DELETE_BY_DATE}>
                【必須】
              </label>
            </label>
          }
          className={mode !== DELETE_BY_DATE ? 'disable-input' : ''}
        >
          <Space size={4}>
            <PickerCustom
              title={'発注日'}
              disabled={mode !== DELETE_BY_DATE}
              nextFocus={() => {
                formRef.current.btnSubmit.focus();
              }}
              form={form}
              refForm={formRef}
              autoFocus={false}
              formName="hatchubi"
              hideDD={true}
              afterChangeValue={(value: any) => {
                form.setFieldsValue({
                  hatchubiFull: moment(value).format('YYYY/MM/DD (ddd)'),
                });
                setTimeout(() => {
                  formRef.current.btnSubmit.focus();
                }, 0);
              }}
              validateRules={(value: any) => {
                if (!value) {
                  return '発注日' + MSG_ERROR['MSG_INPUT'];
                }
                return null;
              }}
            />
            {/* <Form.Item
              noStyle
              name="hatchubi"
              rules={[
                () => ({
                  validator(_: any, value: string) {
                    if (mode !== DELETE_BY_DATE) {
                      return Promise.resolve();
                    }
                    if (!value) {
                      return Promise.reject(
                        new Error('発注日' + MSG_ERROR['MSG_INPUT']),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePickerCommon
                disabled={mode !== DELETE_BY_DATE}
                allowClear={false}
                itemName={'発注日'}
                refForm={formRef}
                formName="hatchubi"
                nextFocus={() => formRef.current.btnSubmit.focus()}
                onChange={(value: any) => {
                  form.setFieldsValue({
                    hatchubiFull: moment(value).format('YYYY/MM/DD (ddd)'),
                  });
                  setTimeout(() => {
                    formRef.current.btnSubmit.focus();
                  }, 0);
                }}
                style={{ width: 120, padding: '2px 10px' }}
                format={'YYMMDD'}
                getPopupContainer={(trigger: HTMLElement) =>
                  trigger.parentNode as HTMLElement
                }
              />
            </Form.Item> */}
            <Form.Item noStyle name="hatchubiFull">
              <Input
                ref={el => (formRef.current.hatchubiFull = el)}
                disabled
                style={{ width: 150 }}
              ></Input>
            </Form.Item>
            <div>{'以前を全て削除'}</div>
          </Space>
        </Form.Item>
        <div style={{ marginTop: 20, fontWeight: 'bold', fontSize: 16 }}>
          <div>{'日付で削除を選択すると無条件で発注データを削除します。'}</div>
          <div>
            {'間違えて日付を入力すると全部消えますので注意してください。'}
          </div>
        </div>
        <FooterForm>
          <Space>
            <Button
              ref={el => (formRef.current.btnSubmit = el)}
              type="primary"
              htmlType="submit"
              form="backlog-deletion"
              style={{ minWidth: 100 }}
            >
              確認
            </Button>
            <Button
              ref={el => (formRef.current.btnReset = el)}
              type="default"
              style={{ minWidth: 100 }}
              onClick={event => {
                messagePopup({
                  type: 'question',
                  content: '画面をクリアします。よろしいですか?',
                  onOk: () => clearScreen(),
                  onCancel: () => {
                    (
                      (event.target as HTMLElement)
                        .offsetParent as HTMLButtonElement
                    ).focus();
                  },
                });
              }}
            >
              画面クリア
            </Button>
            <Button
              ref={el => (formRef.current.btnClose = el)}
              type="default"
              style={{ minWidth: 100 }}
              onClick={event => {
                messagePopup({
                  type: 'question',
                  content: '終了します。よろしいですか?',
                  onOk: () => navigate(PATH.TOP),
                  onCancel: () => {
                    (
                      (event.target as HTMLElement)
                        .offsetParent as HTMLButtonElement
                    ).focus();
                  },
                });
              }}
            >
              メニューへ戻る
            </Button>
          </Space>
        </FooterForm>
      </FormWrapper>
      <ConfirmModal
        isModalVisible={openConfirmModal}
        setIsModalVisible={setOpenConfirmModal}
        data={{
          trokno: form.getFieldValue('trokno'),
          cd24: form.getFieldValue('cd24'),
          syohinNm: form.getFieldValue('syohinNm'),
          hatchubi: form.getFieldValue('hatchubi'),
          syohinList: dataBacklog?.data?.map((item: any, index: number) => {
            return { ...item, index };
          }),
        }}
        onResetPage={clearScreen}
      />
    </WrapperMainte>
  );
};

export default BacklogDeletion;
