export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>受付キー</div>,
    dataIndex: 'orderKey',
    key: 'orderKey',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>受付番号</div>,
    dataIndex: 'orderNo',
    key: 'orderNo',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>受付行番</div>,
    dataIndex: 'orderLine',
    key: 'orderLine',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>伝区</div>,
    dataIndex: 'denKbn',
    key: 'denKbn',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>伝票番号</div>,
    dataIndex: 'denNo',
    key: 'denNo',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 20 }}>行</div>,
    dataIndex: 'denhyouLine',
    key: 'denhyouLine',
    render: (_value: any) => (
      <div style={{ minWidth: 20, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>便CD</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 70 }}>協力店CD</div>,
    dataIndex: 'tokuiCd',
    key: 'tokuiCd',
    render: (_value: any) => (
      <div style={{ minWidth: 70, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>班CD</div>,
    dataIndex: 'hanCd',
    key: 'hanCd',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>住所１</div>,
    dataIndex: 'sendAdr1',
    key: 'sendAdr1',
    width: 100,
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>住所２</div>,
    dataIndex: 'sendAdr2',
    key: 'sendAdr12',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>送先名</div>,
    dataIndex: 'sendTo1',
    key: 'sendTo1',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>会社名</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>分類CD</div>,
    dataIndex: 'code5',
    key: 'code5',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>品名</div>,
    dataIndex: 'nnm',
    key: 'nnm',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>規格</div>,
    dataIndex: 'nnmsz',
    key: 'nnmsz',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>数量</div>,
    dataIndex: 'bara',
    key: 'bara',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>単重量</div>,
    dataIndex: 'jyuryo',
    key: 'jyuryo',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ﾛｹｰｼｮﾝ</div>,
    dataIndex: 'location',
    key: 'location',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
];
