import { MenuOutlined } from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import { useGetKigyoType } from 'api/customer';
import {
  useDeleteNotification,
  useGetNotificationInfo,
  useOrderNotification,
} from 'api/notification';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { PATH } from 'configs/routes';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SortableHandle } from 'react-sortable-hoc';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import {
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';
import ModalOsirase from './modalOsirase';
import TableOsirase from './tableOsirase';

const MODE_CREATE = 'CREATE';
const MODE_DUPLICATE = 'DUPLICATE';
const MODE_UPDATE = 'UPDATE';

const OsiraseMainte = () => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  const [dataSource, setDataSource] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [kigyoTypeOptions, setKigyoTypeOptions] = useState<any>([]);

  const selectOsirase = useRef<any>({});
  const modeMutation = useRef<any>('');
  const disableSubmitOrder = useRef<any>(true);
  const refForm = useRef<any>({});

  const { mutate: deleteOsirase } = useDeleteNotification();

  const {
    data: lstOsirase,
    refetch,
    isLoading: loadingOsireseMainte,
  } = useGetNotificationInfo();

  // const {
  //   mutate: getKigyoGroup,
  //   data: lstKigyoGroup,
  //   isLoading: loadingKigyoGroup,
  // } = useGetKigyoGroup();

  const {
    mutate: getKigyoType,
    data: lstKigyoType,
    isLoading: loadingKigyoType,
  } = useGetKigyoType();

  const { mutate: sortOrderOsirase, isLoading: loadingSortOrderOsirase } =
    useOrderNotification();

  useEffect(() => {
    disableSubmitOrder.current = true;
    refetch();
    // getKigyoGroup(
    //   {},
    //   {
    //     onSuccess({ data }, variables, context) {
    //       // let options = {};
    //       // data?.data?.forEach((kigyotype: any) => {
    //       //   if (!(kigyotype.kigyoKbn in options)) {
    //       //     options = {
    //       //       ...options,
    //       //       [`${kigyotype.kigyoKbn}`]: data?.data?.filter(
    //       //         (item: any) => item.kigyoKbn === kigyotype.kigyoKbn,
    //       //       ),
    //       //     };
    //       //   }
    //       // });
    //       setKigyoTypeOptions(data);
    //     },
    //   },
    // );
    getKigyoType(
      { fromCustomerMainte: false },
      {
        onSuccess(data, variables, context) {
          let options = {};
          data?.data?.forEach((kigyotype: any) => {
            if (!(kigyotype.kigyoKbn in options)) {
              options = {
                ...options,
                [`${kigyotype.kigyoKbn}`]: data?.data?.filter(
                  (item: any) => item.kigyoKbn === kigyotype.kigyoKbn,
                ),
              };
            }
          });
          setKigyoTypeOptions(options);
        },
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lstOsirase) {
      let arrOsirase = lstOsirase?.data?.map((item: any) => {
        return { ...item, index: item.oshiraseOrder };
      });
      setDataSource(arrOsirase);
    }
  }, [lstOsirase]);

  // Handle submit when update
  const handleSubmit = () => {
    const sortData = dataSource?.map((item: any) => ({
      oshiraseId: item.oshiraseId,
      oshiraseOrder: item.uptIndex,
    }));

    const initData = lstOsirase?.data?.map((item: any) => ({
      oshiraseId: item.oshiraseId,
      oshiraseOrder: item.oshiraseOrder,
    }));

    const request = initData
      ?.map((item: any) => {
        return {
          ...Object.assign(
            {},
            ...sortData?.filter(
              (itemRequest: any) =>
                itemRequest.oshiraseId === item.oshiraseId &&
                itemRequest.oshiraseOrder !== item.oshiraseOrder,
            ),
          ),
        };
      })
      .filter((item: any) => Object.keys(item).length !== 0);

    messagePopup({
      type: 'question',
      content: '表示順を変更してもよろしいですか',
      onOk: () => {
        sortOrderOsirase(request, {
          onSuccess() {
            disableSubmitOrder.current = true;
            messagePopup({
              type: 'success',
              content: '表示順を保存しました',
              onOk: () => {
                refetch();
              },
            });
          },
          onError(error) {
            messagePopup({
              type: 'error',
              content:
                MSG_ERROR[(error as any)?.response?.data?.message] ||
                error?.response?.data?.message,
            });
          },
        });
      },
    });
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        padding: 8,
        cursor: 'grab',
        color: '#343434',
        fontSize: 18,
      }}
    />
  ));

  const columns: any = [
    {
      title: () => <div style={{ minWidth: 50 }}>表示順</div>,
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 35,
            minWidth: 50,
          }}
        >
          <DragHandle />
        </div>
      ),
    },
    {
      title: () => <div style={{ minWidth: 60 }}></div>,
      dataIndex: 'sort',
      width: 60,
      render: (_value: any, record: any) => {
        const {
          oshiraseId,
          oshiraseTitle,
          dateFrom,
          dateTo,
          oshiraseContent,
          oshiraseOrder,
          kigyotypes,
          files,
        } = record;
        let osiraseInfo = {
          oshiraseId,
          oshiraseTitle,
          dateFrom,
          dateTo,
          oshiraseContent,
          oshiraseOrder,
          kigyotypes,
          files,
        };
        return (
          <Space
            size={2}
            style={{
              minWidth: 60,
            }}
          >
            <Button
              onClick={() => {
                modeMutation.current = MODE_UPDATE;
                selectOsirase.current = osiraseInfo;
                setVisibleModal(true);
              }}
            >
              修正
            </Button>
            <Button
              onClick={() => {
                modeMutation.current = MODE_DUPLICATE;
                selectOsirase.current = osiraseInfo;
                setVisibleModal(true);
              }}
            >
              複写
            </Button>
            <Button
              danger
              onClick={() => {
                messagePopup({
                  type: 'question',
                  content: 'お知らせを削除してもよろしいですか',
                  onOk: () => {
                    deleteOsirase(
                      { oshiraseId },
                      {
                        onSuccess(data, variables, context) {
                          messagePopup({
                            type: 'success',
                            content: 'お知らせを削除しました',
                          });
                          refetch();
                        },
                        onError(error, variables, context) {
                          messagePopup({
                            type: 'error',
                            content:
                              MSG_ERROR[
                                (error as any)?.response?.data?.message
                              ] || error?.response?.data?.message,
                          });
                        },
                      },
                    );
                  },
                });
              }}
            >
              削除
            </Button>
          </Space>
        );
      },
    },
    {
      title: () => <div style={{ width: 300 }}>タイトル</div>,
      dataIndex: 'oshiraseTitle',
      key: 'oshiraseTitle',
      render: (_value: any, _record: any) => {
        return (
          <div
            className="ellipsis"
            style={{
              width: 300,
            }}
          >
            {_value}
          </div>
        );
      },
    },
    {
      title: () => (
        <div style={{ minWidth: 110 }}>
          掲載期間
          <br />
          開始日
        </div>
      ),
      dataIndex: 'dateFrom',
      key: 'dateFrom',
      render: (_value: any, _record: any) => {
        return (
          <div
            style={{
              minWidth: 110,
            }}
          >
            {moment(_value, 'YYYYMMDD').format('YYYY/MM/DD (dd)')}
          </div>
        );
      },
    },
    {
      title: () => (
        <div style={{ minWidth: 110 }}>
          掲載期間
          <br />
          終了日
        </div>
      ),
      dataIndex: 'dateTo',
      key: 'dateTo',
      render: (_value: any, _record: any) => {
        return (
          <div style={{ minWidth: 110 }}>
            {moment(_value, 'YYYYMMDD').format('YYYY/MM/DD (dd)')}
          </div>
        );
      },
    },
    ...(lstKigyoType?.data || []).map((item: any) => {
      return {
        title: () => (
          <div
            style={{
              minWidth: 25,
              height: 'max-content',
              padding: '8px 0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {item.kigyotypeNm.split('').map((item: any, index: any) => {
              return (
                <span key={index} style={{ lineHeight: 1 }}>
                  {item}
                </span>
              );
            })}
          </div>
        ),
        dataIndex: 'kigyotypes',
        key: 'kigyotypes',
        render: (_value: any, _record: any) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 25,
                width: 'max-content',
              }}
            >
              {!!_value?.find((itemVl: any) => itemVl === item.kigyotypeId)
                ? '●'
                : ''}
            </div>
          );
        },
      };
    }),
  ];

  return (
    <WrapperMainte style={{ width: 'auto' }}>
      <HeaderForm>
        <span>{labelTokuisaki}</span>
      </HeaderForm>

      <Space style={{ width: '100%', marginBottom: 8 }}>
        <Button
          autoFocus
          style={{ minWidth: 80 }}
          onClick={() => {
            selectOsirase.current = {};
            modeMutation.current = MODE_CREATE;
            setVisibleModal(true);
          }}
        >
          新規
        </Button>
        <Divider
          type="vertical"
          style={{ borderLeft: '1px solid rgb(30 27 27)' }}
        />
        <Button
          style={{ minWidth: 80 }}
          type="primary"
          loading={loadingSortOrderOsirase}
          disabled={disableSubmitOrder.current}
          htmlType="submit"
          onClick={handleSubmit}
        >
          表示順保存
        </Button>
        <Button
          ref={el => (refForm.current.btnReset = el)}
          onClick={() => {
            disableSubmitOrder.current ||
              messagePopup({
                type: 'warning',
                content: MSG_ERROR['MSG_VALUES_CHANGE'],
                onOk: () => {
                  disableSubmitOrder.current = true;
                  refetch();
                },
                onCancel: () => refForm?.current?.btnReset?.focus(),
              });
          }}
        >
          {'再読込'}
        </Button>
        <Button
          ref={el => (refForm.current.btnClose = el)}
          onClick={() => {
            disableSubmitOrder.current
              ? navigate(PATH.TOP)
              : messagePopup({
                  type: 'warning',
                  content: MSG_ERROR['MSG_VALUES_CHANGE'],
                  onOk: () => {
                    navigate(PATH.TOP);
                  },
                  onCancel: () => refForm?.current?.btnClose?.focus(),
                });
          }}
        >
          {'メニューへ戻る'}
        </Button>
      </Space>
      {loadingKigyoType || loadingOsireseMainte ? (
        <LoadingCallAPI />
      ) : (
        <TableOsirase
          columns={columns}
          dataSource={dataSource}
          setDataSource={setDataSource}
          disableSubmit={disableSubmitOrder}
        />
      )}
      <ModalOsirase
        refetch={refetch}
        kigyoTypeOptions={kigyoTypeOptions}
        isModalVisible={visibleModal}
        setIsModalVisible={setVisibleModal}
        dataSource={selectOsirase.current}
        modeMutation={modeMutation.current}
        afterClose={() => {
          selectOsirase.current = {};
        }}
      />
    </WrapperMainte>
  );
};

export default OsiraseMainte;
