import { API_URL } from 'constants/api';
import { useMutation, useQuery } from 'react-query';
import AxiosDelete from 'utils/axios/axiosDelete';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

interface GetLstSyohinProps {
  searchStr?: string | null;
  cd24?: string | null;
}

export function useGetLstSyohin() {
  /**
   * *API GET USER
   * todo Hooks get user
   * @param API
   */
  const getLstSyohin = async ({ searchStr }: GetLstSyohinProps) => {
    const params = {
      searchStr,
    };
    return await AxiosPost(API_URL.GET_LIST_SYOHIN, params);
  };

  const mutation = useMutation(getLstSyohin as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetLstSyohinByCode() {
  /**
   * *API GET USER
   * todo Hooks get user
   * @param API
   */
  const getLstSyohin = async ({ cd24 }: GetLstSyohinProps) => {
    const params = {
      cd24,
    };
    return await AxiosPost(API_URL.GET_LIST_SYOHIN_CODE, params);
  };

  const mutation = useMutation(getLstSyohin as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface GetSyohinInfosProps {
  syohinCds: string[];
}

export function useGetSyohinInfos() {
  /**
   * *API GET SYOHIN INFOS FROM SYOHIN CODES
   * todo Hooks get syohin
   * @param API
   */
  const getLstSyohin = async ({ syohinCds }: GetSyohinInfosProps) => {
    return await AxiosPost(API_URL.GET_SYOHIN_INFOS, syohinCds);
  };

  const mutation = useMutation(getLstSyohin as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

interface GetSyohinInfosShinkiProps {
  siireCd: string;
  listCd24: string[];
}

export function useGetSyohinInfosShinki() {
  /**
   * *API GET SYOHIN INFOS FROM SYOHIN CODES (CREATE MODE)
   * todo Hooks get syohin
   * @param API
   */
  const getLstSyohin = async (request: GetSyohinInfosShinkiProps) => {
    return await AxiosPost(API_URL.GET_SYOHIN_INFOS_SHINKI, request);
  };

  const mutation = useMutation(getLstSyohin as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetSyohinKbn() {
  const getSyohinKbn = async () => {
    return await AxiosPost(API_URL.GET_SYOHINKBN);
  };

  const response = useQuery(['syohinkbn'], getSyohinKbn, {
    enabled: false,
    retry: 1,
  });

  return response;
}

export function useGetSyohinMainte() {
  const getSyohinInfo = async (cd24: string) => {
    const body = {
      cd24,
    };
    return await AxiosPost(API_URL.GET_SYOHIN_MAINTE, body, {});
  };

  const mutation = useMutation(getSyohinInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useCreateSyohinMainte() {
  const createSyohinInfo = async ({
    st2,
    st3,
    cd24,
    code5,
    anm,
    kanm,
    nnm,
    nnmsz,
    kpd,
    bold,
    nok,
    tnicd,
    tninm,
    jyuryo,
    lsz,
    kigyoTypes,
    ordermax,
    hatcd24,
    siirecd,
    syohinkbn,
    ptype,
    pbiasu,
    zaikomax,
    orderpoint,
    hachutani,
    zaikokbn,
    location,
    captyCode24,
    siyo,
    arrivalcontrol,
  }: any) => {
    const params = {
      // st1,
      st2,
      st3,
      cd24,
      // mkey,
      code5,
      // cd10,
      anm,
      kanm,
      nnm,
      nnmsz,
      // vszyu,
      // tni,
      kpd,
      bold,
      nok,
      tnicd,
      tninm,
      jyuryo,
      lsz,
      kigyoTypes,
      ordermax,
      hatcd24,
      siirecd,
      syohinkbn,
      ptype,
      pbiasu,
      zaikomax,
      orderpoint,
      hachutani,
      zaikokbn,
      location,
      captyCode24,
      siyo,
      // inpdt: new Date(),
      // updt: new Date(),
      arrivalcontrol,
    };
    return await AxiosPost(API_URL.SYOHIN, params);
  };

  const mutation = useMutation(createSyohinInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useUpdateSyohinMainte() {
  const updateSyohinInfo = async ({
    st1,
    st2,
    st3,
    cd24,
    mkey,
    code5,
    anm,
    kanm,
    nnm,
    nnmsz,
    vszyu,
    tni,
    kpd,
    bold,
    nok,
    tnicd,
    tninm,
    jyuryo,
    lsz,
    kigyoTypes,
    ordermax,
    hatcd24,
    siirecd,
    syohinkbn,
    ptype,
    pbiasu,
    zaikomax,
    orderpoint,
    hachutani,
    zaikokbn,
    location,
    captyCode24,
    siyo,
    arrivalcontrol,
  }: any) => {
    const params = {
      // st1,
      st2,
      st3,
      cd24,
      // mkey,
      code5,
      anm,
      kanm,
      nnm,
      nnmsz,
      // vszyu,
      // tni,
      kpd,
      bold,
      nok,
      tnicd,
      tninm,
      jyuryo,
      lsz,
      kigyoTypes,
      ordermax,
      hatcd24,
      siirecd,
      syohinkbn,
      ptype,
      pbiasu,
      zaikomax,
      orderpoint,
      hachutani,
      zaikokbn,
      location,
      captyCode24,
      siyo,
      arrivalcontrol,
    };
    return await AxiosPut(API_URL.SYOHIN, params);
  };

  const mutation = useMutation(updateSyohinInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useDeleteSyohinMainte() {
  const deleteSyohinInfo = async (cd24: any) => {
    const body = {
      cd24,
    };
    return await AxiosDelete(API_URL.SYOHIN, {}, body);
  };

  const mutation = useMutation(deleteSyohinInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useCheckHatCodeSyohinMainte() {
  const checkHatCodeSyohinInfo = async (hatSyohinCd: any) => {
    const body = {
      hatSyohinCd,
    };
    return await AxiosPost(API_URL.CHECK_HAT_CODE_SYOHIN, body, {});
  };

  const mutation = useMutation(checkHatCodeSyohinInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}

export function useGetSyohinInfo(cd24: string) {
  const getSyohinInfo = async () => {
    return await AxiosPost(`${API_URL.GET_SYOHIN}`, { cd24 });
  };

  const mutation = useMutation(getSyohinInfo as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
