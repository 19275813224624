import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { useLogAccess } from 'api/auth';
import { iKigyoType } from 'api/customer';
import { useCreateNotification, useUpdateNotification } from 'api/notification';
import ModalCustom from 'components/ModalCustom';
import RangeDatePickerCustom from 'components/RangePickerCustom';
import { BodyContent, FlexBetween } from 'components/StyleCommon';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import CKEditor from './ckEditor';
import DraggerUpload from './inputUpload';

const MODE_CREATE = 'CREATE';
const MODE_DUPLICATE = 'DUPLICATE';
const MODE_UPDATE = 'UPDATE';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  dataSource: any;
  afterClose?: any;
  kigyoTypeOptions: any;
  refetch?: any;
  modeMutation?: any;
}

function getDifference(array1: any, array2: any) {
  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return object1.fileId === object2.fileId;
    });
  });
}

function renderTitleModal(mode: string) {
  let title = '';
  switch (mode) {
    case MODE_CREATE:
      title = 'お知らせ新規作成';
      break;

    case MODE_UPDATE:
      title = 'お知らせ修正';
      break;

    case MODE_DUPLICATE:
      title = 'お知らせ複写';
      break;

    default:
      break;
  }
  return { title };
}

const ModalOsirase = ({
  isModalVisible,
  setIsModalVisible,
  dataSource,
  afterClose,
  kigyoTypeOptions,
  refetch,
  modeMutation,
}: Props) => {
  const userInfo = useSelector(selectUserInfo);
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const { title } = renderTitleModal(modeMutation);
  const [fileList, setFileList] = useState<any>([]);

  const {
    mutate: updateOsirase,
    isLoading: loadingUpdateOsirase,
    reset: rsUpdateOsirase,
  } = useUpdateNotification();

  const {
    mutate: createOsirase,
    isLoading: loadingCreateOsirase,
    reset: rsCreateOsirase,
  } = useCreateNotification();

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const onFinish = async (_record: any) => {
    const fileListUpload = fileList?.filter((item: any) => !item.oldFile);

    const oldFile = fileList
      ?.filter((item: any) => item.oldFile)
      ?.map((item: any) => ({
        fileId: item.uid,
        fileName: item.name,
      }));

    const {
      oshiraseId,
      oshiraseTitle,
      oshiraseOrder,
      kigyoTypes,
      fromDate,
      toDate,
      oshiraseContent,
    } = form.getFieldsValue(true);

    switch (modeMutation) {
      // Khởi tạo osirase
      case MODE_CREATE:
        const objKigyoTypesCreate =
          kigyoTypes?.map((item: any, index: any) => ({
            [`KigyoTypes[${index}]`]: item,
          })) || [];
        const requestCreate: any = {
          CloneFromId: -1,
          OshiraseTitle: oshiraseTitle,
          DateFrom: moment(fromDate).format('YYYYMMDD'),
          DateTo: moment(toDate).format('YYYYMMDD'),
          OshiraseContent: oshiraseContent,
          ...Object.assign({}, ...objKigyoTypesCreate),
        };
        let formDataCreate = new FormData();
        Object.keys(requestCreate).forEach(function (key) {
          formDataCreate.append(key, requestCreate[key]);
        });
        fileListUpload?.forEach((file: any) => {
          formDataCreate.append(`Files`, file.originFileObj);
        });

        messagePopup({
          type: 'question',
          content: 'お知らせを登録してもよろしいですか',
          onOk: () => {
            createOsirase(formDataCreate, {
              onSuccess(data, variables, context) {
                refetch();
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: 'お知らせメンテ',
                    pgName: '登録モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: 'お知らせを作成しました',
                  onOk: () => {
                    setIsModalVisible(false);
                  },
                });
              },
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                });
              },
            });
          },
        });
        break;

      // Tạo một bản sao theo Osirase mà được chọn
      case MODE_DUPLICATE:
        const lstDeleteFileDuplicate = [
          ...getDifference(oldFile, dataSource?.files),
          ...getDifference(dataSource?.files, oldFile),
        ];
        const objKigyoTypesDuplicate =
          kigyoTypes?.map((item: any, index: any) => ({
            [`KigyoTypes[${index}]`]: item,
          })) || [];
        const objListDeleteFileDuplicate =
          lstDeleteFileDuplicate?.map((item: any, index: any) => ({
            [`ListDeleteFiles[${index}]`]: item.fileId,
          })) || [];
        const requestDuplicate: any = {
          CloneFromId: oshiraseId,
          OshiraseTitle: oshiraseTitle,
          DateFrom: moment(fromDate).format('YYYYMMDD'),
          DateTo: moment(toDate).format('YYYYMMDD'),
          OshiraseContent: oshiraseContent,
          ...Object.assign({}, ...objKigyoTypesDuplicate),
          ...Object.assign({}, ...objListDeleteFileDuplicate),
        };
        let formDataDuplicate = new FormData();
        Object.keys(requestDuplicate).forEach(function (key) {
          formDataDuplicate.append(key, requestDuplicate[key]);
        });
        fileListUpload?.forEach((file: any) => {
          formDataDuplicate.append(`Files`, file.originFileObj);
        });

        messagePopup({
          type: 'question',
          content: 'お知らせを修正してもよろしいですか',
          onOk: () => {
            createOsirase(formDataDuplicate, {
              onSuccess(data, variables, context) {
                refetch();
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: 'お知らせメンテ',
                    pgName: '登録モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: 'お知らせを作成しました',
                  onOk: () => {
                    setIsModalVisible(false);
                  },
                });
              },
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                });
              },
            });
          },
        });
        break;

      // Chỉnh sửa thông tin osirase
      case MODE_UPDATE:
        const lstDeleteFileUpdate = [
          ...getDifference(oldFile, dataSource?.files),
          ...getDifference(dataSource?.files, oldFile),
        ];
        const objKigyoTypesUpdate =
          kigyoTypes?.map((item: any, index: any) => ({
            [`KigyoTypes[${index}]`]: item,
          })) || [];
        const objListDeleteFileUpdate =
          lstDeleteFileUpdate?.map((item: any, index: any) => ({
            [`ListDeleteFiles[${index}]`]: item.fileId,
          })) || [];
        const requestUpdate: any = {
          OshiraseId: oshiraseId,
          OshiraseTitle: oshiraseTitle,
          DateFrom: moment(fromDate).format('YYYYMMDD'),
          DateTo: moment(toDate).format('YYYYMMDD'),
          OshiraseContent: oshiraseContent,
          OshiraseOrder: oshiraseOrder,
          ...Object.assign({}, ...objKigyoTypesUpdate),
          ...Object.assign({}, ...objListDeleteFileUpdate),
        };
        let formDataUpdate = new FormData();
        Object.keys(requestUpdate).forEach(function (key) {
          formDataUpdate.append(key, requestUpdate[key]);
        });
        fileListUpload?.forEach((file: any) => {
          formDataUpdate.append(`Files`, file.originFileObj);
        });

        messagePopup({
          type: 'question',
          content: 'お知らせを修正してもよろしいですか',
          onOk: () => {
            updateOsirase(formDataUpdate, {
              onSuccess(data, variables, context) {
                refetch();
                logAccess([
                  {
                    tokuiCd: userInfo.torihikicd,
                    gyoumuId: 'お知らせメンテ',
                    pgName: '修正モード',
                    logType: '2',
                    action: '確定ボタン',
                  },
                ] as any);
                messagePopup({
                  type: 'success',
                  content: 'お知らせを更新しました',
                  onOk: () => {
                    setIsModalVisible(false);
                  },
                });
              },
              onError(error, variables, context) {
                messagePopup({
                  type: 'error',
                  content:
                    MSG_ERROR[(error as any)?.response?.data?.message] ||
                    error?.response?.data?.message,
                });
              },
            });
          },
        });
        break;

      default:
        break;
    }
  };

  // Validate form input
  const onFinishFailed = ({ errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  useEffect(() => {
    const {
      oshiraseTitle,
      dateFrom,
      dateTo,
      kigyotypes,
      oshiraseContent,
      oshiraseId,
      oshiraseOrder,
      files,
    } = dataSource;
    if (isModalVisible) {
      form.setFieldsValue({
        oshiraseTitle,
        kigyoTypes: kigyotypes,
        fromDate: moment(dateFrom),
        toDate: moment(dateTo),
        oshiraseId,
        oshiraseOrder,
        oshiraseContent,
      });
      const fakeFiles = files?.map((item: any) => ({
        uid: item.fileId,
        name: item.fileName,
        oldFile: true,
      }));
      setFileList(fakeFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  return (
    <ModalCustom
      titleModal={title}
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        afterClose();
      }}
      hideButtonClose={true}
    >
      <BodyContent className="osirase-mainte">
        <FormWrapper
          form={form}
          className="form-mainte"
          name="osirase-detail-mainte"
          labelCol={{ flex: '250px' }}
          wrapperCol={{ flex: 1 }}
          requiredMark={false}
          labelAlign="left"
          colon={false}
          validateTrigger="onSubmit"
          scrollToFirstError
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onChange={() => (refForm.current.changeValue = true)}
          style={{ maxHeight: 750, overflow: 'auto' }}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <Form.Item
            style={{ alignItems: 'flex-start' }}
            name="oshiraseTitle"
            label={
              <label>
                {'タイトル'} <label style={{ color: 'red' }}> 【必須】</label>
              </label>
            }
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('タイトル' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              autoFocus
              ref={el => (refForm.current.oshiraseTitle = el)}
              maxLength={100}
              onFocus={event => {
                event.currentTarget.select();
              }}
              style={{ width: '210mm', resize: 'none' }}
              onBlur={event => {
                event.preventDefault();
                let value = event.target.value.trim();
                form.setFieldsValue({
                  oshiraseTitle: value,
                });
              }}
              onPressEnter={(event: any) => {
                event.preventDefault();
                refForm.current.fromDate.focus();
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label={
              <label>
                {'掲載期間'}
                <label style={{ color: 'red' }}> 【必須】</label>
              </label>
            }
          >
            <Space style={{ minWidth: 500 }}>
              <RangeDatePickerCustom
                title={'掲載期間'}
                autoFocus={false}
                nextFocus="btnCheckAll"
                form={form}
                refForm={refForm}
              />
            </Space>
          </Form.Item>
          <Form.Item
            label={<label>{'掲載対象'}</label>}
            style={{ alignItems: 'baseline', width: 1050 }}
          >
            <Space style={{ marginBottom: 8 }}>
              <Button
                ref={el => (refForm.current.btnCheckAll = el)}
                onClick={() => {
                  const arrKigyoType = Object.values(kigyoTypeOptions).flat(1);
                  form.setFieldsValue({
                    kigyoTypes: arrKigyoType?.map(
                      (kigyoType: any) => kigyoType.kigyotypeId,
                    ),
                  });
                }}
                onKeyDown={e => {
                  e.key === 'Enter' && (e.target as any).click();
                }}
              >
                全チェック
              </Button>
              <Button
                onClick={() => {
                  form.setFieldsValue({
                    kigyoTypes: [],
                  });
                }}
                onKeyDown={e => {
                  e.key === 'Enter' && (e.target as any).click();
                }}
              >
                全チェック解除
              </Button>
            </Space>
            <Form.Item name="kigyoTypes" noStyle>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row gutter={[0, 2]} style={{ flexWrap: 'wrap' }}>
                  {Object.keys(kigyoTypeOptions).map(key => {
                    return (
                      <Col
                        key={key}
                        flex={1}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {kigyoTypeOptions[key].map((kigyo: iKigyoType) => {
                          if (kigyo.kigyotypeId === '21')
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                key={kigyo.kigyotypeId}
                              >
                                <Checkbox
                                  style={{ margin: 0, marginBottom: 4 }}
                                  value={kigyo.kigyotypeId}
                                >
                                  {kigyo.kigyotypeNm}
                                </Checkbox>
                                <Checkbox
                                  style={{
                                    margin: 0,
                                    marginBottom: 4,
                                    visibility: 'hidden',
                                  }}
                                  key={kigyo.kigyotypeId + '1'}
                                >
                                  {kigyo.kigyotypeNm}
                                </Checkbox>
                                <Checkbox
                                  style={{
                                    margin: 0,
                                    marginBottom: 4,
                                    visibility: 'hidden',
                                  }}
                                  key={kigyo.kigyotypeId + '2'}
                                >
                                  {kigyo.kigyotypeNm}
                                </Checkbox>
                              </div>
                            );
                          else
                            return (
                              <Checkbox
                                style={{ margin: 0, marginBottom: 4 }}
                                value={kigyo.kigyotypeId}
                                key={kigyo.kigyotypeId}
                              >
                                {kigyo.kigyotypeNm}
                              </Checkbox>
                            );
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={<label>{'コンテンツ'}</label>}
            style={{ alignItems: 'flex-start', width: 1050 }}
            name="oshiraseContent"
            hidden={true}
            rules={[
              () => ({
                validator(_: any, value: string) {
                  if (!value) {
                    return Promise.reject(
                      new Error('コンテンツ' + MSG_ERROR['MSG_INPUT']),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <label>
                {'コンテンツ'}
                <label style={{ color: 'red' }}> 【必須】</label>
              </label>
            }
            style={{ alignItems: 'flex-start', width: 1050 }}
          >
            <div className="ckeditor-wrapper">
              <CKEditor refForm={refForm} form={form} />
            </div>
          </Form.Item>
          <Form.Item
            label={
              <div>
                <label>{`添付ファイル（ファイル数：${
                  fileList?.length || 0
                }）`}</label>
              </div>
            }
            style={{ alignItems: 'flex-start', width: 1050 }}
          >
            <DraggerUpload fileList={fileList} setFileList={setFileList} />
          </Form.Item>
        </FormWrapper>
        <FlexBetween style={{ marginTop: 8 }}>
          <div></div>
          <Space>
            <Button
              ref={el => (refForm.current.btnSubmit = el)}
              htmlType="submit"
              form="osirase-detail-mainte"
              type="primary"
              loading={loadingUpdateOsirase || loadingCreateOsirase}
              style={{ minWidth: 100 }}
            >
              確 定
            </Button>
            <Button
              type="default"
              disabled={loadingUpdateOsirase || loadingCreateOsirase}
              onClick={event => {
                refForm.current.changeValue
                  ? messagePopup({
                      type: 'question',
                      content: '終了します。よろしいですか?',
                      onOk: () => {
                        rsCreateOsirase();
                        rsUpdateOsirase();
                        setIsModalVisible(false);
                      },
                      onCancel: () => {
                        (
                          (event.target as HTMLElement)
                            .offsetParent as HTMLButtonElement
                        ).focus();
                      },
                    })
                  : setIsModalVisible(false);
              }}
              style={{ width: 100 }}
            >
              戻る
            </Button>
          </Space>
        </FlexBetween>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(ModalOsirase);
