import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: hidden;
  .spinner-box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .three-quarter-spinner {
    width: 70px;
    height: 70px;
    border: 5px solid #1890ff;
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: SPIN_LOADING 0.5s linear 0s infinite;
  }
  /* @media (min-width: 768px) {
    max-height: 400px;
  } */

  @keyframes SPIN_LOADING {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
