import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';

export const ManualContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const ManualSidebar = styled.div`
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: 6px 0px 12px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
`;

export const ManualContent = styled.div`
  width: max-content;
  height: 100%;
  overflow-y: auto;
  padding: 20px 0;
  scroll-behavior: smooth;
`;

export const Header = styled.div`
  width: 100%;
  height: 120px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); */
  &.logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LogoText = styled.span`
  color: #1928da;
  font-size: 1.7rem;
  font-weight: 700;

  & > span {
    display: block;
    text-align: center;
  }
`;

export const MenuList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;
export const MenuItem = styled.li`
  height: 53px;
  display: flex;
  padding: 0 10px;
  width: 100%;
  align-items: center;
  /* &:nth-last-child(2) {
    margin-bottom: 13px;
  } */
  & > button {
    padding-left: 20px;
    border: none;
    height: 100%;
    background: transparent;
    cursor: pointer;
    .round {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #3442de;
      background-color: #3442de;
      color: #fff;
      height: 24px;
      width: 24px;
    }
  }
  & > button.active {
    width: 100%;
    height: 100%;
    background-color: #e9f0ff;
    position: relative;
    transition: all 0.2s ease-in-out;
    .round {
      border: 1px dotted #3442de;
      background-color: transparent;
      color: #3442de;
      font-weight: 600;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background: #3442de;
    }
    & > button > span {
      color: #3442de;
    }
    & > button.round {
      background: transparent;
      color: #3442de;
      border: 1px solid #b6bcff;
    }
    & > span {
      color: #3442de;
    }
  }
  &:hover {
    cursor: pointer;
    & > button > span {
      color: #3442de;
    }
  }
  &.separate {
    width: 100%;
    position: relative;
    padding-top: 35px;
    padding-left: 35px;
    margin-top: 1rem;
    height: 53px;
    .separate-btn {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 93%;
      height: 7px;
      margin: 0 10px;
      background: #e9f0ff;
    }
  }

  & > button {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  /* & > button > button {
    background-color: #3442de;
    color: #fff;
    width: 29px;
    height: 29px;
    outline: none;
    border-radius: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rounded {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #3442de;
      background-color: transparent;
      color: #3442de;
    }
  } */
`;

export const TitleText = styled.span`
  color: #6a6a6a;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
  transition: all ease-in-out 0.25s;
`;

export const Section = styled.section`
  padding-left: 350px;
  &.section {
    margin-top: 52px;
  }
  & + section {
    margin-top: 80px;
  }
`;

export const TopTitle = styled.div`
  display: flex;
  align-items: center;
  & > img {
    width: 30px;
    height: 30px;
  }
`;

export const SubText = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: #6a6a6a;
  line-height: 48px;
  margin-left: 22px;
`;

export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: #3442de;
  margin-top: 30px;
  border-radius: 2px;
  &.content {
    /* width: 1370p; */
    min-height: 58px;
    background-color: #e9f0ff;
    display: flex;
    align-items: center;
    padding: 10px 27px;
    & > span.text {
      font-size: 14px;
      font-weight: 400;
      color: #6a6a6a;
      line-height: 22px;
      margin-left: 32px;
    }
  }
`;

export const Content = styled.div`
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  width: 600px;
  min-height: 173px;
  margin-top: 22px;
`;

export const Row = styled.div`
  display: flex;
  &.border {
    border-bottom: 1px solid #c9c9c9;
  }
`;

export const Col = styled.div`
  &.left {
    border-right: 1px solid #c9c9c9;
    width: 159px;
    min-height: 60px;
    padding: 20px 30px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
  &.right {
    padding: 20px 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #000;
  }
`;

export const SubNumber = styled.div`
  background-color: #3442de;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  line-height: 30px;
  text-align: center;
`;

export const SubContent = styled.div`
  margin-top: 40px;
  & > span.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-left: 8px;
    color: #000;
  }
`;

export const Title = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  & > span.text {
    margin-left: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    object-fit: contain;
  }
`;

export const Table = styled.div`
  width: 420px;
  border-radius: 5px;
  &.table {
    margin-left: 39px;
  }
  & > div.footer {
    margin-top: 2rem;
    text-align: center;
  }
`;

export const TableHeader = styled.div`
  height: 50px;
  background-color: #cadbff;
  color: #3442de;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const TableRow = styled.div`
  display: flex;
  height: 50px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const TableCol = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  &:not(:first-child) {
    border-left: 1px solid #e5e5e5;
  }
  border-right: none;
  border-bottom: none;

  border-top: none;
  color: #000000;
`;

export const TextSpan = styled(Text)`
  cursor: pointer;
  & > code {
    padding: 6px 10px;
    background: #fff;
    border-color: #000;
  }
  &.primary > code {
    color: #fff;
    border-color: #1928da;
    background: #1928da;
  }
`;

export const SectionContent = styled.div`
  margin-top: 40px;
  & > div {
    margin-top: 16px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3442de;
  &.heading {
    margin-top: 39px;
  }
`;

export const StyledDiv = styled.div`
  & > div.right {
    text-align: right;
  }
`;

export const SectionRow = styled.div`
  &.flex {
    margin-top: 21px;
    display: flex;
  }

  & > span.left {
    color: green;
  }

  & > div.container-right {
    border: 1px solid #ff0000;
    height: 60px;
    /* min-width: 640px; */
    margin-left: 12rem;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    & > span > code {
      margin-left: 15px;
    }
  }

  & > span.text-title {
    color: green;
    margin-left: 0;
    margin-top: 1rem;
  }

  & > span {
    display: inline-block;
    /* min-width: 563px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-left: 17px;
    margin-right: 36px;
  }

  & > a {
    text-decoration: underline;
  }

  &.round {
    /* width: 1349px; */
    border-radius: 2px;
    background-color: #ffffd4;
    height: 39px;
    color: #3442de;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  &.container {
    margin-top: 14px;
    display: flex;
  }
`;

export const SpanLink = styled.span`
  text-decoration: underline;
  color: #3442de !important;
  transition: all 0.3s;
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;

export const Left = styled.div`
  width: 185px;
  margin-right: 59px;
  &.left {
    margin: 0;
  }
  & > span.text {
    margin-left: 12px;
  }
  &.flex {
    display: flex;
    border: 1px solid #000;
    margin-left: 20px;
    height: 100px;
    margin-right: 15px;
  }
  & > div.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
  }

  & > p > span > svg {
    margin-left: 10px;
    width: 1.4rem;
    height: 1.4rem;
  }

  & > p.text-left-color {
    display: flex;
    align-items: center;
    // margin-left: 20px;
    color: green;
    & > span.round {
      background: #efefef;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      height: 30px;
      padding: 0 10px;
      margin-right: 30px;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > p {
    /* margin-top: 5px; */
  }

  & > span.text {
    margin-left: 22px;
  }

  & > div.flex {
    display: flex;
    flex-direction: column;

    & > span.arrow {
      margin-left: 5px;
    }
  }

  & > div > span.red {
    text-align: left;
    color: #ff0000;
  }

  & > div > span.text {
    margin-left: 30px;
  }

  & > span.top {
    margin-top: 21px;
  }
  & > p > span.round {
    background: #efefef;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    height: 30px;
    padding: 0 10px;
  }

  & > p > span > code {
    margin-right: 10px;
  }

  & > span > span.round {
    background: #efefef;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    height: 30px;
    padding: 0 10px;
  }

  & > span > p {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & > span > p > span > span > svg {
    margin-left: 1rem;
  }

  & > span > p > span > span > svg,
  & > span > span > svg {
    width: 1.4rem;
    height: 1.4rem;
  }

  & > a {
    text-decoration: underline;
  }

  & > span {
    display: flex;
    align-items: center;
  }

  & > span > span.text-left-color,
  & > span > span.text-right-color {
    color: green;
    & > svg {
      display: flex;
      align-items: center;
    }
    & > span > svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
`;

export const Flex = styled.div`
  padding-left: 120px;
  & > p.center {
    line-height: 0.1rem;
    margin-left: 80px;
  }

  & > p > span.text {
    margin-right: 20px;
  }

  & > div.img {
    margin-left: 60px;
  }
`;
