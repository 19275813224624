import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import { axiosPost } from 'utils/axios';

export function useLogin() {
  const loginAPI = async ({ id, passwd }: any) => {
    const data = { id, passwd };
    return await axiosPost(API_URL.LOGIN, data);
  };

  const mutation = useMutation(loginAPI as any, {
    onMutate: data => {},
    onSuccess: data => {},
    onError: (err: any, newUser, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}

export function useLogAccess() {
  const logAccess = async (arrData: any) => {
    return await axiosPost(API_URL.LOG_ACCESS, arrData);
  };

  const mutation = useMutation(logAccess as any, {
    onMutate: data => {},
    onSuccess: data => {},
    onError: (err: any, newUser, context?: any): Promise<any> | void => {
      // // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
