import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';

export const useMasterCheck = () => {
  /**
   * *API MASTER CHECK
   * todo Hooks Master check
   * @param API
   */
  const masterCheck = async () => {
    return await AxiosPost(API_URL.MASTER_CHECK);
  };

  const mutation = useMutation(masterCheck as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
};
