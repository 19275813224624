export const columns = [
  {
    title: () => <div style={{ minWidth: 50 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 45 }}>便ＣＤ</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 45, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>協力企業名</div>,
    dataIndex: 'n2Atesaki1',
    key: 'n2Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>現場名</div>,
    dataIndex: 'genbanm',
    key: 'genbanm',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>配送住所</div>,
    dataIndex: 'sendAdr',
    key: 'sendAdr',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>内部番号</div>,
    dataIndex: 'naibuNo',
    key: 'naibuNo',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>伝票区分</div>,
    dataIndex: 'denKbn',
    key: 'denKbn',
    render: (_value: any) => <div style={{ width: 60 }}>{_value}</div>,
  },
  {
    title: () => <div style={{ minWidth: 60 }}>伝票番号</div>,
    dataIndex: 'denNo',
    key: 'denNo',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>枚数</div>,
    dataIndex: 'cnt',
    key: 'cnt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
];
