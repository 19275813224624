import { Button, Form, Input, Space } from 'antd';
import { useGetStockDetails } from 'api/stockDetail_WebSearch';
import RangeDatePickerCustom from 'components/RangePickerCustom';
import { PATH } from 'configs/routes';
import {
  checkForSpecialChar,
  getKeyboardFocusableElements,
  IsHankaku,
  ToASCII,
} from 'constants/common';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import { ControlFilter, MenuWrapper, Wrapper } from '../webSearchMenu.style';
import ModalResultStockDetails from './modalResult';
import { useGetStockDetailsExcel } from 'api/stockDetail_WebSearch';

//Format Date
const dateFormat = 'YYMMDD';

const StockDetails = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const refPreviousValue = useRef<any>();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any>({
    count: 0,
    listData: [],
  });
  const [visibleModalResult, setVisibleModalResult] = useState<any>(false);
  const { mutate, isLoading, reset } = useGetStockDetails();
  const {
    mutate: excelMutation,
    isLoading: isLoadingExcel,
    reset: resetExecel,
  } = useGetStockDetailsExcel();

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    reset();
    resetExecel();
    setDataSource({
      count: 0,
      listData: [],
    });
  }

  const keyboardFocusElement: any = useRef([]);
  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.current.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement.current[index + 1]
          ? keyboardFocusElement.current[index + 1]?.focus()
          : keyboardFocusElement.current[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement.current[index - 1]
          ? keyboardFocusElement.current[index - 1]?.focus()
          : keyboardFocusElement.current[
              keyboardFocusElement.current.length
            ]?.focus();
      }
    }
  };

  useEffect(() => {
    keyboardFocusElement.current = getKeyboardFocusableElements();
  });

  // In first render
  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit to get response list  hat product delivery
  const onFinish = async (record: any) => {
    let params = {
      fromDt: moment(record.fromDate).format(dateFormat),
      toDt: moment(record.toDate).format(dateFormat),
      code24: record.code24,
      kikaku: record.kikaku,
    };
    mutate(params as any, {
      onSuccess(data, variables, context) {
        const { data: dataList } = data;
        if (dataList?.count > 0) {
          setDataSource({
            count: dataList?.count,
            listData: dataList?.data?.map((order: any, index: number) => ({
              id: index + 1,
              ...order,
            })),
          });
          setVisibleModalResult(true);
        } else {
          messagePopup({
            type: 'error',
            content: MSG_ERROR['MSG_NO_DATA'],
            onOk: () => {
              refForm?.current?.fromDate?.focus();
            },
          });
        }
      },
      onError(error, variables, context) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            (error as any)?.response?.data?.message,
          onOk: () => {
            refForm?.current?.fromDate?.focus();
          },
        });
      },
    });
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  //Handle Excel
  const handleExcel = () => {
    const { fromDate, toDate, code24, kikaku } = form.getFieldsValue();
    const params = {
      fromDt: moment(fromDate).format('YYMMDD'),
      toDt: moment(toDate).format('YYMMDD'),
      code24: code24,
      kikaku: kikaku,
    };

    form
      .validateFields()
      .then(() =>
        excelMutation(params as any, {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.fromDate?.focus();
              },
            });
          },
        }),
      )
      .catch(({ errorFields }) => {
        messagePopup({
          type: 'info',
          content: errorFields[0]?.errors[0],
          onOk: () => {
            refForm?.current?.[errorFields[0]?.name[0]]?.focus();
            !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
              refForm?.current?.[errorFields[0]?.name[0]]?.select();
          },
        });
      });
  };

  return (
    <Wrapper>
      <MenuWrapper style={{ marginBottom: 8 }}>
        <span>{labelTokuisaki}</span>
      </MenuWrapper>

      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '180px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        validateTrigger="onSubmit"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
        initialValues={{
          fromDate: moment(),
          toDate: moment(),
          code24: '',
          kikaku: '',
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Form.Item label={<label>{'入荷日'}</label>}>
          <RangeDatePickerCustom
            title={'入荷日'}
            autoFocus={true}
            nextFocus="code24"
            form={form}
            refForm={refForm}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item
          label={<label>品名CD（前方一致）</label>}
          name="code24"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (value && checkForSpecialChar(value)) {
                  return Promise.reject(
                    new Error('品名CD' + MSG_ERROR['MSG_INVALID_CHAR']),
                  );
                }
                if (value && !IsHankaku(value)) {
                  return Promise.reject(
                    new Error('品名CD' + MSG_ERROR['MSG_ZENKAKU']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            disabled={isLoading}
            maxLength={11}
            ref={el => (refForm.current.code24 = el)}
            style={{ width: 275 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onFocus={event => {
              refPreviousValue.current = event.target.value;
            }}
            onBlur={event => {
              event.preventDefault();
              let value = ToASCII(event.target.value.trim(), true, false);
              form.setFieldsValue({
                code24: value,
              });
              if (value !== refPreviousValue.current) {
                form.validateFields(['code24']).catch(({ errorFields }) => {
                  let convertValue = ToASCII(
                    event.target.value.trim(),
                    true,
                    true,
                  );
                  form.setFieldsValue({ code24: convertValue });
                  messagePopup({
                    type: 'info',
                    content: errorFields[0]?.errors[0],
                    onOk: () => {
                      refForm?.current?.[errorFields[0]?.name[0]]?.focus();
                      refForm?.current?.[errorFields[0]?.name[0]]?.select();
                    },
                  });
                });
              }
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
        <Form.Item label={<label>品名半角（中間一致）</label>} name="kikaku">
          <Input
            disabled={isLoading}
            maxLength={50}
            ref={el => (refForm.current.kikaku = el)}
            style={{ width: 400 }}
            onKeyUp={event =>
              (event.key === 'Enter' || event.key === 'Tab') &&
              event.currentTarget.select()
            }
            onBlur={event => {
              event.preventDefault();
              // form.setFieldsValue({
              //   kikaku: ToASCII(event.target.value.trim()),
              // });
            }}
            onKeyDown={focusNextEle}
          ></Input>
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            loading={isLoading}
            type="primary"
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              setDataSource({
                count: 0,
                listData: [],
              });
              setTimeout(() => {
                form.submit();
              }, 100);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                form.submit();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              resetExecel();
              handleExcel();
            }}
            loading={isLoadingExcel}
            type="default"
            style={{ width: 180 }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            onClick={event => {
              event.preventDefault();
              resetPage();
            }}
            type="default"
            ref={el => (refForm.current.btnReturn = el)}
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              event.preventDefault();
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <ModalResultStockDetails
        form={form}
        isModalVisible={visibleModalResult}
        setIsModalVisible={setVisibleModalResult}
        dataSource={dataSource}
        afterClose={() => {
          refForm.current.btnSubmit.focus();
          reset();
        }}
      />
    </Wrapper>
  );
};

export default StockDetails;
