export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 30 }}>伝発</div>,
    dataIndex: 'st10',
    key: 'st10',
    render: (_value: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>受付番号</div>,
    dataIndex: 'orderNo',
    key: 'orderNo',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>受行</div>,
    dataIndex: 'orderLine',
    key: 'orderLine',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>伝区</div>,
    dataIndex: 'denKbn',
    key: 'denKbn',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>売区</div>,
    dataIndex: 'urikubun',
    key: 'urikubun',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>伝票No.</div>,
    dataIndex: 'denNo',
    key: 'denNo',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>伝行</div>,
    dataIndex: 'denhyouLine',
    key: 'denhyouLine',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>内部No.</div>,
    dataIndex: 'naibuNo',
    key: 'naibuNo',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>納入日</div>,
    dataIndex: 'nohinDt',
    key: 'nohinDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>入荷日</div>,
    dataIndex: 'nyukaDt',
    key: 'nyukaDt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 85 }}>企業グループ</div>,
    dataIndex: 'kigyoGroupName',
    key: 'kigyoGroupName',
    render: (_value: any) => (
      <div style={{ minWidth: 85, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>協力店CD</div>,
    dataIndex: 'tokuiCd',
    key: 'tokuiCd',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>会社名</div>,
    dataIndex: 'n2_Atesaki1',
    key: 'n2_Atesaki1',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>事業所名</div>,
    dataIndex: 'n2_Atesaki2',
    key: 'n2_Atesaki2',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>班CD</div>,
    dataIndex: 'hanCd',
    key: 'hanCd',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>班名</div>,
    dataIndex: 'hannm',
    key: 'hannm',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>所属</div>,
    dataIndex: 'syozoku',
    key: 'syozoku',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>現場CD</div>,
    dataIndex: 'genbaCd',
    key: 'genbaCd',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>送先名</div>,
    dataIndex: 'sendTo1',
    key: 'sendTo1',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>便CD</div>,
    dataIndex: 'binCd',
    key: 'binCd',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>便名</div>,
    dataIndex: 'binnm1',
    key: 'binnm1',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>仕入先CD</div>,
    dataIndex: 'siirecd',
    key: 'siirecd',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>仕入先名</div>,
    dataIndex: 'siirenm',
    key: 'siirenm',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 100 }}>品名</div>,
    dataIndex: 'nnm',
    key: 'nnm',
    render: (_value: any) => (
      <div style={{ minWidth: 100, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>規格</div>,
    dataIndex: 'nnmsz',
    key: 'nnmsz',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>数量</div>,
    dataIndex: 'bara',
    key: 'bara',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>ﾛｹｰｼｮﾝ</div>,
    dataIndex: 'location',
    key: 'location',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
];
