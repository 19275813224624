import { Button } from 'antd';
import { PATH } from 'configs/routes';
import { toFullWidthAll } from 'constants/fullWidth';
import { TEXT_ALL } from 'constants/text';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserInfo } from 'slice/app';
import { data } from './infoList';
import {
  LinkMenu,
  MenuContainer,
  MenuGroup,
  MenuWrapper,
} from './webSearchMenu.style';

const WebSearchMenu = () => {
  const navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;

  return (
    <MenuContainer size={10} direction="vertical">
      <MenuWrapper>
        <span>{labelTokuisaki}</span>
        <Button
          onClick={() => {
            navigate(PATH.TOP);
          }}
        >
          メニューへ戻る
        </Button>
      </MenuWrapper>
      {data.map((menu: any) => (
        <MenuGroup
          key={menu.title}
          title={menu.title}
          background={menu.backgroundColor}
        >
          <div>
            {menu.group.map((link: any, index: any) => (
              <LinkMenu key={link.path} to={`/WebSearchMenu${link.path}`}>
                {toFullWidthAll((index + 1).toString())}
                {'. '}
                {link.title}
              </LinkMenu>
            ))}
          </div>
        </MenuGroup>
      ))}

      {/* <Col>
        {menuList.map((item: any, index: any) => (
          <Row gutter={[24, 12]} key={index}>
            {item.group.map((menuItem: any, idx: any) => (
              <Tooltip
                placement="bottom"
                title={
                  <div style={{ color: '#000', fontSize: '12px' }}>
                    {menuItem.textToolTip}
                  </div>
                }
                overlayStyle={{
                  maxWidth: '500px',
                  border: '1px solid #cbd5e1',
                  borderRadius: '5px',
                  padding: 0,
                }}
                color={'#fff'}
                overlayInnerStyle={{
                  padding: '3px 5px',
                  minHeight: '10px',
                }}
                key={idx}
              >
                <Col span={12} key={idx}>
                  <div style={{ position: 'relative' }}>
                    <MenuItem
                      autoFocus={menuItem.number === '40.'}
                      number={menuItem.number}
                      backgroundColor={menuItem.backgroundColor}
                      title={menuItem.title}
                      extraText={menuItem.extraText}
                      path={`/WebSearchMenu${menuItem.path}`}
                    />
                  </div>
                </Col>
              </Tooltip>
            ))}
            {index + 1 === menuList.length || (
              <Divider style={{ margin: '10px 0px', borderColor: '#868686' }} />
            )}
          </Row>
        ))}
      </Col> */}
    </MenuContainer>
  );
};

export default WebSearchMenu;
