import { Button, Col, Space } from 'antd';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import messagePopup from 'utils/message/mesage';
import moment from 'moment';
import { memo, useRef } from 'react';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';
import { MSG_ERROR } from 'constants/text';
import { useReturnInput } from 'api/returnInput';
import { selectUserInfo } from 'slice/app';
import { useSelector } from 'react-redux';
import { useLogAccess } from 'api/auth';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  data: any;
  onResetPage: any;
  onSubmitFailed: (firstErrSyohinCd: string) => void;
}

const columns = [
  {
    title: 'Ｎｏ．',
    dataIndex: 'index',
    width: 50,
    key: 'index',
    render: (_value: any) => (
      <div style={{ textAlign: 'right' }}>{_value + 1}</div>
    ),
  },
  {
    title: '品名ＣＤ',
    dataIndex: 'cd24',
    width: 120,
    key: 'cd24',
    render: (_value: any) => (
      <div style={{ textAlign: 'center' }}>{_value}</div>
    ),
  },
  {
    title: '品名',
    dataIndex: 'nnm',
    width: 450,
    key: 'nnm',
    render: (_value: any) => <div style={{ lineHeight: 1 }}>{_value}</div>,
  },
  {
    title: '返品数',
    width: 120,
    dataIndex: 'bara',
    key: 'bara',
    render: (_value: any) => (
      <div style={{ textAlign: 'right' }}>
        {_value ? Number(_value).toLocaleString() : _value}
      </div>
    ),
  },
];

const ReturnConfirm = ({
  isModalVisible,
  setIsModalVisible,
  data,
  onResetPage,
  onSubmitFailed,
}: Props) => {
  const btnSubmitRef = useRef<any>(null);
  const userInfo = useSelector(selectUserInfo);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();

  const {
    mutate: returnInput,
    isLoading: loadingReturnInput,
    reset,
  } = useReturnInput();

  const onSubmit = () => {
    const { nyukaDt, torihikiNm, ...restHeader } = data.header;
    let request = {
      header: {
        ...restHeader,
        shukkaDt: moment(nyukaDt).format('YYMMDD'),
        nohinDt: moment(nyukaDt).format('YYMMDD'),
      },
      meisai: data.meisai.map((item: any) => {
        return { cd24: item.cd24, bara: item.bara };
      }),
    };
    messagePopup({
      type: 'question',
      content: '登録します。よろしいですか?',
      onOk: () => {
        returnInput(request, {
          onSuccess({ data }, variables, context) {
            if (!!data.length) {
              messagePopup({
                type: 'info',
                content: (
                  <div style={{ textAlign: 'left' }}>
                    <p>
                      {'以下の商品は在庫処分数量が現在庫数を超えています。'}
                    </p>
                    {data.map((item: { cd24: string; zaiko: number }) => {
                      return (
                        <div key={item.cd24}>
                          <span>{`　${item.cd24}（現在庫数：${item.zaiko}）`}</span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                ),
                onOk: () => onSubmitFailed(data[0].cd24),
              });
              return;
            }
            logAccess([
              {
                tokuiCd: userInfo.torihikicd,
                gyoumuId: '返品入力',
                pgName: '返品入力確認',
                logType: '2',
                action: '返品実行',
              },
            ] as any);
            messagePopup({
              type: 'success',
              content: '％処理区分％情報を登録しました。'.replace(
                '％処理区分％',
                restHeader?.shorikubun,
              ),
              onOk: () => {
                onResetPage();
              },
            });
          },
          onError(error, variables, context) {
            messagePopup({
              type: 'error',
              content:
                MSG_ERROR[(error as any)?.response?.data?.message] ||
                error?.response?.data?.message,
            });
          },
        });
      },
      onCancel: () => btnSubmitRef.current.focus(),
    });
  };
  return (
    <ModalCustom
      titleModal={'返品入力確認'}
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {
        reset();
      }}
      disabledClose={loadingReturnInput}
      hideButtonClose={true}
    >
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>{'内容をご確認ください'}</h2>
          <DetailsOrder style={{ width: 750 }}>
            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>伝票区分</Label>
                <Content>{'32：返品'}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>扱便</Label>
                <Content>{'903：返品入荷'}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>得意先</Label>
                <Content>
                  {data?.header?.torihikiCd} {data?.header?.torihikiNm}
                </Content>
              </DetailsOrderItem>
            </Col>
            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>入荷日</Label>
                <Content>
                  {moment(data?.header?.nyukaDt).format('YYYY/MM/DD (ddd)')}
                </Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>処理区分</Label>
                <Content>{data?.header?.shorikubun}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>売区</Label>
                <Content>
                  {data?.header?.urikubun === '53'
                    ? '53：協力店責任'
                    : '55：ＣＡＰ責任'}
                </Content>
              </DetailsOrderItem>
            </Col>
          </DetailsOrder>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="index"
            height={'230px'}
            dataSource={data?.meisai || []}
            columns={columns}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button
              ref={btnSubmitRef}
              autoFocus
              type="primary"
              loading={loadingReturnInput}
              onClick={onSubmit}
            >
              登録します
            </Button>
            <Button
              disabled={loadingReturnInput}
              type="default"
              onClick={() => {
                reset();
                setIsModalVisible(false);
              }}
            >
              訂正します（戻る）
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(ReturnConfirm);
