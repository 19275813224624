// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/ja';
import { CKEditor as CKEditor4 } from 'ckeditor4-react';
// import DecoupledEditor from 'ckeditor5-custom-build/build/ckeditor';

const CKEditor = ({ form, refForm }) => {
  // const { data: dataNotification, isLoading } = useGetNotification();
  // // console.log(dataNotification);

  return (
    <div className="document-editor">
      <div className="document-editor__toolbar"></div>
      <div className="document-editor__editable-container">
        <CKEditor4
          config={{
            language: 'ja',
            height: '300px',
            toolbar: [
              { name: 'tools', items: ['Maximize'] },
              {
                name: 'clipboard',
                items: [
                  'Cut',
                  'Copy',
                  'Paste',
                  'PasteText',
                  '-',
                  'Undo',
                  'Redo',
                ],
              },
              { name: 'links', items: ['Link', 'Unlink'] },
              { name: 'document', items: ['Source'] },
              {
                name: 'basicstyles',
                items: [
                  'Bold',
                  'Italic',
                  'Underline',
                  'Strike',
                  '-',
                  'Subscript',
                  'Superscript',
                ],
              },
              {
                name: 'paragraph',
                items: [
                  'NumberedList',
                  'BulletedList',
                  '-',
                  'Outdent',
                  'Indent',
                  'Blockquote',
                ],
              },
              {
                name: 'align',
                items: [
                  'AlignLeft',
                  'JustifyLeft',
                  'JustifyCenter',
                  'JustifyRight',
                  'JustifyBlock',
                ],
              },
              {
                name: 'styles',
                items: ['Format', '-', 'Font', '-', 'FontSize'],
              },
              { name: 'colors', items: ['TextColor', 'BGColor'] },
              { name: 'insert', items: ['Image', 'Table', 'HorizontalRule'] },
              '/',
            ],
            extraPlugins: 'colorbutton,colordialog,font',
            allowedContent: '',
          }}
          initData={form.getFieldValue('oshiraseContent') || ''}
          onChange={event => {
            refForm.current.changeValue = true;
            let value = event.editor.getData() || '';
            form.setFieldsValue({ oshiraseContent: value });
          }}
          onInstanceReady={event =>
            (refForm.current.oshiraseContent = event.editor)
          }
        />
      </div>
    </div>
  );
};

export default CKEditor;
