import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosDelete from 'utils/axios/axiosDelete';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosPut from 'utils/axios/axiosPut';

export interface iKigyoType {
  kigyotypeId: string;
  kigyotypeNm: string;
  kigyoKbn: string;
}

export function useGetKigyoType() {
  /**
   * *API GET KIGYO TYPE MASTER
   * todo Hooks get kigyo type
   * @param API
   */
  const getKigyoType = async (body: any) => {
    return await AxiosPost(API_URL.GET_KIGYO_TYPE_MASTER, body);
  };

  const mutation = useMutation(getKigyoType as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
  });

  return mutation;
}

export interface iKigyoGroup {
  kigyogroupId: string;
  kigyogroupNm: string;
}

export function useGetKigyoGroup() {
  /**
   * *API GET KIGYO TYPE MASTER
   * todo Hooks get kigyo type
   * @param API
   */
  const getKigyoGroup = async (body: any) => {
    return await AxiosPost(API_URL.GET_KIGYO_GROUP_MASTER, body);
  };

  const mutation = useMutation(getKigyoGroup as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
  });

  return mutation;
}

export function useGetTensyoh() {
  /**
   * *API GET TENSYOH MASTER
   * todo Hooks get tensyoh
   * @param API
   */
  const getTensyoh = async () => {
    return await AxiosPost(API_URL.GET_TENSYOH_MASTER);
  };

  const mutation = useMutation(getTensyoh as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
  });

  return mutation;
}

interface GetListCustomerProps {
  name: string;
}

export function useGetCustomer() {
  /**
   * *API GET USER
   * todo Hooks get user
   * @param API
   */
  const getCustomer = async ({ name }: GetListCustomerProps) => {
    const params = {
      name,
    };
    return await AxiosPost(API_URL.GET_CUSTOMER, params);
  };

  const mutation = useMutation(getCustomer as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}

interface GetCustomerMainteProps {
  torihikiCd: string;
  hanList: boolean;
  tantoList: boolean;
}

export function useGetCustomerMainte() {
  const mutationGetCustomer = async (data: GetCustomerMainteProps) => {
    return await AxiosPost(API_URL.GET_INFOR, data);
  };

  const mutation = useMutation(mutationGetCustomer as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}

export function useCreateCustomerMainte() {
  const mutationCreateCustomer = async ({
    atukai,
    torihikicd,
    torihikiKana,
    tensyoKana,
    torihikiNm,
    tensyo,
    postcd,
    adrs1,
    adrs2,
    adrs3,
    tel,
    fax,
    captyTorihikiCd,
    id,
    passwd,
  }: any) => {
    const params = {
      atukai,
      torihikicd,
      torihikiKana,
      tensyoKana,
      torihikiNm,
      tensyo,
      postcd,
      adrs1,
      adrs2,
      adrs3,
      tel,
      fax,
      captyTorihikiCd,
      id,
      passwd,
      dateNow: new Date(),
    };
    return await AxiosPost(API_URL.CUSTOMER, params);
  };

  const mutation = useMutation(mutationCreateCustomer as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}

export function useUpdateCustomerMainte() {
  const mutationUpdateCustomer = async ({
    atukai,
    torihikicd,
    torihikiKana,
    tensyoKana,
    torihikiNm,
    tensyo,
    postcd,
    adrs1,
    adrs2,
    adrs3,
    tel,
    fax,
    captyTorihikiCd,
    id,
    passwd,
  }: any) => {
    const params = {
      atukai,
      torihikicd,
      torihikiKana,
      tensyoKana,
      torihikiNm,
      tensyo,
      postcd,
      adrs1,
      adrs2,
      adrs3,
      tel,
      fax,
      captyTorihikiCd,
      id,
      passwd,
    };
    return await AxiosPut(API_URL.CUSTOMER, params);
  };

  const mutation = useMutation(mutationUpdateCustomer as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}

export function useDeleteCustomerMainte() {
  const mutationDeleteCustomer = async (torihikicd: any) => {
    const params = {
      torihikicd,
    };
    return await AxiosDelete(API_URL.CUSTOMER, {}, params);
  };

  const mutation = useMutation(mutationDeleteCustomer as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {},
    onSettled: () => {},
  });

  return mutation;
}
