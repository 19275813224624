import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Form, Select, Space } from 'antd';
import { useGetShippedLines, useGetShippedLinesExcel } from 'api/shippedLines';
import RangeDatePickerCustom from 'components/RangePickerCustom';
import TableCustom from 'components/TableCommon';
import { PATH } from 'configs/routes';
import { MSG_ERROR, TEXT_ALL } from 'constants/text';
import { selectUserInfo } from 'slice/app';
import messagePopup from 'utils/message/mesage';
import { FormWrapper } from 'views/login/FormLogin/formLogin.style';
import {
  BodyContent,
  ControlFilter,
  MenuWrapper,
  Wrapper,
} from '../webSearchMenu.style';
import { columns } from './columnsTable';
import { getKeyboardFocusableElements } from 'constants/common';

//Format Date
const dateFormat = 'YYMMDD';

const { Option } = Select;

const ShippedLines = () => {
  const userInfo = useSelector(selectUserInfo);
  const labelTokuisaki =
    (userInfo.kaisya ?? '') + (userInfo.tensyo ?? '') + TEXT_ALL.SAMA;
  const [form] = Form.useForm();
  const refForm = useRef<any>({});
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any>([]);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const { mutate, isLoading, reset } = useGetShippedLines();
  const dataDenKbn = [
    { value: '11', label: '在庫' },
    { value: '15', label: '取継' },
    { value: '21', label: '直送' },
    { value: '32', label: '返品、在庫処分' },
  ];

  const keyboardFocusElement: any = useRef([]);
  const focusNextEle = (e: any) => {
    const index = keyboardFocusElement.current.findIndex(
      (element: any) => element === e.target,
    );
    if (index !== -1) {
      if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
        e.preventDefault();
        keyboardFocusElement.current[index + 1]
          ? keyboardFocusElement.current[index + 1]?.focus()
          : keyboardFocusElement.current[0]?.focus();
      } else if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        keyboardFocusElement.current[index - 1]
          ? keyboardFocusElement.current[index - 1]?.focus()
          : keyboardFocusElement.current[
              keyboardFocusElement.current.length
            ]?.focus();
      }
    }
  };

  useEffect(() => {
    keyboardFocusElement.current = getKeyboardFocusableElements();
  });

  // Func reset form and clear cache data
  function resetPage() {
    form.resetFields();
    reset();
    resetExcel();
    setDataSource([]);
  }

  // In first render
  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Excel
  const {
    mutate: excelMutation,
    isLoading: isLoadingExcel,
    reset: resetExcel,
  } = useGetShippedLinesExcel();

  //Handle Excel
  const handleExcel = () => {
    const { fromDate, toDate, denKbn } = form.getFieldsValue();
    const params = {
      fromDt: moment(fromDate).format('YYMMDD'),
      toDt: moment(toDate).format('YYMMDD'),
      denKbn: denKbn,
    };

    form
      .validateFields()
      .then(() =>
        excelMutation(params as any, {
          onSuccess(data, variables, context) {
            let fileName = data.headers['content-disposition'] || 'errorName';
            const file = new Blob([data?.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = decodeURIComponent(
              fileName?.split('filename*=')[1].split(';')[0],
            ).substring(7);
            link.click();
          },
          onError: async (error, variables, context) => {
            const errorMsg = JSON.parse(
              await error.response.data.text(),
            )?.message;
            messagePopup({
              type: 'error',
              content: MSG_ERROR[errorMsg] || errorMsg,
              onOk: () => {
                refForm?.current?.denKbn?.focus();
              },
            });
          },
        }),
      )
      .catch(({ errorFields }) => {
        messagePopup({
          type: 'info',
          content: errorFields[0]?.errors[0],
          onOk: () => {
            refForm?.current?.[errorFields[0]?.name[0]]?.focus();
            !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
              refForm?.current?.[errorFields[0]?.name[0]]?.select();
          },
        });
      });
  };

  // onSubmit to get response list  Shipped Lines
  const onFinish = async (record: any) => {
    let params = {
      fromDt: moment(record.fromDate).format(dateFormat),
      toDt: moment(record.toDate).format(dateFormat),
      denKbn: record.denKbn,
    };
    mutate(params as any, {
      onSuccess(data, variables, context) {
        const { data: dataLines } = data;
        const { denKbn } = form.getFieldsValue();
        if (!denKbn) {
          messagePopup({
            type: 'error',
            content: '伝票区分' + MSG_ERROR['MSG_INPUT'],
            onOk: () => {
              refForm?.current?.denKbn.focus();
              setDataSource([]);
            },
          });
          return;
        }
        const dataTemp = [{ ...dataLines }];
        const dataSource = dataTemp.map((order: any, index: number) => ({
          id: index + 1,
          ...order,
        }));
        setDataSource(dataSource);
        refForm.current.btnSubmit.focus();
      },
      onError(error, variables, context) {
        messagePopup({
          type: 'error',
          content:
            MSG_ERROR[(error as any)?.response?.data?.message] ||
            (error as any)?.response?.data?.message,
          onOk: () => setDataSource([]),
        });
      },
    });
  };

  // Validate form input
  const onFinishFailed = ({ values, errorFields }: any) => {
    messagePopup({
      type: 'info',
      content: errorFields[0]?.errors[0],
      onOk: () => {
        refForm?.current?.[errorFields[0]?.name[0]]?.focus();
        !!refForm?.current?.[errorFields[0]?.name[0]]?.select?.toString() &&
          refForm?.current?.[errorFields[0]?.name[0]]?.select();
      },
    });
  };

  return (
    <Wrapper>
      <FormWrapper
        form={form}
        className="form-mainte"
        name="websearch-mainte"
        labelCol={{ flex: '150px' }}
        wrapperCol={{ flex: 1 }}
        requiredMark={false}
        labelAlign="left"
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        validateTrigger="onSubmit"
        scrollToFirstError
        initialValues={{
          fromDate: moment(),
          toDate: moment(),
          denKbn: null,
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <MenuWrapper style={{ marginBottom: 8 }}>
          <span>{labelTokuisaki}</span>
        </MenuWrapper>
        <Form.Item
          label={
            <label>
              伝票区分<label style={{ color: 'red' }}> 【必須】</label>
            </label>
          }
          name="denKbn"
          rules={[
            () => ({
              validator(_: any, value: string) {
                if (!value) {
                  return Promise.reject(
                    new Error('伝票区分' + MSG_ERROR['MSG_SELECT']),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            open={openDropdown}
            onDropdownVisibleChange={open => setOpenDropdown(open)}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                setOpenDropdown(false);
                refForm.current?.fromDate?.focus();
              } else if (event.key === ' ') {
                event.preventDefault();
                setOpenDropdown(true);
              }
              focusNextEle(event);
            }}
            disabled={isLoading}
            autoFocus
            placeholder={'選択してください'}
            ref={el => (refForm.current.denKbn = el)}
            style={{ width: 150 }}
            // showAction={['focus']}
            onSelect={(value: any) => {
              if (value) {
                setTimeout(() => {
                  refForm.current?.fromDate?.focus();
                }, 0);
              }
            }}
            getPopupContainer={(trigger: HTMLElement) =>
              trigger.parentNode as HTMLElement
            }
          >
            {dataDenKbn?.map((item: any) => {
              return (
                <Option value={item.value} key={item.value}>
                  {item.value}: {item.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={<label>{'納入日'}</label>}>
          <Form.Item>
            <RangeDatePickerCustom
              title={'納入日'}
              autoFocus={false}
              nextFocus="btnSubmit"
              form={form}
              refForm={refForm}
              disabled={isLoading}
            />
          </Form.Item>
        </Form.Item>
      </FormWrapper>

      <ControlFilter style={{ marginTop: 15, marginBottom: 7 }}>
        <Space>
          <Button
            style={{ minWidth: 100 }}
            type="primary"
            loading={isLoading}
            ref={el => (refForm.current.btnSubmit = el)}
            onClick={e => {
              setTimeout(() => {
                form.submit();
              }, 100);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                form.submit();
              }
            }}
          >
            検索
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              handleExcel();
            }}
            loading={isLoadingExcel}
            type="default"
            style={{ width: '160px' }}
          >
            EXCEL作成（XLSX）
          </Button>
          <Button
            onClick={event => {
              event.preventDefault();
              resetPage();
            }}
            type="default"
          >
            画面クリア
          </Button>
          <Button
            type="default"
            onClick={event => {
              event.preventDefault();
              resetPage();
              navigate(PATH.WEBSEARCH_MENU);
            }}
          >
            メニューへ戻る
          </Button>
        </Space>
      </ControlFilter>

      <BodyContent>
        <TableCustom
          height={'23px'}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
        />
      </BodyContent>
    </Wrapper>
  );
};

export default ShippedLines;
