/* eslint-disable prettier/prettier */
import moment from 'moment';
import { useRef } from 'react';
import { toKatakana } from 'wanakana';
import { toFullWidthAll } from './fullWidth';

export const SESSION_STORAGE_ITEM = Object.freeze({
  USER_INFO: 'userInfo',
  ACCESS_TOKEN: 'access_token',
  EXPIREDAT: 'expiredAt',
});

export const checkForSpecialChar = function (string: any) {
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[!%;'",]/;
  return specialChars.test(string);
};

//TO Hankaku, chuyển thành chữ nhỏ
export function ToASCII(
  chars: string,
  isUpper: boolean = true,
  isTokata: boolean = true,
) {
  const shiftCharCode = (Δ: any) => (c: any) =>
    String.fromCharCode(c?.charCodeAt(0) + Δ);
  const toHalfWidth = (str: any) =>
    str?.replace(/[！-～]/g, shiftCharCode(-0xfee0));

  function convertToHalfWidth(string: any) {
    let characters = getCharacters(string);
    let halfWidthString = '';
    characters?.forEach((character: any) => {
      halfWidthString += mapToHankaku(character);
    });
    return halfWidthString;
  }

  function getCharacters(string: any) {
    return string?.split('');
  }

  function mapToHankaku(character: any) {
    let zenHanMap: any = getZenkakuToHankakuMap();
    if (typeof zenHanMap[character] === 'undefined') {
      return character;
    } else {
      return zenHanMap[character];
    }
  }

  function getZenkakuToHankakuMap() {
    let zenHanMap = {
      ァ: 'ｧ',
      ア: 'ｱ',
      ィ: 'ｨ',
      イ: 'ｲ',
      ゥ: 'ｩ',
      ウ: 'ｳ',
      ェ: 'ｪ',
      エ: 'ｴ',
      ォ: 'ｫ',
      オ: 'ｵ',
      カ: 'ｶ',
      ガ: 'ｶﾞ',
      キ: 'ｷ',
      ギ: 'ｷﾞ',
      ク: 'ｸ',
      グ: 'ｸﾞ',
      ケ: 'ｹ',
      ゲ: 'ｹﾞ',
      コ: 'ｺ',
      ゴ: 'ｺﾞ',
      サ: 'ｻ',
      ザ: 'ｻﾞ',
      シ: 'ｼ',
      ジ: 'ｼﾞ',
      ス: 'ｽ',
      ズ: 'ｽﾞ',
      セ: 'ｾ',
      ゼ: 'ｾﾞ',
      ソ: 'ｿ',
      ゾ: 'ｿﾞ',
      タ: 'ﾀ',
      ダ: 'ﾀﾞ',
      チ: 'ﾁ',
      ヂ: 'ﾁﾞ',
      ッ: 'ｯ',
      ツ: 'ﾂ',
      ヅ: 'ﾂﾞ',
      テ: 'ﾃ',
      デ: 'ﾃﾞ',
      ト: 'ﾄ',
      ド: 'ﾄﾞ',
      ナ: 'ﾅ',
      ニ: 'ﾆ',
      ヌ: 'ﾇ',
      ネ: 'ﾈ',
      ノ: 'ﾉ',
      ハ: 'ﾊ',
      バ: 'ﾊﾞ',
      パ: 'ﾊﾟ',
      ヒ: 'ﾋ',
      ビ: 'ﾋﾞ',
      ピ: 'ﾋﾟ',
      フ: 'ﾌ',
      ブ: 'ﾌﾞ',
      プ: 'ﾌﾟ',
      ヘ: 'ﾍ',
      ベ: 'ﾍﾞ',
      ペ: 'ﾍﾟ',
      ホ: 'ﾎ',
      ボ: 'ﾎﾞ',
      ポ: 'ﾎﾟ',
      マ: 'ﾏ',
      ミ: 'ﾐ',
      ム: 'ﾑ',
      メ: 'ﾒ',
      モ: 'ﾓ',
      ャ: 'ｬ',
      ヤ: 'ﾔ',
      ュ: 'ｭ',
      ユ: 'ﾕ',
      ョ: 'ｮ',
      ヨ: 'ﾖ',
      ラ: 'ﾗ',
      リ: 'ﾘ',
      ル: 'ﾙ',
      レ: 'ﾚ',
      ロ: 'ﾛ',
      ヮ: '',
      ワ: 'ﾜ',
      // 'ヰ': 'ﾞ  ﾟ',
      // 'ヱ': '',
      ヲ: 'ｦ',
      ン: 'ﾝ',
      ヴ: 'ｳﾞ',
      // 'ヵ': '',
      // 'ヶ': '',
      // 'ヷ': '',
      // 'ヸ': '',
      // 'ヹ': '',
      // 'ヺ': '',
      '・': '･',
      ー: 'ｰ',
      // 'ヽ': '',
      // 'ヾ': '',
      // 'ヿ': '',
    };
    return zenHanMap;
  }

  const convertUpcase = isUpper ? chars?.toUpperCase() : chars;

  let convertTokatakana = isTokata
    ? toKatakana(toHalfWidth(convertUpcase), { passRomaji: true })
    : toHalfWidth(convertUpcase);

  convertTokatakana = convertToHalfWidth(convertTokatakana);

  return convertTokatakana;
}

export function IsHankakuEisu(chars: string) {
  let valid = false;
  if (chars?.match(/[^A-Za-z0-9]/)) {
    // match: sai thì trả về true
    valid = true;
  }
  return valid;
}

export const IsHankaku = (val: string) => {
  return (
    val?.replace(
      // eslint-disable-next-line no-control-regex
      /[^\x00-\x80｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝ ﾞ ﾟ]/g,
      'xx',
    )?.length === val?.length
  );
};

export const IsZenkaku = (val: string) => {
  return (
    val?.replace(
      // eslint-disable-next-line no-control-regex
      /[^\x00-\x80｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝ ﾞ ﾟ]/g,
      'xx',
    )?.length ===
    val?.length * 2
  );
};

export const UseFocus = () => {
  const htmlElRef = useRef<any>(null);
  const setFocus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
      !!htmlElRef.current.select?.toString() && htmlElRef.current.select();
      // document.execCommand('selectall', false, undefined);
    }
  };

  return [htmlElRef, setFocus] as const;
};

export function EditCanma(value: string) {
  return Number(value).toLocaleString();
}

export function Hankaku2Zenkaku(str: string) {
  return toFullWidthAll(str);
}

export const numberJapan = (number: any) => {
  let _number = number?.toString().split('');
  for (let i = 0; i <= _number.length; i++) {
    const index = '0123456789'.indexOf(_number[i]);
    if (index !== -1) {
      _number[i] = '０１２３４５６７８９'[index];
    }
  }
  switch (_number.join('')) {
    case '１':
    case '２':
    case '３':
    case '４':
    case '５':
    case '６':
    case '７':
    case '８':
    case '９':
      return '　' + _number.join('');
    default:
      return _number.join('');
  }
};

export const isNumeric = (val: string, includesNegative?: boolean) => {
  if (val !== undefined || val !== '') {
    return includesNegative ? /^-?[0-9]+$/.test(val) : /^[0-9]+$/.test(val);
  }
  return false;
};

export const onlyLettersAndNumbers = (str: string) => {
  return /^[A-Za-z0-9]*$/.test(str);
};

export const isDouble = (val: string) => {
  return !!val.match(/^\d{0,7}(?:\.\d{0,2}){0,1}$/);
};

export const isNumberPhoneFax = (val: string) => {
  return /^[0-9-]+$/.test(val);
};

export function InputCheck(value: string) {
  var pattern = /^[0-9]{6}$/;
  if (value === '') {
    return 'MSG_INPUT';
  }
  if (!value.match(pattern)) {
    return 'MSG_DATE_FORMAT_YYMMDD';
  }
  let iYear = parseInt(value?.substring(0, 2));
  let iMonth = parseInt(value?.substring(2, 4));
  let iDay = parseInt(value?.substring(4, 6));
  // let iYear = parseInt(
  //   moment(value, 'YYMMDD').year().toString().substring(2, 4),
  // );
  // let iMonth = moment(value, 'YYMMDD').month() + 1;
  // let iDay = moment(value, 'YYMMDD').date();
  if (!(iMonth > 0 && iMonth < 13)) {
    return 'MSG_DATE_FORMAT_MONTH';
  }
  let iLastDay = new Date(iYear, iMonth, 0).getDate();
  if (1 > iDay || iDay > iLastDay) {
    return 'MSG_DATE_FORMAT_DAY';
  }
  return '';
}

const dateFormat = 'YYMMDD';

export const CheckRangeDate = (_from: any, _to: any) => {
  var dateFrom = moment(_from, dateFormat);
  var dateTo = moment(_to, dateFormat);
  if (dateFrom > dateTo) {
    return 'MSG_DATE_RANGE';
  }
  return '';
};

export const hasKanji = (string: any) => {
  const regex =
    /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;
  return string.match(regex) ? true : false;
};

export function checkBinCd(chars: string) {
  if (chars?.match(/^P\d{2}$/)) {
    return false;
  }
  return true;
}

export function getKeyboardFocusableElements(element = document) {
  return [
    ...element.querySelectorAll(
      'a[href], button:not([tabindex="-1"]), input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    ),
  ].filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
  );
}
