import { Button, Col, Space } from 'antd';
import ModalCustom from 'components/ModalCustom';
import { BodyContent, FlexColumn, FlexEnd } from 'components/StyleCommon';
import TableCustom from 'components/TableCommon';
import moment from 'moment';
import messagePopup from 'utils/message/mesage';
import { memo, useRef } from 'react';
import {
  Content,
  DetailsOrder,
  DetailsOrderItem,
  Label,
} from 'views/common/orderSheet/orderSheet.style';
import { useCreateStockReceive } from 'api/stockDetails';
import { MSG_ERROR } from 'constants/text';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'slice/app';
import { useLogAccess } from 'api/auth';

export interface stockRefillReceiveData {
  index: number;
  code24: string;
  nnm: string;
  location: string;
  suryo: number;
  code10: string;
  code5: string;
  kanm: string;
  nyukaDt: any;
  siirecd: string;
  siirenm: string;
}

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: any;
  data: Array<stockRefillReceiveData>;
  onResetPage: any;
  onSubmitFailed: (firstErrSyohinCd: string) => void;
}

const columns = [
  {
    title: 'Ｎｏ．',
    dataIndex: 'index',
    width: 50,
    key: 'index',
    render: (_value: any) => (
      <div style={{ textAlign: 'right' }}>{_value + 1}</div>
    ),
  },
  {
    title: '品名ＣＤ',
    dataIndex: 'code24',
    width: 120,
    key: 'code24',
    render: (_value: any) => (
      <div style={{ textAlign: 'center' }}>{_value}</div>
    ),
  },
  {
    title: '品名',
    dataIndex: 'nnm',
    width: 450,
    key: 'nnm',
    render: (_value: any, record: any) => (
      <div style={{ lineHeight: 1 }}>{_value}</div>
    ),
  },
  {
    title: 'ﾛｹｰｼｮﾝ',
    dataIndex: 'location',
    width: 120,
    key: 'location',
    render: (_value: any) => (
      <div style={{ textAlign: 'center' }}>{_value}</div>
    ),
  },
  {
    title: '入荷数',
    width: 120,
    dataIndex: 'suryo',
    key: 'suryo',
    render: (_value: any) => <div style={{ textAlign: 'right' }}>{_value}</div>,
  },
];

const ConfirmModal = ({
  isModalVisible,
  setIsModalVisible,
  data,
  onResetPage,
  onSubmitFailed,
}: Props) => {
  const userInfo = useSelector(selectUserInfo);
  const btnSubmitRef = useRef<any>(null);

  /**
   * *API LogAccess
   * todo log
   * @param {...}
   */
  const { mutate: logAccess } = useLogAccess();
  const { mutate: mutationCreateStockReceive, isLoading } =
    useCreateStockReceive();

  const onSubmit = () => {
    let request = data.map(
      ({
        code24,
        code10,
        nyukaDt,
        siirecd,
        code5,
        suryo,
        kanm,
      }: stockRefillReceiveData) => {
        return {
          code24,
          code10,
          nyukaDt: moment(nyukaDt).format('YYMMDD'),
          siirecd,
          code5,
          suryo,
          kanm,
          chuNo2: '',
        };
      },
    );
    messagePopup({
      type: 'question',
      content: '登録します。よろしいですか?',
      onOk: () => {
        mutationCreateStockReceive(
          { arr: request },
          {
            onSuccess(data, variables, context) {
              // Download file excel
              // let fileName = data.headers['content-disposition'] || 'errorName';
              // const file = new Blob([data?.data], {
              //   type: 'application/octet-stream',
              // });
              // const fileURL = URL.createObjectURL(file);
              // const link = document.createElement('a');
              // link.href = fileURL;

              // link.download = decodeURIComponent(
              //   fileName.split('filename*=')[1].split(';')[0],
              // ).substring(7);
              // link.click();
              logAccess([
                {
                  tokuiCd: userInfo.torihikicd,
                  gyoumuId: '入荷入力',
                  pgName: '新規モード',
                  logType: '2',
                  action: '新規モード',
                },
              ] as any);
              messagePopup({
                type: 'success',
                content: MSG_ERROR['MSG_CREATE_STOCK_REFILL_RECEIVE_SUCCESS'],
                onOk: () => {
                  onResetPage();
                },
              });
            },
            onError: async (error, variables, context) => {
              const errorMsg = JSON.parse(
                await error.response.data.text(),
              )?.message;
              const parsedErrorMsg = JSON.parse(errorMsg);
              if (Array.isArray(parsedErrorMsg)) {
                messagePopup({
                  type: 'info',
                  content: (
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        {'以下の商品は在庫処分数量が現在庫数を超えています。'}
                      </p>
                      {parsedErrorMsg.map(
                        (item: { Code24: string; Zaiko: number }) => {
                          return (
                            <div key={item.Code24}>
                              <span>{`　${item.Code24}（現在庫数：${item.Zaiko}）`}</span>
                              <br />
                            </div>
                          );
                        },
                      )}
                    </div>
                  ),
                  onOk: () => onSubmitFailed(parsedErrorMsg[0].Code24),
                });
              } else {
                messagePopup({
                  type: 'error',
                  content: MSG_ERROR[errorMsg] || errorMsg,
                });
              }
            },
          },
        );
      },
      onCancel: () => btnSubmitRef.current.focus(),
    });
  };
  return (
    <ModalCustom
      titleModal={'入荷入力確認'}
      size="medium"
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      afterClose={() => {}}
      hideButtonClose={true}
    >
      <BodyContent className="order-confirm">
        <FlexColumn>
          <h2>{'内容をご確認ください'}</h2>
          <DetailsOrder style={{ width: 880 }}>
            <Col span={12}>
              <DetailsOrderItem span={24}>
                <Label>仕入先</Label>
                <Content>{`${data[0]?.siirecd} ${data[0]?.siirenm}`}</Content>
              </DetailsOrderItem>
              <DetailsOrderItem span={24}>
                <Label>入荷日</Label>
                <Content>
                  {moment(data[0]?.nyukaDt).format('YYYY/MM/DD (ddd)')}
                </Content>
              </DetailsOrderItem>
            </Col>
          </DetailsOrder>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 20 }}>
          <TableCustom
            rowKey="index"
            height={'230px'}
            dataSource={data || []}
            columns={columns}
          />
        </FlexColumn>
        <FlexEnd style={{ marginTop: 20 }}>
          <Space>
            <Button
              ref={btnSubmitRef}
              autoFocus
              type="primary"
              loading={isLoading}
              onClick={onSubmit}
            >
              登録します
            </Button>
            <Button
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              訂正します（戻る）
            </Button>
          </Space>
        </FlexEnd>
      </BodyContent>
    </ModalCustom>
  );
};

export default memo(ConfirmModal);
