/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Tabs } from 'antd';
import { useGetSiirem } from 'api/siirem';
import LoadingCallAPI from 'components/LoadingCallAPI/LoadingCallAPI';
import { SwitchModeTabs } from 'components/StyleCommon';
import { MSG_ERROR } from 'constants/text';
import React from 'react';
import messagePopup from 'utils/message/mesage';
import InputStockRefillReceiveCreate from 'views/inputStockRefillReceiveCreate';
import InputStockRefillReceiveUpdate from 'views/inputStockRefillReceiveUpdate';
import {
  BodyForm,
  HeaderForm,
  WrapperMainte,
} from 'views/login/FormLogin/formLogin.style';

const StockRefillReceive = () => {
  /**
   * *API GetSiiresakiInfo
   * todo Get Siiresaki info to init dropdown
   * @param
   */
  const {
    data: dataSiirem,
    error: errorSiirem,
    isLoading: loadingSiirem,
    refetch: refetchSiirem,
  } = useGetSiirem();

  React.useEffect(() => {
    refetchSiirem({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Display msg when get Siirem failed
  React.useEffect(() => {
    if (!errorSiirem) {
      return;
    }
    messagePopup({
      type: 'error',
      content:
        MSG_ERROR[(errorSiirem as any)?.response?.data?.message] ||
        (errorSiirem as any)?.response?.data?.message,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSiirem]);

  const [mode, setMode] = React.useState<any>('create');
  const [valuesChange, setValuesChange] = React.useState<boolean>(false);

  // Render label with mode
  //   function labelModeOnChange() {
  //     switch (mode) {
  //       case 'create':
  //         return '登録モード';
  //       case 'update':
  //         return '修正モード';
  //       default:
  //         break;
  //     }
  //   }

  // Render element
  return (
    <WrapperMainte>
      {loadingSiirem && <LoadingCallAPI />}
      <HeaderForm>
        <div></div>
        {/* <TitleMainte>
          <span className="label-mode">入荷入力　モード選択</span>
        </TitleMainte> */}
        <Space>
          <b>モード選択：</b>
          <SwitchModeTabs
            activeKey={mode}
            onChange={key => {
              if (valuesChange) {
                messagePopup({
                  type: 'warning',
                  content: MSG_ERROR['MSG_VALUES_CHANGE'],
                  onOk: () => {
                    setMode(key);
                    setValuesChange(false);
                  },
                });
              } else {
                setMode(key);
              }
            }}
          >
            <Tabs.TabPane tab={'新規モード'} key="create" />
            <Tabs.TabPane tab={'呼出モード'} key="update" />
          </SwitchModeTabs>
        </Space>
      </HeaderForm>
      <BodyForm>
        {mode === 'create' ? (
          <InputStockRefillReceiveCreate
            dataSiirem={dataSiirem}
            setValuesChange={setValuesChange}
          />
        ) : (
          <InputStockRefillReceiveUpdate
            dataSiirem={dataSiirem}
            valuesChange={valuesChange}
            setValuesChange={setValuesChange}
          />
        )}
      </BodyForm>
    </WrapperMainte>
  );
};

export default StockRefillReceive;
