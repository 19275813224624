import styled from 'styled-components';
import { Table } from 'antd';
interface StyleProps {
  height?: string;
  dataSource?: any;
}

// eslint-disable-next-line prettier/prettier
export const CustomTable = styled(Table)<StyleProps>`
  &.virtual-table .ant-table-container:before,
  &.virtual-table .ant-table-container:after {
    display: none;
  }
  &.virtual-table {
    .virtual-table-cell {
      box-sizing: border-box;
      padding: 1px 4px;
      border-left: 0.2px solid #9e9e9e;
      border-bottom: 0.2px solid #9e9e9e;
    }
    .virtual-table-cell-first {
      border-left: none;
    }
    [data-theme='dark'] .virtual-table-cell {
      box-sizing: border-box;
      padding: 8px;
      border: 1px solid #303030;
    }
  }

  .ant-table-footer {
    padding: 0;
  }

  .ant-table-container {
    border-left: 1px solid #9e9e9e;
    border-right: 1px solid #9e9e9e;
    border-bottom: 0.2px solid #9e9e9e;
  }
  .ant-table-thead > tr > th {
    text-align: center;
    border-top: 1px solid #9e9e9e;
    border-left: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
  }
  .ant-table-thead > tr > th:first-child {
    border-left: none;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0px;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    display: none;
  }
  .ant-table-header th {
    background-color: #3b3bcf;
    color: #fff;
    height: 36px;
    text-align: center !important;
    font-size: 14px;
    /* font-weight: 600; */
    padding: 0px 2px;
  }
  .table-row-light td:first-child {
    /* padding: 0px; */
    border-left: 1px solid #fff !important;
  }
  .table-row-dark td:first-child {
    /* padding: 0px; */
    border-left: 1px solid #e9ebfe !important;
  }
  .table-row-light {
    background-color: #eeeeee;
  }
  .table-row-light td:last-child {
    border-right: 1px solid #9e9e9e;
  }
  .table-row-dark {
    background-color: #d0daff;
  }
  .total-jyuryogoukei,
  .total-jyuryogoukei:hover,
  .cnt,
  .cnt:hover {
    background-color: pink !important;
  }
  .new-data,
  .new-data:hover {
    background-color: yellow !important;
  }
  .orirase-future,
  .orirase-future:hover {
    background-color: #fff !important;
  }
  .orirase-present,
  .orirase-present:hover {
    background-color: #d0daff !important;
  }
  .orirase-past,
  .orirase-past:hover {
    background-color: #cccccc !important;
  }
  .table-row-dark td:last-child {
    border-right: 1px solid #9e9e9e;
  }
  .ant-table-cell {
    padding: 0px 2px;
    border-bottom: ${props =>
      props.dataSource?.length > 0 ? '1px solid #9e9e9e' : ''};
    border-left: ${props =>
      props.dataSource?.length > 0 ? '1px solid #9e9e9e' : ''};
  }
  &.table-osirase {
    table {
      border-collapse: collapse;
    }
    .ant-table-cell {
      padding: 0px 2px;
      border: ${props =>
        props.dataSource?.length > 0 ? '1px solid #9e9e9e' : ''};
    }
  }
  .ant-table-expanded-row .ant-table-cell {
    padding: 0px;
  }
  .ant-table-row-expand-icon-cell {
    border: none;
    /* display: none; */
  }
  .ant-table-placeholder {
    height: ${props => props.height ?? '470px'};
    overflow-x: hidden;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #58a8ff;
    border-color: #9e9e9e;
  }
  .ant-table-cell-scrollbar {
    width: 17px;
    box-shadow: none !important;
    // background-color: #e5e5e5 !important;
  }
  .ant-table-body {
    min-height: ${props => props.height ?? 'auto'};
  }
  &.table-order {
    .ant-table-container {
      border-right: none;
      border-bottom: none;
    }
  }
  &.table-websearch.ant-table-wrapper {
    width: max-content;
    max-width: max-content;
    height: ${props => props.height ?? 'auto'};
    //-----Scroll table
    .ant-table-body {
      overflow-y: inherit !important;
      max-height: unset !important;
      min-height: unset;
    }
    .ant-table-cell-scrollbar,
    col:last-child {
      width: 0px !important;
      padding: 0px !important;
    }
    //-----------------
    .ant-table-container {
      border: none !important;
    }
    .ant-checkbox-wrapper {
      display: none;
    }
    .ant-table-selection-column {
      border: none !important;
    }
    .ant-table-header {
      position: sticky;
      top: 0;
      z-index: 9999;
    }
  }
  &.no-color-row {
    .table-row-light,
    .table-row-dark {
      background-color: #eeeeee;
    }
  }
  .ant-table table {
    width: auto;
    .ant-table-row {
      /* &:hover {
        box-shadow: rgba(0, 0, 0) 0px 1px 2px inset,
          rgba(0, 0, 0) 0px 0px 0px 2px inset;
      } */
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // 2 rows ellipsis
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
    line-height: 1em; 
    max-height: 2em; */
  }
`;
export const ContentTable = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
`;
// export const NoDataContent = styled.div`
//   width: 100%;
//   height: 300px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* border: 1px solid #9e9e9e; */
//   border-top: none;
// `;

export const NoDataContent = styled.div.attrs((/* props */) => ({
  tabIndex: 0,
}))`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #9e9e9e; */
  border-top: none;
  &.fromPage {
    height: 450px;
  }
  &.fromModalSearch {
    height: 400px;
  }
`;
