import { Col, Row } from 'antd';
import styled from 'styled-components';
export const HeaderLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
`;

export const DetailsOrder = styled(Row)`
  /* display: grid;
  height: 111px;
  grid-template-columns: 90px auto 90px auto; */
  background-color: #e6edff;
  padding: 10px;
  border-radius: 10px;
  width: 1200px;
`;

export const DetailsOrderItem = styled(Col)`
  display: flex;
  align-items: baseline;
  padding: 0 5px;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  min-width: 150px;
`;

export const Content = styled.div`
  word-break: break-all;
  font-size: 16px;
  &.orderNo {
    font-weight: 500;
    font-size: 18px;
  }
  .new-data {
    background-color: yellow;
    display: block;
  }
`;

export const Address = styled.div`
  display: grid;
  width: 100%;
  height: 85px;
  grid-template-columns: 100px auto;
  padding: 5px 0px;
  border-radius: 10px;
`;

export const BodyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Footer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 10px;
  }
`;
