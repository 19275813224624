import { CheckSquareOutlined, DownSquareOutlined } from '@ant-design/icons';
import { Arrow, images, Logo, Polygon, RadioButton } from 'assets/images/index';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import {
  Col,
  Content,
  Header,
  Left,
  LogoText,
  Main,
  ManualContainer,
  ManualContent,
  ManualSidebar,
  MenuItem,
  MenuList,
  Right,
  Row,
  Section,
  SectionContent,
  SectionRow,
  SectionTitle,
  SpanLink,
  StyledDiv,
  SubContent,
  SubNumber,
  SubText,
  SubTitle,
  Table,
  TableCol,
  TableHeader,
  TableRow,
  TextSpan,
  Title,
  TitleText,
  TopTitle,
} from './manual.style';

const Manual = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuRef = useRef<any>([]);
  const manualRef = useRef<any>({});

  const menuList = [
    {
      id: 1,
      path: '#Hajimeni',
      titleText: 'はじめに',
      onClick: (e: any) => {
        e.preventDefault();
        navigate({
          hash: '#Hajimeni',
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
    {
      id: 2,
      path: '#CalendarMainte',
      titleText: 'カレンダーメンテ',
      onClick: () => {
        navigate({
          hash: '#SyohinMainte',
        });
        manualRef.current['calendarRef'].scrollIntoView();
      },
    },
    {
      id: 3,
      path: '#TorihikisakiMainte',
      titleText: '取引先メンテ',
      onClick: () => {
        navigate({
          hash: '#TorihikisakiMainte',
        });
        manualRef.current['torihikisakiRef'].scrollIntoView();
      },
    },
    {
      id: 4,
      path: '#HanOtodokesakiMainte',
      titleText: '	班・お届け先(便)メンテ',
      onClick: () => {
        navigate({
          hash: '#HanOtodokesakiMainte',
        });
        manualRef.current['hanOtodokesakiRef'].scrollIntoView();
      },
    },
    {
      id: 5,
      path: '#TantouMainte',
      titleText: 'ご注文担当メンテ',
      onClick: () => {
        navigate({
          hash: '#TantouMainte',
        });
        manualRef.current['tantouRef'].scrollIntoView();
      },
    },
    {
      id: 6,
      path: '#OsiraseMainte',
      titleText: 'お知らせメンテ',
      onClick: () => {
        navigate({
          hash: '#OsiraseMainte',
        });
        manualRef.current['osiraseRef'].scrollIntoView();
      },
    },
    {
      id: 7,
      path: '#SyohinMainte',
      titleText: '商品メンテ',
      onClick: () => {
        navigate({
          hash: '#SyohinMainte',
        });
        manualRef.current['syohinRef'].scrollIntoView();
      },
    },
    {
      id: 8,
      path: '#MasterCheck',
      titleText: 'マスタチェック',
      onClick: () => {
        navigate({
          hash: '#MasterCheck',
        });
        manualRef.current['masterCheckRef'].scrollIntoView();
      },
    },
    {
      id: 9,
      path: '#ShippingCorrection',
      titleText: '出荷訂正',
      onClick: () => {
        navigate({
          hash: '#ShippingCorrection',
        });
        manualRef.current['shippingCorrection'].scrollIntoView();
      },
    },
    {
      id: 10,
      path: '#ReturnInput',
      titleText: '返品入力',
      onClick: () => {
        navigate({
          hash: '#ReturnInput',
        });
        manualRef.current['returnInput'].scrollIntoView();
      },
    },
    {
      id: 11,
      path: '#InputStockRefillOrder',
      titleText: '発注入力',
      onClick: () => {
        navigate({
          hash: '#InputStockRefillOrder',
        });
        manualRef.current['inputStockRefillOrder'].scrollIntoView();
      },
    },
    {
      id: 12,
      path: '#CreateStockRefillReceive',
      titleText: '入荷入力',
      onClick: () => {
        navigate({
          hash: '#CreateStockRefillReceive',
        });
        manualRef.current['createStockRefillReceive'].scrollIntoView();
      },
    },
    {
      id: 13,
      path: '#BacklogDeletion',
      titleText: '注残削除処理',
      onClick: () => {
        navigate({
          hash: '#BacklogDeletion',
        });
        manualRef.current['backlogDeletion'].scrollIntoView();
      },
    },
    {
      id: 14,
      path: '#StockInquiry',
      titleText: '在庫問合せ',
      onClick: () => {
        navigate({
          hash: '#StockInquiry',
        });
        manualRef.current['stockInquiry'].scrollIntoView();
      },
    },
    {
      id: 15,
      path: '#InventoryList',
      titleText: '棚卸用一覧リスト',
      onClick: () => {
        navigate({
          hash: '#InventoryList',
        });
        manualRef.current['inventoryList'].scrollIntoView();
      },
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      if (location.hash === '#Hajimeni') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (location.hash === '#CalendarMainte') {
        manualRef.current['calendarRef'].scrollIntoView();
      } else if (location.hash === '#TorihikisakiMainte') {
        manualRef.current['torihikisakiRef'].scrollIntoView();
      } else if (location.hash === '#HanOtodokesakiMainte') {
        manualRef.current['hanOtodokesakiRef'].scrollIntoView();
      } else if (location.hash === '#TantouMainte') {
        manualRef.current['tantouRef'].scrollIntoView();
      } else if (location.hash === '#OsiraseMainte') {
        manualRef.current['osiraseRef'].scrollIntoView();
      } else if (location.hash === '#SyohinMainte') {
        manualRef.current['syohinRef'].scrollIntoView();
      } else if (location.hash === '#MasterCheck') {
        manualRef.current['masterCheckRef'].scrollIntoView();
      } else if (location.hash === '#ShippingCorrection') {
        manualRef.current['shippingCorrection'].scrollIntoView();
      } else if (location.hash === '#ReturnInput') {
        manualRef.current['returnInput'].scrollIntoView();
      } else if (location.hash === '#InputStockRefillOrder') {
        manualRef.current['inputStockRefillOrder'].scrollIntoView();
      } else if (location.hash === '#CreateStockRefillReceive') {
        manualRef.current['createStockRefillReceive'].scrollIntoView();
      } else if (location.hash === '#BacklogDeletion') {
        manualRef.current['backlogDeletion'].scrollIntoView();
      } else if (location.hash === '#StockInquiry') {
        manualRef.current['stockInquiry'].scrollIntoView();
      } else if (location.hash === '#InventoryList') {
        manualRef.current['inventoryList'].scrollIntoView();
      }
    }, 500);

    const handleScroll = (e: any) => {
      if (window.scrollY <= 0 && window.scrollY <= window.innerHeight / 2) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[0]?.classList.add('active');
      } else if (
        manualRef.current['calendarRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['torihikisakiRef'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[1]?.classList.add('active');
      } else if (
        manualRef.current['torihikisakiRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['hanOtodokesakiRef'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[2]?.classList.add('active');
      } else if (
        manualRef.current['hanOtodokesakiRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['tantouRef'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[3]?.classList.add('active');
      } else if (
        manualRef.current['tantouRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['osiraseRef'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[4]?.classList.add('active');
      } else if (
        manualRef.current['osiraseRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['syohinRef'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[5]?.classList.add('active');
      } else if (
        manualRef.current['syohinRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['masterCheckRef'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[6]?.classList.add('active');
      } else if (
        manualRef.current['masterCheckRef'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['shippingCorrection'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[7]?.classList.add('active');
      } else if (
        manualRef.current['shippingCorrection'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['returnInput'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[8]?.classList.add('active');
      } else if (
        manualRef.current['returnInput'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['inputStockRefillOrder'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[9]?.classList.add('active');
      } else if (
        manualRef.current['inputStockRefillOrder'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['createStockRefillReceive'].offsetTop -
          window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[10]?.classList.add('active');
      } else if (
        manualRef.current['createStockRefillReceive'].offsetTop -
          window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['backlogDeletion'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[11]?.classList.add('active');
      } else if (
        manualRef.current['backlogDeletion'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['stockInquiry'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[12]?.classList.add('active');
      } else if (
        manualRef.current['stockInquiry'].offsetTop - window.scrollY <
          window.innerHeight / 2 &&
        manualRef.current['inventoryList'].offsetTop - window.scrollY >=
          window.innerHeight / 2
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[13]?.classList.add('active');
      } else if (
        manualRef.current['inventoryList'].offsetTop - window.scrollY <
        window.innerHeight
      ) {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
        menuRef.current[14]?.classList.add('active');
      } else {
        menuRef.current.forEach((e: any) => {
          e.classList.remove('active');
        });
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManualContainer>
      <ManualSidebar>
        <Header className="logo">
          <LogoText>
            <span>目次</span>
            <span>（ＹＨＫ機材メニュー）</span>
          </LogoText>
        </Header>
        <MenuList>
          {menuList.map((menu, idx) => (
            <MenuItem key={menu.id}>
              {menu.path === '#Hajimeni' ? (
                <button
                  ref={el => (menuRef.current[idx] = el)}
                  onClick={menu.onClick}
                  className="active"
                >
                  <Logo />
                  <TitleText>{menu.titleText}</TitleText>
                </button>
              ) : (
                <button
                  ref={el => (menuRef.current[idx] = el)}
                  onClick={menu.onClick}
                >
                  <div className="round">{menu.id - 1}</div>
                  <TitleText>{menu.titleText}</TitleText>
                </button>
              )}
            </MenuItem>
          ))}

          <MenuItem className="separate">
            <div className="separate-btn" onClick={() => window.close()}>
              <Arrow />
              <TitleText>閉じる</TitleText>
            </div>
          </MenuItem>
        </MenuList>
      </ManualSidebar>

      <ManualContent>
        <Section
          ref={el => (manualRef.current['hajimeniRef'] = el)}
          id="Hajimeni"
        >
          <TopTitle>
            <Logo />
            <SubText>はじめに</SubText>
          </TopTitle>
          <SubTitle>■動作環境■</SubTitle>
          <Content>
            <Row className="border">
              <Col className="left">OS</Col>
              <Col className="right">
                Microsoft Windows 10, Microsoft Windows 11
              </Col>
            </Row>
            <Row>
              <Col className="left">ブラウザ</Col>
              <Col className="right">
                Microsoft Edge
                <br /> Google Chrome
                <br /> Safari
              </Col>
            </Row>
          </Content>
        </Section>
        <Section
          ref={el => (manualRef.current['calendarRef'] = el)}
          id="CalendarMainte"
          className="section"
        >
          <TopTitle className="rounded">
            <SubNumber>1</SubNumber>
            <SubText>カレンダーメンテ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">
              納期算出に使用するカレンダーの設定を行います。
            </span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">カレンダーメンテ画面</span>
            </Title>
            <Main>
              <img className="left" width={700} src={images.image1} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>登録／更新</TextSpan>
                  </TableCol>
                  <TableCol>登録／更新モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>参照</TextSpan>
                  </TableCol>
                  <TableCol>参照モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      ｶﾚﾝﾀﾞｰ表示
                    </TextSpan>
                  </TableCol>
                  <TableCol>カレンダー画面表示</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle>■操作方法■</SectionTitle>
              <SectionRow>
                <Left>
                  <p className="text-left-color">
                    対象年月を入力し、カレンダー表示を行います。
                  </p>
                </Left>
              </SectionRow>
              <SectionRow className="container">
                <Left>・１．対象年月</Left>
                <Right>
                  <span>
                    表示させたい年月の入力を行います。（西暦４桁月２桁)
                    <br />
                    例：200803
                  </span>
                </Right>
              </SectionRow>

              <SectionRow className="container">
                <Left>・カレンダー表示</Left>
                <Right>
                  <p>
                    <TextSpan className="primary" code>
                      ｶﾚﾝﾀﾞｰ表示
                    </TextSpan>
                    をクリックし、カレンダー画面を表示します。
                  </p>
                </Right>
              </SectionRow>

              <StyledDiv style={{ marginBottom: '24px' }}>
                <Title>
                  <Polygon />
                  <span className="text">カレンダー画面</span>
                </Title>
                <Main>
                  <img
                    className="left"
                    width={700}
                    src={images.image2}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>登録／更新</TextSpan>
                      </TableCol>
                      <TableCol>登録／更新モード切替</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>参照</TextSpan>
                      </TableCol>
                      <TableCol>参照モード切替</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>印刷</TextSpan>
                      </TableCol>
                      <TableCol>画面印刷</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                      </TableCol>
                      <TableCol>入力内容確定</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>画面クリア</TextSpan>
                      </TableCol>
                      <TableCol>入力内容クリア</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>メニューへ戻る</TextSpan>
                      </TableCol>
                      <TableCol>処理終了</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <StyledDiv style={{ marginBottom: '24px' }}>
                  <SectionRow>
                    <span className="text-title">
                      各種カレンダーの設定を行います。
                    </span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・２．YHK営業日</Left>
                    <Right>
                      <span>
                        YHK営業日である場合にチェック
                        <span className="text-left-color">
                          <CheckSquareOutlined />
                        </span>
                        します。
                      </span>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・３．荷積日（キャプティ）</Left>
                    <Right>
                      <span>
                        荷積可能（キャプティ）である場合にチェック
                        <span className="text-left-color">
                          <CheckSquareOutlined />
                        </span>
                        します。
                      </span>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・４．荷積日（共同倉庫）</Left>
                    <Right>
                      <span>
                        荷積可能（共同倉庫）である場合にチェック
                        <span className="text-left-color">
                          <CheckSquareOutlined />
                        </span>
                        します。
                      </span>
                    </Right>
                  </SectionRow>

                  <SectionRow className="container">
                    <Left>・５．ガスメーター</Left>
                    <Right>
                      <p>
                        画面の日付で注文頂いた場合のガスメーターの配送、引取日を入力します。
                        <br />
                        入力された日付が画面の日付より小さい日付である場合は、翌月の日付として登録されます。
                        <br />
                        例）3月24日(月)の欄に7と入力した場合、翌月の4月7日の意味になります。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・入力内容の確定</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、入力内容を確定します。
                      </p>
                    </Right>
                  </SectionRow>
                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['hanOtodokesakiRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['torihikisakiRef'] = el)}
          id="TorihikisakiMainte"
        >
          <TopTitle className="rounded">
            <SubNumber>2</SubNumber>
            <SubText>取引先メンテ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">取引先の設定を行います。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">取引先メンテ画面</span>
            </Title>
            <Main>
              <img className="left" width={700} src={images.image3} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>登録</TextSpan>
                  </TableCol>
                  <TableCol>登録モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>修正</TextSpan>
                  </TableCol>
                  <TableCol>修正モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>削除</TextSpan>
                  </TableCol>
                  <TableCol>削除モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>取引先検索</TextSpan>
                  </TableCol>
                  <TableCol>
                    取引先検索画面表示 <br />
                    修正・削除時使用可
                  </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">取引先を登録する</SectionRow>
                <SectionRow className="container">
                  <Left>・モード選択</Left>
                  <Right>
                    登録モードにします。
                    <p>
                      <TextSpan code>登録</TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１．取引先コード</Left>
                  <Right>取引先コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        取引先コードの先頭を 11111
                        とした場合、その取引先はデモ用となります。
                      </span>
                      <span className="text">
                        デモ用取引でもご注文や履歴照会などの操作は可能ですが、実際の注文とはなりません。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２．取引先名</Left>
                  <Right>取引先名を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・３．支部/部・課</Left>
                  <Right>店名を入力します。（任意入力）</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・４．取引先名かな</Left>
                  <Right>取引先名かなを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・５．事業部署</Left>
                  <Right>
                    事業部署を入力します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・６．取引先区分</Left>
                  <Right>
                    取引先区分を入力します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・７．郵便番号</Left>
                  <Right> 郵便番号を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・８．事務所（連絡先）住所</Left>
                  <Right>住所を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・９．電話番号</Left>
                  <Right>電話番号を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１０．FAX番号</Left>
                  <Right>FAX番号を入力します。（任意入力）</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１１．ｷｬﾌﾟﾃｨ取引先ｺｰﾄﾞ</Left>
                  <Right>ｷｬﾌﾟﾃｨ管理の取引先ｺｰﾄﾞを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１２．ユーザーID</Left>
                  <Right>
                    ユーザーIDを入力します。　※ログイン画面で入力するユーザーＩＤになります。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１３・パスワード</Left>
                  <Right>
                    パスワードを入力します。　※ログイン画面で入力するパスワードになります。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１４．パスワード確認</Left>
                  <Right>
                    確認の為に上記と同じパスワードを再度入力します。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入力内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan code>検索</TextSpan>
                      ボタンをクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <div className="right">
                  <SpanLink
                    onClick={() =>
                      manualRef.current['torihikisakiRef'].scrollIntoView()
                    }
                  >
                    章の先頭に戻る
                  </SpanLink>
                </div>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">取引先を修正する</SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      修正モードにします。
                      <p>
                        <TextSpan code>修正</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・修正内容表示</Left>
                    <Right>
                      修正する[１．取引先コード]を入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                      <p style={{ marginBottom: '0px' }}>
                        登録内容が表示されますので、修正項目の修正を行います。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          取引先コードの修正を行うことはできません。登録されている内容を
                        </span>
                        <span className="text">
                          一旦削除し、再度登録してください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・パスワード変更</Left>
                    <Right>
                      <span>
                        <span className="text-left-color">
                          <CheckSquareOutlined />
                        </span>
                        チェックボタンの操作により、パスワードの変更が行えます。
                      </span>
                      <p>
                        パスワード、パスワード確認に新しいパスワードを入力します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・修正内容確定</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、入力内容を確定します。
                      </p>
                    </Right>
                  </SectionRow>
                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['torihikisakiRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">取引先を削除する</SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      削除モードにします。
                      <p>
                        <TextSpan code>削除</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・削除内容表示</Left>
                    <Right>
                      削除する[１．取引先コード]を入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                      <p>登録内容が表示されますので、削除内容を確認します。</p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・削除実行</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、登録内容を削除します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          取引先コードの先頭を 11111
                          とした場合、その取引先はデモ用となります。
                        </span>
                        <span className="text">
                          削除したデータは元に戻せませんので、注意して行ってください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>
                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['torihikisakiRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['hanOtodokesakiRef'] = el)}
          id="HanOtodokesakiMainte"
        >
          <TopTitle className="rounded">
            <SubNumber>3</SubNumber>
            <SubText>班・お届け先(便)メンテ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">
              取引先の班・お届け先・便を設定します。
              <br />
              ※事前に取引先メンテでの登録が必要です。
            </span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">班・お届け先（便）メンテ画面</span>
            </Title>
            <Main>
              <img className="left" width={700} src={images.image4} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>登録／更新</TextSpan>
                  </TableCol>
                  <TableCol>登録／修正モード切替</TableCol>
                </TableRow>
                {/* <TableRow>
                  <TableCol>
                    <TextSpan code>削除</TextSpan>
                  </TableCol>
                  <TableCol>削除モード切替</TableCol>
                </TableRow> */}
                <TableRow>
                  <TableCol>
                    <TextSpan code>取引先検索</TextSpan>
                  </TableCol>
                  <TableCol>取引先検索画面表示</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      班削除
                    </TextSpan>
                  </TableCol>
                  <TableCol>班コードの削除</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>班復活</TextSpan>
                  </TableCol>
                  <TableCol>班コードの復活</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      お届け先削除
                    </TextSpan>
                  </TableCol>
                  <TableCol>お届け先の削除</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>お届け先復活</TextSpan>
                  </TableCol>
                  <TableCol>お届け先の復活</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  班・お届け先（便）を登録／更新する
                </SectionRow>
                <SectionRow className="container">
                  <Left>・モード選択</Left>
                  <Right>
                    登録／更新モードにします。
                    <p>
                      <TextSpan code>登録／更新</TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１．取引先コード</Left>
                  <Right>
                    [取引先コード]を入力、または取引先検索画面より選択します。
                    <p>
                      <TextSpan code>取引先検索</TextSpan>
                      をクリックすると、検索画面が表示されます。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２．班コード</Left>
                  <Right>班コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・３．班名</Left>
                  <Right>班名を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・４．所属</Left>
                  <Right>所属を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・５．引取用便</Left>
                  <Right>
                    東雲引取の場合に使用する便コードを選択します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・6．旧班コード</Left>
                  <Right>旧班コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <span className="text-title">
                    お届け先A～Dを設定します。　※お届け先がなければ入力の必要はありません。
                  </span>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・７．お届け先コード</Left>
                  <Right>Ａ～Ｄが設定されています。入力は行えません。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・８．お届け先名</Left>
                  <Right> お届け先名を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・９．郵便番号</Left>
                  <Right>郵便番号を入力します。（任意入力）</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１０。住所</Left>
                  <Right>住所を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１１．電話番号 </Left>
                  <Right>電話番号を入力します。（任意入力）</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１２．FAX番号</Left>
                  <Right>FAX番号を入力します。（任意入力）</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１３．便</Left>
                  <Right>
                    便を入力します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１４．旧班コード</Left>
                  <Right>キャプティ管理の班コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入力内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、入力内容を確定します。
                    </p>
                  </Right>
                </SectionRow>
                <div className="right">
                  <SpanLink
                    onClick={() =>
                      manualRef.current['hanOtodokesakiRef'].scrollIntoView()
                    }
                  >
                    章の先頭に戻る
                  </SpanLink>
                </div>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="container">
                    <span className="text-title">
                      班・お届け先(便)を復活する
                    </span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．取引先コード</Left>
                    <Right>
                      [取引先コード]を入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・２．班コード</Left>
                    <Right>班コードを入力します。</Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">この班は削除済みです。</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・復活実行</Left>
                    <Right>
                      <p style={{ marginBottom: '3px' }}>
                        <TextSpan code>班復活</TextSpan>
                        をクリックして、削除された班コードを復活します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <p style={{ marginBottom: '3px' }}>
                        <TextSpan code>お届け先復活</TextSpan>
                        をクリックして、削除されたお届け先を復活します。
                      </p>
                    </Right>
                  </SectionRow>
                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['hanOtodokesakiRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">
                    班・お届け先（便）を削除する
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      削除モードにします。
                      <p>
                        <TextSpan code>削除</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．取引先コード</Left>
                    <Right>
                      [取引先コード]を入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・２．班</Left>
                    <Right>
                      班を入力します。 <br />
                      <span>
                        <DownSquareOutlined />
                        をクリックして、一覧から選択します。
                      </span>
                      <p style={{ marginBottom: '0px' }}>
                        登録内容が表示されますので、削除内容を確認します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">お届け先のみ削除する場合</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・お届け先削除実行</Left>
                    <Right>
                      <p>
                        お届け先Ａ～Ｄの右に
                        <TextSpan code>お届け先削除</TextSpan>
                        があります。
                      </p>
                      <p style={{ marginBottom: '3px' }}>
                        削除するお届け先の右の
                        <TextSpan code>お届け先削除</TextSpan>
                        をクリックし、お届け先を削除します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">
                      班・お届け先を削除する場合　※全お届け先・班が削除されます。
                    </span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          削除したデータは元に戻せませんので、注意して行ってください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・削除実行</Left>
                    <Right>
                      <p style={{ marginBottom: '3px' }}>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、登録内容を削除します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          削除したデータは元に戻せませんので、注意して行ってください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>
                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['hanOtodokesakiRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['tantouRef'] = el)}
          id="TantouMainte"
        >
          <TopTitle className="rounded">
            <SubNumber>4</SubNumber>
            <SubText>ご注文担当メンテ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">
              取引先の担当者を設定します。
              <br />
              事前に取引先メンテでの登録が必要です。
            </span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">ご注文担当メンテ画面</span>
            </Title>
            <Main>
              <img className="left" width={700} src={images.image5} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>登録モード</TextSpan>
                  </TableCol>
                  <TableCol>登録モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>修正モード</TextSpan>
                  </TableCol>
                  <TableCol>修正モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>削除モード</TextSpan>
                  </TableCol>
                  <TableCol>削除モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>取引先検索</TextSpan>
                  </TableCol>
                  <TableCol>取引先検索画面表示 </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>復活</TextSpan>
                  </TableCol>
                  <TableCol>担当者の復活</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  ご注文担当者を登録する
                </SectionRow>
                <SectionRow className="container">
                  <Left>・モード選択</Left>
                  <Right>
                    登録モードにします。
                    <p>
                      <TextSpan code>登録</TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１．取引先コード</Left>
                  <Right>
                    [取引先コード]を入力、または取引先検索画面より選択します。
                    <p>
                      <TextSpan code>取引先検索</TextSpan>
                      をクリックすると、検索画面が表示されます。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２．担当者コード</Left>
                  <Right>担当者コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・３．担当者名</Left>
                  <Right>担当者名を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入力内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、入力内容を確定します。
                    </p>
                  </Right>
                </SectionRow>
                <div className="right">
                  <SpanLink
                    onClick={() =>
                      manualRef.current['tantouRef'].scrollIntoView()
                    }
                  >
                    章の先頭に戻る
                  </SpanLink>
                </div>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">
                    ご注文担当者を修正する
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      修正モードにします。
                      <p>
                        <TextSpan code>修正</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">修正内容を表示します</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．取引先コード</Left>
                    <Right>
                      [取引先コード]を入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・２．担当者</Left>
                    <Right>
                      担当者を入力します。 <br />
                      <span>
                        <DownSquareOutlined />
                        をクリックして、一覧から選択します。
                      </span>
                      <p style={{ marginBottom: '0px' }}>
                        登録内容が表示されますので、「３．担当者名」の修正を行います。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          担当者コードの修正を行うことはできません。登録されている内容を
                        </span>
                        <span className="text">
                          一旦削除し、再度登録してください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・修正内容確定</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、入力内容を確定します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">ご担当を復活する</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．取引先コード</Left>
                    <Right>
                      [取引先コード]を入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・２．担当者</Left>
                    <Right>
                      <span> 復活したい担当者を選択します。</span>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・復活確認</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          復活
                        </TextSpan>
                        をクリックして、削除されたご担当を復活します。
                      </p>
                    </Right>
                  </SectionRow>

                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['tantouRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">
                    ご注文担当者を削除する
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      削除モードにします。
                      <p>
                        <TextSpan code>削除</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">削除内容を表示します</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．取引先コード</Left>
                    <Right>
                      取引先コードを入力、または取引先検索画面より選択します。
                      <p>
                        <TextSpan code>取引先検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・２．担当者</Left>
                    <Right>
                      担当者を入力します。 <br />
                      <span>
                        <DownSquareOutlined />
                        をクリックして、一覧から選択します。
                      </span>
                      <p style={{ marginBottom: '0px' }}>
                        登録内容が表示されますので、「３．担当者名」の修正を行います。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・削除実行</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、登録内容を削除します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          削除したデータは元に戻せませんので、注意して行ってください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>

                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['tantouRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['osiraseRef'] = el)}
          id="OsiraseMainte"
        >
          <TopTitle className="rounded">
            <SubNumber>5</SubNumber>
            <SubText>お知らせメンテ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">
              メニュー画面に表示されるお知らせ情報を設定します
            </span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">お知らせメンテ画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.osiraseMain}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      表示順保存
                    </TextSpan>
                  </TableCol>
                  <TableCol>表示順変更の確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>新規</TextSpan>
                  </TableCol>
                  <TableCol>お知らせの作成</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>修正</TextSpan>
                  </TableCol>
                  <TableCol>お知らせの修正</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>複写</TextSpan>
                  </TableCol>
                  <TableCol>お知らせの複写</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>削除</TextSpan>
                  </TableCol>
                  <TableCol>お知らせの削除</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・お知らせの表示順</Left>
                  <Right>
                    <p>
                      <TextSpan code>
                        <MenuOutlined />
                      </TextSpan>
                      をドラッグとドロップして、表示順を変更可能です。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・表示順の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックして、表示順を確定します。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">お知らせメンテ画面</span>
            </Title>
            <Main>
              <img className="left" src={images.image6} width={700} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確定{' '}
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・１．タイトル</Left>
                  <Right>タイトルを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２．掲載期間</Left>
                  <Right>掲載期間を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・３．掲載対象</Left>
                  <Right>
                    事業部署を入力します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・４．お知らせ内容の編集を行います。</Left>
                  <Right>
                    ※改行する場合は[Shift]キーを押しながら、[Enter]キーを押してください。
                    <br />
                    ※画面[HTML]をクリックした場合は、[デザイン]をクリックしてください。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・５．添付ファイル</Left>
                  <Right>ファイルをアップロードする。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入力内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、入力内容を確定します。
                    </p>
                  </Right>
                </SectionRow>
                {/* <div className="right">
                  <SpanLink
                    onClick={() =>
                      manualRef.current['osiraseRef'].scrollIntoView()
                    }
                  >
                    章の先頭に戻る
                  </SpanLink>
                </div> */}
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['syohinRef'] = el)}
          id="SyohinMainte"
        >
          <TopTitle className="rounded">
            <SubNumber>6</SubNumber>
            <SubText>商品メンテ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">商品情報を設定します。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">商品メンテ画面</span>
            </Title>
            <Main>
              <img className="left" src={images.image7} width={700} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>登録モード</TextSpan>
                  </TableCol>
                  <TableCol>登録モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>修正モード</TextSpan>
                  </TableCol>
                  <TableCol>修正モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>削除モード</TextSpan>
                  </TableCol>
                  <TableCol>削除モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>商品検索</TextSpan>
                  </TableCol>
                  <TableCol>
                    商品検索画面表示
                    <br />
                    修正・削除時使用可
                  </TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">商品情報を登録する</SectionRow>
                <SectionRow className="container">
                  <Left>・モード選択</Left>
                  <Right>
                    登録モードにします。
                    <p>
                      <TextSpan code>登録</TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１．品名コード</Left>
                  <Right>品名コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２．品名</Left>
                  <Right>品名を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・３．規格</Left>
                  <Right>規格を入力します。（任意入力）</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・４．品名かな</Left>
                  <Right>品名カナを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・５．入数</Left>
                  <Right>入数を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・６．重量</Left>
                  <Right>重量を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・７．手配書</Left>
                  <Right>
                    <span>
                      要手配商品の場合は
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      チェックを付けます。（任意入力）
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・８．販売単価</Left>
                  <Right>販売単価を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・９．仕入先</Left>
                  <Right>
                    仕入先を入力します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１０．リードタイム</Left>
                  <Right>
                    リードタイムを入力します。　※通常、１を入力します。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１１．入荷調整日</Left>
                  <Right>入荷調整日を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１２．換算あり</Left>
                  <Right>
                    <span>
                      換算が必要な場合は
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      チェックを付けます。（任意入力）
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１３．換算係数</Left>
                  <Right>
                    長さを入力します。
                    <br />
                    ※換算が必要の場合、入力します。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１４．商品区分</Left>
                  <Right>
                    商品区分を入力します。
                    <br />
                    <span>
                      <DownSquareOutlined />
                      をクリックして、一覧から選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１５．在庫区分</Left>
                  <Right>
                    <span>
                      在庫区分を選択
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１６．ピッキングシート出力条件</Left>
                  <Right>
                    <span>
                      出力条件を選択
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      、入力します。
                    </span>
                    <p>１品／、１品×の場合は半角の数字を入力します。</p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１７．ロケーション</Left>
                  <Right>ロケーションコードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１８．最大在庫数</Left>
                  <Right>最大在庫数を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・１９．発注点</Left>
                  <Right>発注点を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２０．発注単位</Left>
                  <Right>発注点を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２１．最低注文単位</Left>
                  <Right>最低注文単位を入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２２。注文受付許可</Left>
                  <Right>
                    注文を許可
                    <br />
                    <span>
                      許可の場合は
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      チェックを付けます。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２３．異常発注警告数</Left>
                  <Right>
                    異常発注警告数を入力します。
                    <br />
                    ※ご注文画面の数量入力で指定された数量以上の入力で警告表示されます。
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２４．仕様ファイル</Left>
                  <Right>
                    <span>
                      商品仕様ファイルありの場合は{' '}
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      チェックを付けます。（任意入力
                    </span>
                    ※サーバーへファイルをアップロードして下さい。
                    <br />
                    YHK\Internet\SyohinDataフォルダに品名コードの付いたファイルを配置します。
                    <br />
                    例）品名コードが109395の場合、109395.pdf など
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２５．ＨＡＴ商品コード</Left>
                  <Right>
                    ＨＡＴ商品コードを入力します。
                    <br />
                    仕入先が「橋本総業」の場合、必須入力
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２６．キャプティ品名コード</Left>
                  <Right>キャプティ管理の品名コードを入力します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・２７．フラグ立て</Left>
                  <Right>
                    <span>
                      フラグ立て(廃番)の場合は
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      チェックを付けます。（任意入力）
                    </span>
                    <p>
                      ※廃番にした場合、ご注文画面では商品がない場合と同じメッセージを表示し、入力を受け付けません。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入力内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、入力内容を確定します。
                    </p>
                  </Right>
                </SectionRow>
                <div className="right">
                  <SpanLink
                    onClick={() =>
                      manualRef.current['syohinRef'].scrollIntoView()
                    }
                  >
                    章の先頭に戻る
                  </SpanLink>
                </div>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">商品情報を修正する</SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      修正モードにします。
                      <p>
                        <TextSpan code>修正</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">修正内容を表示します</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．品名コード</Left>
                    <Right>
                      品名コードを入力、または商品検索画面より選択します。
                      <p>
                        <TextSpan code>商品検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                      登録内容が表示されますので、修正項目の修正を行います。
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          品名コードの修正を行うことはできません。登録されている内容を
                        </span>
                        <span className="text">
                          一旦削除し、再度登録してください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・修正内容確定</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、入力内容を確定します。
                      </p>
                    </Right>
                  </SectionRow>
                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['syohinRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
                <StyledDiv style={{ marginTop: '24px' }}>
                  <SectionRow className="round">商品情報を削除する</SectionRow>
                  <SectionRow className="container">
                    <Left>・モード選択</Left>
                    <Right>
                      削除モードにします。
                      <p>
                        <TextSpan code>削除</TextSpan>
                        をクリックします。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <span className="text-title">削除内容を表示します</span>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left>・１．品名コード</Left>
                    <Right>
                      品名コードを入力、または商品検索画面より選択します。
                      <p>
                        <TextSpan code>商品検索</TextSpan>
                        をクリックすると、検索画面が表示されます。
                      </p>
                      登録内容が表示されますので、削除内容を確認します。
                    </Right>
                  </SectionRow>

                  <SectionRow className="container">
                    <Left>・削除実行</Left>
                    <Right>
                      <p>
                        <TextSpan className="primary" code>
                          確定
                        </TextSpan>
                        をクリックし、登録内容を削除します。
                      </p>
                    </Right>
                  </SectionRow>
                  <SectionRow className="container">
                    <Left></Left>
                    <Right>
                      <div className="flex">
                        <span className="red">（注）</span>
                        <span className="text">
                          削除したデータは元に戻せませんので、注意して行ってください。
                        </span>
                      </div>
                    </Right>
                  </SectionRow>

                  <div className="right">
                    <SpanLink
                      onClick={() =>
                        manualRef.current['syohinRef'].scrollIntoView()
                      }
                    >
                      章の先頭に戻る
                    </SpanLink>
                  </div>
                </StyledDiv>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['masterCheckRef'] = el)}
          id="MasterCheck"
        >
          <TopTitle className="rounded">
            <SubNumber>7</SubNumber>
            <SubText>マスタチェック</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">マスタのチェックを行います。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">マスタチェック画面</span>
            </Title>
            <Main>
              <img className="left" src={images.image8} width={700} alt="" />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      ﾏｽﾀﾁｪｯｸ開始
                    </TextSpan>
                  </TableCol>
                  <TableCol>ﾏｽﾀﾁｪｯｸを開始</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>印刷</TextSpan>
                  </TableCol>
                  <TableCol>画面印刷</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・マスタチェック開始</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        ﾏｽﾀﾁｪｯｸ開始
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        {/* Module 3 */}
        <Section
          ref={el => (manualRef.current['shippingCorrection'] = el)}
          id="ShippingCorrection"
        >
          <TopTitle className="rounded">
            <SubNumber>8</SubNumber>
            <SubText>出荷訂正</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">
              出荷伝票の出力後に出荷データの変更を行う。
            </span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">出荷訂正画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.shippingCorrection}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      参 照
                    </TextSpan>
                  </TableCol>
                  <TableCol>注文情報の表示</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      呼出し
                    </TextSpan>
                  </TableCol>
                  <TableCol>伝票明細の表示</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      伝票再発行
                    </TextSpan>
                  </TableCol>
                  <TableCol>伝票を再発行</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      在庫出荷に変更
                    </TextSpan>
                  </TableCol>
                  <TableCol>在庫出荷変更を実行</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確認画面へ
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・受付Ｎｏ</Left>
                  <Right>
                    <span>受付Ｎｏを入力します。</span>
                    <span>受付№を入れると発注書控えと同様の画面が出て</span>
                    <span>
                      【行番】を選択すると該当の内部№、伝票区分、伝票№がセットされます。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内部Ｎｏ</Left>
                  <Right>
                    <span>内部Ｎｏを入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・伝票区分</Left>
                  <Right>
                    <span>伝票区分を入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・伝票№</Left>
                  <Right>
                    <span>伝票№を入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷日</Left>
                  <Right>
                    <span>入荷日を変更します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        伝票区分 ’15’ または ’21’の入力可能 伝票区分
                        ‘21’の時入荷日 = 納入日 とします。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・納入日</Left>
                  <Right>
                    <span>納入日を変更します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        伝票区分 ‘21’の時 入荷日 = 納入日 とします。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・お届け先</Left>
                  <Right>
                    <span>お届け先の変更</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・便</Left>
                  <Right>
                    <span>便の変更</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・伝票削除</Left>
                  <Right>
                    <span>伝票全体を削除する場合は　D　を入力してください</span>
                    <span>
                      伝票削除に D を入れると変更数量に全て　0 が入ります。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・変更数量</Left>
                  <Right>
                    <span>変更数量を入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        変更箇所だけに変更数量を入力します。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・在庫出荷変更の実行</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        在庫出荷に変更
                      </TextSpan>{' '}
                      をクリックし、伝票区分は’11’に変更します。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確認画面へ
                      </TextSpan>{' '}
                      をクリックし、指定されたデータを確定します。
                    </p>
                  </Right>
                </SectionRow>
                {/* Modal shipping correction */}
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">
                    出荷訂正確認画面 （出荷情報訂正の場合）
                  </span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalConfirmShippingCorrection}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>出荷情報を訂正</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・出荷情報訂正の確認</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">
                    出荷訂正確認画面 （在庫出荷変更の場合）
                  </span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalChangeShippingCorrection}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>在庫出荷を変更</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・在庫出荷の確認</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">
                    出荷訂正確認画面 （伝票再発行の場合）
                  </span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalReprintShippingCorrection}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>伝票再発行を実行</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・伝票再発行の確認</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['returnInput'] = el)}
          id="ReturnInput"
        >
          <TopTitle className="rounded">
            <SubNumber>9</SubNumber>
            <SubText>返品入力</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">返品または庫処分返品を実行します。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">返品入力画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.returnInput}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>取引先検索</TextSpan>
                  </TableCol>
                  <TableCol>取引先検索画面表示</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確 定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了 </TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・伝票区分</Left>
                  <Right>
                    <span>伝票区分を入力します。</span>
                    <span>固定値は「32 返品」にします。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・扱便</Left>
                  <Right>
                    <span>扱便を入力します。</span>
                    <span>固定値は「903 返品入荷」にします。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・得意先</Left>
                  <Right>
                    得意先を入力、または取引先検索画面より選択します。
                    <p>
                      <TextSpan code>取引先検索</TextSpan>
                      をクリックすると、検索画面が表示されます。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷日</Left>
                  <Right>
                    <span>入荷日を入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・処理区分</Left>
                  <Right>
                    <span>
                      処理区分
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      を選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・売区</Left>
                  <Right>
                    <span>
                      売区
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      を選択します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・品名CD</Left>
                  <Right>
                    <span>
                      品名コードを入力すると品名を取得して表示 します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        エクセルからコードして、貼り付けすることが出 来ます。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・返品数</Left>
                  <Right>
                    <span>返品数を入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        在庫処分数量は現在庫数より大きく入力する事が
                        できません。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確 定
                      </TextSpan>
                      をクリックし、指定されたデータを確定します。
                    </p>
                  </Right>
                </SectionRow>
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">返品入力確認画面</span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalReturnInput}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>返品実行</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・返品の確認</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        {/* Module 4 */}
        <Section
          ref={el => (manualRef.current['inputStockRefillOrder'] = el)}
          id="InputStockRefillOrder"
        >
          <TopTitle className="rounded">
            <SubNumber>10</SubNumber>
            <SubText>発注入力</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">
              発注勧告リストによる発注入力を行います。
            </span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">発注入力画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.inputStockRefillOrder}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確 定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・登録番号</Left>
                  <Right>
                    <span>
                      発注勧告リストを見て登録番号入力し発注テーブルより発注明細を呼び出す。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・削除</Left>
                  <Right>
                    <span>
                      発注しない明細に
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      を入れる。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・変更数量</Left>
                  <Right>
                    <span>
                      発注数量を変更する明細のみ変更数量に数量を入力する。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入力内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確 定
                      </TextSpan>
                      をクリックし、入力内容を確定します。
                    </p>
                  </Right>
                </SectionRow>
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">発注入力確認画面</span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalInputStockRefillOrder}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>発注確認</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・発注します</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['createStockRefillReceive'] = el)}
          id="CreateStockRefillReceive"
        >
          <TopTitle className="rounded">
            <SubNumber>11</SubNumber>
            <SubText>入荷入力</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">入荷データの入力を行います。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">入荷入力画面 （新規モード）</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.createStockRefillReceive}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>新規モード</TextSpan>
                  </TableCol>
                  <TableCol>新規モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>呼出モード</TextSpan>
                  </TableCol>
                  <TableCol>呼出モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確 定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  入荷情報を新規登録する。
                </SectionRow>
                <SectionRow className="container">
                  <Left>・モード選択</Left>
                  <Right>
                    新規モードにします。
                    <p>
                      <TextSpan code>新規モード</TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・仕入先</Left>
                  <Right>
                    <span>仕入先選択します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷日</Left>
                  <Right>
                    <span>入荷日を入力します。</span>
                    <span>システム日付をデフォルトで設定します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・品名CD</Left>
                  <Right>
                    <span>
                      品名コードを入力すると品名とロケーションを取得して表示します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        ※品名コードと仕入先の組み合わせが誤っていたらエラーとします。
                      </span>
                      <span className="text">
                        エクセルからコードして、貼り付けすることが出来ます。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷数</Left>
                  <Right>
                    <span>入荷数を入力します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        エクセルからコードして、貼り付けすることが出来ます。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、指定されたデータを確定します。
                    </p>
                  </Right>
                </SectionRow>
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">確認画面（新規モード）</span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalCreateStockRefillReceive}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>入荷確認</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・入荷の確認 </Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
          <br />
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">入荷入力画面 （呼出モード）</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.updateStockRefillReceive}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan code>新規モード</TextSpan>
                  </TableCol>
                  <TableCol>新規モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>呼出モード</TextSpan>
                  </TableCol>
                  <TableCol>呼出モード切替</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確 定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  発注残から入荷情報を入力する。
                </SectionRow>
                <SectionRow className="container">
                  <Left>・モード選択</Left>
                  <Right>
                    呼出モードにします。
                    <p>
                      <TextSpan code>呼出モード</TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷日</Left>
                  <Right>
                    <span>入荷日を入力します。</span>
                    <span>システム日付をデフォルトで設定します。</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷保留</Left>
                  <Right>
                    <span>
                      入荷保留に
                      <span className="text-left-color">
                        <CheckSquareOutlined />
                      </span>
                      を入れると入荷の対象外にします。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・入荷数</Left>
                  <Right>
                    <span>
                      発注残と異なる入荷数の場合だけ入荷数量を入力します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        入荷数は発注数より大きく入力する事ができません。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内容の確定 </Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、指定されたデータを確定します。
                    </p>
                  </Right>
                </SectionRow>
                <br />
                <br />
                <Title>
                  <Polygon />
                  <span className="text">確認画面 （呼出モード）</span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalUpdateStockRefillReceive}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          登録します
                        </TextSpan>
                      </TableCol>
                      <TableCol>入荷確認</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>訂正します（戻る）</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・入荷の確認</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        登録します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['backlogDeletion'] = el)}
          id="BacklogDeletion"
        >
          <TopTitle className="rounded">
            <SubNumber>12</SubNumber>
            <SubText>注残削除処理</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">発注データの削除を行います。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">注残削除処理画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.backlogDeletion}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      確 定
                    </TextSpan>
                  </TableCol>
                  <TableCol>入力内容確定</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  登録番号と品名コードの指定で削除する。
                </SectionRow>
                <SectionRow className="container">
                  <Left>・処理区分</Left>
                  <Right>
                    <span>
                      指定削除を選択
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・登録番号</Left>
                  <Right>
                    <span>登録番号を入力します。（必須入力）</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・品名コード</Left>
                  <Right>
                    <span>品名コードを入力します。（任意入力）</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、 指定されたデータを確定します。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">日付で削除する。</SectionRow>
                <SectionRow className="container">
                  <Left>・処理区分</Left>
                  <Right>
                    <span>
                      日付で削除を選択
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・発注日</Left>
                  <Right>
                    <span>発注日を入力します。（必須項目）</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left></Left>
                  <Right>
                    <div className="flex">
                      <span className="red">（注）</span>
                      <span className="text">
                        日付で削除を選択すると無条件で発注データを削除します。
                      </span>
                      <span className="text">
                        間違えて日付を入力すると全部消えますので注意してください。
                      </span>
                    </div>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・内容の確定</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        確定
                      </TextSpan>
                      をクリックし、指定されたデータを確定します。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
              <br />
              <StyledDiv>
                <Title>
                  <Polygon />
                  <span className="text">確認画面</span>
                </Title>
                <Main>
                  <img
                    className="left"
                    src={images.modalBacklogDeletion}
                    width={700}
                    alt=""
                  />
                  <Table className="table">
                    <TableHeader>主なボタン</TableHeader>
                    <TableRow>
                      <TableCol>
                        <TextSpan className="primary" code>
                          削除します
                        </TextSpan>
                      </TableCol>
                      <TableCol>注残削除実行</TableCol>
                    </TableRow>
                    <TableRow>
                      <TableCol>
                        <TextSpan code>戻る</TextSpan>
                      </TableCol>
                      <TableCol>画面閉じる</TableCol>
                    </TableRow>
                  </Table>
                </Main>
                <SectionTitle className="heading">■操作方法■</SectionTitle>
                <SectionRow className="container">
                  <Left>・注残の削除</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        削除します
                      </TextSpan>
                      をクリックします。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        {/* Module 5 */}
        <Section
          ref={el => (manualRef.current['stockInquiry'] = el)}
          id="StockInquiry"
        >
          <TopTitle className="rounded">
            <SubNumber>13</SubNumber>
            <SubText>在庫問合せ</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">在庫問合せを行います。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">在庫問合せ画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.stockInquiry}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      呼出し
                    </TextSpan>
                  </TableCol>
                  <TableCol>検索実行</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>EXCEL出力</TextSpan>
                  </TableCol>
                  <TableCol>エクセル出力</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>画面クリア</TextSpan>
                  </TableCol>
                  <TableCol>入力内容クリア</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  品名コードで検索する。
                </SectionRow>
                <SectionRow className="container">
                  <Left>・検索対象</Left>
                  <Right>
                    <span>
                      品名コードを選択
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・品名コード</Left>
                  <Right>
                    <span>品名コードを入力します。（必須入力）</span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・検索実行</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        呼出し
                      </TextSpan>
                      をクリックし、検索結果を表示ます。
                    </p>
                    <p>
                      <TextSpan code>EXCEL出力</TextSpan>
                      をクリックし、検索結果をダウンロードされます。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="round">
                  品名の一部で検索する。
                </SectionRow>
                <SectionRow className="container">
                  <Left>・検索対象</Left>
                  <Right>
                    <span>
                      品名を選択
                      <span className="text-right-color">
                        <RadioButton />
                      </span>
                      します。
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・品名</Left>
                  <Right>
                    <span>
                      品名を入力します。（
                      入力しない場合は全ての在庫を検索実行します ）
                    </span>
                  </Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・検索実行</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        呼出し
                      </TextSpan>
                      をクリックし、検索結果を表示ます。
                    </p>
                    <p>
                      <TextSpan code>EXCEL出力</TextSpan>
                      をクリックし、検索結果をダウンロードされます。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
        <Section
          ref={el => (manualRef.current['inventoryList'] = el)}
          id="InventoryList"
        >
          <TopTitle className="rounded">
            <SubNumber>14</SubNumber>
            <SubText>棚卸用一覧リスト</SubText>
          </TopTitle>
          <SubTitle className="content">
            <span>概 要</span>
            <span className="text">棚卸用一覧リストの出力を行います。</span>
          </SubTitle>
          <SubContent>
            <Title>
              <Polygon />
              <span className="text">棚卸用一覧リスト画面</span>
            </Title>
            <Main>
              <img
                className="left"
                src={images.inventoryList}
                width={700}
                alt=""
              />
              <Table className="table">
                <TableHeader>主なボタン</TableHeader>
                <TableRow>
                  <TableCol>
                    <TextSpan className="primary" code>
                      実 行
                    </TextSpan>
                  </TableCol>
                  <TableCol>エクセル出力</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>
                    <TextSpan code>メニューへ戻る</TextSpan>
                  </TableCol>
                  <TableCol>処理終了</TableCol>
                </TableRow>
              </Table>
            </Main>
            <SectionContent>
              <SectionTitle className="heading">■操作方法■</SectionTitle>
              <StyledDiv style={{ margin: '24px 0px' }}>
                <SectionRow className="container">
                  <Left>・棚卸日</Left>
                  <Right>システム日付をデフォルトで設定します。</Right>
                </SectionRow>
                <SectionRow className="container">
                  <Left>・実 行</Left>
                  <Right>
                    <p>
                      <TextSpan className="primary" code>
                        実 行
                      </TextSpan>
                      をクリックし、棚卸用一覧リストをダウンロードされます。
                    </p>
                  </Right>
                </SectionRow>
              </StyledDiv>
            </SectionContent>
          </SubContent>
        </Section>
      </ManualContent>
    </ManualContainer>
  );
};
export default Manual;
