export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No.</div>,
    dataIndex: 'id',
    key: 'id',
    render: (_value: any) => (
      <div style={{ minWidth: 30, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>発注日</div>,
    dataIndex: 'hachubi',
    key: 'hachubi',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 65 }}>登録番号</div>,
    dataIndex: 'cpuNo',
    key: 'cpuNo',
    render: (_value: any) => (
      <div style={{ minWidth: 65, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 55 }}>注文番号</div>,
    dataIndex: 'chuNo2',
    key: 'chuNo2',
    render: (_value: any) => (
      <div style={{ minWidth: 55, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 55 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 55, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 150 }}>品名</div>,
    dataIndex: 'kanm',
    key: 'kanm',
    render: (_value: any) => (
      <div style={{ minWidth: 150, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>ｹｰｽ数</div>,
    dataIndex: 'kSuu',
    key: 'kSuu',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>入数</div>,
    dataIndex: 'hIrisu',
    key: 'hIrisu',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>発注数</div>,
    dataIndex: 'hSuu',
    key: 'hSuu',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 50 }}>注残数</div>,
    dataIndex: 'hachuzan',
    key: 'hachuzan',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 50, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷日１</div>,
    dataIndex: 'nyukabi1',
    key: 'nyukabi1',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷数１</div>,
    dataIndex: 'nyukasu1',
    key: 'nyukasu1',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },

  {
    title: () => <div style={{ width: 60 }}>入荷日２</div>,
    dataIndex: 'nyukabi2',
    key: 'nyukabi2',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷数２</div>,
    dataIndex: 'nyukasu2',
    key: 'nyukasu2',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷日３</div>,
    dataIndex: 'nyukabi3',
    key: 'nyukabi3',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷数３</div>,
    dataIndex: 'nyukasu3',
    key: 'nyukasu3',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷日４</div>,
    dataIndex: 'nyukabi4',
    key: 'nyukabi4',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ width: 60 }}>入荷数４</div>,
    dataIndex: 'nyukasu4',
    key: 'aaa',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
];

export const columnsSum = [
  {
    title: () => <div style={{ minWidth: 40 }}>No.</div>,
    dataIndex: 'id',
    key: 'id',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 300 }}>品名CD</div>,
    dataIndex: 'code24',
    key: 'code24',
    render: (_value: any) => (
      <div style={{ minWidth: 300, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 200 }}>登録ＮＯ</div>,
    dataIndex: 'sumHachuzan',
    key: 'sumHachuzan',
    render: (_value: any) => (
      <div style={{ minWidth: 200, width: 'max-content', textAlign: 'right' }}>
        {_value}
      </div>
    ),
  },
];
