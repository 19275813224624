import { Space } from 'antd';
import styled from 'styled-components';

export const MenuContainer = styled(Space)`
  width: 100%;
  max-width: 990px;
  .ant-space-item {
    width: 100%;
  }
`;

export const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`;
