import { Space, Table } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-btn {
    font-weight: 500;
  }
  button {
    min-width: 100px;
  }
`;

export const Header = styled.div`
  width: 990px;
`;

export const Footer = styled(Space)`
  margin-top: 50px;
  width: 990px;
  justify-content: flex-end;
`;

export const BodyContent = styled.div`
  min-height: 280px;
`;

export const CalendarTable = styled(Table)`
  border-color: #9e9e9e !important;
  .ant-table-row {
    &.ant-table-row:first-child {
      background-color: #b6cbff;
      .ant-table-cell {
        background-color: #b6cbff;
        border-top: 1px solid #9e9e9e;
        line-height: 2;
      }
    }
    &.ant-table-row:nth-child(2) {
      background-color: #b6cbff;
      .ant-table-cell {
        background-color: #b6cbff;
      }
    }
    .ant-table-cell {
      padding: 0px !important;
      font-size: 14px;
      font-weight: 600;
      min-width: 38px;
      border-color: #9e9e9e !important;
      background-color: unset !important;
      &.ant-table-cell:first-child {
        background-color: #b6cbff !important;
        border-left: 1px solid #9e9e9e;
        white-space: nowrap;
        padding: 3px 10px !important;
      }
      .ant-form-item {
        margin: 0;
        .ant-form-item-explain {
          display: none;
        }
        input {
          font-size: 14px;
          border: 1px solid #d9d9d9;
          &:focus {
            border-color: #1928da;
            box-shadow: 0 0 0 2px rgba(25, 40, 218, 0.2);
          }
          &:hover:not(:disabled) {
            border-color: #1928da;
          }
        }
        .ant-checkbox-disabled {
          &:not(.ant-checkbox-checked) {
            .ant-checkbox-inner {
              background-color: rgba(239, 239, 239, 0.7);
            }
          }
          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: #c8c8c8;
              &::after {
                border-color: black;
                border-width: 2.5px;
              }
            }
          }
        }
      }
    }
  }
`;
