import { Button, Modal, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';

interface MessageProps {
  type: 'warning' | 'success' | 'error' | 'info' | 'question';
  content?: any;
  onOk?: any;
  onCancel?: any;
}

export const ContentMessage = ({ content, onOk, onCancel, type }: any) => {
  const refButton = React.useRef<any>({});

  const [breakPress, setBreakPress] = React.useState(false)
  React.useEffect(() => {
    let timeout = setTimeout(() => {
      refButton.current.btnOK.focus();
    }, 0);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span
        style={{
          whiteSpace: 'pre-line',
          lineHeight: '18px',
        }}
      >
        {content}
      </span>
      <div
        style={{
          marginTop: 16,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Space>
          <Button
            type="primary"
            ref={el => (refButton.current.btnOK = el)}
            style={{ width: 100 }}
            onClick={() => { setBreakPress(true); onOk() }}
            disabled={breakPress}
            onKeyDown={event => {
              if(event.key === 'Enter') {
                setBreakPress(true)
              }
              event.preventDefault();
              if (event.key === 'Tab') {
                refButton.current.btnCancel.focus();
              }
              if (event.key === 'Enter') {
                refButton.current.btnOK.click();
              }
            }}
          >
            OK
          </Button>
          {type === 'success' || type === 'error' || type === 'info' || (
            <Button
              ref={el => (refButton.current.btnCancel = el)}
              style={{ width: 100 }}
              onClick={onCancel}
              onKeyDown={event => {
                event.preventDefault();
                if (event.key === 'Tab') {
                  refButton.current.btnOK.focus();
                }
                if (event.key === 'Enter') {
                  refButton.current.btnCancel.click();
                }
              }}
            >
              キャンセル
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

export default function messagePopup({
  type,
  content,
  onOk = () => { },
  onCancel = () => { },
}: MessageProps) {
  switch (type) {
    case 'question':
      Modal.confirm({
        icon: <QuestionCircleOutlined />,
        className: 'message-popup',
        content: (
          <ContentMessage
            key={type}
            type={type}
            content={content}
            onOk={() => {
              Modal.destroyAll();
              onOk();
            }}
            onCancel={() => {
              Modal.destroyAll();
              onCancel();
            }}
          />
        ),
      });
      break;

    case 'warning':
      Modal.confirm({
        className: 'message-popup',
        content: (
          <ContentMessage
            key={type}
            type={type}
            content={content}
            onOk={() => {
              Modal.destroyAll();
              onOk();
            }}
            onCancel={() => {
              Modal.destroyAll();
              onCancel();
            }}
          />
        ),
      });
      break;

    case 'success':
      Modal.success({
        className: 'message-popup',
        content: (
          <ContentMessage
            key={type}
            type={type}
            content={content}
            onOk={() => {
              Modal.destroyAll();
              onOk();
            }}
            onCancel={() => {
              Modal.destroyAll();
              onCancel();
            }}
          />
        ),
      });
      break;

    case 'error':
      Modal.error({
        className: 'message-popup',
        content: (
          <ContentMessage
            key={type}
            type={type}
            content={content}
            onOk={() => {
              Modal.destroyAll();
              onOk();
            }}
            onCancel={() => {
              Modal.destroyAll();
              onCancel();
            }}
          />
        ),
      });
      break;

    case 'info':
      Modal.info({
        className: 'message-popup',
        content: (
          <ContentMessage
            key={type}
            type={type}
            content={content}
            onOk={() => {
              Modal.destroyAll();
              onOk();
            }}
            onCancel={() => {
              Modal.destroyAll();
              onCancel();
            }}
          />
        ),
      });

      break;
    default:
      break;
  }
}
