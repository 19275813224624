import { PATH } from 'configs/routes';
import moment from 'moment';
import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDefinedValues,
  selectServerTime,
  setServerTime,
} from 'slice/app';
import { Flex } from './layout.style';

interface Props {
  pathname: string;
}
const Timer = ({ pathname }: Props) => {
  const serverTime = useSelector(selectServerTime);
  const defindValue = useSelector(selectDefinedValues);
  const intervalRef = useRef<any>();
  const dispatch = useDispatch();

  const pathnameHideTimer = [PATH.LOGIN, PATH.ORDER_MANUAL];
  useEffect(() => {
    let serverTime = moment(defindValue?.serverTime?.split('+')[0]);
    intervalRef.current = setInterval(() => {
      serverTime = serverTime.add(1, 'seconds');
      dispatch(setServerTime(serverTime.format('YYYY/MM/DD(dd) HH:mm:ss')));
    }, 1000);
    return () => {
      // Avoid re-rendering
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defindValue?.serverTime]);

  return (
    <Flex className="time">
      {pathnameHideTimer.includes(pathname) || <span>日時：{serverTime}</span>}
    </Flex>
  );
};
export default memo(Timer);
