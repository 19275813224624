import styled from 'styled-components';

interface StyleProps {
  displayDropdown?: any;
}

export const DatePickerWrapper = styled.div<StyleProps>`
  .ant-picker-suffix {
    pointer-events: unset;
  }
  .ant-picker-dropdown {
    display: ${props =>
      props?.displayDropdown ? props.displayDropdown : 'none'};
  }
`;
