import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';

const { Dragger } = Upload;

const props = {
  name: 'file',
  multiple: true,

  onDrop(_e: any) {},

  beforeUpload(_file: any) {
    const isLt10M = _file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('添付ファイルのサイズが最大値(10MB)を越えています');
    }
    return false;
  },
};

const DraggerUpload = ({ fileList, setFileList }: any) => (
  <Dragger
    style={{ width: '210mm' }}
    {...props}
    onChange={({ fileList }) => {
      const lst = fileList.filter(
        file => file.size && file.size / 1024 / 1024 < 10,
      );
      setFileList(lst);
    }}
    fileList={fileList}
    defaultFileList={fileList}
  >
    <p className="ant-upload-drag-icon" style={{ marginBottom: 8 }}>
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">ファイル選択</p>
    <p className="ant-upload-hint">
      単一アップロードまたは一括アップロードのサポート
    </p>
  </Dragger>
);

export default DraggerUpload;
