import { API_URL } from 'constants/api';
import { useMutation } from 'react-query';
import AxiosPost from 'utils/axios/axiosPost';
import AxiosGetFile from 'utils/axios/getFile/axiosGetFile';

interface TypeProps {
  binCd: string | null;
  nohinDt: string | null;
  n2Atesaki1: string | null;
  sendAdr: string | null;
  bunrui: string | null;
  hinmei: string | null;
}

export function useGetPreDelivery() {
  const getPre = async ({
    binCd,
    nohinDt,
    n2Atesaki1,
    sendAdr,
    bunrui,
    hinmei,
  }: TypeProps) => {
    const params = {
      binCd,
      nohinDt,
      n2Atesaki1,
      sendAdr,
      bunrui,
      hinmei,
    };
    return await AxiosPost(API_URL.GET_PREDELIVERY, params);
  };

  const mutation = useMutation(getPre as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });
  return mutation;
}

export function useGetPreDeliveryExcel() {
  const getPre = async ({
    binCd,
    nohinDt,
    n2Atesaki1,
    sendAdr,
    bunrui,
    hinmei,
  }: TypeProps) => {
    const params = {
      binCd,
      nohinDt,
      n2Atesaki1,
      sendAdr,
      bunrui,
      hinmei,
    };
    return await AxiosGetFile(API_URL.GET_PREDELIVERY_EXCEL, params);
  };

  const mutation = useMutation(getPre as any, {
    onMutate: data => {},
    onSuccess: (data: any) => {},
    onError: (err: any, newUser: any, context?: any): Promise<any> | void => {
      // console.log(err.response.data.message);
    },
    onSettled: () => {},
  });

  return mutation;
}
