import { Input } from 'antd';
import { ToASCII } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import { useEffect, useRef, useState } from 'react';
import messagePopup from 'utils/message/mesage';

interface Props {
  setLstMeisai?: any;
  dfValue?: any;
  lstMeisai?: any;
  record?: any;
  form?: any;
  inputRef?: any;
  formRef?: any;
}

const InputBara = ({
  form,
  lstMeisai,
  setLstMeisai,
  dfValue,
  record,
  inputRef,
  formRef,
}: Props) => {
  const previousBara = useRef<any>();
  const [baraInput, setBaraInput] = useState<string | null>(null);

  function FocusInput() {
    inputRef.current[record.index].focus();
    inputRef.current[record.index].select();
  }

  const handleBara = (value: any) => {
    let updateArray: any;
    let suryo = value?.toString().replaceAll(',', '').trim() || '';
    if (suryo !== previousBara.current) {
      if (suryo) {
        if (!/^[0-9]+$/.test(suryo)) {
          let updateNouki = {
            ...record,
            uptBara: suryo,
            previousBara: suryo,
            errorBara: true,
            msgErrorBara: '変更数量' + MSG_ERROR['MSG_010'],
            focusBara: () => FocusInput(),
          };
          updateArray = lstMeisai?.map((u: any) =>
            u?.index !== updateNouki.index ? u : updateNouki,
          );
          messagePopup({
            type: 'info',
            content: '変更数量' + MSG_ERROR['MSG_010'],
            onOk: () => FocusInput(),
          });
        } else {
          let valueSuryo = Number(suryo);
          if (valueSuryo > record.bara) {
            let updateNouki = {
              ...record,
              uptBara: valueSuryo,
              previousBara: valueSuryo,
              errorBara: true,
              msgErrorBara: MSG_ERROR['MSG_OVER_BARA'],
              focusBara: () => FocusInput(),
            };
            updateArray = lstMeisai?.map((u: any) =>
              u?.index !== updateNouki.index ? u : updateNouki,
            );
            messagePopup({
              type: 'info',
              content: MSG_ERROR['MSG_OVER_BARA'],
              onOk: () => FocusInput(),
            });
          } else {
            let updateNouki = {
              ...record,
              uptBara: valueSuryo,
              previousBara: valueSuryo,
              errorBara: false,
              msgErrorBara: '',
              focusBara: () => FocusInput(),
            };
            updateArray = lstMeisai?.map((u: any) =>
              u?.index !== updateNouki.index ? u : updateNouki,
            );
          }
        }
      } else {
        let updateNouki = {
          ...record,
          uptBara: suryo,
          previousBara: suryo,
          errorBara: true,
          msgErrorBara: '変更数量' + MSG_ERROR['MSG_INPUT'],
          focusBara: () => FocusInput(),
        };
        updateArray = lstMeisai?.map((u: any) =>
          u?.index !== updateNouki.index ? u : updateNouki,
        );
        messagePopup({
          type: 'info',
          content: '変更数量' + MSG_ERROR['MSG_INPUT'],
          onOk: () => FocusInput(),
        });
      }
      setLstMeisai(updateArray);
    }
    setBaraInput(!isNaN(parseInt(suryo)) ? Number(suryo).toString() : suryo);
  };

  useEffect(() => {
    setBaraInput(dfValue?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dfValue]);

  return (
    <Input
      size="small"
      ref={el => (inputRef.current[Number(record.index)] = el)}
      style={{ textAlign: 'right', width: 100 }}
      maxLength={6}
      value={baraInput ?? ''}
      disabled={form.getFieldValue('confirmDel') === 'D'}
      onChange={e => {
        setBaraInput(e.target.value);
      }}
      onBlur={(event: any) => {
        event.preventDefault();
        let value = ToASCII(event.target.value);
        handleBara(value);
      }}
      onKeyUp={event =>
        (event.key === 'Enter' || event.key === 'Tab') &&
        event.currentTarget.select()
      }
      onFocus={event => {
        previousBara.current = event.target.value;
      }}
      onPressEnter={event => {
        event.preventDefault();
        if (inputRef.current[record.index + 1]) {
          inputRef.current[record.index + 1].focus();
        } else formRef.current.btnSubmit.focus();
      }}
    />
  );
};

export default InputBara;
