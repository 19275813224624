import { arrayMoveImmutable } from 'array-move';
import TableCustom from 'components/TableCommon';
import moment from 'moment';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const TableOsirase = ({
  columns,
  dataSource,
  setDataSource,
  disableSubmit,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      disableSubmit.current = false;

      const arr = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex)
        .filter(el => !!el)
        .reverse()
        .map((item, index) => ({ ...item, uptIndex: index }))
        .reverse();

      // // console.log('Sorted items: ', arr);
      setDataSource(arr);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key'],
    );
    let classTime = '';
    //	future		systemdate < datefrom
    if (moment(dataSource[index]?.dateFrom) > moment().startOf('day'))
      classTime = 'orirase-future';
    //	can see today	datefrom <= systemdate <= dateto
    if (
      moment(dataSource[index]?.dateFrom) <= moment().startOf('day') &&
      moment(dataSource[index]?.dateTo) >= moment().startOf('day')
    )
      classTime = 'orirase-present';
    //	past		dateto < systemdateAK6
    if (moment(dataSource[index]?.dateTo) < moment().startOf('day'))
      classTime = 'orirase-past';
    return <SortableItem index={index} className={classTime} {...restProps} />;
  };

  return (
    <TableCustom
      className={'table-osirase'}
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      height={'433px'}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default TableOsirase;
