import { DatePicker, Form, FormInstance, Input, Space } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { InputCheck, ToASCII } from 'constants/common';
import { MSG_ERROR } from 'constants/text';
import moment from 'moment';
import { useRef, useState } from 'react';
import messagePopup from 'utils/message/mesage';
import { SCInputDatePicker } from './DatepickerCommonV2.style';

const DatePickerCommonV2 = ({
  refForm,
  formName,
  nextFocus,
  placeholder,
  form,
  label,
  noStyle,
  hideDD,
  disabled,
  afterChangeValue,
  validateRules,
}: {
  refForm: React.MutableRefObject<any>;
  form: FormInstance<any>;
  formName: string;
  nextFocus: () => any;
  afterChangeValue: (vl: any) => any;
  placeholder: string;
  label: string;
  noStyle?: boolean;
  hideDD?: boolean;
  disabled?: boolean;
  validateRules?: (vl: any) => any;
}) => {
  const [valueDatePicker, setValueDatePicker] = useState<any>();
  const [open, setOpen] = useState(false);

  const refDatepicker = useRef<any>();
  const isFirstError = useRef(false);

  const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
    let vl = ToASCII(elem.target.value);
    form.setFieldsValue({
      [formName]: vl,
    });

    let value = moment(vl, 'YYMMDD');
    if (value.isValid() && !InputCheck(vl)) {
      setValueDatePicker(value);
      afterChangeValue(value);
    } else {
      if (vl && !isFirstError.current) {
        isFirstError.current = true;
        messagePopup({
          type: 'info',
          content: `${label || '入力日'}` + MSG_ERROR[InputCheck(vl) || ''],
          onOk: () => {
            refForm?.current[formName]?.focus();
            refForm?.current[formName]?.select();
          },
        });
      }
    }
  };

  return (
    <Space>
      <Form.Item
        noStyle
        name={formName}
        rules={[
          ({ getFieldValue }) => ({
            validator(_: any, value: string) {
              if (!disabled && value && !!InputCheck(value)) {
                return Promise.reject(
                  new Error(label + MSG_ERROR[InputCheck(value)]),
                );
              }
              if (!disabled && validateRules && validateRules(value)) {
                return Promise.reject(new Error(validateRules(value)));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <SCInputDatePicker
          ref={(el: any) => (refForm.current[formName] = el)}
          maxLength={6}
          disabled={disabled}
          onBlur={onBlur}
          onChange={() => (isFirstError.current = false)}
          onPressEnter={(event: any) => {
           nextFocus && nextFocus()
          }}
          placeholder={placeholder}
          suffix={
            <DatePicker
              disabled={disabled}
              inputReadOnly
              value={valueDatePicker}
              open={open}
              onFocus={event => {
                setOpen(true);
              }}
              ref={refDatepicker}
              onSelect={(value: any) => {
                setValueDatePicker(value);
                form.setFieldsValue({
                  [formName]: moment(value).format('YYMMDD'),
                });
                afterChangeValue(value);
              }}
              onOpenChange={(isOpen: any) => {
                if (!isOpen) {
                  setOpen(false);
                } else {
                  setOpen(true);
                }
              }}
              allowClear={false}
            />
          }
          // addonAfter={
          //   <DatePicker
          //     disabled={disabled}
          //     inputReadOnly
          //     value={valueDatePicker}
          //     open={open}
          //     onFocus={event => {
          //       setOpen(true);
          //     }}
          //     ref={refDatepicker}
          //     onSelect={(value: any) => {
          //       setValueDatePicker(value);
          //       form.setFieldsValue({
          //         [formName]: moment(value).format('YYMMDD'),
          //       });
          //       afterChangeValue(value);
          //     }}
          //     onOpenChange={(isOpen: any) => {
          //       if (!isOpen) {
          //         setOpen(false);
          //       } else {
          //         setOpen(true);
          //       }
          //     }}
          //     allowClear={false}
          //   />
          // }
        />
      </Form.Item>
      {hideDD || (
        <span>
          （{moment(form.getFieldValue(valueDatePicker)).format('dd')}）
        </span>
      )}
    </Space>
  );
};
export default DatePickerCommonV2;
