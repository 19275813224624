export const API_URL = Object.freeze({
  LOG_ACCESS: '/accesslog',
  LOGIN: '/auth/login',
  REFETCH_TOKEN: '/auth/refresh-token',
  GET_DEFINED_VALUE: '/customers/value',
  GET_KIGYO_TYPE_MASTER: '/customers/kigyotype',
  GET_KIGYO_GROUP_MASTER: '/customers/kigyogroup',
  GET_TENSYOH_MASTER: '/customers/tensyoh',
  GET_INFOR: '/customers/infor',
  GET_CUSTOMER: '/customers/search',
  GET_SYOHIN_MAINTE: '/syohin/infor',
  SYOHIN: '/syohin',
  GET_LIST_SYOHIN: '/syohin/list',
  GET_LIST_SYOHIN_CODE: '/syohin/code',
  GET_SYOHIN_INFOS: '/syohin/infos',
  GET_SYOHIN_INFOS_SHINKI: '/syohin/infos/shinki',
  CHECK_HAT_CODE_SYOHIN: '/syohin/checkhat',
  GET_HAN_INFO: '/han/info',
  GET_LISTBIN: '/han/binm',
  CUSTOMER: '/customers',
  GET_CALENDAR_DATA: '/calendars/search',
  UPDATE_INSERT_CALENDAR: '/calendars',
  GET_SIIREM: '/siirem',
  GET_SYOHINKBN: '/syohinkbn',
  POST_PUT_HAN: '/han',
  TANTOU: '/tantou',
  TANTOU_RESTORE: '/tantou/restore',
  DELETE_OTODOKESAKI: '/otodokesaki',
  DELETE_HAN_BINCDS: '/han',
  MASTER_CHECK: '/mastercheck',
  RESTORE_OTODOKESAKI: '/otodokesaki/restore',
  RESTORE_HAN: '/han/restore',
  GET_SYOHIN: '/syohin/info',
  GET_ORDER_HISTORY: '/orders/detail',
  GET_INQUIRY_HAT: 'inquiry/hat',
  GET_INQUIRY_HAT_EXCEL: 'inquiry/hat-export-excel',
  GET_MONITORDELIVERY: 'inquiry/delivery-by-bin',
  GET_MONITORDELIVERY_EXCEL: 'inquiry/delivery-by-bin-xlsx',
  GET_MONITORDELIVERYBYFLIGHT: 'inquiry/delivery-by-bin-pdf',
  NOTIFICATION: '/notification',
  NOTIFICATION_INFO: '/notification/info',
  NOTIFICATION_ORDER: '/notification/order',
  GET_SHIPPING_INFO: '/inquiry/shippinginfo',
  CHANGE_DENKBN_SHIPPING_INFO: '/inquiry/shippinginfo/change',
  REPRINT_SHIPPING: '/inquiry/reprint',
  RETURN_INPUT: '/inquiry/returninput',
  RETURN_INPUT_CHECK_ZAIKO: '/inquiry/returninput/check',
  GET_STOCK: '/stock',
  UPDATE_STOCK_PROCESS: '/stock/process',
  GET_STOCK_VIEW: '/stock/view/',
  GET_STOCK_VIEW_XLSX: '/stock/view-xlsx/',
  GET_STOCK_RECEIVE: '/stock/receive',
  UPDATE_STOCK_RECEIVE: '/stock/process/receive/youbi',
  CREATE_STOCK_RECEIVE: '/stock/process/receive/shinki',
  GET_STOCKCHECKLIST: '/stock/checklist',
  CHECK_STOCK_ZAIKO: '/stock/receive/shinki/check',
  BACKLOG_DELETION: '/stock/refill',
  GET_SHIPPINGINFORMATION: '/inquiry/shipping-detail',
  GET_SHIPPINGINFORMATION_EXCEL: '/inquiry/shipping-detail-xlsx',
  GET_SHIPPEDLINES: '/inquiry/shipping-lines-aggregation',
  GET_SHIPPEDLINES_EXCEL: '/inquiry/shipping-lines-aggregation-xlsx',
  GET_SHIPPINGTOTALBYPRODUCT: '/inquiry/shipping-aggregation-by-product',
  GET_SHIPPINGTOTALBYPRODUCT_EXCEL:
    '/inquiry/shipping-aggregation-by-product-xlsx',
  GET_STOCKDETAILS: '/inquiry/stock-details',
  GET_STOCKDETAILS_EXCEL: '/inquiry/stock-details-xlsx',
  GET_COLLECTINGGOODSBYPRODUCT: '/inquiry/moto-by-syohinm',
  GET_COLLECTINGGOODSBYPRODUCT_EXCEL: '/inquiry/moto-by-syohinm-xlsx',
  GET_COOPERATINGSTOREINFORMATION: '/inquiry/partnership-store',
  GET_COOPERATINGSTOREINFORMATION_EXCEL: '/inquiry/partnership-store-xlsx',
  GET_SHIPPINGDETAILSAGGREGATE: '/inquiry/product-aggregation-by-shipping',
  GET_SHIPPINGDETAILSAGGREGATE_EXCEL:
    '/inquiry/product-aggregation-by-shipping-xlsx',
  GET_VEHICLEALLOCATIONLIST: '/inquiry/delivery-list-by-bin',
  GET_VEHICLEALLOCATIONLIST_EXCEL: '/inquiry/delivery-list-by-bin-xlsx',
  GET_INVENTORYINFORMATION: '/inquiry/inventory-information',
  GET_INVENTORYINFORMATION_EXCEL: '/inquiry/inventory-information-xlsx',
  GET_STOCKINFORMATIONPRODUCT: '/inquiry/product-inventory-information',
  GET_STOCKINFORMATIONPRODUCT_EXCEL:
    '/inquiry/product-inventory-information-xlsx',
  GET_PARTNERSHIPSTORE: '/inquiry/partnerShip-store-no-flight',
  GET_PARTNERSHIPSTORE_EXCEL: '/inquiry/partnerShip-store-no-flight-xlsx',
  GET_SHIPPINGDETAILSINFORMATION: '/inquiry/shipping-detail-input-date-time',
  GET_SHIPPINGDETAILSINFORMATION_EXCEL:
    '/inquiry/shipping-detail-input-date-time-xlsx',
  GET_ORDERPROCESSINGCHECK: '/inquiry/order-processing-check',
  GET_ORDERPROCESSINGCHECK_EXCEL: '/inquiry/order-processing-check-xlsx',
  GET_LISTREVOKEDVOUCHERS_PDF: '/inquiry/revoked-voucher-check-list-pdf',
  GET_LISTREVOKEDVOUCHERS: '/inquiry/revoked-voucher-check-list',
  GET_LISTREVOKEDVOUCHERSFORSURVEY:
    '/inquiry/revoked-voucher-check-list-for-survey',
  GET_LISTREVOKEDVOUCHERSFORSURVEY_EXCEL:
    '/inquiry/revoked-voucher-check-list-for-survey-xlsx',
  GET_LISTREVOKEDVOUCHERS_EXCEL: '/inquiry/revoked-voucher-check-list-xlsx',
  GET_CHECKORDERBACKLOGDETAIL: '/inquiry/check-order-backlog/detail',
  GET_CHECKORDERBACKLOGDETAIL_EXCEL: '/inquiry/check-order-backlog-xlsx/detail',
  GET_CHECKORDERBACKLOGSUM: '/inquiry/check-order-backlog/sum-hachuzan',
  GET_CHECKORDERBACKLOGSUM_EXCEL:
    '/inquiry/check-order-backlog-xlsx/sum-hachuzan',
  GET_FLIGHTDISPATCHLIST: '/inquiry/delivery-list-by-bin-pdf',
  GET_CONFIRMLISTVOUCHER: '/inquiry/voucher-confirm-list-pdf',
  GET_SHIPPINGINFORMATIONFORREPORT: '/inquiry/shipping-detail-for-reporting',
  GET_SHIPPINGINFORMATIONFORREPORT_EXCEL:
    '/inquiry/shipping-detail-for-reporting-xlsx',
  GET_PREDELIVERY: '/inquiry/pre-delivery',
  GET_PREDELIVERY_EXCEL: '/inquiry/pre-delivery-xlsx',
});
