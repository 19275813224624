export const columns = [
  {
    title: () => <div style={{ minWidth: 30 }}>No</div>,
    dataIndex: 'id',
    key: 'id',
    align: 'right',
    render: (_value: any, record: any, index: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{index + 1}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 30 }}>処理</div>,
    dataIndex: 'srkub',
    key: 'srkub',
    render: (_value: any) => (
      <div style={{ minWidth: 30, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>発注日</div>,
    dataIndex: 'syodt',
    key: 'syodt',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 60 }}>登録ＮＯ</div>,
    dataIndex: 'trokno',
    key: 'trokno',
    render: (_value: any) => (
      <div style={{ minWidth: 60, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>注文番号</div>,
    dataIndex: 'chuno',
    key: 'chuno',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 80 }}>品名CD</div>,
    dataIndex: 'cd24',
    key: 'cd24',
    render: (_value: any) => (
      <div style={{ minWidth: 80, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 300 }}>品名</div>,
    dataIndex: 'anm',
    key: 'anm',
    render: (_value: any) => (
      <div style={{ minWidth: 300, width: 'max-content' }}>{_value}</div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>ｹｰｽ数</div>,
    dataIndex: 'case',
    key: 'case',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>
        {_value.toLocaleString()}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>入数</div>,
    dataIndex: 'dai',
    key: 'dai',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>
        {_value.toLocaleString()}
      </div>
    ),
  },
  {
    title: () => <div style={{ minWidth: 40 }}>数量</div>,
    dataIndex: 'suu',
    key: 'suu',
    align: 'right',
    render: (_value: any) => (
      <div style={{ minWidth: 40, width: 'max-content' }}>
        {_value.toLocaleString()}
      </div>
    ),
  },
];
